/* @flow */

export enum SortKind {
  Chronological,
  Default,
}

export enum SortDirection {
  Ascendant,
  Descendant,
}

export type SortAndFilterType = {|
  direction: SortDirection,
  filter: string,
  kind: SortKind,
|};
