/* @flow */

import { checkUpdatePageTitle, cleanLanguageList } from './helper';
import Backend from 'i18next-xhr-backend';
import type { KeyValuePair } from '../types';
import i18nInstance from 'i18next';
import { initReactI18next } from 'react-i18next';

class Localizer {
  static instance: any;

  static language: string;

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'Localizer';
  }

  static initialize: (language: string, translations: { [string]: any }) => Promise<any> = (language, translations) => {
    if (Localizer.instance) {
      return Promise.resolve();
    }

    Localizer.language = language;

    // Ensure that the page title is in the right language
    checkUpdatePageTitle(language);

    const resources: KeyValuePair<{| translation: any |}> = {};
    Object.entries(translations).forEach(([lang, file]) => {
      resources[lang] = { translation: file };
    });

    return i18nInstance
      .use(initReactI18next)
      .use(Backend)
      .init({
        debug: false,
        defaultNS: 'translation',
        interpolation: {
          escapeValue: false,
        },
        keySeparator: '.',
        lng: language,
        ns: ['translation'],
        resources,
      })
      .then(() => {
        Localizer.instance = i18nInstance;
      });
  };

  static getInstance: () => any = () => Localizer.instance;

  static localize: (key: string, options: any) => string = (key, options) =>
    Localizer.instance
      .t(key, options)
      .replace(/(?: )([:!?-])/giu, '\u00A0$1')
      .replace(/_/giu, '\u00A0');

  /*
   * What this function does:
   *  - Takes all languages defined in browser
   *  - Only keeps languages supported by application
   *  - If resulting list is empty, returns supported languages
   *  - Preserves order
   */
  static getAppLanguages: (supportedLanguages: $ReadOnlyArray<string>) => Array<string> = (supportedLanguages) => {
    const { language, languages } = navigator;
    const browserLanguages = cleanLanguageList(languages && languages.length ? languages : [language]);
    const appLanguages = browserLanguages.filter((lang) => supportedLanguages.includes(lang));

    // Add supported languages if they are missing (not among the browser languages)
    supportedLanguages.forEach((lang) => {
      if (!appLanguages.includes(lang)) {
        appLanguages.push(lang);
      }
    });

    return appLanguages;
  };

  /*
   * Find given key in locales and return, by order of priority:
   *  - localized string for current language
   *  - default value, if given
   *  - first value, if present
   */
  static getLocalizedStringFromAppConf: (locales: ?{| [string]: Array<{| lang: string, value: string |}> |}, key: string, defaultValue?: string) => string = (locales, key, defaultValue) => {
    let firstValue: string | null = null;

    if (locales) {
      const { [key]: allStrings } = locales;

      if (allStrings && allStrings.length > 0) {
        const localizedString = allStrings.find(({ lang }) => lang === Localizer.language);

        if (localizedString?.value) {
          // Return value for current language
          return localizedString.value;
        }

        firstValue = allStrings[0].value ?? null;
      }
    }

    // Return default value or first value
    return defaultValue || firstValue || `***missing localized string for ${key}***`;
  };
}

module.exports = {
  Localizer,
};
