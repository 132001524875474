/* @flow */

import type { NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_HUB } from '../../../libs/netgemLibrary/v8/types/Hub';
import type { NETGEM_API_V8_LANG } from '../../../libs/netgemLibrary/v8/types/Lang';

export enum AvenueType {
  Explore,
  Myvideos,
  Other,
  Regular,
  Search,
}

export type BASE_AVENUE_TYPE = {|
  index: number,
  type: AvenueType,
|};

export type NAVIGATION_AVENUE_TYPE = {|
  ...BASE_AVENUE_TYPE,
  title: Array<NETGEM_API_V8_LANG>,
|};

export type FOCUSED_AVENUE_TYPE = {|
  ...BASE_AVENUE_TYPE,
  hub?: NETGEM_API_V8_HUB,
  hubItem?: NETGEM_API_V8_FEED_ITEM,
  searchString?: string,
|};
