/* @flow */

import type { NETGEM_API_V8_SCHEDULED_RECORDING, NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION_SERIES, NETGEM_SCHEDULED_RECORDINGS_MAP } from '../../libs/netgemLibrary/v8/types/Npvr';
import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import type { Undefined } from '@ntg/utils/dist/types';

const isSeriesScheduledRecordingRunning: (seriesScheduledRecording: NETGEM_API_V8_SCHEDULED_RECORDING) => boolean = (seriesScheduledRecording) => {
  if (seriesScheduledRecording) {
    const { description } = seriesScheduledRecording;
    const { toUtc } = ((description: any): NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION_SERIES);
    if (!toUtc || new Date(toUtc) > AccurateTimestamp.nowAsDate()) {
      return true;
    }
  }

  return false;
};

const hasRunningSeriesScheduledRecording: (seriesId: Undefined<string>, npvrScheduledRecordingsList: NETGEM_SCHEDULED_RECORDINGS_MAP) => boolean = (seriesId, npvrScheduledRecordingsList) => {
  if (seriesId) {
    const { [seriesId]: seriesScheduledRecording } = npvrScheduledRecordingsList;
    return isSeriesScheduledRecordingRunning(seriesScheduledRecording);
  }

  return false;
};

export { hasRunningSeriesScheduledRecording, isSeriesScheduledRecordingRunning };
