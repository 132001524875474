/* @flow */

import { APPLICATION_ID } from './types';
import animationDataFrCh from '@ntg/francechannel/dist/assets/lottie-loader.json';
import animationDataGaumont from '@ntg/gaumont/dist/assets/lottie-loader.json';
import animationDataMyVF from '@ntg/myvideofutur/dist/assets/lottie-loader.json';
import animationDataPost from '@ntg/post/dist/assets/lottie-loader.json';
import animationDataPremiere from '@ntg/premiere/dist/assets/lottie-loader.json';
import animationDataViva from '@ntg/viva/dist/assets/lottie-loader.json';
import animationDataZeopTV from '@ntg/zeoptv/dist/assets/lottie-loader.json';
import backgroundImageGaumont from '@ntg/gaumont/dist/assets/images/tiled-background.jpg';
import backgroundImageMyVF from '@ntg/myvideofutur/dist/assets/images/tiled-background.jpg';
import backgroundImagePost from '@ntg/post/dist/assets/images/tiled-background.jpg';
import backgroundImagePremiere from '@ntg/premiere/dist/assets/images/tiled-background.jpg';
import backgroundImageViva from '@ntg/viva/dist/assets/images/tiled-background.jpg';
import headerLogoFrCh from '@ntg/francechannel/dist/assets/images/header-logo.svg';
import headerLogoGaumont from '@ntg/gaumont/dist/assets/images/header-logo.svg';
import headerLogoMyVF from '@ntg/myvideofutur/dist/assets/images/header-logo.svg';
import headerLogoPost from '@ntg/post/dist/assets/images/header-logo.svg';
import headerLogoPremiere from '@ntg/premiere/dist/assets/images/header-logo.svg';
import headerLogoViva from '@ntg/viva/dist/assets/images/header-logo.svg';
import headerLogoZeopTV from '@ntg/zeoptv/dist/assets/images/header-logo.svg';
import mobileAppLogoFrCh from '@ntg/francechannel/dist/assets/images/mobile-app-logo.svg';
import mobileAppLogoGaumont from '@ntg/gaumont/dist/assets/images/mobile-app-logo.svg';
import mobileAppLogoMyVF from '@ntg/myvideofutur/dist/assets/images/mobile-app-logo.svg';
import mobileAppLogoPost from '@ntg/post/dist/assets/images/mobile-app-logo.svg';
import mobileAppLogoPremiere from '@ntg/premiere/dist/assets/images/mobile-app-logo.svg';
import mobileAppLogoViva from '@ntg/viva/dist/assets/images/mobile-app-logo.svg';
import mobileAppLogoZeopTV from '@ntg/zeoptv/dist/assets/images/mobile-app-logo.svg';
import storeLogoAppleFrCh from '@ntg/francechannel/dist/assets/images/store-logo-apple-en.svg';
import storeLogoAppleGaumont from '@ntg/gaumont/dist/assets/images/store-logo-apple-fr.svg';
import storeLogoAppleMyVF from '@ntg/myvideofutur/dist/assets/images/store-logo-apple-fr.svg';
import storeLogoApplePost from '@ntg/post/dist/assets/images/store-logo-apple-fr.svg';
import storeLogoApplePremiere from '@ntg/premiere/dist/assets/images/store-logo-apple-fr.svg';
import storeLogoAppleViva from '@ntg/viva/dist/assets/images/store-logo-apple-fr.svg';
import storeLogoAppleZeopTV from '@ntg/zeoptv/dist/assets/images/store-logo-apple-fr.svg';
import storeLogoGoogleFrCh from '@ntg/francechannel/dist/assets/images/store-logo-google-en.svg';
import storeLogoGoogleGaumont from '@ntg/gaumont/dist/assets/images/store-logo-google-fr.svg';
import storeLogoGoogleMyVF from '@ntg/myvideofutur/dist/assets/images/store-logo-google-fr.svg';
import storeLogoGooglePost from '@ntg/post/dist/assets/images/store-logo-google-fr.svg';
import storeLogoGooglePremiere from '@ntg/premiere/dist/assets/images/store-logo-google-fr.svg';
import storeLogoGoogleViva from '@ntg/viva/dist/assets/images/store-logo-google-fr.svg';
import storeLogoGoogleZeopTV from '@ntg/zeoptv/dist/assets/images/store-logo-google-fr.svg';

const getAppCss: () => Promise<any> = () => {
  switch (process.env.REACT_APP_ID) {
    case APPLICATION_ID.FranceChannel:
      return import('@ntg/francechannel/dist/themes/base.css');
    case APPLICATION_ID.Gaumont:
      return import('@ntg/gaumont/dist/themes/base.css');
    case APPLICATION_ID.MyVideofutur:
      return import('@ntg/myvideofutur/dist/themes/base.css');
    case APPLICATION_ID.Post:
      return import('@ntg/post/dist/themes/base.css');
    case APPLICATION_ID.Premiere:
      return import('@ntg/premiere/dist/themes/base.css');
    case APPLICATION_ID.Viva:
      return import('@ntg/viva/dist/themes/base.css');
    case APPLICATION_ID.ZeopTV:
      return import('@ntg/zeoptv/dist/themes/base.css');
    default:
      // No app-specific CSS
      return Promise.resolve();
  }
};

const createAppFooterContent: () => Promise<any> = () => {
  switch (process.env.REACT_APP_ID) {
    case APPLICATION_ID.FranceChannel:
      return import('@ntg/francechannel/dist/components/footer/FooterContent').then((module) => module.default);
    case APPLICATION_ID.Gaumont:
      return import('@ntg/gaumont/dist/components/footer/FooterContent').then((module) => module.default);
    case APPLICATION_ID.MyVideofutur:
      return import('@ntg/myvideofutur/dist/components/footer/FooterContent').then((module) => module.default);
    case APPLICATION_ID.Post:
      return import('@ntg/post/dist/components/footer/FooterContent').then((module) => module.default);
    case APPLICATION_ID.Premiere:
      return import('@ntg/premiere/dist/components/footer/FooterContent').then((module) => module.default);
    case APPLICATION_ID.Viva:
      return import('@ntg/viva/dist/components/footer/FooterContent').then((module) => module.default);
    case APPLICATION_ID.ZeopTV:
      return import('@ntg/zeoptv/dist/components/footer/FooterContent').then((module) => module.default);
    default:
      // No footer
      return Promise.resolve(null);
  }
};

const getAppLottieLoader: () => any = () => {
  switch (process.env.REACT_APP_ID) {
    case APPLICATION_ID.FranceChannel:
      return animationDataFrCh;
    case APPLICATION_ID.Gaumont:
      return animationDataGaumont;
    case APPLICATION_ID.MyVideofutur:
      return animationDataMyVF;
    case APPLICATION_ID.Post:
      return animationDataPost;
    case APPLICATION_ID.Premiere:
      return animationDataPremiere;
    case APPLICATION_ID.Viva:
      return animationDataViva;
    case APPLICATION_ID.ZeopTV:
      return animationDataZeopTV;
    default:
      // No loading animation
      return null;
  }
};

const createAppHeaderLogo: () => string | null = () => {
  switch (process.env.REACT_APP_ID) {
    case APPLICATION_ID.FranceChannel:
      return headerLogoFrCh;
    case APPLICATION_ID.Gaumont:
      return headerLogoGaumont;
    case APPLICATION_ID.MyVideofutur:
      return headerLogoMyVF;
    case APPLICATION_ID.Post:
      return headerLogoPost;
    case APPLICATION_ID.Premiere:
      return headerLogoPremiere;
    case APPLICATION_ID.Viva:
      return headerLogoViva;
    case APPLICATION_ID.ZeopTV:
      return headerLogoZeopTV;
    default:
      // No header logo
      return null;
  }
};

const createAppMobileAppLogo: () => string | null = () => {
  switch (process.env.REACT_APP_ID) {
    case APPLICATION_ID.FranceChannel:
      return mobileAppLogoFrCh;
    case APPLICATION_ID.Gaumont:
      return mobileAppLogoGaumont;
    case APPLICATION_ID.MyVideofutur:
      return mobileAppLogoMyVF;
    case APPLICATION_ID.Post:
      return mobileAppLogoPost;
    case APPLICATION_ID.Premiere:
      return mobileAppLogoPremiere;
    case APPLICATION_ID.Viva:
      return mobileAppLogoViva;
    case APPLICATION_ID.ZeopTV:
      return mobileAppLogoZeopTV;
    default:
      // No logo for mobile app links page
      return null;
  }
};

const createAppAppleStoreLogo: () => string | null = () => {
  switch (process.env.REACT_APP_ID) {
    case APPLICATION_ID.FranceChannel:
      return storeLogoAppleFrCh;
    case APPLICATION_ID.Gaumont:
      return storeLogoAppleGaumont;
    case APPLICATION_ID.MyVideofutur:
      return storeLogoAppleMyVF;
    case APPLICATION_ID.Post:
      return storeLogoApplePost;
    case APPLICATION_ID.Premiere:
      return storeLogoApplePremiere;
    case APPLICATION_ID.Viva:
      return storeLogoAppleViva;
    case APPLICATION_ID.ZeopTV:
      return storeLogoAppleZeopTV;
    default:
      // No logo for Apple mobile app
      return null;
  }
};

const createAppGoogleStoreLogo: () => string | null = () => {
  switch (process.env.REACT_APP_ID) {
    case APPLICATION_ID.FranceChannel:
      return storeLogoGoogleFrCh;
    case APPLICATION_ID.Gaumont:
      return storeLogoGoogleGaumont;
    case APPLICATION_ID.MyVideofutur:
      return storeLogoGoogleMyVF;
    case APPLICATION_ID.Post:
      return storeLogoGooglePost;
    case APPLICATION_ID.Premiere:
      return storeLogoGooglePremiere;
    case APPLICATION_ID.Viva:
      return storeLogoGoogleViva;
    case APPLICATION_ID.ZeopTV:
      return storeLogoGoogleZeopTV;
    default:
      // No logo for Android mobile app
      return null;
  }
};

const createAppBackgroundImage: () => string | null = () => {
  switch (process.env.REACT_APP_ID) {
    case APPLICATION_ID.Gaumont:
      return backgroundImageGaumont;
    case APPLICATION_ID.MyVideofutur:
      return backgroundImageMyVF;
    case APPLICATION_ID.Post:
      return backgroundImagePost;
    case APPLICATION_ID.Premiere:
      return backgroundImagePremiere;
    case APPLICATION_ID.Viva:
      return backgroundImageViva;
    case APPLICATION_ID.FranceChannel:
    case APPLICATION_ID.ZeopTV:
    default:
      // No background image
      return null;
  }
};

const createAppHasHeaderCloseButton: () => boolean = () => {
  switch (process.env.REACT_APP_ID) {
    case APPLICATION_ID.Gaumont:
    case APPLICATION_ID.Premiere:
    case APPLICATION_ID.Post:
    case APPLICATION_ID.Viva:
      return true;
    case APPLICATION_ID.FranceChannel:
    case APPLICATION_ID.MyVideofutur:
    case APPLICATION_ID.ZeopTV:
    default:
      return false;
  }
};

const AppAppleStoreLogo: string | null = createAppAppleStoreLogo();
const AppGoogleStoreLogo: string | null = createAppGoogleStoreLogo();
const AppFooterContent: Promise<any> = createAppFooterContent();
const AppHasHeaderCloseButton: boolean = createAppHasHeaderCloseButton();
const AppHeaderLogo: string | null = createAppHeaderLogo();
const AppMobileAppLogo: string | null = createAppMobileAppLogo();
const AppBackgroundImage: string | null = createAppBackgroundImage();

export { AppAppleStoreLogo, AppBackgroundImage, AppFooterContent, AppGoogleStoreLogo, AppHasHeaderCloseButton, AppHeaderLogo, AppMobileAppLogo, getAppCss, getAppLottieLoader };
