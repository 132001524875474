/* @flow */

import { BO_API_STOP_STREAM, type BO_RESPONSE_WITH_ETAG } from './types/common';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_BO_STREAM_STOP } from '../../constants';
import getRequestDetails from './helpers/requestCommon';

const sendStreamStopRequest: (distributorId: string, streamId: number, bookmark?: string, signal?: AbortSignal, keepAlive?: boolean) => RequestResponseMethodDefinitionType =
  (distributorId, streamId, bookmark, signal, keepAlive) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration: { useBOV2Api },
    } = getState();

    return dispatch(getRequestDetails(distributorId, BO_API_STOP_STREAM, useBOV2Api))
      .then((requestDetails) =>
        // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
        NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_STREAM_STOP, {
          ...requestDetails,
          bookmark,
          keepAlive,
          signal,
          streamId,
          useBOV2Api,
        }),
      )
      .then(({ response }: BO_RESPONSE_WITH_ETAG) => response);
  };

export default sendStreamStopRequest;
