/* @flow */

import type { BasicFunction } from '@ntg/utils/dist/types';

export default class CancellablePromise {
  abortHandler: BasicFunction | null;

  abortHandlerController: AbortController | null;

  promise: Promise<any>;

  constructor(promise: Promise<any>, signal: ?AbortSignal) {
    this.abortHandler = null;

    if (!signal) {
      // No signal
      this.promise = promise;
      return;
    }

    if (signal.aborted) {
      // Signal already aborted

      // $FlowFixMe: flow doesn't know DOMException
      this.promise = Promise.reject(new DOMException('Promise cancelled from CancellablePromise (early abort)', 'AbortError'));
      return;
    }

    // Signal passed and still alive

    this.abortHandlerController = new AbortController();

    const signalPromise = new Promise((_, reject) => {
      // $FlowFixMe: flow doesn't know DOMException
      this.abortHandler = () => reject(new DOMException('Promise cancelled from CancellablePromise', 'AbortError'));
      signal.addEventListener('abort', this.abortHandler);
    });

    this.promise = Promise.race([promise, signalPromise]).then((data) => {
      // Removes event listener
      this.abortHandlerController?.abort();
      return Promise.resolve(data);
    });
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'CancellablePromise';
  }

  getPromise: () => Promise<any> = () => this.promise;
}
