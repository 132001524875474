/* @flow */

const getValueFromPrefix: (item: any, prefix: string) => any = (item, prefix) => {
  const q = [item];

  while (q.length > 0) {
    const o = q.pop();
    if (o) {
      if (typeof o === 'object') {
        Object.entries(o).forEach(([k, v]) => {
          if (k !== 'elements' && (typeof v === 'string' || typeof v === 'object')) {
            q.push(v);
          }
        });
      } else if (o.startsWith(prefix)) {
        // Value found
        return o;
      }
    }
  }

  // Not found
  return null;
};

export { getValueFromPrefix };
