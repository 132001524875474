/* @flow */

import './infiniteCircleLoader.css';
import * as React from 'react';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import clsx from 'clsx';

/* eslint-disable react/require-default-props */
type InfiniteCircleLoaderPropType = {|
  +className?: string,
  +theme?: ThemeType,
|};
/* eslint-enable react/require-default-props */

const InfiniteCircleLoader = ({ className, theme = Theme.Dark }: InfiniteCircleLoaderPropType): React.Node => (
  <div className={clsx('infiniteCircleLoader', className, theme)}>
    <svg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
      <path d='M119.777,65.198c-2.638,30.685 -28.413,54.802 -59.777,54.802c-31.364,0 -57.139,-24.117 -59.777,-54.802l6.756,0c2.617,26.966 25.373,48.074 53.021,48.074c27.648,0 50.404,-21.108 53.021,-48.074l6.756,0ZM0,60c0,0 0,0 0,0ZM0.223,54.802c2.638,-30.685 28.413,-54.802 59.777,-54.802c31.364,0 57.139,24.117 59.777,54.802l-6.756,0c-2.617,-26.966 -25.373,-48.074 -53.021,-48.074c-27.648,0 -50.404,21.108 -53.021,48.074l-6.756,0Z' />
    </svg>
  </div>
);

export default InfiniteCircleLoader;
