/* @flow */

import './ResizeDetectorWrapper.css';
import * as React from 'react';
import clsx from 'clsx';
import { useResizeDetector } from 'react-resize-detector';

type RefreshModeType = 'throttle' | 'debounce';

export type ResizeType = {| entry: ResizeObserverEntry, height: number | null, width: number | null |};

/* eslint-disable react/require-default-props */
type ResizeDetectorWrapperPropType = {|
  +className?: string,
  +children?: React.Node,
  +handleHeight?: boolean,
  +handleWidth?: boolean,
  +onResize?: (data: ResizeType) => void,
  +refreshMode?: RefreshModeType,
  +refreshRate?: number,
|};
/* eslint-enable react/require-default-props */

const ResizeDetectorWrapper: React.ComponentType<ResizeDetectorWrapperPropType> = ({
  children,
  className,
  handleHeight = false,
  handleWidth = false,
  onResize,
  refreshMode,
  refreshRate,
}: ResizeDetectorWrapperPropType) => {
  const { ref } = useResizeDetector({ handleHeight, handleWidth, onResize, refreshMode, refreshRate });

  if (children) {
    return (
      <div className={clsx('resizeDetector', 'withChildren', className)} ref={ref}>
        {children}
      </div>
    );
  }

  return <div className='resizeDetector' ref={ref} />;
};

export default ResizeDetectorWrapper;
