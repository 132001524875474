/* @flow */

import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import type { RequestResponseMethodDefinitionType } from '../emitter';
import { SECONDS_PER_DAY } from '../../../../helpers/dateTime/Format';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { fetchJsonWithErrorCheck } from '../../../../helpers/jsHelpers/xhr';
import { generateApiUrl } from '../helpers/api';
import { logError } from '../../../../helpers/debug/debug';
import { updateConfiguration } from '../../../appConf/actions';

const sendV8AppConfigurationRequest: (overriddenUrl: string) => RequestResponseMethodDefinitionType =
  (overriddenUrl) =>
  async (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const state = getState();
    const uri = overriddenUrl || (process.env.REACT_APP_CONFIGURATION_URL ?? '');

    if (uri === '') {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'appConfigurationUrl' }));
    }

    const appConfigurationUrl: NETGEM_API_V8_URL_DEFINITION = {
      authent: false,
      params: [
        {
          name: 'date',
          value: {
            args: [SECONDS_PER_DAY],
            op: 'now',
          },
        },
        {
          name: 'lang',
          value: {
            args: [],
            op: 'lang',
          },
        },
      ],
      uri,
    };

    const builtUri = generateApiUrl(appConfigurationUrl, {}, state);

    try {
      const { didomi, locales, ...configuration } = await fetchJsonWithErrorCheck(builtUri);

      // Special HTML app case
      const { htmlLoginUrl } = configuration;
      if (htmlLoginUrl) {
        configuration.loginUrl = htmlLoginUrl;
      }

      dispatch(updateConfiguration(configuration, didomi, locales));
      return true;
    } catch (error) {
      if (error instanceof SyntaxError) {
        logError('Error parsing AppConf');
      }
      logError(error);
      return false;
    }
  };

export default sendV8AppConfigurationRequest;
