/* @flow */

import {
  BO_API_ADD_FAVORITE,
  BO_API_CREATE_STREAM2,
  BO_API_DELETE_FAVORITE,
  BO_API_FAVORITE_LIST,
  BO_API_GET_STREAMS,
  BO_API_PERSONAL_DATA,
  BO_API_PURCHASE,
  BO_API_PURCHASE_LIST,
  BO_API_PURCHASE_STATUS,
  BO_API_START_STREAM,
  BO_API_STOP_STREAM,
} from '../../../../redux/netgemApi/actions/videofutur/types/common';
import { type CommonPromisedXMLHttpRequestReturnType, commonNetworkErrorCallBack, commonPromisedXMLHttpRequest } from '../../helpers/CommonPromisedXMLHttpRequest';
import { HttpMethod } from '../../v8/types/HttpMethod';
import { XhrResponseType } from '../../../../helpers/jsHelpers/xhr';
import { buildUrl } from './bo';
import { convertXmlResponseToJson } from '../../../../redux/netgemApi/actions/helpers/bo';

// BO API v2
const convertJson: (jsonResponse: any) => any = (jsonResponse) => {
  const {
    body: { createStream, favorites, getStreams, personalData, purchase, purchases, purchaseStatus, startStream, stopStream },
  } = jsonResponse;

  /* eslint-disable dot-notation */
  return {
    settings: [
      {
        type: BO_API_PERSONAL_DATA,
        url: personalData['_links'].personalData.href,
      },
      {
        type: BO_API_PURCHASE,
        url: purchase['_links'].purchase.href,
      },
      {
        type: BO_API_PURCHASE_STATUS,
        url: purchaseStatus['_links'].purchaseStatus.href,
      },
      {
        type: BO_API_PURCHASE_LIST,
        url: purchases['_links'].purchases.href,
      },
      {
        type: BO_API_ADD_FAVORITE,
        url: favorites['_links'].addFavorites.href,
      },
      {
        type: BO_API_DELETE_FAVORITE,
        url: favorites['_links'].deleteFavorites.href,
      },
      {
        type: BO_API_FAVORITE_LIST,
        url: favorites['_links'].favorites.href,
      },
      {
        type: BO_API_CREATE_STREAM2,
        url: createStream['_links'].createStream.href,
      },
      {
        type: BO_API_START_STREAM,
        url: startStream['_links'].startStream.href,
      },
      {
        type: BO_API_STOP_STREAM,
        url: stopStream['_links'].stopStream.href,
      },
      {
        type: BO_API_GET_STREAMS,
        url: getStreams['_links'].getStreams.href,
      },
    ],
  };
  /* eslint-enable dot-notation */
};

const discoveryRequest: (discoveryUrl: string, device: string, distributor: string, identity: string, signal?: AbortSignal, useBOV2Api?: boolean) => Promise<any> = (
  discoveryUrl,
  device,
  distributor,
  identity,
  signal,
  useBOV2Api,
) => {
  const returnDiscoveryObject: CommonPromisedXMLHttpRequestReturnType = commonPromisedXMLHttpRequest(
    buildUrl(discoveryUrl, {
      device,
      distributor,
      identity,
    }),
    HttpMethod.GET,
    useBOV2Api ? XhrResponseType.Json : XhrResponseType.Xml,
    null,
    null,
    null,
    false,
    commonNetworkErrorCallBack,
    signal,
  );

  return returnDiscoveryObject.promise.then((response: any) => (useBOV2Api ? convertJson(response) : convertXmlResponseToJson(response)));
};

export default discoveryRequest;
