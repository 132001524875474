/* @flow */

export enum SynopsisType {
  Full,
  Short,
}

export type NETGEM_API_V8_LANG = {|
  lang: string,
  type?: SynopsisType,
  value: string,
|};
