/* @flow */

export enum SubtitlesSize {
  Big = 'big',
  Medium = 'medium',
  Small = 'small',
}

const SUBTITLES_SIZE_BIG = 0.85;
const SUBTITLES_SIZE_MEDIUM = 0.7;
const SUBTITLES_SIZE_SMALL = 0.55;

// eslint-disable-next-line consistent-return
export const getCoeffFromSubtitlesSize = (size: SubtitlesSize): number => {
  switch (size) {
    case SubtitlesSize.Big:
      return SUBTITLES_SIZE_BIG;
    case SubtitlesSize.Small:
      return SUBTITLES_SIZE_SMALL;
    case SubtitlesSize.Medium:
      return SUBTITLES_SIZE_MEDIUM;

    // No default
  }
};

export enum SubtitlesBGColor {
  Yellow = 'yellow',
  White = 'white',
}
