/* @flow */

import type { NETGEM_API_VIEWINGHISTORY, NETGEM_API_VIEWINGHISTORY_ITEM, VIEWING_HISTORY_TYPE } from '../../libs/netgemLibrary/v8/types/ViewingHistory';
import { LoadableStatus } from '../loadable/loadable';
import { getHashFromDate } from '../request/cache';

const getTVItem: (i: NETGEM_API_VIEWINGHISTORY_ITEM) => boolean = (i) => i.id.indexOf('://netgem/') > -1;

class ViewingHistoryCache {
  static items: VIEWING_HISTORY_TYPE = [];

  static hashCode: string | null = null;

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'ViewingHistoryCache';
  }

  static checkGet: (newViewingHistory: NETGEM_API_VIEWINGHISTORY, status: LoadableStatus, ordered?: boolean) => VIEWING_HISTORY_TYPE = (newViewingHistory, status, ordered = false) => {
    if (status !== LoadableStatus.Loaded) {
      // Not loaded yet
      return [];
    }

    const items = ((Object.values(newViewingHistory): any): VIEWING_HISTORY_TYPE);
    const newHashCode = getHashFromDate(items);

    if (newHashCode !== ViewingHistoryCache.hashCode) {
      // Cache items
      ViewingHistoryCache.items = items.filter(getTVItem);
      if (ordered) {
        ViewingHistoryCache.items.sort((a, b) => b.date.localeCompare(a.date));
      }

      ViewingHistoryCache.hashCode = newHashCode;
    }

    return ViewingHistoryCache.items;
  };
}

export default ViewingHistoryCache;
