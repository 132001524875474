/* @flow */

import './MainView.css';
import * as React from 'react';
import { FEATURE_NPVR, FEATURE_TV } from '../../redux/appConf/constants';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { clearBOFavoriteList, clearBOPurchaseList, setDeeplink, updateSetting, updateWholeViewingHistory, updateWishlist } from '../../redux/ui/actions';
import { showCardModal, showLegalNoticeModal } from '../../redux/modal/actions';
import { updateExistingRecordings, updateFutureRecordings, updateScheduledRecordings } from '../../redux/npvr/actions';
import type { BasicFunction } from '@ntg/utils/dist/types';
import type { CARD_DATA_MODAL_TYPE } from '../modal/cardModal/CardModalConstsAndTypes';
import type { CombinedReducers } from '../../redux/reducers';
import { DEFAULT_RECORDINGS_STATUS_ITEM } from '../../libs/netgemLibrary/v8/types/Npvr';
import { DidomiSDK } from '@didomi/react';
import DidomiWrapper from '../../helpers/consent/didomi';
import type { Dispatch } from '../../redux/types/types';
import EpgManager from '../../helpers/epg/epgManager';
import LocalStorageManager from '../../helpers/localStorage/localStorageManager';
import MainLayout from './MainLayout';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { RegistrationType } from '../../redux/appRegistration/types/types';
import { Setting } from '../../helpers/settings/types';
import { type SettingValueType } from '../settings/SettingsConstsAndTypes';
import { StorageKeys } from '../../helpers/localStorage/keys';
import { connect } from 'react-redux';
import { getAllRecordings } from '../../redux/netgemApi/actions/v8/recordings';
import { getAllScheduledRecordings } from '../../redux/netgemApi/actions/v8/scheduledRecordings';
import getFavoriteList from '../../redux/netgemApi/actions/videofutur/favoriteList';
import { getPurchaseList } from '../../redux/netgemApi/actions/videofutur/helpers/purchaseList';
import { getViewingHistory } from '../../redux/netgemApi/actions/personalData/viewingHistory';
import { getWishlist } from '../../redux/netgemApi/actions/personalData/wishlist';
import { ignoreIfAborted } from '../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isGoogleBot } from '../../helpers/jsHelpers/robots';
import { sendV8HubRequest } from '../../redux/netgemApi/actions/v8/hub';
import { updateSmartBannerTag } from '../../helpers/jsHelpers/metaTag';

// Wait 500 ms before actually refreshing personal data when switching avenue (in ms)
const REFRESH_TIMEOUT = 500;

type ReduxMainReducerStateType = {|
  +dataCollectionColdUrl: ?NETGEM_API_V8_URL_DEFINITION,
  +dataCollectionHotUrl: ?NETGEM_API_V8_URL_DEFINITION,
  +defaultSubtitles: string,
  +didomiApiKey: string,
  +didomiNoticeId: string,
  +focusedAvenueIndex: number,
  +hubMaxAge: ?number,
  +isBORunning: boolean,
  +isNpvrEnabled: boolean,
  +isRegisteredAsGuest: boolean,
  +isTVEnabled: boolean,
|};

type ReduxMainDispatchToPropsType = {|
  +localClearExistingRecordings: BasicFunction,
  +localClearFavoriteList: BasicFunction,
  +localClearFutureRecordings: BasicFunction,
  +localClearPurchaseList: BasicFunction,
  +localClearScheduledRecordings: BasicFunction,
  +localClearViewingHistory: BasicFunction,
  +localClearWishList: BasicFunction,
  +localGetAllRecordings: (signal?: AbortSignal) => Promise<any>,
  +localGetAllScheduledRecordings: (signal?: AbortSignal) => Promise<any>,
  +localGetFavoriteList: (signal?: AbortSignal) => Promise<any>,
  +localGetPurchaseList: (signal?: AbortSignal) => Promise<any>,
  +localGetViewingHistory: (signal?: AbortSignal) => Promise<any>,
  +localGetWishlist: (signal?: AbortSignal) => Promise<any>,
  +localSendV8HubRequest: () => Promise<any>,
  +localUpdateDeeplink: (deeplink: string) => void,
  +localUpdateSetting: (setting?: Setting, value: SettingValueType) => Promise<any>,
  +showCard: (cardData: CARD_DATA_MODAL_TYPE) => void,
  +showLegalNotice: BasicFunction,
|};

type MainPropType = {||};

type CompleteMainPropType = {|
  ...MainPropType,
  ...ReduxMainReducerStateType,
  ...ReduxMainDispatchToPropsType,
|};

class MainView extends React.PureComponent<CompleteMainPropType> {
  abortController: AbortController;

  hubRefreshTimer: TimeoutID | null;

  refreshTimer: TimeoutID | null;

  constructor(props: CompleteMainPropType) {
    super(props);

    this.abortController = new AbortController();
    this.hubRefreshTimer = null;
    this.refreshTimer = null;

    const {
      location: { href, origin, search },
    } = window;

    if (search) {
      const { localUpdateDeeplink } = props;
      localUpdateDeeplink(href);
      updateSmartBannerTag(href);

      if (!isGoogleBot()) {
        // Don't clean up address bar for Google bots
        window.history.pushState({ path: origin }, '', origin);
      }
    }

    const { isTVEnabled } = props;
    if (isTVEnabled) {
      // Start EPG retrieval and refresh mechanism
      EpgManager.initializeCachedFeed();
    }
  }

  componentDidMount() {
    const { dataCollectionColdUrl, dataCollectionHotUrl } = this.props;

    Messenger.on(MessengerEvents.CONSENT_OPEN_SETTINGS, this.openConsentSettings);
    Messenger.on(MessengerEvents.CONSENT_RESET, this.resetConsent);
    Messenger.on(MessengerEvents.REFRESH_HUB, this.refreshHub);
    Messenger.on(MessengerEvents.REFRESH_NPVR, this.refreshNpvr);
    Messenger.on(MessengerEvents.REFRESH_PURCHASE_LIST, this.refreshPurchaseList);
    Messenger.on(MessengerEvents.REFRESH_VIEWING_HISTORY, this.refreshViewingHistory);
    Messenger.on(MessengerEvents.REFRESH_WISH_LIST, this.refreshWishList);
    Messenger.on(MessengerEvents.CLEAR_PURCHASE_LIST, this.clearPurchaseList);
    Messenger.on(MessengerEvents.CLEAR_VIEWING_HISTORY, this.clearViewingHistory);
    Messenger.on(MessengerEvents.CLEAR_WISH_LIST, this.clearWishList);
    Messenger.on(MessengerEvents.CLEAR_NPVR, this.clearNpvr);
    Messenger.on(MessengerEvents.OPEN_CARD, this.openCard);
    Messenger.on(MessengerEvents.SHOW_LEGAL_NOTICE, this.showLegalNotice);

    this.startHubRefreshTimer();

    this.refreshNpvr();
    this.refreshPersonalData();

    if (dataCollectionColdUrl && dataCollectionHotUrl) {
      // Retrieve from local storage and flush data collection messages at startup
      Messenger.emit(MessengerEvents.UNSTASH_COLLECTOR);
    }

    this.checkDefaultSubtitles();
  }

  componentDidUpdate(prevProps: CompleteMainPropType) {
    const { focusedAvenueIndex, hubMaxAge } = this.props;
    const { focusedAvenueIndex: prevFocusedAvenueIndex, hubMaxAge: prevHubMaxAge } = prevProps;

    if (hubMaxAge !== prevHubMaxAge) {
      // New hub expiration: setup a new refresh timer
      this.startHubRefreshTimer();
    }

    if (focusedAvenueIndex !== prevFocusedAvenueIndex) {
      // Avenue change: refresh stuff
      this.refreshPersonalData();
    }
  }

  componentWillUnmount() {
    const { abortController, refreshTimer } = this;

    abortController.abort('Component MainView will unmount');

    Messenger.off(MessengerEvents.CONSENT_OPEN_SETTINGS, this.openConsentSettings);
    Messenger.off(MessengerEvents.CONSENT_RESET, this.resetConsent);
    Messenger.off(MessengerEvents.REFRESH_HUB, this.refreshHub);
    Messenger.off(MessengerEvents.REFRESH_NPVR, this.refreshNpvr);
    Messenger.off(MessengerEvents.REFRESH_PURCHASE_LIST, this.refreshPurchaseList);
    Messenger.off(MessengerEvents.REFRESH_VIEWING_HISTORY, this.refreshViewingHistory);
    Messenger.off(MessengerEvents.REFRESH_WISH_LIST, this.refreshWishList);
    Messenger.off(MessengerEvents.CLEAR_PURCHASE_LIST, this.clearPurchaseList);
    Messenger.off(MessengerEvents.CLEAR_VIEWING_HISTORY, this.clearViewingHistory);
    Messenger.off(MessengerEvents.CLEAR_WISH_LIST, this.clearWishList);
    Messenger.off(MessengerEvents.CLEAR_NPVR, this.clearNpvr);
    Messenger.off(MessengerEvents.OPEN_CARD, this.openCard);
    Messenger.off(MessengerEvents.SHOW_LEGAL_NOTICE, this.showLegalNotice);

    this.resetHubRefreshTimer();

    if (refreshTimer) {
      clearTimeout(refreshTimer);
      this.refreshTimer = null;
    }
  }

  checkDefaultSubtitles = () => {
    const { defaultSubtitles, localUpdateSetting } = this.props;

    if (!defaultSubtitles) {
      // App does not have any default subtitles
      return;
    }

    const defaultSubtitlesApplied = LocalStorageManager.loadBoolean(StorageKeys.DefaultSubtitlesApplied, false);
    if (defaultSubtitlesApplied) {
      // Default subtitles are only applied (i.e. forced) once
      return;
    }

    localUpdateSetting(Setting.AutoSelectSubtitlesTrack, true);
    localUpdateSetting(Setting.LastSubtitlesTrack, defaultSubtitles);
    LocalStorageManager.save(StorageKeys.DefaultSubtitlesApplied, true);
  };

  startHubRefreshTimer = () => {
    const { hubMaxAge } = this.props;

    if (typeof hubMaxAge === 'number') {
      this.hubRefreshTimer = setTimeout(this.refreshHub, hubMaxAge);
    }
  };

  resetHubRefreshTimer = () => {
    const { hubRefreshTimer } = this;

    if (hubRefreshTimer) {
      clearTimeout(hubRefreshTimer);
      this.hubRefreshTimer = null;
    }
  };

  refreshHub = () => {
    const { localSendV8HubRequest } = this.props;

    this.resetHubRefreshTimer();
    localSendV8HubRequest().then(this.startHubRefreshTimer);
  };

  refreshNpvr = () => {
    const { isNpvrEnabled, isRegisteredAsGuest, localGetAllRecordings, localGetAllScheduledRecordings } = this.props;

    if (isNpvrEnabled && !isRegisteredAsGuest) {
      this.refreshPersonalDataInternal(localGetAllRecordings);
      this.refreshPersonalDataInternal(localGetAllScheduledRecordings);
    }
  };

  refreshPurchaseList = () => {
    const { isBORunning, localGetPurchaseList } = this.props;

    if (isBORunning) {
      this.refreshPersonalDataInternal(localGetPurchaseList);
    }
  };

  refreshViewingHistory = () => {
    const { localGetViewingHistory } = this.props;

    this.refreshPersonalDataInternal(localGetViewingHistory);
  };

  refreshWishList = () => {
    const { isBORunning, isTVEnabled, localGetWishlist, localGetFavoriteList } = this.props;
    const {
      abortController: { signal },
    } = this;

    // TV wishlist
    if (isTVEnabled) {
      this.refreshPersonalDataInternal(localGetWishlist);
    }

    // VOD wishlist
    if (isBORunning) {
      localGetFavoriteList(signal);
    }
  };

  refreshPersonalDataInternal = (localFunction: (signal?: AbortSignal) => Promise<any>): void => {
    const {
      abortController: { signal },
    } = this;

    localFunction(signal).catch((error) => ignoreIfAborted(signal, error));
  };

  clearPurchaseList = (): void => {
    const { localClearPurchaseList } = this.props;

    localClearPurchaseList();
  };

  clearViewingHistory = (): void => {
    const { localClearViewingHistory } = this.props;

    localClearViewingHistory();
  };

  clearWishList = (): void => {
    const { localClearFavoriteList, localClearWishList } = this.props;

    localClearWishList();
    localClearFavoriteList();
  };

  clearNpvr = (): void => {
    const { isNpvrEnabled, isRegisteredAsGuest, localClearExistingRecordings, localClearFutureRecordings, localClearScheduledRecordings } = this.props;

    if (isNpvrEnabled && !isRegisteredAsGuest) {
      localClearExistingRecordings();
      localClearFutureRecordings();
      localClearScheduledRecordings();
    }
  };

  showLegalNotice = (): void => {
    const { showLegalNotice } = this.props;

    showLegalNotice();
  };

  openCard = (cardData: CARD_DATA_MODAL_TYPE) => {
    const { showCard } = this.props;

    showCard(cardData);
  };

  refreshPersonalData = () => {
    const { isRegisteredAsGuest } = this.props;
    const { refreshTimer } = this;

    if (refreshTimer || isRegisteredAsGuest) {
      return;
    }

    // Debouncing
    this.refreshTimer = setTimeout(() => {
      this.refreshTimer = null;
      this.refreshPurchaseList();
      this.refreshViewingHistory();
      this.refreshWishList();
    }, REFRESH_TIMEOUT);
  };

  openConsentSettings: () => void = () => DidomiWrapper.manageConsent();

  resetConsent: () => void = () => DidomiWrapper.resetConsent();

  handleDidomiReady: (didomi: any) => void = (didomi) => {
    DidomiWrapper.initialize(didomi);
  };

  render(): React.Node {
    const { didomiApiKey, didomiNoticeId } = this.props;

    return (
      <>
        {didomiApiKey && didomiNoticeId ? <DidomiSDK apiKey={didomiApiKey} gdprAppliesGlobally iabVersion={2} noticeId={didomiNoticeId} onReady={this.handleDidomiReady} /> : null}
        <MainLayout />
      </>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxMainReducerStateType => {
  return {
    dataCollectionColdUrl: state.netgemApi.dataCollectionColdUrl,
    dataCollectionHotUrl: state.netgemApi.dataCollectionHotUrl,
    defaultSubtitles: state.appConfiguration.defaultSubtitles,
    didomiApiKey: state.appConfiguration.configuration.didomiApiKey ?? '',
    didomiNoticeId: state.appConfiguration.configuration.didomiNoticeId ?? '',
    focusedAvenueIndex: state.ui.focusedAvenue?.index ?? -1,
    hubMaxAge: state.ui.hubMaxAge,
    isBORunning: state.appConfiguration.isBORunning,
    isNpvrEnabled: state.appConfiguration.features[FEATURE_NPVR] && state.npvr.isEnabled,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
    isTVEnabled: state.appConfiguration.features[FEATURE_TV],
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxMainDispatchToPropsType => {
  return {
    localClearExistingRecordings: () => dispatch(updateExistingRecordings({}, null, [])),
    localClearFavoriteList: () => dispatch(clearBOFavoriteList()),
    localClearFutureRecordings: () =>
      dispatch(
        updateFutureRecordings({}, null, [], {
          current: DEFAULT_RECORDINGS_STATUS_ITEM,
          expectedError: DEFAULT_RECORDINGS_STATUS_ITEM,
          expectedSuccess: DEFAULT_RECORDINGS_STATUS_ITEM,
          maxQuota: DEFAULT_RECORDINGS_STATUS_ITEM,
        }),
      ),
    localClearPurchaseList: () => dispatch(clearBOPurchaseList()),
    localClearScheduledRecordings: () => dispatch(updateScheduledRecordings({}, {}, null)),
    localClearViewingHistory: () => dispatch(updateWholeViewingHistory({}, null)),
    localClearWishList: () => dispatch(updateWishlist([], null)),
    localGetAllRecordings: (signal?: AbortSignal): Promise<any> => dispatch(getAllRecordings(signal)),
    localGetAllScheduledRecordings: (signal?: AbortSignal): Promise<any> => dispatch(getAllScheduledRecordings(signal)),
    localGetFavoriteList: (signal?: AbortSignal): Promise<any> => dispatch(getFavoriteList(signal)),
    localGetPurchaseList: (signal?: AbortSignal): Promise<any> => dispatch(getPurchaseList(signal)),
    localGetViewingHistory: (signal?: AbortSignal): Promise<any> => dispatch(getViewingHistory(signal)),
    localGetWishlist: (signal?: AbortSignal): Promise<any> => dispatch(getWishlist(signal)),
    localSendV8HubRequest: (): Promise<any> => dispatch(sendV8HubRequest()),
    localUpdateDeeplink: (deeplink: string) => dispatch(setDeeplink(deeplink)),
    localUpdateSetting: (setting?: Setting, value: SettingValueType) => dispatch(updateSetting(setting, value)),
    showCard: (cardData: CARD_DATA_MODAL_TYPE) => dispatch(showCardModal(cardData)),
    showLegalNotice: () => dispatch(showLegalNoticeModal()),
  };
};

const Dark: React.ComponentType<MainPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MainView);

export default Dark;
