/* @flow */

import './PlaceholderTVEpisode.css';
import * as React from 'react';
import { PictoArrowDown } from '@ntg/components/dist/pictos/Element';

type PlaceholderTVEpisodePropType = {||};

class PlaceholderTVEpisode extends React.PureComponent<PlaceholderTVEpisodePropType> {
  render(): React.Node {
    return (
      <div className='episode tv'>
        <div className='header'>
          <div className='dateAndTitle placeholder'>
            <div className='date' />
            <div className='title' />
          </div>
          <div className='actions'>
            <PictoArrowDown className='arrow' />
          </div>
        </div>
      </div>
    );
  }
}

export default PlaceholderTVEpisode;
