/* @flow */

export enum WidthKind {
  Content = 'widthContent', // Adapt to content
  Stretched = 'widthStretched', // 100%
  Small = 'widthSmall', // 130px
  Large = 'widthLarge', // 210px
}

export enum HeightKind {
  Stretched = 'heightStretched', // 100%
  Small = 'heightSmall', // 30px
  Medium = 'heightMedium', // 40px
  Large = 'heightLarge', // 46px
}
