/* @flow */

import { ItemType, type NETGEM_API_V8_FEED_ITEM, NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP } from '../types/FeedItem';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../types/RequestResponse';

export const getStartOverItem: (item: NETGEM_API_V8_FEED_ITEM, requestResponse: NETGEM_API_V8_REQUEST_RESPONSE) => NETGEM_API_V8_FEED_ITEM | null = (item, requestResponse) => {
  const { result } = requestResponse;

  if (!result.feed) {
    return null;
  }

  const {
    feed: [firstItem],
  } = result;
  if (!firstItem) {
    return null;
  }

  const { locations } = firstItem;

  const {
    elements,
    endTime,
    id,
    score,
    selectedLocation: { startDate },
    selectedProgramId,
    seriesId,
    startTime,
  } = item;

  for (let i = 0; i < locations.length; i++) {
    const { [i]: location } = locations;
    const { scheduledEventStartDate } = location;

    if (startDate === scheduledEventStartDate) {
      return {
        elements,
        endTime,
        id,
        locType: NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
        locations,
        score,
        selectedLocation: location,
        selectedProgramId,
        seriesId,
        startTime,
        type: ItemType.Program,
      };
    }
  }

  // No catchup matching live item was found
  return null;
};
