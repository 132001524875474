/* @flow */

import type { KeyValuePair, Undefined } from '@ntg/utils/dist/types';
import type { BO_API_AUTHENTICATION_SETTINGS } from '../../libs/netgemLibrary/videofutur/types/AuthenticationSettings';
import { CustomNetworkError } from '../../libs/netgemLibrary/helpers/CustomNetworkError';
import { buildMissingParametersMessage } from '../debug/debug';
import { createCustomNetworkError } from '../../libs/netgemLibrary/helpers/CreateCustomNetworkError';

const getVfAuthenticationSettings: (authenticationSettings: BO_API_AUTHENTICATION_SETTINGS) => { error?: CustomNetworkError, settings?: KeyValuePair<string> } = (authenticationSettings) => {
  const { authentication, product } = authenticationSettings;

  let errorMsg: Undefined<string> = undefined;
  let settings: Undefined<KeyValuePair<string>> = undefined;

  if (authentication) {
    const { customerId, firstName, identity, name } = authentication;

    if (customerId && identity) {
      settings = {
        customerId,
        firstName: firstName ?? '',
        identity,
        name: name ?? '',
        productId: product?.toString() ?? '',
      };
    } else {
      errorMsg = buildMissingParametersMessage(
        {
          customerId,
          identity,
        },
        'BO authentication settings'
      );
    }
  } else {
    errorMsg = buildMissingParametersMessage({ authentication }, 'BO authentication settings');
  }

  return {
    error: errorMsg ? createCustomNetworkError(-1, errorMsg) : undefined,
    settings,
  };
};

export { getVfAuthenticationSettings };
