/* @flow */

import { BO_PURCHASE_UNKNOWN_ERROR, mapV2ErrorToV1Error } from '../../../../libs/netgemLibrary/videofutur/types/ErrorCodes';
import { type BO_API_ERROR_TYPE } from '../videofutur/types/common';
import { CustomNetworkError } from '../../../../libs/netgemLibrary/helpers/CustomNetworkError';
import { Localizer } from '@ntg/utils/dist/localization';
import { XMLParser } from 'fast-xml-parser';

const convertXmlResponseToJson: (xmlResponse: Document) => any = (xmlResponse) => {
  const options = {
    alwaysCreateTextNode: true,
    attributeNamePrefix: '',
    ignoreAttributes: false,
    ignoreDeclaration: true,
    ignorePiTags: true,
    parseTagValue: false,
    processEntities: false,
    textNodeName: '_text',
    trimValues: true,
  };

  const { documentElement } = xmlResponse;

  if (documentElement !== null) {
    const xmlString = new XMLSerializer().serializeToString(documentElement);
    const parser = new XMLParser(options);
    return parser.parse(xmlString);
  }

  return {};
};

const buildErrorResponse: (defaultStatus: ?string, data: any) => BO_API_ERROR_TYPE = (defaultStatus, data) => {
  if (data instanceof CustomNetworkError) {
    const status = mapV2ErrorToV1Error(data);
    if (status !== null) {
      return {
        errorMsg: '',
        status,
      };
    }
  }

  const { errormsg, message, status } = data;

  let errorMsg1: ?string = null;
  let errorMsg2: ?string = null;
  let errorStatus: ?string = null;

  if (errormsg) {
    if (typeof errormsg === 'string') {
      errorMsg2 = errormsg;
    } else {
      ({ _text: errorMsg2 } = errormsg);
    }
  }

  if (status) {
    if (typeof status === 'string') {
      errorStatus = status;
    } else {
      ({ _text: errorStatus } = status);
    }
  }

  if (message) {
    try {
      const messageObj = JSON.parse(message);
      if (messageObj) {
        const { result, status: errStatus } = messageObj;
        if (result && result.message) {
          errorMsg1 = result.message;
        }
        if (errStatus) {
          errorStatus = errStatus;
        }
      }
    } catch {
      errorMsg1 = message;
    }
  }

  return {
    errorMsg: errorMsg1 || errorMsg2 || Localizer.localize('common.messages.errors.unknown_bo_error'),
    status: defaultStatus || errorStatus || BO_PURCHASE_UNKNOWN_ERROR,
  };
};

export { buildErrorResponse, convertXmlResponseToJson };
