/* @flow */

export enum WikipediaResultStatusType {
  Disambiguation,
  Error,
  Notfound,
  Success,
}

export type WikipediaSummary = {|
  authorsUrl: string,
  description: ?string,
  extract: ?string,
  extractHtml: ?string,
  imageHeight: ?number,
  imageUrl: ?string,
  imageWidth: ?number,
  pageUrl: string,
  timestamp: string,
  title: string,
|};

export type WikipediaResultType = {|
  rawData: any,
  status: WikipediaResultStatusType,
  summary: WikipediaSummary | null,
  url: string,
|};
