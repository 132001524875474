/* @flow */

import {
  AUTHENTICATION_REQUIRED_MODAL,
  AVENUE_MODAL,
  CARD_MODAL,
  CONFIRMATION_MODAL,
  EXTERNAL_CONTENT_MODAL,
  KEYBOARD_SHORTCUTS_MODAL,
  LEGAL_NOTICE_MODAL,
  type MODAL_TYPE,
  NEW_VERSION_MODAL,
  NPVR_HELP_MODAL,
  NPVR_MODAL,
  VOD_PURCHASE_MODAL,
} from '../../../components/modal/modalTypes';
import { type VOD_PURCHASE_DATA_TYPE, clearPendingOperationFromCard } from '../../../helpers/rights/pendingOperations';
import type { AVENUE_DATA_MODAL_TYPE } from '../../../components/modal/avenueModal/AvenueModal';
import type { CARD_DATA_MODAL_TYPE } from '../../../components/modal/cardModal/CardModalConstsAndTypes';
import type { CONFIRMATION_DATA_MODAL_TYPE } from '../../../components/modal/confirmationModal/ConfirmationModal';
import type { ModalAction } from '../actions';
import { ModalActionType } from '../constants';
import type { NEW_VERSION_DATA_MODAL_TYPE } from '../../../components/modal/newVersionModal/NewVersionModal';
import type { NPVR_DATA_MODAL_TYPE } from '../../../components/modal/npvrModal/NpvrModal';
import { produce } from 'immer';

export type ModalState = {|
  avenueData?: AVENUE_DATA_MODAL_TYPE,
  cardData?: CARD_DATA_MODAL_TYPE,
  confirmationData?: CONFIRMATION_DATA_MODAL_TYPE,
  externalContentUrl?: string,
  index?: number,
  modalType: ?MODAL_TYPE,
  newVersionData?: NEW_VERSION_DATA_MODAL_TYPE,
  npvrData?: NPVR_DATA_MODAL_TYPE,
  vodPurchaseData?: VOD_PURCHASE_DATA_TYPE,
|};

export type AllModalsState = {|
  openModals: Array<ModalState>,
  savedModalCard: ?ModalState,
|};

const InitialState: AllModalsState = {
  openModals: [],
  savedModalCard: null,
};

const DefaultAction: ModalAction = { type: null };

/* eslint-disable max-lines-per-function */
const modalReducer: (state: AllModalsState, action: ModalAction) => AllModalsState = produce((draft = InitialState, action = DefaultAction) => {
  const { length: openModalCount } = draft.openModals;

  if (action.type === null) {
    return;
  }

  switch (action.type) {
    case ModalActionType.Show: {
      let newModal = {};

      switch (action.modalType) {
        case AVENUE_MODAL:
          newModal = {
            avenueData: action.avenueData,
            modalType: action.modalType,
          };
          break;

        case CARD_MODAL:
          newModal = {
            cardData: action.cardData,
            modalType: action.modalType,
          };
          break;

        case CONFIRMATION_MODAL:
          newModal = {
            confirmationData: action.confirmationData,
            modalType: action.modalType,
          };
          break;

        case EXTERNAL_CONTENT_MODAL:
          newModal = {
            externalContentUrl: action.externalContentUrl,
            modalType: action.modalType,
          };
          break;

        case KEYBOARD_SHORTCUTS_MODAL:
        case LEGAL_NOTICE_MODAL:
        case NPVR_HELP_MODAL:
          newModal = {
            modalType: action.modalType,
          };
          break;

        case NEW_VERSION_MODAL:
          newModal = {
            modalType: action.modalType,
            newVersionData: action.newVersionData,
          };
          break;

        case NPVR_MODAL:
          newModal = {
            modalType: action.modalType,
            npvrData: action.npvrData,
          };
          break;

        case AUTHENTICATION_REQUIRED_MODAL:
          // Hide all modals since user will be sent to the login screen
          draft.openModals.length = 0;

          newModal = {
            modalType: action.modalType,
          };
          break;

        case VOD_PURCHASE_MODAL:
          newModal = {
            modalType: action.modalType,
            vodPurchaseData: action.vodPurchaseData,
          };
          break;

        default:
          return;
      }

      const sameModalIndex = draft.openModals.findIndex((m) => m.modalType === action.modalType);
      if (sameModalIndex > -1) {
        // Replace existing modal of same type
        draft.openModals[sameModalIndex] = newModal;
      } else {
        // Add new modal
        draft.openModals.push(newModal);
      }
      break;
    }

    case ModalActionType.Reopen: {
      if (draft.savedModalCard) {
        draft.openModals.push(draft.savedModalCard);
        draft.savedModalCard = null;
      }
      break;
    }

    case ModalActionType.SaveAndHide:
    case ModalActionType.Hide: {
      if (openModalCount === 0) {
        // Return entirely new draft
        // eslint-disable-next-line consistent-return
        return {
          ...InitialState,
          savedModalCard: draft.savedModalCard,
        };
      }

      if (action.force !== true && draft.openModals[openModalCount - 1].modalType === AUTHENTICATION_REQUIRED_MODAL) {
        // Do not close this kind of modal
        break;
      }

      // Remove top modal and store modal card if required
      const topModal = draft.openModals.pop();

      // Since flowjs v0.239.0, "topModal" is said to be potentially undefined (which is impossible, here)
      draft.savedModalCard ||= action.type === ModalActionType.SaveAndHide && topModal?.modalType === CARD_MODAL ? clearPendingOperationFromCard(topModal) : null;
      break;
    }

    // No default
  }
}, InitialState);
/* eslint-enable max-lines-per-function */

export default modalReducer;
