/* @flow */

import type { NETGEM_API_WISHLIST, NETGEM_API_WISHLIST_ITEM } from '../../libs/netgemLibrary/v8/types/Wishlist';
import { LoadableStatus } from '../loadable/loadable';
import { getHashFromDate } from '../request/cache';

class WishlistCache {
  static ids: Set<string> = new Set();

  static items: NETGEM_API_WISHLIST = [];

  static hashCode: string | null = null;

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'WishlistCache';
  }

  static reset: () => void = () => {
    WishlistCache.ids = new Set();
    WishlistCache.items = [];
    WishlistCache.hashCode = null;
  };

  static checkGet: (newWishlist: NETGEM_API_WISHLIST, status: LoadableStatus, ordered?: boolean) => { ids: Set<string>, items: NETGEM_API_WISHLIST } = (newWishlist, status, ordered = false) => {
    if (status !== LoadableStatus.Loaded) {
      // Not loaded yet
      return { ids: new Set(), items: [] };
    }

    const newHashCode = getHashFromDate(newWishlist);

    if (newHashCode !== WishlistCache.hashCode) {
      // Cache items
      WishlistCache.items = [...newWishlist];
      if (ordered) {
        WishlistCache.items.sort((a, b) => a.date.localeCompare(b.date));
      }

      // Cache Ids
      WishlistCache.ids = new Set();
      newWishlist.forEach((item: NETGEM_API_WISHLIST_ITEM) => WishlistCache.ids.add(item.id));

      WishlistCache.hashCode = newHashCode;
    }

    return { ids: WishlistCache.ids, items: WishlistCache.items };
  };
}

export default WishlistCache;
