/* @flow */

import './ButtonFXBicolor.css';
import * as React from 'react';
import { HeightKind, WidthKind } from './types';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import ButtonFX from './ButtonFX';

/* eslint-disable react/require-default-props */
type PropType = {|
  +data?: any,
  +hasPadding?: boolean,
  +heightKind?: HeightKind,
  +isDisabled?: boolean,
  +leftPart: React.Node,
  +onClick?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>, data: any) => void,
  +rightPart: React.Node,
  +theme?: ThemeType,
  +widthKind?: WidthKind,
|};
/* eslint-enable react/require-default-props */

const ButtonFXBicolor = ({ data, hasPadding, heightKind, isDisabled = false, leftPart, onClick, rightPart, theme = Theme.Dark, widthKind }: PropType): React.Node => (
  <ButtonFX className='buttonFXBicolor' data={data} hasPadding={hasPadding} heightKind={heightKind} isDisabled={isDisabled} onClick={onClick} theme={theme} widthKind={widthKind}>
    <div className='part left'>{leftPart}</div>
    <div className='part right'>{rightPart}</div>
  </ButtonFX>
);

export default ButtonFXBicolor;
