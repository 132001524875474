/* @flow */

import {
  ItemType,
  type NETGEM_API_V8_FEED_ITEM,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_EST,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { getLocationType } from '../../../libs/netgemLibrary/v8/helpers/Item';

const hasItemMetadata: (item: NETGEM_API_V8_FEED_ITEM) => boolean = (item) => {
  const { type } = item;

  return type === ItemType.Program || type === ItemType.Series;
};

const isVodItem: (item: NETGEM_API_V8_FEED_ITEM) => boolean = (item) => {
  const {
    locType,
    selectedLocation: { id },
  } = item;

  const type = locType ?? getLocationType(id);
  return (
    type === NETGEM_API_V8_ITEM_LOCATION_TYPE_EST || type === NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD || type === NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD || type === NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD
  );
};

export { hasItemMetadata, isVodItem };
