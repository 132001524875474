/* @flow */

import type { BOApi } from '../types/common';
import type { CombinedReducers } from '../../../../reducers';
import type { Dispatch } from '../../../../types/types';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { RequestResponseMethodDefinitionType } from '../../emitter';
import { buildBOHeaders } from './headers';
import { createCustomNetworkErrorFromKey } from '../../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';

const substitutePlaceholders: (uri: string, uriParameters?: KeyValuePair<string>) => string = (uri, uriParameters) => {
  if (!uriParameters) {
    return uri;
  }

  let localUri = uri;
  Object.entries(uriParameters).forEach(([key, value]) => {
    if (typeof value === 'string') {
      localUri = localUri.replace(key, value);
    }
  });

  return localUri;
};

const getRequestDetails: (distributorId: string, apiMethodName: BOApi, useBOV2Api: boolean, uriParameters?: KeyValuePair<string>, extraHeaders?: Array<any>) => RequestResponseMethodDefinitionType =
  (distributorId, apiMethodName, useBOV2Api, uriParameters, extraHeaders) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      appConfiguration,
      netgemApi: {
        distributors: {
          [distributorId]: {
            urls: { [apiMethodName]: urlDefinition },
          },
        },
      },
    } = getState();

    if (!urlDefinition) {
      return Promise.reject(
        createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition_for_distributor', {
          distributorId,
          methodName: apiMethodName,
        }),
      );
    }

    const { authent, uri, method } = urlDefinition;
    const headers = buildBOHeaders(distributorId, appConfiguration, extraHeaders, useBOV2Api);

    return Promise.resolve({
      authent,
      headers,
      method,
      uri: substitutePlaceholders(uri, uriParameters),
    });
  };

export default getRequestDetails;
