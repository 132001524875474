/* @flow */

export enum DataCollectionMessage {
  CurrentState = 'v1_currentState',
  Navigation = 'v1_navigation',
  PlayerState = 'v1_playerState',
  TurnOff = 'v1_turnOff',
  TurnOn = 'v1_turnOn',
}

export enum DataCollectionStream {
  Catchup = 'catchup',
  EST = 'est',
  Live = 'live',
  Recording = 'recording',
  SVOD = 'svod',
  Timeshift = 'timeshift',
  Trailer = 'trailer',
  TVOD = 'tvod',
}

export enum DataCollectionPlayerState {
  Error = 'error',
  Pause = 'pause',
  Play = 'play',
  Start = 'start',
  Stop = 'stop',
}

export const DataCollectionHubCold = 'cold';
export const DataCollectionHubHot = 'hot';

export type DataCollectionHubKind = 'cold' | 'hot';

// Position in the program without taking margins into account (in seconds, ISO 8601)
export type DATA_COLLECTION_INTERNAL_MESSAGE = {|
  channelId?: string,
  errorDetails?: string,
  locationId?: string,
  playbackDuration?: number,
  position?: number,
  programDuration?: number,
  programId?: string,
  sessionDuration?: number,
  sessionGuid?: string,
  state?: DataCollectionPlayerState,
  streamType?: DataCollectionStream,
|};

export type NETGEM_API_V8_DATA_COLLECTION_OBJECT = {|
  ...DATA_COLLECTION_INTERNAL_MESSAGE,
  messageType: DataCollectionMessage,
  serialNumber: number,
  timestamp: string,
|};

export type NETGEM_API_V8_DATA_COLLECTION_USER_PROPERTIES = {|
  appVersion?: string,
  browserVersion?: string,
  collectorId?: string,
  device?: string,
  osVersion?: string,
  realm?: string,
|};

export type NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD = Array<{|
  Body: string,
  UserProperties: NETGEM_API_V8_DATA_COLLECTION_USER_PROPERTIES,
|}>;
