/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_GENERIC } from '../../constants/';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const sendV8LocationVodForAssetRequest: (assetId: string, startDate: number, range: number, channelIds?: Array<string>, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (assetId, startDate, range, channelIds, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { locationVodForAssetUrl },
    } = state;

    if (!locationVodForAssetUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'locationVodForAssetUrl' }));
    }

    const { authent, method } = locationVodForAssetUrl;
    const channelList = channelIds ? JSON.stringify(channelIds) : undefined;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      method,
      signal,
      uri: generateApiUrl(
        locationVodForAssetUrl,
        {
          assetId,
          channelList,
          endDate: startDate + range,
          range,
          startDate,
        },
        state
      ),
    });
  };

export default sendV8LocationVodForAssetRequest;
