/* @flow */

import type { ReadOnlyKeyValuePair } from '@ntg/utils/dist/types';

export const HttpStatus: ReadOnlyKeyValuePair<number> = Object.freeze({
  BadRequest: 400,
  Conflict: 409,
  Error: 0, // Not an existing HTTP status code but used when XHR fails
  Forbidden: 403,
  MultipleChoices: 300,
  NoContent: 204,
  NotFound: 404,
  NotModified: 304,
  OK: 200,
  PreconditionFailed: 412,
  Unauthorized: 401,
});

// XML HTTP request status
export const HTTP_READY_STATE_DONE = 4;

export const NetgemNetworkCode: ReadOnlyKeyValuePair<number> = Object.freeze({
  AuthenticationFailed: 401000,
  ClientError: 400000,
  ConcurrencyLimit: 10207,
  Geolock: 10208,
  HubNotFound: 404005,
  InvalidDimensions: 400008,
  InvalidModalState: 400001,
  MissingDmsChannel: 10206,
  MissingRight: 10220,
  NotFound: 404000,
  OK: 200000,
  RealmNotFound: 404006,
});

export const NetgemNetworkErrorMessage: ReadOnlyKeyValuePair<string> = Object.freeze({
  AuthenticationFailed: 'The authentication has failed or has not been provided',
  ClientError: 'The server cannot or will not process the request due to an apparent client error',
  HubNotFound: 'The hub was not found',
  InvalidDimensions: 'The requested dimensions are invalid',
  InvalidModalState: 'The model state could not be correctly read',
  NotFound: 'The requested resource could not be found but may be available in the future',
  RealmNotFound: 'The realm (OEM) was not found',
});
