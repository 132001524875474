/* @flow */

import { Setting, type Settings } from './types';
import LocalStorageManager from '../localStorage/localStorageManager';
import { StorageKeys } from '../localStorage/keys';
import { getDefaultValueFromSetting } from './defaultValues';
import { isUndefinedOrNull } from '@ntg/utils/dist/types';

// Restore all settings to their default values and store them into local storage
const resetSettings: () => Settings = () => {
  const settings = initializeUndefinedSettings({});
  saveSettings(settings);
  return settings;
};

const initializeUndefinedSettings: (settings: Settings) => Settings = (settings) => {
  Array.from(Setting.members()).forEach((setting) => {
    const { [setting]: settingValue } = settings;
    if (isUndefinedOrNull(settingValue)) {
      settings[setting] = getDefaultValueFromSetting(setting);
    }
  });

  return settings;
};

// Load settings from local storage
const loadSettings: () => Settings = () => {
  const settings = LocalStorageManager.loadObject(StorageKeys.Settings);
  if (!settings) {
    // No settings stored yet
    return resetSettings();
  }

  return initializeUndefinedSettings(settings);
};

// Save settings into local storage
const saveSettings: (settings: Settings) => void = (settings) => LocalStorageManager.save(StorageKeys.Settings, settings);

export { resetSettings, loadSettings, saveSettings };
