/* @flow */

import './Equalizer.css';
import * as React from 'react';
import type { BasicFunction } from '@ntg/utils/dist/types';
import { Localizer } from '@ntg/utils/dist/localization';
import { TIPPY_DEFAULT_OPTIONS } from '@ntg/ui/dist/tooltip';
import Tippy from '@tippyjs/react';
import { connect } from 'react-redux';

/* eslint-disable no-magic-numbers */
const EQUALIZER = Object.freeze({
  AnimationSpeed: 180,
  BarCount: 5,
  MinValues: Object.freeze([0, 0.3, 0.4, 0.5, 0.4, 0.2]),
  RangeValues: Object.freeze([0, 0.5, 0.6, 0.4, 0.5, 0.6]),
});
/* eslint-enable no-magic-numbers */

type ReduxEqualizerReducerStateType = {||};

type DefaultProps = {|
  +isEnabled?: boolean,
|};

type EqualizerPropType = {|
  ...DefaultProps,
  +isMuted: boolean,
  +onClick: BasicFunction,
|};

type CompleteEqualizerPropType = {|
  ...EqualizerPropType,
  ...ReduxEqualizerReducerStateType,
|};

type EqualizerStateType = {|
  barHeights: Array<number>,
|};

const InitialState = Object.freeze({
  barHeights: [],
});

class EqualizerView extends React.PureComponent<CompleteEqualizerPropType, EqualizerStateType> {
  equalizerAnimationTimer: ?IntervalID;

  static defaultProps: DefaultProps = {
    isEnabled: true,
  };

  constructor(props: CompleteEqualizerPropType) {
    super(props);

    this.equalizerAnimationTimer = null;

    this.state = {
      ...InitialState,
      barHeights: this.getEqualizerBarHeights(),
    };
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: CompleteEqualizerPropType) {
    const { isEnabled, isMuted } = this.props;
    const { isEnabled: prevIsEnabled, isMuted: prevIsMuted } = prevProps;

    if (isEnabled !== prevIsEnabled || isMuted !== prevIsMuted) {
      this.initialize();
    }
  }

  componentWillUnmount() {
    this.stopEqualizerAnimation();
  }

  initialize = () => {
    const { isEnabled, isMuted } = this.props;

    if (isEnabled && !isMuted) {
      this.startEqualizerAnimation();
    } else {
      this.stopEqualizerAnimation();
    }
  };

  startEqualizerAnimation = () => {
    if (this.equalizerAnimationTimer) {
      // Already started
      return;
    }
    this.equalizerAnimationTimer = setInterval(this.updateEqualizer, EQUALIZER.AnimationSpeed);
  };

  stopEqualizerAnimation = () => {
    if (this.equalizerAnimationTimer) {
      clearInterval(this.equalizerAnimationTimer);
      this.equalizerAnimationTimer = null;
    }
  };

  getEqualizerBarHeights = (): Array<number> => {
    const barHeights = [];

    for (let i = 1; i < EQUALIZER.BarCount + 1; i++) {
      const h = EQUALIZER.MinValues[i] + Math.random() * EQUALIZER.RangeValues[i];
      barHeights.push(h);
    }

    return barHeights;
  };

  updateEqualizer = () => {
    this.setState({ barHeights: this.getEqualizerBarHeights() });
  };

  render(): React.Node {
    const { isMuted, onClick } = this.props;
    const { barHeights } = this.state;

    // eslint-disable-next-line react/no-array-index-key
    const bars = barHeights.map((h, i) => <span key={i} style={{ transform: `scaleY(${h})` }} />);

    const muteSign = isMuted ? (
      <div className='muteSign'>
        <svg viewBox='0 0 18 20' xmlns='http://www.w3.org/2000/svg'>
          <line x1='3' x2='15' y1='20' y2='0' />
        </svg>
      </div>
    ) : null;

    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <Tippy {...TIPPY_DEFAULT_OPTIONS} content={<div className='tooltipContent button'>{Localizer.localize('carousel.tooltip.toggle_sound')}</div>}>
        <div className='equalizer' onClick={onClick}>
          {bars}
          {muteSign}
        </div>
      </Tippy>
    );
    /* eslint-enable react/jsx-props-no-spreading */
  }
}

const Equalizer: React.ComponentType<EqualizerPropType> = connect(null, null, null, { forwardRef: true })(EqualizerView);

export default Equalizer;
