/* @flow */

import { HeaderName } from '../../v8/constants/Headers';
import { HttpMethod } from '../../v8/types/HttpMethod';
import { buildHeaderList } from '../../v8/helpers/RequestHeaders';
import sendV8Request from '../../v8/requests/v8';

const sendPersonalDataGetRequest: (url: string, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal: ?AbortSignal) => Promise<any> = (
  url,
  eTag,
  method,
  authenticationToken,
  signal,
) => {
  const headerList = buildHeaderList(HeaderName.IfNoneMatch, eTag);
  return sendV8Request(url, authenticationToken, null, headerList, null, signal, null, method, true);
};

export default sendPersonalDataGetRequest;
