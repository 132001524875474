/* @flow */

import './AvenueModal.css';
import * as React from 'react';
import { AvenueType } from '../../../helpers/ui/avenue/types';
import AvenueView from '../../avenue/Avenue';
import Modal from '../modal';
import type { ModalState } from '../../../redux/modal/reducers';
import type { NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_HUB } from '../../../libs/netgemLibrary/v8/types/Hub';
import clsx from 'clsx';
import { connect } from 'react-redux';

export type AVENUE_DATA_MODAL_TYPE = {|
  hasBackButton?: boolean,
  hub: ?NETGEM_API_V8_HUB,
  hubItem: ?NETGEM_API_V8_FEED_ITEM,
  index: number,
  isInExploreModal: boolean,
  type: AvenueType,
|};

class AvenueModalView extends React.PureComponent<ModalState> {
  render(): React.Node {
    const { avenueData, index: modalIndex } = this.props;

    if (!avenueData) {
      return null;
    }

    const { hasBackButton, hub, hubItem, index, isInExploreModal, type } = avenueData;

    return (
      <Modal className={clsx('avenue', (type: string).toLowerCase())} index={modalIndex}>
        <AvenueView hasBackButton={hasBackButton} hub={hub} hubItem={hubItem} index={index} isInExploreModal={isInExploreModal} key='avenue' type={type} />
      </Modal>
    );
  }
}

const AvenueModal: React.ComponentType<ModalState> = connect(null, null, null, { forwardRef: true })(AvenueModalView);

export default AvenueModal;
