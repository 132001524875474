/* @flow */

import { BO_API_PERSONAL_DATA, type BO_RESPONSE_WITH_ETAG } from './types/common';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_BO_PERSONAL_DATA } from '../../constants';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import getRequestDetails from './helpers/requestCommon';
import { logError } from '../../../../helpers/debug/debug';
import { updateBOProductId } from '../../../appConf/actions';

const sendVideofuturPersonalDataRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (distributorId, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration: { mainDistributorId },
    } = getState();

    if (!mainDistributorId) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_distributor_id'));
    }

    return dispatch(getRequestDetails(mainDistributorId, BO_API_PERSONAL_DATA, true))
      .then((requestDetails) =>
        // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
        NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_PERSONAL_DATA, {
          ...requestDetails,
          signal,
        }),
      )
      .then(({ response }: BO_RESPONSE_WITH_ETAG) => {
        if (signal?.aborted) {
          return;
        }

        const {
          body: {
            data: { productId },
          },
        } = response;

        dispatch(updateBOProductId(productId));
      })
      .catch((error) => logError(`Error retrieving personal data for "${mainDistributorId}": ${error.message}`));
  };

export default sendVideofuturPersonalDataRequest;
