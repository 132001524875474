/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { REDUX_MSG_REQUEST_DATA_COLLECTION_COLLECTOR_ID } from '../../constants';

const sendGetCollectorIdRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appRegistration: { upgradeDeviceUrl },
    } = getState();

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_DATA_COLLECTION_COLLECTOR_ID, {
      signal,
      uri: `${upgradeDeviceUrl}getCollectorID`,
    }).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { result } = response;
      const { collectorId } = JSON.parse(result);
      return Promise.resolve(collectorId);
    });
  };

export default sendGetCollectorIdRequest;
