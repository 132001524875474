/* @flow */

import type { KeyValuePair } from '../../dist/types';

const cleanLanguage: (language: string) => string = (language) => {
  const [code] = language.toLowerCase().split('-');
  return code;
};

/*
 * What this function does:
 *  - Removes duplicates
 *  - Removes country-specific codes (e.g. ["fr", "fr-BE"] and ["fr-CA"] both becomes ["fr"])
 *  - Converts to lower case
 *  - Preserves order
 */
const cleanLanguageList: (languages: $ReadOnlyArray<string>) => Array<string> = (languages) => {
  const langDic: KeyValuePair<number> = {};

  if (languages) {
    let i = 0;
    languages.forEach((l) => {
      const code = cleanLanguage(l);
      if (typeof langDic[code] !== 'number') {
        langDic[code] = i;
        i += 1;
      }
    });
  }

  const langCodes = Object.keys(langDic);
  const cleanedLanguages = Array(langCodes.length);

  langCodes.forEach((code) => {
    const index = langDic[code];
    cleanedLanguages[index] = code;
  });

  return cleanedLanguages;
};

const checkUpdatePageTitle: (language: string) => void = (language) => {
  // Find all titles in page
  document.head?.querySelectorAll('meta[name=title]').forEach((tag) => {
    if (tag instanceof HTMLMetaElement) {
      const { content, lang } = tag;

      if (lang === language && content) {
        document.title = content;
      }
    }
  });
};

export { checkUpdatePageTitle, cleanLanguage, cleanLanguageList };
