/* @flow */

import {
  BO_INVALID_APPLICATION_V2,
  BO_INVALID_JWT_V2,
  BO_INVALID_SUBSCRIBER_V2,
  BO_PURCHASE_ALREADY_PURCHASED_V2,
  BO_PURCHASE_INVALID_PROMOCODE_FOR_TITLE_V2,
  BO_PURCHASE_INVALID_PROMOCODE_V2,
  BO_PURCHASE_INVALID_USER_IP_V2,
  BO_PURCHASE_SVOD_CONTENT_RIGHT_NOT_FOUND_V2,
  BO_STREAM_DEVICE_NOT_FOUND,
  BO_STREAM_INVALID_USER_IP,
  BO_STREAM_MAX_CONCURRENT_STREAMS_REACHED,
  BO_STREAM_NO_SVOD_CONTENT_RIGHT,
} from '../videofutur/types/ErrorCodes';
import { type SentryDataType, SentryTagName, SentryTagValue } from '../../../helpers/debug/sentryTypes';
import { XhrResponseType, getXhrResponseTypeFromString } from '../../../helpers/jsHelpers/xhr';
import CancellablePromise from './cancellablePromise/CancellablePromise';
import { CustomNetworkError } from './CustomNetworkError';
import { HttpMethod } from '../v8/types/HttpMethod';
import { HttpStatus } from '../v8/constants/NetworkCodesAndMessages';
import type { NETGEM_API_V8_REQUEST_HEADERS } from '../v8/types/Headers';
import SentryWrapper from '../../../helpers/debug/sentry';
import { createCustomNetworkError } from './CreateCustomNetworkError';
import { logError } from '../../../helpers/debug/debug';

export type CommonPromisedXMLHttpRequestReturnType = {|
  promise: Promise<any>,
  xhr: XMLHttpRequest,
|};

const getETagFromRequest: (req: XMLHttpRequest) => string | null = (req) => {
  const responseHeaders = req.getAllResponseHeaders();
  const result = /\betag:\s*(.+)/giu.exec(responseHeaders);
  if (result && result.length > 0) {
    return result[1] ?? null;
  }

  return null;
};

const isErrorExcluded: (req: XMLHttpRequest, url: string) => boolean = (req, url) => {
  const { status } = req;

  if (status === HttpStatus.NotModified) {
    // Do not report 304 status because we heavily rely on them
    return true;
  }

  if (status === HttpStatus.NotFound && (url.endsWith('key=wishlist') || url.indexOf('key=viewinghistory') > -1)) {
    // Do not report 404 status for wishlist and viewing history since it's expected when these lists are empty
    return true;
  }

  return status === HttpStatus.Unauthorized && req.getResponseHeader('WWW-Authenticate') !== null;
};

const notifySentry: (req: XMLHttpRequest, url: string, customNetworkError: CustomNetworkError, message: string, error?: Error) => void = (req, url, customNetworkError, message, error) => {
  if (isErrorExcluded(req, url)) {
    return;
  }

  let tagValue: SentryTagValue = SentryTagValue.Xhr;
  const breadcrumbs: Array<string> = [message];
  const customCode: number = customNetworkError.getCustomCode();

  if (url.indexOf('datacoll') > -1) {
    tagValue = SentryTagValue.DataCollect;
    breadcrumbs.push('data collect');
  } else if (url.endsWith('/purchase') || url.endsWith('/purchaseStatus')) {
    tagValue = SentryTagValue.VodPurchase;
    breadcrumbs.push('purchase or purchaseStatus');
  } else if (url.endsWith('/record') || url.endsWith('/record/future') || url.endsWith('/scheduledRecord/List') || url.endsWith('/scheduledRecord') || url.indexOf('/scheduledRecord?id=') > -1) {
    tagValue = SentryTagValue.Recorder;
    breadcrumbs.push('recorder');
  } else if (url.endsWith('/stream/create') || url.endsWith('/stream/start') || url.endsWith('/stream/stop')) {
    tagValue = SentryTagValue.Player;
    breadcrumbs.push('stream create/start/stop');
  }

  // Possible codes for stream and purchase endpoints
  if (customCode === BO_STREAM_MAX_CONCURRENT_STREAMS_REACHED) {
    breadcrumbs.push('max concurrent streams reached');
  } else if (customCode === BO_STREAM_NO_SVOD_CONTENT_RIGHT || customCode === BO_PURCHASE_SVOD_CONTENT_RIGHT_NOT_FOUND_V2) {
    breadcrumbs.push('no SVOD right');
  } else if (customCode === BO_STREAM_INVALID_USER_IP || customCode === BO_PURCHASE_INVALID_USER_IP_V2) {
    breadcrumbs.push('invalid user IP');
  } else if (customCode === BO_STREAM_DEVICE_NOT_FOUND || customCode === BO_INVALID_JWT_V2 || customCode === BO_INVALID_APPLICATION_V2 || customCode === BO_INVALID_SUBSCRIBER_V2) {
    breadcrumbs.push('device not found or invalid token');
  } else if (customCode === BO_PURCHASE_INVALID_PROMOCODE_V2 || customCode === BO_PURCHASE_INVALID_PROMOCODE_FOR_TITLE_V2) {
    breadcrumbs.push('invalid promocode');
  } else if (customCode === BO_PURCHASE_ALREADY_PURCHASED_V2) {
    breadcrumbs.push('already purchased');
  }

  const data: SentryDataType = {
    breadcrumbs,
    context: {
      customCode: customNetworkError.getCustomCode(),
      customMessage: customNetworkError.message,
      customStatus: customNetworkError.getCustomStatus(),
      status: customNetworkError.getStatus(),
      url,
    },
    message,
    tagName: SentryTagName.Component,
    tagValue,
  };

  if (error) {
    data.error = error;
  }

  SentryWrapper.error(data);
};

const commonPromisedXMLHttpRequest: (
  url: string,
  method: HttpMethod,
  expectedResponseType: XhrResponseType,
  requestHeaderList: ?NETGEM_API_V8_REQUEST_HEADERS,
  bodyParam: ?string,
  requestParam: ?string,
  withCredentials: boolean,
  networkErrorCallBack: (request: XMLHttpRequest, message: ?string) => CustomNetworkError,
  signal: ?AbortSignal,
  keepAlive?: boolean,
) => CommonPromisedXMLHttpRequestReturnType = (url, method, expectedResponseType, requestHeaderList, bodyParam, requestParam, withCredentials, networkErrorCallBack, signal, keepAlive) => {
  const headers = requestHeaderList ?? [];
  const req = new XMLHttpRequest();

  const initialPromise = new Promise((resolve, reject) => {
    const baseUrl = `${url}${requestParam ?? ''}`;

    req.open((method: string), baseUrl, true);

    req.onload = () => {
      try {
        const { status } = req;

        if (status >= HttpStatus.OK && status < HttpStatus.MultipleChoices) {
          // Response OK: let's process it
          const { response, responseType } = req;
          if (getXhrResponseTypeFromString(responseType) === XhrResponseType.Xml) {
            const { responseXML } = req;
            if (responseXML) {
              resolve(responseXML);
              return;
            }
          }
          resolve(response);
          return;
        }

        // Error
        const customNetworkError = networkErrorCallBack(req);
        notifySentry(req, url, customNetworkError, 'XHR onload bad status');
        reject(customNetworkError);
      } catch (err) {
        logError(`[OnLoad] XHR error for: ${url}`);
        const customNetworkError = networkErrorCallBack(req, err.message);
        notifySentry(req, url, customNetworkError, 'XHR onload catch', err);
        reject(customNetworkError);
      }
    };

    req.onerror = () => {
      const customNetworkError = networkErrorCallBack(req);
      logError(`[OnError] XHR error for: ${url}`);
      notifySentry(req, url, customNetworkError, 'XHR onerror');
      reject(customNetworkError);
    };

    req.responseType = (expectedResponseType: string);
    req.withCredentials = withCredentials;

    // Keep-alive if required
    if (keepAlive === true) {
      req.setRequestHeader('Connection', 'keep-alive');
    }

    // Set request header
    if (headers) {
      headers.forEach((header) => {
        const { name, value } = header;
        req.setRequestHeader(name, value);
      });
    }

    // Make the request
    req.send(bodyParam);
  });

  const cancellablePromise = new CancellablePromise(initialPromise, signal);

  return {
    promise: cancellablePromise.getPromise(),
    xhr: req,
  };
};

const commonNetworkErrorCallBack: (request: XMLHttpRequest, message: ?string) => CustomNetworkError = (request, message) => {
  const { response, status, statusText } = request;

  // In BO v2 everything is wrapped in 'body'
  return createCustomNetworkError(status, message ?? statusText, request.getAllResponseHeaders(), response?.body ?? response);
};

export { commonNetworkErrorCallBack, commonPromisedXMLHttpRequest, getETagFromRequest };
