/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import { updateCommercialOffers, updateDefaultRights, updateUserRights } from '../../../appConf/actions';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { REDUX_MSG_REQUEST_GENERIC } from '../../constants';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const sendCommercialOffersRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { rightsCommercialOffersUrl },
    } = state;

    if (!rightsCommercialOffersUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'rightsCommercialOffersUrl' }));
    }

    const { authent, method } = rightsCommercialOffersUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      method,
      signal,
      uri: generateApiUrl(rightsCommercialOffersUrl, {}, state),
    }).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { result } = response;

      dispatch(updateCommercialOffers(result));
      return Promise.resolve(response);
    });
  };

const sendDefaultRightsRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { rightsDefaultUrl },
    } = state;

    if (!rightsDefaultUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'rightsDefaultUrl' }));
    }

    const { authent, method } = rightsDefaultUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      method,
      signal,
      uri: generateApiUrl(rightsDefaultUrl, {}, state),
    }).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { result } = response;

      dispatch(updateDefaultRights(result));
      return Promise.resolve(response);
    });
  };

const sendUserRightsRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { rightsUserUrl },
    } = state;

    if (!rightsUserUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'rightsUserUrl' }));
    }

    const { authent, method } = rightsUserUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      method,
      signal,
      uri: generateApiUrl(rightsUserUrl, {}, state),
    }).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const {
        result: { rights },
      } = response;

      dispatch(updateUserRights(rights));
      return Promise.resolve(response);
    });
  };

export { sendCommercialOffersRequest, sendDefaultRightsRequest, sendUserRightsRequest };
