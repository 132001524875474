/* @flow */

import { BO_INVALID_APPLICATION_V2, BO_INVALID_JWT_V2, BO_INVALID_SUBSCRIBER_V2, BO_STREAM_DEVICE_NOT_FOUND } from '../types/ErrorCodes';
import { type CommonPromisedXMLHttpRequestReturnType, commonNetworkErrorCallBack, commonPromisedXMLHttpRequest, getETagFromRequest } from '../../helpers/CommonPromisedXMLHttpRequest';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { BO_API_PARAMETERS_TYPE } from '../../../../helpers/videofutur/queryParameters';
import type { BO_RESPONSE_WITH_ETAG } from '../../../../redux/netgemApi/actions/videofutur/types/common';
import { CustomNetworkError } from '../../helpers/CustomNetworkError';
import { HeaderName } from '../../v8/constants/Headers';
import { HttpMethod } from '../../v8/types/HttpMethod';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_V8_REQUEST_HEADERS } from '../../v8/types/Headers';
import { XhrResponseType } from '../../../../helpers/jsHelpers/xhr';
import { convertXmlResponseToJson } from '../../../../redux/netgemApi/actions/helpers/bo';

const buildUrl: (url: string, parameters: BO_API_PARAMETERS_TYPE) => string = (url, parameters) => {
  let urlWithParameters = url;

  Object.entries(parameters).forEach(([key, value]) => {
    urlWithParameters = urlWithParameters.replace(`{${key}}`, typeof value === 'string' ? value : '');
  });

  return urlWithParameters;
};

const replaceParameters: (baseUrl: string, parameters: ?BO_API_PARAMETERS_TYPE) => string = (baseUrl, parameters) => {
  if (!parameters) {
    return baseUrl;
  }

  const params = (parameters: BO_API_PARAMETERS_TYPE);

  let url = baseUrl;
  Object.entries(params).forEach(([key, value]) => {
    const pattern = `{${key}}`;

    if (typeof value === 'boolean' || typeof value === 'number' || typeof value === 'string') {
      if (url.indexOf(pattern) > -1) {
        // Mandatory parameter
        url = url.replace(pattern, value.toString());
      } else {
        // Optional parameter
        url += `&${key}=${value.toString()}`;
      }
    }
  });

  return url;
};

const sendBORequest: (
  baseUrl: string,
  method: HttpMethod,
  authenticationToken: string | null,
  responseType: XhrResponseType,
  parameters: ?BO_API_PARAMETERS_TYPE,
  body: ?KeyValuePair<string | number | boolean>,
  headers: ?NETGEM_API_V8_REQUEST_HEADERS,
  signal?: AbortSignal,
  keepAlive?: boolean,
  eTag?: string,
) => Promise<BO_RESPONSE_WITH_ETAG> = (baseUrl, method, authenticationToken, responseType, parameters, body, headers, signal, keepAlive, eTag) => {
  // Add authentication token
  const headerList = headers ?? [];

  if (authenticationToken) {
    // BO API v2: add authentication token if present
    headerList.push({
      name: HeaderName.Authorization,
      value: `Bearer ${authenticationToken ?? ''}`,
    });
  }

  if (eTag) {
    headerList.push({
      name: !method || method === HttpMethod.GET ? HeaderName.IfNoneMatch : HeaderName.IfMatch,
      value: eTag,
    });
  }

  const returnObject: CommonPromisedXMLHttpRequestReturnType = commonPromisedXMLHttpRequest(
    replaceParameters(baseUrl, parameters),
    method,
    responseType,
    headers,
    body ? JSON.stringify(body) : null,
    null,
    false,
    commonNetworkErrorCallBack,
    signal,
    keepAlive,
  );

  return returnObject.promise
    .then((response: any) => {
      const { xhr } = returnObject;
      const newETag = getETagFromRequest(xhr);

      if (responseType === XhrResponseType.Xml) {
        return Promise.resolve({ eTag: newETag, response: convertXmlResponseToJson((response: Document)) });
      }

      return Promise.resolve({ eTag: newETag, response });
    })
    .catch((error: CustomNetworkError) => {
      const code = error.getCustomCode();
      if (code === BO_STREAM_DEVICE_NOT_FOUND || code === BO_INVALID_JWT_V2 || code === BO_INVALID_APPLICATION_V2 || code === BO_INVALID_SUBSCRIBER_V2) {
        // Token expired (device probably disconnected)
        Messenger.emit(MessengerEvents.AUTHENTICATION_REQUIRED);
      }

      throw error;
    });
};

export { buildUrl, sendBORequest };
