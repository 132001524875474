/* @flow */

import { SubtitlesBGColor, SubtitlesSize } from './subtitles';
import { Setting } from './types';

/*
 * See Shaka Player doc for information about the 3 following values:
 * https://shaka-player-demo.appspot.com/docs/api/tutorial-network-and-buffering-config.html
 */

// The amount of content attempted to be buffered (in s)
const DefaultBufferingGoal = 60;

// The minimum amount of content required to start playing (in s)
const DefaultRebufferingGoal = 2;

// The amount of content kept in buffer behind the playhead (in s)
const DefaultBufferBehind = 30;

// eslint-disable-next-line consistent-return
export const getDefaultValueFromSetting = (name: Setting): any => {
  switch (name) {
    case Setting.AutoOpenPlayerDebugPanel:
      // By default, player debug panel is not automatically reopened if it was open the last time the player exited
      return false;
    case Setting.AutoResume:
      // By default, if a viewing history exists, the player resumes to the last position without prompting the user
      return true;
    case Setting.AutoSelectAudioTrack:
      // By default, the player automatically tries to select the last audio track selected by the user
      return true;
    case Setting.AutoSelectSubtitlesTrack:
      // By default, the player automatically tries to select the last subtitles track selected by the user
      return true;
    case Setting.BufferingGoal:
      // By default, the buffering goal is set to 60s (see at the top of this file)
      return DefaultBufferingGoal;
    case Setting.RebufferingGoal:
      // By default, the rebuffering goal is set to 2s (see at the top of this file)
      return DefaultRebufferingGoal;
    case Setting.GreenStreaming:
      // By default, green streaming is disabled
      return false;
    case Setting.LastAudioTrack:
      // By default, there is no last audio track
      return '';
    case Setting.LastSubtitlesTrack:
      // By default, there is no last subtitles track
      return '';
    case Setting.BufferBehind:
      // By default, the buffer behind is set to 30s (see at the top of this file)
      return DefaultBufferBehind;
    case Setting.SubtitlesBGColor:
      // By default, the background color of subtitles is white (not implemented yet)
      return SubtitlesBGColor.White;
    case Setting.SubtitlesSize:
      // By default, the size of subtitles is Medium (not implemented yet)
      return SubtitlesSize.Medium;
    case Setting.Volume:
      // By default, the volume is 100%
      return 1;

    // No default
  }
};
