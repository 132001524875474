/* @flow */

import { AvenueType, type NAVIGATION_AVENUE_TYPE } from './types';
import { AVENUE_ID_EXPLORE } from './constants';
import type { NETGEM_API_V8_HUB } from '../../../libs/netgemLibrary/v8/types/Hub';

const getAvenueFromId: (avenueList: ?NETGEM_API_V8_HUB, avenueId: string) => ?NAVIGATION_AVENUE_TYPE = (avenueList, avenueId) => {
  if (!avenueList) {
    return null;
  }

  for (let index = 0; index < avenueList.length; ++index) {
    const { [index]: avenue } = avenueList;
    const { id, title } = avenue;

    if (avenueId === id) {
      const type = avenue.id === AVENUE_ID_EXPLORE ? AvenueType.Explore : AvenueType.Regular;

      return {
        index,
        title,
        type,
      };
    }
  }

  return null;
};

export { getAvenueFromId };
