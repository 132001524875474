/* @flow */

import { type AllSettledPromises, type SettledPromise, SettledPromiseRejected } from '../../../../helpers/jsHelpers/promise';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import { filterAndSortPlaybackUrls, generateApiUrl } from '../helpers/api';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { type NETGEM_API_V8_ITEM_LOCATION } from '../../../../libs/netgemLibrary/v8/types/FeedItem';
import { type NETGEM_API_V8_METADATA_SCHEDULE } from '../../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { REDUX_MSG_REQUEST_GENERIC } from '../../constants/';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { extractDistributorId } from '../../../../helpers/videofutur/metadata';

const fixVtiIdType: (response: NETGEM_API_V8_REQUEST_RESPONSE) => NETGEM_API_V8_REQUEST_RESPONSE = (response) => {
  const vtiId = response.result?.location?.purchaseInfo?.vtiId;
  if (typeof vtiId === 'string' && vtiId !== '' && !isNaN(vtiId)) {
    response.result.location.purchaseInfo.vtiId = Number(vtiId);
  }

  return response;
};

const sendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (assetId, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      appConfiguration: { playerStreamPriorities },
      netgemApi: { metadataScheduleUrl },
    } = state;

    if (!metadataScheduleUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'metadataScheduleUrl' }));
    }

    const { authent, method } = metadataScheduleUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      method,
      signal,
      state,
      uri: generateApiUrl(
        metadataScheduleUrl,
        {
          assetId,
        },
        state
      ),
    })
      .then((response: NETGEM_API_V8_REQUEST_RESPONSE) => fixVtiIdType(response))
      .then((response: NETGEM_API_V8_REQUEST_RESPONSE) => filterAndSortPlaybackUrls(response, playerStreamPriorities));
  };

const addDistributorId: (metadata: NETGEM_API_V8_METADATA_SCHEDULE, deviceOS: string) => NETGEM_API_V8_METADATA_SCHEDULE = (metadata, deviceOS) => {
  const {
    location: { id, providerInfo },
  } = metadata;

  if (!providerInfo) {
    return metadata;
  }

  if (providerInfo.distributorId) {
    // Distributor Id is present in providerInfo
    return metadata;
  }

  // Distributor Id is not present in providerInfo: try to extract it from location Id
  const distributorId = extractDistributorId(id, deviceOS);
  if (distributorId) {
    providerInfo.distributorId = distributorId;
  }

  return metadata;
};

const addAvailabilityStartDate: (metadata: NETGEM_API_V8_METADATA_SCHEDULE, locations: Array<NETGEM_API_V8_ITEM_LOCATION>) => NETGEM_API_V8_METADATA_SCHEDULE = (metadata, locations) => {
  const {
    location,
    location: { id },
  } = metadata;

  const matchingLocation = locations.find((loc) => loc.id === id);
  if (matchingLocation) {
    const { availabilityStartDate } = matchingLocation;
    if (availabilityStartDate) {
      location.availabilityStartDate = availabilityStartDate;
    }
  }

  return metadata;
};

/*
 * Used to retrieve the purchase info for VOD locations
 * Creates, for each of the given locations, a promise to retrieve that location's metadata, then builds a promise waiting all of these promises and returns it
 */
const getVodLocations: (locations: Array<NETGEM_API_V8_ITEM_LOCATION>, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (locations, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    // $FlowFixMe: Ids can't be undefined here but flow couldn't see it
    const promises = locations.map((loc) => dispatch(sendV8MetadataLocationRequest(loc.id, signal)));

    return Promise.allSettled(promises).then((results: AllSettledPromises) =>
      results
        .map((result: SettledPromise) => {
          const { status, value } = result;

          if (status === SettledPromiseRejected || !value) {
            return null;
          }

          const {
            appConfiguration: { deviceOS },
          } = getState();
          const { result: metadata } = value;
          return addAvailabilityStartDate(addDistributorId(metadata, deviceOS), locations);
        })
        .filter((m) => m !== null)
    );
  };

export default sendV8MetadataLocationRequest;

export { getVodLocations };
