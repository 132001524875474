/* @flow */

import type { MetadataKind, NETGEM_API_V8_METADATA } from '../../../../libs/netgemLibrary/v8/types/MetadataProgram';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { REDUX_MSG_REQUEST_GENERIC } from '../../constants/';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const sendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (assetId, type, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { metadataProgramUrl },
    } = state;

    if (!metadataProgramUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'metadataUrl' }));
    }

    const { authent, method } = metadataProgramUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      method,
      signal,
      state,
      uri: generateApiUrl(metadataProgramUrl, { assetId }, state),
    }).then(({ result }: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const metadata = {
        ...result.metadata,
        type,
      };
      return Promise.resolve((metadata: NETGEM_API_V8_METADATA));
    });
  };

export default sendV8MetadataRequest;
