/* @flow */

export enum SectionType {
  Carousel = 'carousel',
  ChannelGroup = 'channelgroup',
  Grid = 'grid',
  HeroPanel = 'heroPanel',
  Regular = 'sectionList',
}

export enum SectionDisplayType {
  Collapsed = 'collapsed',
  Grid = 'grid',
  Hidden = 'hidden',
  Regular = 'regular',
}

export type SECTION_PAGINATION = {|
  maxPageIndex: number,
  pageIndex: number,
  sliderPositions: Array<number>,
|};

export type SwipeEventData = {|
  deltaX: number,
  deltaY: number,
  dir: 'Down' | 'Left' | 'Right' | 'Up',
  velocity: number,
|};
