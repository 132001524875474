/* @flow */

import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import { createCustomNetworkErrorFromKey } from '../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { fetchJsonWithErrorCheck } from '../jsHelpers/xhr';

const getVersion: (isCheck?: boolean) => Promise<any> = async (isCheck) => {
  const filename = `/version.json${isCheck ? `?${AccurateTimestamp.now()}` : ''}`;

  try {
    const { version } = await fetchJsonWithErrorCheck(filename);

    if (version === 'local') {
      // Local development case
      const now = AccurateTimestamp.nowAsDate();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      const dateLengthWithoutSeconds = 16;
      return now.toISOString().substring(0, dateLengthWithoutSeconds).replace('T', ' ').replace(':', 'h');
    }

    // Regular case
    return version;
  } catch {
    // Fetch error
    throw createCustomNetworkErrorFromKey('common.messages.errors.version_error');
  }
};

export { getVersion };
