/* @flow */

import AccurateTimestamp from '../../dateTime/AccurateTimestamp';
import type { NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { getIntegerPercentage } from '../../maths/maths';

const getItemLiveProgress = (item: NETGEM_API_V8_FEED_ITEM): number => {
  const { endTime, startTime } = item;

  return getIntegerPercentage(AccurateTimestamp.nowInSeconds(), startTime, endTime);
};

export { getItemLiveProgress };
