/* @flow */

import { HeaderName, HeaderValue } from '../../v8/constants/Headers';
import { HttpMethod } from '../../v8/types/HttpMethod';
import { buildHeaderList } from '../../v8/helpers/RequestHeaders';
import sendV8Request from '../../v8/requests/v8';

const sendPersonalDataPostRequest: (url: string, data: any, eTag: ?string, method: HttpMethod, authenticationToken: string | null, signal: ?AbortSignal) => Promise<any> = (
  url,
  data,
  eTag,
  method,
  authenticationToken,
  signal,
) => {
  const headerList = buildHeaderList(HeaderName.ContentType, HeaderValue.ApplicationJsonUtf8, HeaderName.IfMatch, eTag);

  const body = JSON.stringify(data);
  return sendV8Request(url, authenticationToken, body, headerList, null, signal, null, method);
};

export default sendPersonalDataPostRequest;
