/* @flow */

import './ConfirmationModal.css';
import * as React from 'react';
import ButtonFX from '../../buttons/ButtonFX';
import Modal from '../modal';
import { ModalIcon } from '../modalTypes';
import type { ModalState } from '../../../redux/modal/reducers';
import { WidthKind } from '../../buttons/types';
import { connect } from 'react-redux';

const { Fragment } = React;

export enum ConfirmationModalResult {
  Button1,
  Button2,
  Cancel,
}

export type CONFIRMATION_DATA_MODAL_TYPE = {|
  button1Title: string,
  button2Title: string,
  header?: string,
  icon?: ModalIcon,
  question: string,
  title?: string,
|};

class ConfirmationModalView extends React.PureComponent<ModalState> {
  modal: React.ElementRef<any> | null;

  constructor(...args: Array<any>) {
    super(...args);

    this.modal = null;
  }

  close = (result: ConfirmationModalResult): void => {
    const { modal } = this;

    if (modal) {
      modal.close(result);
    }
  };

  handleButton1OnClick: () => void = () => {
    this.close(ConfirmationModalResult.Button1);
  };

  handleButton2OnClick: () => void = () => {
    this.close(ConfirmationModalResult.Button2);
  };

  renderQuestion = (): React.Node => {
    const { confirmationData } = this.props;

    if (!confirmationData) {
      return null;
    }

    const { question } = confirmationData;

    return (
      <div className='question'>
        {question.split('\n').map((line) => (
          <Fragment key={line}>
            {line}
            <br />
          </Fragment>
        ))}
      </div>
    );
  };

  renderButtons = (): React.Node => {
    const { confirmationData } = this.props;

    if (!confirmationData) {
      return null;
    }

    const { button1Title, button2Title } = confirmationData;

    return (
      <div className='buttons'>
        <ButtonFX key='button1' onClick={this.handleButton1OnClick} widthKind={WidthKind.Stretched}>
          {button1Title}
        </ButtonFX>
        <ButtonFX key='button2' onClick={this.handleButton2OnClick} widthKind={WidthKind.Stretched}>
          {button2Title}
        </ButtonFX>
      </div>
    );
  };

  render(): React.Node {
    const { confirmationData, index } = this.props;

    if (!confirmationData) {
      return null;
    }

    const { header, icon, title } = confirmationData;

    return (
      <Modal
        canCloseOnEnter={false}
        className='confirmation'
        header={header}
        icon={icon}
        index={index}
        ref={(instance) => {
          this.modal = instance;
        }}
        title={title}
      >
        {this.renderQuestion()}
        {this.renderButtons()}
      </Modal>
    );
  }
}

const ConfirmationModal: React.ComponentType<ModalState> = connect(null, null, null, { forwardRef: true })(ConfirmationModalView);

export default ConfirmationModal;
