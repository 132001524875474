/* @flow */

import { HeaderName } from '../netgemLibrary/v8/constants/Headers';
import type { UserInfo } from '../../redux/appRegistration/types/types';
import { fetchJsonWithErrorCheck } from '../../helpers/jsHelpers/xhr';
import { logError } from '../../helpers/debug/debug';

const getUserInfo: (url: string, token: string) => Promise<UserInfo> = async (url, token) => {
  const options = {
    headers: new Headers({ [HeaderName.Authorization]: `Bearer ${token}` }),
  };

  try {
    return await fetchJsonWithErrorCheck(url, options);
  } catch (error) {
    logError(error);
    throw error;
  }
};

export default getUserInfo;
