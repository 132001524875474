/* @flow */

const isGoogleBot: () => boolean = () => {
  const {
    navigator: { userAgent },
  } = window;
  const lcUserAgent = userAgent.toLowerCase();

  return lcUserAgent.indexOf('googlebot') > -1;
};

const isWhitelisted: () => boolean = () => {
  const {
    navigator: { userAgent },
  } = window;
  const lcUserAgent = userAgent.toLowerCase();

  return lcUserAgent.indexOf('googlebot') > -1 || lcUserAgent.indexOf('chrome-lighthouse') > -1;
};

export { isGoogleBot, isWhitelisted };
