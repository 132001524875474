/* @flow */

export const APPLICATION_ID = {
  FranceChannel: 'francechannel',
  Gaumont: 'gaumont',
  MyVideofutur: 'myvideofutur',
  Post: 'posttvgo',
  Premiere: 'premiere',
  Viva: 'viva',
  ZeopTV: 'zeoptv',
};
