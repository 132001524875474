/* @flow */

import * as React from 'react';
import { Localizer } from '@ntg/utils/dist/localization';
import { PictoExternal } from '@ntg/components/dist/pictos/Element';
import { useMemo } from 'react';

type ExternalLinkPropType = {|
  +textKey: string,
  +url: string,
|};

const ExternalLink = ({ textKey, url }: ExternalLinkPropType): React.Node => {
  const text = useMemo(() => Localizer.localize(textKey), [textKey]);

  return (
    <a href={url} rel='noopener noreferrer' target='_blank'>
      {text}
      <PictoExternal />
    </a>
  );
};

export default ExternalLink;
