/* @flow */

import { SKIP_DURATION_LEVEL0, SKIP_DURATION_LEVEL1, SKIP_DURATION_LEVEL2, type SkipDuration } from './constants';
import { SkipDirection } from './types';
import { SkippingKind } from '../../components/player/constantsAndTypes';

export enum ControlLevel {
  Level0,
  Level1,
  Level2,
}

const eventToControlLevel = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement> | SyntheticKeyboardEvent<HTMLElement>): ControlLevel => {
  const { ctrlKey, shiftKey } = event;

  if (shiftKey) {
    return ControlLevel.Level2;
  }

  if (ctrlKey) {
    return ControlLevel.Level1;
  }

  return ControlLevel.Level0;
};

const getSkippingTypeAndValue = (
  direction: SkipDirection,
  event?: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement> | SyntheticKeyboardEvent<HTMLElement>,
): {| type: SkippingKind, value: SkipDuration |} => {
  let type = direction === SkipDirection.Forward ? SkippingKind.ForwardLevel0 : SkippingKind.BackwardLevel0;
  let value = SKIP_DURATION_LEVEL0;

  if (event) {
    const controlLevel = eventToControlLevel(event);

    switch (controlLevel) {
      case ControlLevel.Level1:
        type = direction === SkipDirection.Forward ? SkippingKind.ForwardLevel1 : SkippingKind.BackwardLevel1;
        value = SKIP_DURATION_LEVEL1;
        break;

      case ControlLevel.Level2:
        type = direction === SkipDirection.Forward ? SkippingKind.ForwardLevel2 : SkippingKind.BackwardLevel2;
        value = SKIP_DURATION_LEVEL2;
        break;

      default:
      // Nothing to do
    }
  }

  if (direction === SkipDirection.Backward) {
    value = -value;
  }

  return {
    type,
    value,
  };
};

export { getSkippingTypeAndValue, eventToControlLevel };
