/* @flow */

import type { METRICS_PARAMS, METRICS_TYPE } from './Channel';
import { Definition } from '../../../../helpers/ui/metadata/Types';
import { Drm } from '../../../../helpers/jsHelpers/Drm';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_V8_AUTHENT_REALM } from './Realm';
import type { NETGEM_API_V8_LOCATION_RIGHTS } from './Rights';
import type { NETGEM_API_V8_METADATA_PROVIDER_INFO } from './MetadataProgram';
import { type NETGEM_API_V8_URL_DEFINITION } from './NtgVideoFeed';
import type { RecordingOutcome } from './Npvr';

export enum StreamType {
  DASH = 'dash',
  HLS = 'hls',
  MP4 = 'mp4',
  MSS = 'mss',
  Smooth = 'smooth',
}

export const STREAM_TYPE_PRIORITIES: Array<StreamType> = Object.freeze([StreamType.DASH, StreamType.HLS, StreamType.MSS, StreamType.MP4, StreamType.Smooth]);

export type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_DRM_LAURL_PARAM = {|
  url: string,
|};

export type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_DRM_CUSTOMDATA_PARAM = {|
  customData: string,
|};

export type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_ENTITLEMENT_PARAM = {|
  customData: string,
  service: string,
  url: string,
|};

export type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_DRM_PARAM = {|
  customData?: NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_DRM_CUSTOMDATA_PARAM,
  laUrl?: NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_DRM_LAURL_PARAM,
  ntgEntitlement?: NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_ENTITLEMENT_PARAM,
  type?: Drm,
|};

export type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_VIDEO_RES_PARAM = {|
  h: number,
  w: number,
|};

export type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_VIDEO_PARAM = {|
  bitrate?: number,
  res?: NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_VIDEO_RES_PARAM,
|};

export type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PROPERTY_PARAM = {|
  hint?: string,
  videos?: Array<NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_VIDEO_PARAM>,
|};

export type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PARAM = {|
  drms: Array<NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_DRM_PARAM>,
  path: string,
  properties?: Array<NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PROPERTY_PARAM>,
  type: StreamType,
|};

export type NETGEM_API_V8_METADATA_LOCATION_VIDEO_STREAM_PARAM = {|
  drms: Array<NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_DRM_PARAM>,
  path: string,
  properties?: KeyValuePair<Array<any>>,
  type: StreamType,
|};

export type NETGEM_API_V8_PRICE = {|
  amount: number,
  currency: string,
  display: string,
|};

export type NETGEM_API_V8_VOD_PURCHASEINFO = {|
  vtiId: number,
  definition: Definition,
  licenseDuration: string,
  priceOptions?: Array<NETGEM_API_V8_PRICE>,
|};

export type NETGEM_API_V8_PLAYBACK_URL = {|
  player: string,
  start: NETGEM_API_V8_URL_DEFINITION,
|};

export type LOCATION_METADATA_METRICS = {|
  type: METRICS_TYPE,
  properties: METRICS_PARAMS,
|};

export type NETGEM_API_V8_METADATA_SCHEDULE_LOCATION = {|
  authority?: NETGEM_API_V8_AUTHENT_REALM,
  availabilityEndDate?: string,
  availabilityStartDate?: string,
  channelId: string,
  duration: string,
  endMargin?: string,
  id: string,
  isLivePlaybackEnabled?: boolean,
  metrics?: Array<LOCATION_METADATA_METRICS>,
  playbackUrls?: Array<NETGEM_API_V8_PLAYBACK_URL>,
  programId?: string,
  providerInfo?: NETGEM_API_V8_METADATA_PROVIDER_INFO,
  purchaseInfo?: NETGEM_API_V8_VOD_PURCHASEINFO,
  recordOutcome: RecordingOutcome,
  rights?: NETGEM_API_V8_LOCATION_RIGHTS,
  scheduledRecordId?: string,
  startDate?: string,
  startMargin?: string,
  target: string,
  targetProgram: {|
    id: string,
    episodeOf?: {|
      id: string,
    |},
  |},
  tracking?: {|
    start: NETGEM_API_V8_URL_DEFINITION,
  |},
  userViewEndOffset?: string,
  userViewStartOffset?: string,
|};

export type NETGEM_API_V8_METADATA_SCHEDULE = {|
  location: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
|};
