/* @flow */

import { BO_API_PURCHASE_STATUS, type BO_RESPONSE_WITH_ETAG } from './types/common';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import { type BO_API_PURCHASE_STATUS_RESPONSE_TYPE } from './types/purchase';
import { BO_PURCHASE_STATUS_OK } from '../../../../libs/netgemLibrary/videofutur/types/ErrorCodes';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_BO_PURCHASE_STATUS } from '../../constants';
import { buildErrorResponse } from '../helpers/bo';
import getRequestDetails from './helpers/requestCommon';

const mapResponse: (response: any) => BO_API_PURCHASE_STATUS_RESPONSE_TYPE = (response) => {
  const { purchase } = response;

  if (!purchase) {
    return buildErrorResponse(null, {});
  }

  const status = purchase.status?._text ?? ''; // eslint-disable-line no-underscore-dangle

  if (status !== BO_PURCHASE_STATUS_OK) {
    // Error
    return buildErrorResponse(status, purchase);
  }

  // Success
  const {
    duration: { _text: duration },
    price: { _text: price },
    url: { _text: url },
    vhiId: { _text: vhiId },
  } = purchase;

  return {
    duration,
    price,
    status,
    url,
    vhiId: Number(vhiId),
  };
};

const mapResponseNew: (response: any) => BO_API_PURCHASE_STATUS_RESPONSE_TYPE = (response) => {
  const { body } = response;

  if (!body?.data) {
    return buildErrorResponse(null, {});
  }

  const {
    data: {
      drmLicense: { duration },
      // TODO: when BO API v2 is OK, use 'price' which is already a number
      displayPrice,
    },
  } = body;

  return {
    duration,
    price: displayPrice,
    status: BO_PURCHASE_STATUS_OK,
  };
};

const sendPurchaseStatusRequest: (distributorId: string, vtiId: number, promocode: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (distributorId, vtiId, promocode, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration: { useBOV2Api },
    } = getState();

    return dispatch(getRequestDetails(distributorId, BO_API_PURCHASE_STATUS, useBOV2Api)).then((requestDetails) =>
      // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
      NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_PURCHASE_STATUS, {
        ...requestDetails,
        promocode,
        signal,
        useBOV2Api,
        vtiId,
      }).then(({ response }: BO_RESPONSE_WITH_ETAG) => (useBOV2Api ? mapResponseNew(response) : mapResponse(response))),
    );
  };

export default sendPurchaseStatusRequest;
