/* @flow */

export const GOOGLE_SEARCH_URL = 'https://www.google.com/search?q={searchString}';

export const CAST_TOOLTIP = Object.freeze({
  Distance: 0,
  HideDelay: 150,
  ShowDelay: 300,
});

export type CommonPropType = {|
  +onHidden: (tip: any) => void,
  +onTrigger: (tip: any) => void,
|};
