/* @flow */

import type { BO_API_BASE_TYPE, BO_API_ERROR_TYPE } from './common';
import type { KeyValuePair } from '@ntg/utils/dist/types';

export type BO_API_PURCHASE_STATUS_SUCCESS_TYPE = {|
  ...BO_API_BASE_TYPE,
  duration: string,
  price: string,
  url?: string,
  vhiId?: number,
|};

export type BO_API_PURCHASE_SUCCESS_TYPE = {|
  ...BO_API_BASE_TYPE,
  keyId: string,
  licenceIssuer: string,
  price: string,
  url: string,
  vhiId: number,
|};

export type BO_API_PURCHASE_SUCCESS_NEW_TYPE = {|
  price: string,
  status: string,
|};

export type BO_API_PURCHASE_STATUS_RESPONSE_TYPE = BO_API_PURCHASE_STATUS_SUCCESS_TYPE | BO_API_ERROR_TYPE;
export type BO_API_PURCHASE_RESPONSE_TYPE = BO_API_PURCHASE_SUCCESS_TYPE | BO_API_PURCHASE_SUCCESS_NEW_TYPE | BO_API_ERROR_TYPE;

export const Purchase = Object.freeze({
  Buy: 'buy',
  BuyPack: 'buy_pack',
  Rent: 'rent',
});

export type PurchaseType = 'buy' | 'buy_pack' | 'rent';

export enum PurchaseSubType {
  Movie = 'movie',
  Season = 'season',
}

// The expiration date is a timestamp in seconds (or 0 for bought items)
export type BO_PURCHASE_LIST_ITEM_TYPE = {|
  expirationTime?: number,
  lastBookmark: number,
  packVtiId?: number,
  purchaseDate: ?string,
  subType: PurchaseSubType,
  type: PurchaseType,
|};

// Keys are VTI Ids
export type BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE = KeyValuePair<BO_PURCHASE_LIST_ITEM_TYPE>;

// Keys are distributor Ids
export type BO_PURCHASE_LIST_TYPE = KeyValuePair<BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE>;

export type BO_API_PURCHASE_TYPE = {|
  canal: number,
  currentTime: number,
  licenseStatus: number,
  licenseTimeLeft: string,
  titId: number,
  token: string,
  vhiDate: string,
  vhiId: number,
  vtiId: number,
|};

export type BO_API_PURCHASE_LIST_TYPE = {|
  purchases: Array<BO_API_PURCHASE_TYPE>,
|};

export type BO_API_PURCHASE_LIST_NEW_TYPE = {|
  body: Array<{|
    data: {|
      itemId: number,
      purchaseTitleData: {|
        expirationDate: string,
        licenseStatus: number,
        licenseTimeLeft: string,
        packVtiId?: number,
        referer: string,
        titId: number,
        vhiDate: string,
        videoPosition: number,
        vtiId: number,
      |},
      subType: PurchaseSubType,
    |},
  |}>,
|};

export type BO_PURCHASE_LISTS = Array<{| distributorId: string, eTag: string | null, purchaseList: BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE |}>;
