/* @flow */

import { CustomNetworkError } from '../../helpers/CustomNetworkError';
import { HttpMethod } from '../../v8/types/HttpMethod';
import { HttpStatus } from '../../v8/constants/NetworkCodesAndMessages';
import { XhrResponseType } from '../../../../helpers/jsHelpers/xhr';
import { commonPromisedXMLHttpRequest } from '../../helpers/CommonPromisedXMLHttpRequest';
import { createCustomNetworkError } from '../../helpers/CreateCustomNetworkError';

const errorCallBack = (): CustomNetworkError => createCustomNetworkError(HttpStatus.Error);

const dmsLogoutRequest: (logoutUrl?: string) => Promise<void> = (logoutUrl) => {
  if (!logoutUrl) {
    return Promise.resolve();
  }

  // We don't care about the response
  return commonPromisedXMLHttpRequest(logoutUrl, HttpMethod.GET, XhrResponseType.Json, null, null, null, true, errorCallBack).promise.catch(() => Promise.resolve());
};

export default dmsLogoutRequest;
