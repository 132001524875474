/* @flow */

import './Credits.css';
import * as React from 'react';
import { CAST_TOOLTIP, type CommonPropType, GOOGLE_SEARCH_URL } from './helper';
import { WikipediaResultStatusType, type WikipediaResultType, type WikipediaSummary } from '../../helpers/Wikipedia/Types';
import { useCallback, useEffect, useRef, useState } from 'react';
import ExternalLink from '../link/ExternalLink';
import { Localizer } from '@ntg/utils/dist/localization';
import { TIPPY_DEFAULT_OPTIONS } from '@ntg/ui/dist/tooltip';
import Tippy from '@tippyjs/react';
import Wikipedia from '../../helpers/Wikipedia/Wikipedia';
import { ignoreIfAborted } from '../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { useSelector } from 'react-redux';

type CreditPropType = {|
  ...CommonPropType,
  +name: string,
|};

const renderNotFoundContent = (name: string): React.Node => (
  <div className='tooltipContent castSummary'>
    <div className='summary' key='not-found'>
      {Localizer.localize('credits.wikipedia.not_found')}
    </div>
    <ExternalLink key='google-link' textKey='credits.google_search' url={GOOGLE_SEARCH_URL.replace('{searchString}', encodeURIComponent(name))} />
  </div>
);

const renderCastContent = (status: WikipediaResultStatusType, summary: WikipediaSummary, wikipediaUrl: string): React.Node => {
  const { authorsUrl, extract, imageUrl, pageUrl, title } = summary;
  const text = status === WikipediaResultStatusType.Success ? extract || '' : Localizer.localize('credits.wikipedia.disambiguation');

  return (
    <div className='tooltipContent castSummary'>
      <div className='detailsAndImage' key='details'>
        <div className='details'>
          <div className='name'>{title}</div>
          <div className='summary'>{text}</div>
          <ExternalLink textKey='credits.wikipedia.more' url={pageUrl} />
        </div>
        {imageUrl ? <img src={imageUrl} /> : null}
      </div>
      <div className='citation' key='citation'>
        <div className='copyright'>{Localizer.localize('credits.wikipedia.copyright')}</div>
        <a href={authorsUrl} rel='noopener noreferrer' target='_blank'>
          {Localizer.localize('credits.wikipedia.copyright_name')}
        </a>
        <div className='license'>{Localizer.localize('credits.wikipedia.license')}</div>
        <a href={wikipediaUrl} rel='noopener noreferrer' target='_blank'>
          {Localizer.localize('credits.wikipedia.license_name')}
        </a>
      </div>
    </div>
  );
};

const Credit = ({ name, onHidden, onTrigger }: CreditPropType): React.Node => {
  const wikipediaUrl = useSelector((state) => state.appConfiguration.wikipediaUrl);
  const abortController = useRef<AbortController>(new AbortController());
  const [wikipediaResult, setWikipediaResult] = useState<WikipediaResultType | null>(null);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      abortController.current.abort('Component Credit will unmount');
    };
  }, []);

  const handleCastSummaryOnShow = useCallback((): void => {
    if (wikipediaResult !== null) {
      // Tooltip content already retrieved
      return;
    }

    const { signal } = abortController.current;

    // Make request
    Wikipedia.getData(name, signal)
      .then((result) => {
        signal.throwIfAborted();
        setWikipediaResult(result);
      })
      .catch((error) => ignoreIfAborted(signal, error));
  }, [name, onTrigger, wikipediaUrl]);

  const renderContent = useCallback(() => {
    if (wikipediaResult === null) {
      return null;
    }

    const { status, summary } = wikipediaResult;

    if ((status === WikipediaResultStatusType.Success || status === WikipediaResultStatusType.Disambiguation) && summary) {
      return renderCastContent(status, summary, wikipediaUrl);
    }

    return renderNotFoundContent(name);
  }, [wikipediaResult]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Tippy
      {...TIPPY_DEFAULT_OPTIONS}
      allowHTML
      appendTo={document.body}
      content={renderContent()}
      delay={[CAST_TOOLTIP.ShowDelay, CAST_TOOLTIP.HideDelay]}
      hideOnClick={false}
      interactive
      offset={[0, CAST_TOOLTIP.Distance]}
      onHidden={onHidden}
      onShow={handleCastSummaryOnShow}
      onTrigger={onTrigger}
    >
      <span className='cast'>{name}</span>
    </Tippy>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export default Credit;
