/* @flow */

import { FAKE_EPG_LIVE_PREFIX, FAKE_LIVE_CHANNEL_PREFIX } from '../item/types';
import { type NETGEM_API_V8_AUTHENT_REALM, NETGEM_API_V8_AUTHENT_REALM_NETGEM } from '../../../libs/netgemLibrary/v8/types/Realm';
import {
  type NETGEM_API_V8_FEED_ITEM,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_APP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_EST,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD,
} from '../../../libs/netgemLibrary/v8/types/FeedItem';
import { getAvailabilityEndTimeOrDefault, getAvailabilityStartTimeOrDefault } from '../../videofutur/metadata';
import AccurateTimestamp from '../../dateTime/AccurateTimestamp';
import { MILLISECONDS_PER_SECOND } from '../../dateTime/Format';
import { getLocationType } from '../../../libs/netgemLibrary/v8/helpers/Item';
import { getStartAndEndTimes } from '../metadata/Format';

export enum WebAppHelpersLocationStatus {
  App = 'app',
  Catchup = 'catchup',
  Future = 'future',
  Live = 'live',
  Past = 'past',
  Preview = 'preview',
  Recording = 'recording',
  Vod = 'vod',
}

export enum BroadcastStatus {
  Future,
  Live,
  Past,
  Preview,
  Unknown,
}

const getLocationStatusInternal: (item: NETGEM_API_V8_FEED_ITEM) => WebAppHelpersLocationStatus | null = (item) => {
  const { locType, startTime, endTime } = item;

  const now = AccurateTimestamp.nowInSeconds();

  switch (locType) {
    case NETGEM_API_V8_ITEM_LOCATION_TYPE_APP:
      return WebAppHelpersLocationStatus.App;

    case NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP:
      return WebAppHelpersLocationStatus.Catchup;

    case NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING:
      return WebAppHelpersLocationStatus.Recording;

    case NETGEM_API_V8_ITEM_LOCATION_TYPE_EST:
    case NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD:
    case NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD:
    case NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD:
      return WebAppHelpersLocationStatus.Vod;

    default:
      // NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT
      if (startTime <= now && now < endTime) {
        return WebAppHelpersLocationStatus.Live;
      } else if (startTime > now) {
        return WebAppHelpersLocationStatus.Future;
      } else if (endTime < now) {
        return WebAppHelpersLocationStatus.Past;
      }
      break;
  }

  return null;
};

const getLocationStatus: (item: NETGEM_API_V8_FEED_ITEM, authority?: NETGEM_API_V8_AUTHENT_REALM) => WebAppHelpersLocationStatus | null = (item, authority) => {
  const { selectedProgramId } = item;

  if (selectedProgramId.startsWith(FAKE_EPG_LIVE_PREFIX) || selectedProgramId.startsWith(FAKE_LIVE_CHANNEL_PREFIX)) {
    // Live program without info (missing PTF channel)
    return WebAppHelpersLocationStatus.Live;
  }

  const locationStatus = getBroadcastStatus(item) === BroadcastStatus.Live ? WebAppHelpersLocationStatus.Live : getLocationStatusInternal(item);

  if (locationStatus === WebAppHelpersLocationStatus.Vod && authority === NETGEM_API_V8_AUTHENT_REALM_NETGEM) {
    return WebAppHelpersLocationStatus.Catchup;
  }

  return locationStatus;
};

// Parameter 'now' is in seconds
const getBroadcastStatus: (item: NETGEM_API_V8_FEED_ITEM, now: ?number) => BroadcastStatus = (item, now) => {
  const { locType } = item;

  if (locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP && locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT && locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING) {
    return BroadcastStatus.Unknown;
  }

  const { endTime, startTime } = getStartAndEndTimes(item);
  const status = getBroadcastStatusFromTimes(startTime, endTime, now);

  if (status === BroadcastStatus.Future && locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP) {
    // Case of a catchup being available before the first broadcast
    const {
      selectedLocation: { availabilityEndDate, availabilityStartDate },
    } = item;
    const localNow = (now || AccurateTimestamp.nowInSeconds()) * MILLISECONDS_PER_SECOND;
    if (getAvailabilityStartTimeOrDefault(availabilityStartDate) <= localNow && localNow < getAvailabilityEndTimeOrDefault(availabilityEndDate)) {
      // Special case for items available as catchups before their first broadcast
      return BroadcastStatus.Preview;
    }
  } else if (status === BroadcastStatus.Live) {
    // Case of a catchup being also live
    const {
      selectedLocation: { id },
    } = item;
    if (getLocationType(id) === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP) {
      return BroadcastStatus.Past;
    }
  }

  return status;
};

const getBroadcastStatusFromTimes: (startTime: number, endTime: number, now: ?number) => BroadcastStatus = (startTime, endTime, now) => {
  const localNow = now || AccurateTimestamp.nowInSeconds();

  if (startTime <= 0) {
    return BroadcastStatus.Unknown;
  }

  if (localNow < startTime) {
    return BroadcastStatus.Future;
  }

  if (endTime <= 0) {
    return BroadcastStatus.Unknown;
  }

  if (endTime <= localNow) {
    return BroadcastStatus.Past;
  }

  return BroadcastStatus.Live;
};

export { getBroadcastStatus, getBroadcastStatusFromTimes, getLocationStatus };
