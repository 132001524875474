/* @flow */

/* eslint-disable no-magic-numbers */
export enum DmsNetworkCode {
  // OK
  Created = 201,
  // Errors
  MissingOrInvalidParameters = 10010,
  MissingOrInvalidRequiredParameters = 10011,
  MissingOrInvalidAuthenticationToken = 10021,
  InvalidChallenge = 10031,
  UnknownDevice = 10504,
  InvalidExchange = 10505,
  InvalidDevice = 10509,
  MalformedJson = 20001,
  UnknownError = 0,
}
/* eslint-enable no-magic-numbers */

const getMessageFromErrorStatus = (status: number): string | null => {
  const code = DmsNetworkCode.cast(status);

  if (typeof code === 'undefined') {
    return null;
  }

  return getMessageFromErrorCode(code);
};

const getMessageFromErrorCode = (code: DmsNetworkCode): string | null => {
  switch (code) {
    case DmsNetworkCode.MissingOrInvalidParameters:
      return 'Missing or invalid parameter(s)';
    case DmsNetworkCode.MissingOrInvalidRequiredParameters:
      return 'Missing or invalid required parameter(s)';
    case DmsNetworkCode.MissingOrInvalidAuthenticationToken:
      return 'Missing or invalid authentication token';
    case DmsNetworkCode.InvalidChallenge:
      return 'Invalid challenge';
    case DmsNetworkCode.UnknownDevice:
      return 'Unknown device';
    case DmsNetworkCode.InvalidExchange:
      return 'Invalid exchange';
    case DmsNetworkCode.InvalidDevice:
      return 'Invalid device';
    case DmsNetworkCode.MalformedJson:
      return 'Malformed JSON';

    default:
      return null;
  }
};

export { getMessageFromErrorCode, getMessageFromErrorStatus };
