/* @flow */

import './ButtonBack.css';
import * as React from 'react';
import { PictoArrowLeft, PictoArrowLeftWithShaft } from '@ntg/components/dist/pictos/Element';
import type { BasicFunction } from '@ntg/utils/dist/types';
import { Localizer } from '@ntg/utils/dist/localization';
import clsx from 'clsx';

/* eslint-disable react/require-default-props */
type PropType = {|
  +className: string,
  +hasShaft?: boolean,
  +hasText?: boolean,
  +onClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => Promise<void> | void,
  +onMouseEnter?: BasicFunction,
  +onMouseLeave?: BasicFunction,
|};
/* eslint-enable react/require-default-props */

const ButtonBack = ({ className, hasShaft = false, hasText = true, onClick, onMouseEnter, onMouseLeave }: PropType): React.Node => (
  <button className={clsx('backButtonBase', className)} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} type='button'>
    {hasShaft === true ? <PictoArrowLeftWithShaft forceHoverEffect /> : <PictoArrowLeft forceHoverEffect />}
    {hasText ? <div className='text'>{Localizer.localize('common.actions.back')}</div> : null}
  </button>
);

export default ButtonBack;
