/* @flow */

import type { ReadOnlyKeyValuePair } from '@ntg/utils/dist/types';

// Names
export const HeaderName: ReadOnlyKeyValuePair<string> = Object.freeze({
  Accept: 'Accept',
  AppKey: 'APP_KEY',
  Authorization: 'Authorization',
  ContentType: 'Content-Type',
  IfMatch: 'If-Match',
  IfNoneMatch: 'If-None-Match',
  VuDrmToken: 'x-vudrm-token',
  WwwAuthenticate: 'WWW-Authenticate',
});

// Values
export const HeaderValue: ReadOnlyKeyValuePair<string> = Object.freeze({
  ApplicationFormUrlencoded: 'application/x-www-form-urlencoded',
  ApplicationJson: 'application/json',
  ApplicationJsonUtf8: 'application/json; charset=utf-8',
  ApplicationMicrosoftServiceBus: 'application/vnd.microsoft.servicebus.json',
  Image: 'image/*',
});
