/* @flow */

import * as SentryBrowser from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import { type SentryDataType, SentryLevel, SentryTagName } from './sentryTypes';
import { parseFloat } from '../jsHelpers/parser';

export default class SentryWrapper {
  static subscriberId: string = '';

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'SentryWrapper';
  }

  static initialize: (sessionId: string) => void = (sessionId) => {
    if (SentryReact.isInitialized()) {
      // Already initialized
      return;
    }

    SentryReact.init({
      debug: process.env.REACT_APP_DEBUG_MODE,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      initialScope: {
        tags: { [(SentryTagName.Session: string)]: sessionId },
      },
      integrations: [SentryBrowser.browserTracingIntegration()],
      release: process.env.REACT_APP_VERSION,
      sampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE, 1),
      sendClientReports: false,
      tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE, 1),
    });
  };

  static updateSubscriberId: (subscriberId: string) => void = (subscriberId) => {
    SentryWrapper.subscriberId = subscriberId;
  };

  static notify: (level: SentryLevel, data: SentryDataType) => void = (level, data) => {
    const { breadcrumbs, context, error, message, tagName, tagValue } = data;

    SentryReact.withScope((scope) => {
      scope.setLevel((level: string));

      if (tagName && tagValue) {
        scope.setTag(tagName, tagValue);
      }

      if (SentryWrapper.subscriberId) {
        scope.setTag(SentryTagName.SubscriberId, SentryWrapper.subscriberId);
      }

      if (context) {
        scope.setContext('data', context);
      }

      breadcrumbs?.forEach((msg) => scope.addBreadcrumb({ message: msg }));

      if (error) {
        SentryReact.captureException(error);
      } else {
        SentryReact.captureMessage(message);
      }
    });
  };

  static info: (data: SentryDataType) => void = (data) => {
    SentryWrapper.notify(SentryLevel.Info, data);
  };

  static warning: (data: SentryDataType) => void = (data) => {
    SentryWrapper.notify(SentryLevel.Warning, data);
  };

  static error: (data: SentryDataType) => void = (data) => {
    SentryWrapper.notify(SentryLevel.Error, data);
  };
}
