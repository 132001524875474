/* @flow */

export const REDUX_MSG_CHANNELS_UPDATE: 'NTG_RDX_MSG_DEVICE_CHANNEL_MAP' = 'NTG_RDX_MSG_DEVICE_CHANNEL_MAP';
export const REDUX_MSG_COMMERCIAL_OFFERS_UPDATE: 'NTG_REDUX_MSG_UPDATE_COMMERCIAL_OFFERS' = 'NTG_REDUX_MSG_UPDATE_COMMERCIAL_OFFERS';
export const REDUX_MSG_DEBUG_MODE_TOGGLE: 'NTG_RDX_MSG_TOGGLE_DEBUG_MODE' = 'NTG_RDX_MSG_TOGGLE_DEBUG_MODE';
export const REDUX_MSG_DEBUG_MODE_UPDATE: 'NTG_RDX_MSG_UPDATE_DEBUG_MODE' = 'NTG_RDX_MSG_UPDATE_DEBUG_MODE';
export const REDUX_MSG_DEFAULT_RIGHTS_UPDATE: 'NTG_REDUX_MSG_UPDATE_DEFAULT_RIGHTS' = 'NTG_REDUX_MSG_UPDATE_DEFAULT_RIGHTS';
export const REDUX_MSG_DEVICE_OS_UPDATE: 'NTG_RDX_MSG_DEVICE_OS_UPDATE' = 'NTG_RDX_MSG_DEVICE_OS_UPDATE';
export const REDUX_MSG_DEVICE_SETTINGS_UPDATE: 'NTG_RDX_MSG_DEVICE_SETTINGS_UPDATE' = 'NTG_RDX_MSG_DEVICE_SETTINGS_UPDATE';
export const REDUX_MSG_DISTRIBUTOR_APPKEYS_UPDATE: 'NTG_RDX_MSG_DISTRIBUTOR_APPKEYS' = 'NTG_RDX_MSG_DISTRIBUTOR_APPKEYS';
export const REDUX_MSG_ENABLE_PACK_PURCHASE_API: 'NTG_RDX_MSG_ENABLE_PACK_PURCHASE_API' = 'NTG_RDX_MSG_ENABLE_PACK_PURCHASE_API';
export const REDUX_MSG_LIST_ALIAS_UPDATE: 'NTG_RDX_MSG_LIST_ALIAS' = 'NTG_RDX_MSG_LIST_ALIAS';
export const REDUX_MSG_MAIN_DISTRIBUTOR_UPDATE: 'NTG_RDX_MSG_MAIN_DISTRIBUTOR' = 'NTG_RDX_MSG_MAIN_DISTRIBUTOR';
export const REDUX_MSG_MESH_STATE_UPDATE: 'NTG_RDX_MSG_MESH_STATE' = 'NTG_RDX_MSG_MESH_STATE';
export const REDUX_MSG_PLAYER_STREAM_PRIORITIES_UPDATE: 'NTG_RDX_MSG_UPDATE_PLAYER_STREAM_PRIORITIES' = 'NTG_RDX_MSG_UPDATE_PLAYER_STREAM_PRIORITIES';
export const REDUX_MSG_APP_CONFIGURATION_UPDATE: 'NTG_RDX_MSG_UPDATE_APP_CONFIGURATION' = 'NTG_RDX_MSG_UPDATE_APP_CONFIGURATION';
export const REDUX_MSG_USER_RIGHTS_UPDATE: 'NTG_REDUX_MSG_UPDATE_USER_RIGHTS' = 'NTG_REDUX_MSG_UPDATE_USER_RIGHTS';
export const REDUX_MSG_UTM_PARAMETERS_UPDATE: 'NTG_REDUX_MSG_UPDATE_UTM_PARAMETERS' = 'NTG_REDUX_MSG_UPDATE_UTM_PARAMETERS';
export const REDUX_MSG_VOD_STATE_UPDATE: 'NTG_RDX_MSG_UPDATE_VOD_STATE' = 'NTG_RDX_MSG_UPDATE_VOD_STATE';
export const REDUX_MSG_BO_PRODUCT_ID_UPDATE: 'NTG_RDX_MSG_BO_UPDATE_PRODUCT_ID' = 'NTG_RDX_MSG_BO_UPDATE_PRODUCT_ID';
export const REDUX_MSG_BO_SETTINGS_UPDATE: 'NTG_RDX_MSG_BO_SETTINGS_MAP' = 'NTG_RDX_MSG_BO_SETTINGS_MAP';
export const REDUX_MSG_VERSION_BO_UPDATE: 'NTG_RDX_MSG_UPDATE_BO_VERSION' = 'NTG_RDX_MSG_UPDATE_BO_VERSION';
export const REDUX_MSG_VERSION_CRM_BACK_UPDATE: 'NTG_RDX_MSG_UPDATE_CRM_BACK_VERSION' = 'NTG_RDX_MSG_UPDATE_CRM_BACK_VERSION';
export const REDUX_MSG_VERSION_CRM_FRONT_UPDATE: 'NTG_RDX_MSG_UPDATE_CRM_FRONT_VERSION' = 'NTG_RDX_MSG_UPDATE_CRM_FRONT_VERSION';
export const REDUX_MSG_PACK_PURCHASE_TOGGLE: 'NTG_RDX_MSG_TOGGLE_PACK_PURCHASE' = 'NTG_RDX_MSG_TOGGLE_PACK_PURCHASE';
export const REDUX_MSG_BO_V1_FORCE: 'NTG_RDX_MSG_BO_V1_FORCE' = 'NTG_RDX_MSG_BO_V1_FORCE';

export const FEATURE_NPVR: 'FEATURE_NPVR' = 'FEATURE_NPVR';
export const FEATURE_SUBSCRIPTION: 'FEATURE_SUBSCRIPTION' = 'FEATURE_SUBSCRIPTION';
export const FEATURE_TV: 'FEATURE_TV' = 'FEATURE_TV';
export const FEATURE_VOD: 'FEATURE_VOD' = 'FEATURE_VOD';
export type FEATURE_TYPE = typeof FEATURE_NPVR | typeof FEATURE_SUBSCRIPTION | typeof FEATURE_TV | typeof FEATURE_VOD;
