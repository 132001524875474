/* @flow */

import { BO_PURCHASE_ALREADY_PURCHASED_V2, BO_PURCHASE_SVOD_CONTENT_RIGHT_NOT_FOUND, BO_STREAM_NO_SVOD_CONTENT_RIGHT } from '../../../../../libs/netgemLibrary/videofutur/types/ErrorCodes';
import { type BO_API_CREATE_STREAM_TYPE } from '../types/stream';
import { CustomNetworkError } from '../../../../../libs/netgemLibrary/helpers/CustomNetworkError';
import type { Dispatch } from '../../../../types/types';
import { HttpStatus } from '../../../../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import type { RequestResponseMethodDefinitionType } from '../../emitter';
import { getPurchaseListForDistributor } from './purchaseList';
import sendCreateStreamRequest from '../createStream';
import sendPurchaseRequest from '../purchase';

const purchase: (distributorId: string, vtiId: number, promocode: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (distributorId, vtiId, promocode, signal) =>
  (dispatch: Dispatch): Promise<any> =>
    // Purchase asset
    dispatch(sendPurchaseRequest(distributorId, vtiId, promocode, signal)).then((response) => {
      // Purchase list refresh
      dispatch(getPurchaseListForDistributor(distributorId));

      return Promise.resolve(response);
    });

const purchaseAndCreate: (distributorId: string, vtiId: number, promocode: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (distributorId, vtiId, promocode, signal) =>
  (dispatch: Dispatch): Promise<any> => {
    const create = () =>
      dispatch(sendCreateStreamRequest(distributorId, vtiId, true, signal)).then((createResponse: BO_API_CREATE_STREAM_TYPE) =>
        // Purchase list refresh
        dispatch(getPurchaseListForDistributor(distributorId)).then(() => createResponse),
      );

    // Purchase asset
    return dispatch(sendPurchaseRequest(distributorId, vtiId, promocode, signal))
      .then((purchaseResponse) => {
        const { status } = purchaseResponse;

        if (status === BO_PURCHASE_SVOD_CONTENT_RIGHT_NOT_FOUND) {
          // Throw same kind of error as stream create
          throw new CustomNetworkError({
            message: status,
            result: { code: BO_STREAM_NO_SVOD_CONTENT_RIGHT },
            status: HttpStatus.Forbidden,
          });
        }

        // Create stream
        return create();
      })
      .catch((error) => {
        if (error.getCustomCode() === BO_PURCHASE_ALREADY_PURCHASED_V2) {
          // Item already purchased (this could happen e.g. Gaumont user clicks "Play" when in guest mode, then after signing in, player tries to purchase item
          return create();
        }

        throw error;
      });
  };

export { purchase, purchaseAndCreate };
