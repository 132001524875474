/* @flow */

import { type AllSettledPromises, SettledPromiseFulfilled } from '../../../../helpers/jsHelpers/promise';
import { HeaderName, HeaderValue } from '../constants/Headers';
import { NETGEM_API_V8_AUTHENT_REALM_NETGEM, NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR } from '../types/Realm';
import type { NETGEM_API_V8_FEED_RESULT, NETGEM_API_V8_RAW_FEED } from '../types/FeedResult';
import type { NETGEM_API_V8_NTG_VIDEO_FEED, NETGEM_API_V8_URL_DEFINITION } from '../types/NtgVideoFeed';
import { filterFeed, flattenFeedIfRequested, mergeFeeds } from '../helpers/Feed';
import type { ChannelMap } from '../types/Channel';
import type { CombinedReducers } from '../../../../redux/reducers';
import { FeedProviderKind } from '../types/Feed';
import { HttpMethod } from '../types/HttpMethod';
import { type NETGEM_API_V8_AGGREGATION_FEED } from '../types/AggregationFeed';
import { type NETGEM_API_V8_FEED } from '../types/FeedItem';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../types/RequestResponse';
import { type NETGEM_API_V8_SECTION } from '../types/Section';
import { type NETGEM_API_V8_WIDGET_CONFIG_TYPE } from '../types/WidgetConfig';
import { buildHeaderList } from '../helpers/RequestHeaders';
import { generateApiUrl } from '../../../../redux/netgemApi/actions/helpers/api';
import { getBOSetting } from '../../../../redux/netgemApi/actions/helpers/boSettings';
import sendV8Request from './v8';

type SourceConfigType = {|
  ...NETGEM_API_V8_NTG_VIDEO_FEED,
  feedIndex: number,
|};

export type FeedRequestResponseType = {|
  feedIndex: number,
  result: NETGEM_API_V8_FEED_RESULT,
|};

type NetworkFeedsArrayType = Array<NETGEM_API_V8_RAW_FEED>;

const sendFeedRequestInternal: (
  url: string,
  method: HttpMethod,
  authenticationToken: string | null,
  extraHeaders: ?Array<any>,
  feedIndex: number,
  state: CombinedReducers,
  signal: ?AbortSignal,
) => Promise<any> = (url, method, authenticationToken, extraHeaders, feedIndex, state, signal) => {
  const extraHeaderList = extraHeaders ? extraHeaders : [null];
  const headerList = buildHeaderList(HeaderName.Accept, HeaderValue.ApplicationJson, ...extraHeaderList);

  return sendV8Request(url, authenticationToken, null, headerList, state, signal).then((data: NETGEM_API_V8_REQUEST_RESPONSE) => {
    const { result } = data;

    return Promise.resolve({
      feedIndex,
      result,
    });
  });
};

const filterMergeSourcesFeed: (section: NETGEM_API_V8_SECTION, networkFeedsArray: NetworkFeedsArrayType, channels: ChannelMap, sessionId: string) => NETGEM_API_V8_FEED = (
  section,
  networkFeedsArray,
  channels,
  sessionId,
) => {
  const { length: feedCount } = networkFeedsArray;

  if (feedCount === 0) {
    return [];
  }

  // Filter feed sources
  const filteredFeedsArray: Array<NETGEM_API_V8_FEED> = [];
  const {
    model: sectionModel,
    model: { scoring },
    widgetConfig,
  }: { model: NETGEM_API_V8_AGGREGATION_FEED | NETGEM_API_V8_NTG_VIDEO_FEED, widgetConfig?: NETGEM_API_V8_WIDGET_CONFIG_TYPE } = section;
  const { filter: sectionFilter, provider: sectionProvider, slice: sectionSlice } = sectionModel;
  const groupItemsBy = widgetConfig?.groupItemsBy;

  if (sectionProvider === FeedProviderKind.Aggregation) {
    const aggregationSectionModel: NETGEM_API_V8_AGGREGATION_FEED = ((sectionModel: any): NETGEM_API_V8_AGGREGATION_FEED);
    const { sources: aggregationSources } = aggregationSectionModel;

    if (!aggregationSources) {
      return [];
    }

    for (let i = 0; i < feedCount; i++) {
      const {
        [i]: { filter, slice },
      } = aggregationSources;

      let filteredFeed = filterFeed(networkFeedsArray[i], channels, filter, slice);
      if (filteredFeed.length > 0) {
        if (sectionFilter) {
          filteredFeed = filterFeed(((filteredFeed: any): NETGEM_API_V8_RAW_FEED), channels, sectionFilter, slice);
        }
        filteredFeedsArray.push(flattenFeedIfRequested(filteredFeed, groupItemsBy));
      }
    }
  } else if (sectionProvider === FeedProviderKind.NtgVideo) {
    filteredFeedsArray.push(flattenFeedIfRequested(filterFeed(networkFeedsArray[0], channels, sectionFilter), groupItemsBy));
  }

  // Merge and sort feed sources
  return mergeFeeds(filteredFeedsArray, scoring, { sessionId }, sectionSlice);
};

const sendFeedRequest: (section: NETGEM_API_V8_SECTION, searchString: ?string, state: CombinedReducers, sourceList: Array<SourceConfigType>, signal?: AbortSignal) => Promise<any> = (
  section,
  searchString,
  state,
  sourceList,
  signal,
) => {
  const { length: sourceCount } = sourceList;
  const {
    appConfiguration,
    appConfiguration: { deviceChannels, distributorAppKeys, mainDistributorId, sessionId },
    appRegistration,
  } = state;

  if (sourceCount === 0) {
    return Promise.resolve();
  }

  const promises = [];
  const additionalParameters = searchString ? { searchString } : {};

  for (let i: number = 0; i < sourceCount; i++) {
    const source = sourceList[i];
    const url = generateApiUrl(((source: any): NETGEM_API_V8_URL_DEFINITION), additionalParameters, state);
    const { authentRealm, feedIndex } = source;

    let authenticationToken: string | null = null;
    let extraHeaders: ?Array<any> = null;

    if (authentRealm === NETGEM_API_V8_AUTHENT_REALM_NETGEM) {
      // Use PTF authentication token
      ({ authenticationToken } = appRegistration);
    } else if (authentRealm) {
      /*
       * Examples of authentRealm:
       *   authentRealm: "videofutur"
       *   authentRealm: "videofutur?distributor=vitis-vno-fibre-v8"
       *   authentRealm: "videofutur?distributor=vitis-vno-fibre-v8&foo=bar"
       *
       * NOTE: Additional parameters are not currently used
       */
      const m = authentRealm.match(/^(.+?)(?:\?distributor=(.+?)(&.+?=.+)?)?$/iu);

      if (m) {
        const [, realm, distributorId] = m;

        if (realm === NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR) {
          // Use BO  authentication token
          const distributor = distributorId ?? mainDistributorId;

          if (distributor) {
            const { [distributor]: appKey } = distributorAppKeys;
            extraHeaders = [HeaderName.AppKey, appKey];
            authenticationToken = getBOSetting('identity', appConfiguration);
          }
        }
      }
    }

    promises.push(sendFeedRequestInternal(url, HttpMethod.GET, authenticationToken, extraHeaders, feedIndex, state, signal));
  }

  return Promise.allSettled(promises).then((results: AllSettledPromises) => {
    const networkFeedsArray: NetworkFeedsArrayType = [];
    for (let i = 0; i < sourceCount; ++i) {
      networkFeedsArray.push([]);
    }

    results.forEach(({ status, value }) => {
      if (status === SettledPromiseFulfilled) {
        const {
          feedIndex,
          result: { feed },
        } = ((value: any): FeedRequestResponseType);
        networkFeedsArray[feedIndex] = feed;
      }
    });

    return Promise.resolve(filterMergeSourcesFeed(section, networkFeedsArray, deviceChannels, sessionId));
  });
};

const sendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, searchString: ?string, state: CombinedReducers, signal?: AbortSignal) => Promise<any> = (section, searchString, state, signal) => {
  const sourceList: Array<SourceConfigType> = [];
  const sectionModel: NETGEM_API_V8_NTG_VIDEO_FEED | NETGEM_API_V8_AGGREGATION_FEED = section.model;

  if (sectionModel.provider === FeedProviderKind.Aggregation) {
    const { sources } = ((sectionModel: any): NETGEM_API_V8_AGGREGATION_FEED);

    if (!sources) {
      return Promise.reject(new Error('Missing sources in feed'));
    }

    for (let i: number = 0; i < sources.length; i++) {
      sourceList.push({
        ...sources[i],
        feedIndex: i,
      });
    }
  } else if (sectionModel.provider === FeedProviderKind.NtgVideo) {
    const ntgVideoFeed: NETGEM_API_V8_NTG_VIDEO_FEED = ((sectionModel: any): NETGEM_API_V8_NTG_VIDEO_FEED);
    sourceList.push({
      ...ntgVideoFeed,
      feedIndex: 0,
    });
  }

  return sendFeedRequest(section, searchString, state, sourceList, signal);
};

export { sendV8SectionFeedRequest };
