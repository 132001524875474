/* @flow */

import { HeaderName, HeaderValue } from '../../../../libs/netgemLibrary/v8/constants/Headers';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import type { NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD } from '../../../../libs/netgemLibrary/v8/types/DataCollection';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { REDUX_MSG_REQUEST_DATA_COLLECTION } from '../../constants';
import { buildHeaderList } from '../../../../libs/netgemLibrary/v8/helpers/RequestHeaders';
import { computeSas } from '../helpers/api';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { getSettingValueByName } from '../helpers/settings';

export const sendColdDataRequest: (content: NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD, sasDelay: number, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (content, sasDelay, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { dataCollectionColdUrl },
    } = state;

    return dispatch(sendDataRequest(dataCollectionColdUrl, content, sasDelay, signal));
  };

export const sendHotDataRequest: (content: NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD, sasDelay: number, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (content, sasDelay, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { dataCollectionHotUrl },
    } = state;

    return dispatch(sendDataRequest(dataCollectionHotUrl, content, sasDelay, signal));
  };

const sendDataRequest: (
  urlDefinition: ?NETGEM_API_V8_URL_DEFINITION,
  content: NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD,
  sasDelay: number,
  signal?: AbortSignal,
) => RequestResponseMethodDefinitionType =
  (urlDefinition, content, sasDelay, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const { appConfiguration } = getState();

    if (!urlDefinition) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'data collection URL' }));
    }

    const { method, sakName, uri } = urlDefinition;

    if (!sakName) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_data_collection_sak_name'));
    }

    const apiKeyValue = getSettingValueByName('platform.sas', sakName, appConfiguration);

    if (!apiKeyValue) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_data_collection_sak_value', { sakName }));
    }

    const sas = computeSas(uri, sakName, apiKeyValue, sasDelay);
    const headers = buildHeaderList(HeaderName.Authorization, sas, HeaderName.ContentType, HeaderValue.ApplicationMicrosoftServiceBus);

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_DATA_COLLECTION, {
      content,
      headers,
      method,
      signal,
      uri,
    });
  };
