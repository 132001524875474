/* @flow */

import type { NETGEM_API_V8_AVENUE } from '../../../libs/netgemLibrary/v8/types/Avenue';
import type { NETGEM_API_V8_METADATA } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../../libs/netgemLibrary/v8/types/RequestResponse';

/*
 * Hard-coded list of lowercase names that should be excluded from the cast
 * Useful to avoid, e.g. "TFOU MAX", in cast or in related sections of series/program card
 */
const CastExclusionList: Array<string> = ['adult swim', 'boing tv', 'boomerang tv', 'tfou max', 'toonami tv'];

const filterSections: (response: NETGEM_API_V8_REQUEST_RESPONSE) => NETGEM_API_V8_AVENUE = (response) => {
  const { result }: { result: NETGEM_API_V8_AVENUE } = response;
  const { sections } = result;

  result.sections = sections.filter((section) => {
    if (section.title.length === 0) {
      return true;
    }

    const title = section.title[0].value.toLowerCase();
    return CastExclusionList.every((excluded) => !title.endsWith(excluded));
  });

  return result;
};

const filterCredits: (metadata: NETGEM_API_V8_METADATA) => NETGEM_API_V8_METADATA = (metadata) => {
  const { credits } = metadata;

  if (credits) {
    metadata.credits = credits.filter((person) => {
      let { name } = person;
      if (!name || name.match(/^\s$/u)) {
        return false;
      }

      name = name.toLowerCase();
      return CastExclusionList.every((excluded) => name !== excluded);
    });
  }

  return metadata;
};

export { filterCredits, filterSections };
