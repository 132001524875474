/* @flow */

import type { NETGEM_API_V8_LANG } from './Lang';
import type { NETGEM_API_V8_PARAM } from './Param';
import type { NETGEM_API_V8_URL_DEFINITION } from './NtgVideoFeed';

export enum TileConfigTileType {
  Channel = 'channel',
  ChannelGroup = 'channelgroup',
  ChannelGroupBig = 'channelgroup.big',
  ChannelGroupBigFill = 'channelgroup.big.fill',
  ChannelGroupFill = 'channelgroup.fill',
  Deeplink = 'deeplink',
  Deeplink3x1 = 'deeplink.3x1',
  Deeplink5x1 = 'deeplink.5x1',
  Gemtv = 'gemtv',
  GemtvBig = 'gemtv.big',
  GemtvMedium = 'gemtv.medium',
  Landscape = 'landscape',
  LandscapeBig = 'landscape.big',
  LandscapeVod = 'landscape.vod',
  Portrait = 'portrait',
}

export enum TileConfig {
  ProgramTitle = 'programTitle',
  SeriesTitle = 'seriesTitle',
  ProgramSynopsis = 'programSynopsis',
  SeriesSynopsis = 'seriesSynopsis',
  Date = 'date',
  Duration = 'duration',
  Year = 'year',
  Genre = 'genre',
  Language = 'language',
  ParentalGuidance = 'parentalGuidance',
}

export type TILE_CONFIG_DESCRIPTION = {
  program?: Array<TileConfig>,
  series?: Array<TileConfig>,
};

export enum TileContentImageKind {
  Channel = 'channel',
  Other = 'other',
  Program = 'program',
  Series = 'series',
}

export enum TileImageLayout {
  Stretched = 'stretched',
  Cover = 'cover',
  Contain = 'contain',
}

export enum TileOnFocus {
  Details = 'details',
  Selection = 'selection',
}

type NETGEM_API_V8_WIDGET_TILE_CONFIG_BASE_TYPE = {|
  caption: ?TILE_CONFIG_DESCRIPTION,
  channelLogo: boolean,
  contentImage: TileContentImageKind,
  contentImageVariant?: string,
  hoverContent: ?TILE_CONFIG_DESCRIPTION,
  imageLayout: TileImageLayout,
  onFocus: TileOnFocus,
|};

export type NETGEM_API_V8_WIDGET_TILE_CONFIG_TYPE = {|
  ...NETGEM_API_V8_WIDGET_TILE_CONFIG_BASE_TYPE,
  type?: TileConfigTileType,
|};

export type TILE_CONFIG_TYPE = {|
  ...NETGEM_API_V8_WIDGET_TILE_CONFIG_BASE_TYPE,
  type: TileConfigTileType,
|};

export enum WidgetLayoutType {
  Grid = 'grid',
  List = 'list',
}

export const NETGEM_API_V8_WIDGET_TILE_VOD: 'vod' = 'vod';

/*
 * Available actions:
 *  card1:  open associated card
 *  hub1:   open associated hub
 *  none:   no action is performed
 *  player: open player and play the stream associated with the item
 *  switch: action depends on item
 */
export const NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_CARD: 'card1' = 'card1';
export const NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_HUB: 'hub1' = 'hub1';
export const NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_NONE: 'none' = 'none';
export const NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_PLAYER: 'player' = 'player';
export const NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_SWITCH: 'switch' = 'switch';

export type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE0 = {|
  action: typeof NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_NONE,
|};

export type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE1 = {|
  action: typeof NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_CARD | typeof NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_HUB,
  params: NETGEM_API_V8_URL_DEFINITION,
  type?: typeof NETGEM_API_V8_WIDGET_TILE_VOD,
|};

export type NETGEM_API_V8_WIDGET_ITEM_CLICK_PARAMS_TYPE = Array<{|
  // eslint-disable-next-line no-use-before-define
  action: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE0 | NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE1 | NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE2,
  prefix?: string,
|}>;

export type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE2 = {|
  action: typeof NETGEM_API_V8_WIDGET_ITEM_CLICK_ACTION_SWITCH,
  params?: NETGEM_API_V8_WIDGET_ITEM_CLICK_PARAMS_TYPE,
|};

export type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE = NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE0 | NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE1 | NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE2;

export enum GroupItemsBy {
  Program = 'program',
  Season = 'season',
  Series = 'series',
}

export type NETGEM_API_V8_WIDGET_PANEL_IMAGE_URL_TYPE = {|
  params?: Array<NETGEM_API_V8_PARAM>,
  uri: string,
|};

type NETGEM_API_V8_WIDGET_PANEL_TYPE = {|
  kind: 'customLockPanel',
  widgetConfig: {|
    title?: Array<NETGEM_API_V8_LANG>,
    info?: Array<NETGEM_API_V8_LANG>,
    imageUri?: string,
    imageUrl?: NETGEM_API_V8_WIDGET_PANEL_IMAGE_URL_TYPE,
    instructions?: Array<NETGEM_API_V8_LANG>,
  |},
|};

export type NETGEM_API_V8_WIDGET_CONFIG_TYPE = {|
  groupItemsBy?: GroupItemsBy,
  headerUri?: string,
  imageUri?: string,
  layout?: WidgetLayoutType,
  tileConfig?: NETGEM_API_V8_WIDGET_TILE_CONFIG_TYPE,
  tileType?: TileConfigTileType,
  onEmptyAvenue?: NETGEM_API_V8_WIDGET_PANEL_TYPE,
  onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
|};

export type NETGEM_API_V8_WIDGET_ACTIONS_TYPE = {|
  onItemClick: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
|};

export type NETGEM_API_V8_DEFAULT_ACTIONS = {|
  actions: NETGEM_API_V8_WIDGET_ACTIONS_TYPE,
|};
