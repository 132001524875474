/* @flow */

import { type CommonPromisedXMLHttpRequestReturnType } from '../../helpers/CommonPromisedXMLHttpRequest';
import { HttpMethod } from '../../v8/types/HttpMethod';
import createDmsCommonRequestHeader from '../helpers/CreateCommonRequestHeader';
import sendDmsPromisedXMLHttpRequest from '../helpers/PromisedXMLHttpRequest';

const dmsQuotaPvrRequest: (baseAuthDeviceUrl: string, authenticationToken: string | null) => Promise<any> = (baseAuthDeviceUrl, authenticationToken) => {
  const returnObject: CommonPromisedXMLHttpRequestReturnType = sendDmsPromisedXMLHttpRequest(
    `${baseAuthDeviceUrl}quotaPvr`,
    HttpMethod.GET,
    createDmsCommonRequestHeader(authenticationToken),
    null,
    null,
    false,
  );

  return returnObject.promise;
};

export default dmsQuotaPvrRequest;
