/* @flow */

import {
  type DATA_COLLECTION_INTERNAL_MESSAGE,
  DataCollectionHubCold,
  DataCollectionHubHot,
  type DataCollectionHubKind,
  DataCollectionMessage,
  type NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD,
  type NETGEM_API_V8_DATA_COLLECTION_OBJECT,
  type NETGEM_API_V8_DATA_COLLECTION_USER_PROPERTIES,
} from '../../libs/netgemLibrary/v8/types/DataCollection';
import type { DATA_COLLECTION_MESSAGE_SETTINGS, DATA_COLLECTION_SETTINGS } from './types';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { sendColdDataRequest, sendHotDataRequest } from '../../redux/netgemApi/actions/dataCollection/post';
import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import type { Dispatch } from '../../redux/types/types';
import LocalStorageManager from '../localStorage/localStorageManager';
import { StorageKeys } from '../localStorage/keys';
import { fireAndForget } from '../jsHelpers/promise';
import { getBrowserAndOS } from '../jsHelpers/environment';
import { getRandomInteger } from '../maths/maths';
import { logWarning } from '../debug/debug';
import sendGetCollectorIdRequest from '../../redux/netgemApi/actions/dataCollection/getCollectorId';

const MAX_PERCENT = 100;

type QueueType = {| [DataCollectionHubKind]: NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD |};

export default class Collector {
  batchTimers: {| [DataCollectionHubKind]: TimeoutID | null |};

  dispatch: Dispatch;

  isEnabled: boolean;

  // In milliseconds
  maxFlushDelay: {| [DataCollectionHubKind]: number |};

  maxFlushSize: {| [DataCollectionHubKind]: number |};

  msgCurrentState: DATA_COLLECTION_MESSAGE_SETTINGS;

  msgNavigation: DATA_COLLECTION_MESSAGE_SETTINGS;

  msgPlayerState: DATA_COLLECTION_MESSAGE_SETTINGS;

  msgTurnOff: DATA_COLLECTION_MESSAGE_SETTINGS;

  msgTurnOn: DATA_COLLECTION_MESSAGE_SETTINGS;

  // Queued data is sent when first thing occurs: last piece of data received was maxFlushDelay (in milliseconds) ago or queue size is maxFlushSize
  queue: QueueType;

  // In milliseconds
  samplingDelay: number;

  sasDelay: {| [DataCollectionHubKind]: number |};

  serialNumber: number;

  // In milliseconds
  startDelay: number;

  userProperties: NETGEM_API_V8_DATA_COLLECTION_USER_PROPERTIES;

  // eslint-disable-next-line no-use-before-define
  static instance: Collector;

  constructor(dispatch: Dispatch, appVersion: string, realm: string, settings: DATA_COLLECTION_SETTINGS) {
    if (!dispatch) {
      throw new Error('"dispatch" cannot be null');
    }

    const {
      coldHub: { maxFlushDelay: coldMaxFlushDelay, maxFlushSize: coldMaxFlushSize, sasDelay: coldSasDelay },
      hotHub: { maxFlushDelay: hotMaxFlushDelay, maxFlushSize: hotMaxFlushSize, sasDelay: hotSasDelay },
      isEnabled,
      msgCurrentState,
      msgNavigation,
      msgPlayerState,
      msgTurnOff,
      msgTurnOn,
      sampling,
      samplingDelay,
      startDelay,
    } = settings;

    this.isEnabled = isEnabled && (sampling === -1 || getRandomInteger(0, MAX_PERCENT) <= sampling);

    if (!isEnabled) {
      return;
    }

    this.userProperties = {
      appVersion,
      realm,
    };

    this.serialNumber = 1;
    this.dispatch = dispatch;
    this.samplingDelay = samplingDelay;
    this.startDelay = startDelay;

    this.batchTimers = {
      [DataCollectionHubCold]: null,
      [DataCollectionHubHot]: null,
    };
    this.maxFlushDelay = {
      [DataCollectionHubCold]: coldMaxFlushDelay,
      [DataCollectionHubHot]: hotMaxFlushDelay,
    };
    this.maxFlushSize = {
      [DataCollectionHubCold]: coldMaxFlushSize,
      [DataCollectionHubHot]: hotMaxFlushSize,
    };
    this.queue = {
      [DataCollectionHubCold]: [],
      [DataCollectionHubHot]: [],
    };
    this.sasDelay = {
      [DataCollectionHubCold]: coldSasDelay,
      [DataCollectionHubHot]: hotSasDelay,
    };

    this.msgCurrentState = msgCurrentState;
    this.msgNavigation = msgNavigation;
    this.msgPlayerState = msgPlayerState;
    this.msgTurnOff = msgTurnOff;
    this.msgTurnOn = msgTurnOn;

    fireAndForget(this.setCollectorId(true));
    this.initializeBrowserAndOs();
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'Collector';
  }

  static initialize: (dispatch: Dispatch, appVersion: string, realm: string, settings: DATA_COLLECTION_SETTINGS) => void = (dispatch, appVersion, realm, settings) => {
    if (Collector.instance) {
      // Update collector Id since user signed in or out
      fireAndForget(Collector.instance.setCollectorId(false));
      return;
    }

    Collector.instance = new Collector(dispatch, appVersion, realm, settings);
  };

  static getPlayerStateSettings: () => DATA_COLLECTION_MESSAGE_SETTINGS | null = () => {
    const { isEnabled, msgPlayerState } = Collector.instance;

    return isEnabled ? msgPlayerState : null;
  };

  setCollectorId: (subscribe: boolean) => Promise<void> = async (subscribe) => {
    const { dispatch, userProperties } = this;

    try {
      userProperties.collectorId = await dispatch(sendGetCollectorIdRequest());

      if (subscribe) {
        this.subscribeToEvents();
      }
    } catch {
      // Error while retrieving collector Id: no data collection
      logWarning('An error occurred while retrieving the collector Id. No data collection will be performed.');
    }
  };

  initializeBrowserAndOs: () => void = () => {
    const { userProperties } = this;
    const { browserName, browserVersion, osName, osVersion } = getBrowserAndOS();

    userProperties.browserVersion = browserVersion || 'unknown browser version';
    userProperties.device = `${osName || 'unknown OS name'}.${browserName || 'unknown browser name'}`;
    userProperties.osVersion = osVersion || 'unknown OS version';
  };

  subscribeToEvents: () => void = () => {
    const { msgCurrentState, msgNavigation, msgPlayerState, msgTurnOff, msgTurnOn } = this;

    if (msgCurrentState) {
      Messenger.on(DataCollectionMessage.CurrentState, this.onCurrentStateEvent);
    }

    if (msgNavigation) {
      Messenger.on(DataCollectionMessage.Navigation, this.onNavigationEvent);
    }

    if (msgPlayerState) {
      Messenger.on(DataCollectionMessage.PlayerState, this.onPlayerStateEvent);
    }

    if (msgTurnOff) {
      Messenger.on(DataCollectionMessage.TurnOff, this.onTurnOffEvent);
    }

    if (msgTurnOn) {
      Messenger.on(DataCollectionMessage.TurnOn, this.onTurnOnEvent);
    }

    Messenger.on(MessengerEvents.FLUSH_COLLECTOR, this.flushAll);
    Messenger.on(MessengerEvents.STASH_COLLECTOR, this.stash);
    Messenger.on(MessengerEvents.UNSTASH_COLLECTOR, this.unstash);
  };

  onCurrentStateEvent: (msg: DATA_COLLECTION_INTERNAL_MESSAGE) => void = (msg) => {
    this.onEvent(DataCollectionHubHot, DataCollectionMessage.CurrentState, msg);
  };

  onNavigationEvent: (msg: DATA_COLLECTION_INTERNAL_MESSAGE) => void = (msg) => {
    this.onEvent(DataCollectionHubCold, DataCollectionMessage.Navigation, msg);
  };

  onPlayerStateEvent: (msg: DATA_COLLECTION_INTERNAL_MESSAGE) => void = (msg) => {
    this.onEvent(DataCollectionHubHot, DataCollectionMessage.PlayerState, msg);
  };

  onTurnOffEvent: () => void = () => {
    this.onEvent(DataCollectionHubCold, DataCollectionMessage.TurnOff);
  };

  onTurnOnEvent: () => void = () => {
    this.onEvent(DataCollectionHubCold, DataCollectionMessage.TurnOn);
  };

  onEvent: (hubType: DataCollectionHubKind, messageType: DataCollectionMessage, msg?: DATA_COLLECTION_INTERNAL_MESSAGE) => void = (hubType, messageType, msg) => {
    const { serialNumber } = this;
    const timestamp = AccurateTimestamp.nowAsIsoString();

    this.enqueue(hubType, {
      ...msg,
      messageType,
      serialNumber,
      timestamp,
    });

    this.serialNumber += 1;
  };

  enqueue: (hubType: DataCollectionHubKind, msg: NETGEM_API_V8_DATA_COLLECTION_OBJECT) => void = (hubType, msg) => {
    const {
      batchTimers,
      maxFlushDelay: { [hubType]: flushDelay },
      maxFlushSize: { [hubType]: flushSize },
      queue: { [hubType]: typedQueue },
      userProperties,
    } = this;

    typedQueue.push({
      Body: JSON.stringify(msg),
      UserProperties: userProperties,
    });

    if (typedQueue.length === flushSize) {
      this.flush(hubType);
    } else if (!batchTimers[hubType]) {
      batchTimers[hubType] = setTimeout(this.flush, flushDelay, hubType);
    }
  };

  resetBatchTimer: (hubType: DataCollectionHubKind) => void = (hubType) => {
    const {
      batchTimers,
      batchTimers: { [hubType]: timer },
    } = this;

    if (timer) {
      clearTimeout(timer);
      batchTimers[hubType] = null;
    }
  };

  flush: (hubType: DataCollectionHubKind) => void = (hubType) => {
    const {
      queue: { [hubType]: typedQueue },
    } = this;

    this.resetBatchTimer(hubType);
    if (typedQueue.length > 0) {
      this.sendData(hubType, typedQueue);
    }
  };

  flushAll: () => void = () => {
    this.flush(DataCollectionHubCold);
    this.flush(DataCollectionHubHot);
  };

  sendData: (hubType: DataCollectionHubKind, queue: NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD) => void = (hubType, queue) => {
    const {
      dispatch,
      sasDelay: { [hubType]: sasDelay },
    } = this;

    const sendRequest = hubType === DataCollectionHubCold ? sendColdDataRequest : sendHotDataRequest;

    dispatch(sendRequest(queue, sasDelay))
      .catch(() => {
        // Data could not be sent, but we don't want to bloat things with old data
      })
      .finally(() => {
        queue.length = 0;
      });
  };

  stash: () => void = () => {
    const {
      queue,
      queue: { [DataCollectionHubCold]: coldQueue, [DataCollectionHubHot]: hotQueue },
    } = this;

    if (coldQueue.length + hotQueue.length > 0) {
      LocalStorageManager.save(StorageKeys.DataCollectionStash, queue);
    }
  };

  // Retrieve and flush data that has been stored when the app last exited
  unstash: () => void = () => {
    const stashedQueue = LocalStorageManager.loadObject(StorageKeys.DataCollectionStash);

    if (!stashedQueue) {
      return;
    }

    // Clean up
    LocalStorageManager.delete(StorageKeys.DataCollectionStash);

    try {
      const queue = JSON.parse(stashedQueue);
      if (queue instanceof Object) {
        [DataCollectionHubCold, DataCollectionHubHot].forEach((hubType) => {
          const { [hubType]: typedQueue } = ((queue: any): QueueType);
          if (typedQueue && typedQueue instanceof Array && typedQueue.length > 0) {
            this.sendData(hubType, typedQueue);
          }
        });
      }
    } catch {
      // Nothing to do: outcome does not matter that much
    }
  };
}
