/* @flow */

import type { DidomiSettings, LocalesType } from '../types/types';
import {
  REDUX_MSG_APP_CONFIGURATION_UPDATE,
  REDUX_MSG_BO_PRODUCT_ID_UPDATE,
  REDUX_MSG_BO_SETTINGS_UPDATE,
  REDUX_MSG_BO_V1_FORCE,
  REDUX_MSG_CHANNELS_UPDATE,
  REDUX_MSG_COMMERCIAL_OFFERS_UPDATE,
  REDUX_MSG_DEBUG_MODE_TOGGLE,
  REDUX_MSG_DEBUG_MODE_UPDATE,
  REDUX_MSG_DEFAULT_RIGHTS_UPDATE,
  REDUX_MSG_DEVICE_OS_UPDATE,
  REDUX_MSG_DEVICE_SETTINGS_UPDATE,
  REDUX_MSG_DISTRIBUTOR_APPKEYS_UPDATE,
  REDUX_MSG_ENABLE_PACK_PURCHASE_API,
  REDUX_MSG_LIST_ALIAS_UPDATE,
  REDUX_MSG_MAIN_DISTRIBUTOR_UPDATE,
  REDUX_MSG_MESH_STATE_UPDATE,
  REDUX_MSG_PACK_PURCHASE_TOGGLE,
  REDUX_MSG_PLAYER_STREAM_PRIORITIES_UPDATE,
  REDUX_MSG_USER_RIGHTS_UPDATE,
  REDUX_MSG_UTM_PARAMETERS_UPDATE,
  REDUX_MSG_VERSION_BO_UPDATE,
  REDUX_MSG_VERSION_CRM_BACK_UPDATE,
  REDUX_MSG_VERSION_CRM_FRONT_UPDATE,
  REDUX_MSG_VOD_STATE_UPDATE,
} from '../constants/';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import type { DmsSettingMap } from '../../../libs/netgemLibrary/dms/types/DeviceInfoSettings';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_V8_RIGHTS } from '../../../libs/netgemLibrary/v8/types/Rights';
import type { STREAM_PRIORITIES_TYPE } from '../../../helpers/ui/metadata/Types';

export type AppConfigurationAction =
  // Default action
  | {| type: null |}
  // Update channel list
  | {|
      channels: ChannelMap,
      type: typeof REDUX_MSG_CHANNELS_UPDATE,
    |}
  // Update device OS
  | {|
      deviceOS: string,
      type: typeof REDUX_MSG_DEVICE_OS_UPDATE,
    |}
  // Toggle debug mode
  | {| type: typeof REDUX_MSG_DEBUG_MODE_TOGGLE |}
  // Update debug mode
  | {|
      isDebugModeEnabled: boolean,
      type: typeof REDUX_MSG_DEBUG_MODE_UPDATE,
    |}
  // Settings
  | {|
      settings: DmsSettingMap,
      type: typeof REDUX_MSG_DEVICE_SETTINGS_UPDATE,
    |}
  // BO settings
  | {|
      settings: KeyValuePair<string>,
      type: typeof REDUX_MSG_BO_SETTINGS_UPDATE,
    |}
  // BO product Id
  | {|
      productId: number,
      type: typeof REDUX_MSG_BO_PRODUCT_ID_UPDATE,
    |}
  // Distributor app keys
  | {|
      distributorAppKeys: KeyValuePair<string>,
      type: typeof REDUX_MSG_DISTRIBUTOR_APPKEYS_UPDATE,
    |}
  // Update a list alias
  | {|
      channelList: Array<string>,
      listAlias: string,
      tag: string | null,
      type: typeof REDUX_MSG_LIST_ALIAS_UPDATE,
    |}
  // Update main distributor Id
  | {|
      distributorId: string,
      type: typeof REDUX_MSG_MAIN_DISTRIBUTOR_UPDATE,
    |}
  // Update mesh state
  | {|
      isMeshEnabled: boolean | null,
      type: typeof REDUX_MSG_MESH_STATE_UPDATE,
    |}
  // Update player stream priorities
  | {|
      priorities: STREAM_PRIORITIES_TYPE | null,
      type: typeof REDUX_MSG_PLAYER_STREAM_PRIORITIES_UPDATE,
    |}
  // Update app configuration
  | {|
      configuration: KeyValuePair<string>,
      didomi?: DidomiSettings,
      locales?: LocalesType,
      type: typeof REDUX_MSG_APP_CONFIGURATION_UPDATE,
    |}
  // Update VOD state
  | {|
      isBoRunning: boolean,
      type: typeof REDUX_MSG_VOD_STATE_UPDATE,
    |}
  // Update BO version
  | {|
      type: typeof REDUX_MSG_VERSION_BO_UPDATE,
      version: string,
    |}
  // Update CRM Back version
  | {|
      type: typeof REDUX_MSG_VERSION_CRM_BACK_UPDATE,
      version: string,
    |}
  // Update CRM Front version
  | {|
      type: typeof REDUX_MSG_VERSION_CRM_FRONT_UPDATE,
      version: string,
    |}
  // Update commercial offers
  | {|
      commercialOffers: KeyValuePair<Array<string>>,
      type: typeof REDUX_MSG_COMMERCIAL_OFFERS_UPDATE,
    |}
  // Update default rights
  | {|
      rights: NETGEM_API_V8_RIGHTS,
      type: typeof REDUX_MSG_DEFAULT_RIGHTS_UPDATE,
    |}
  // Update user rights
  | {|
      rights: Array<string>,
      type: typeof REDUX_MSG_USER_RIGHTS_UPDATE,
    |}
  // Update UTM parameters
  | {|
      type: typeof REDUX_MSG_UTM_PARAMETERS_UPDATE,
      utmParameters: KeyValuePair<string>,
    |}
  // Enable pack purchase API (will be removed later)
  | {| type: typeof REDUX_MSG_ENABLE_PACK_PURCHASE_API |}
  // Toggle pack purchase feature
  | {| type: typeof REDUX_MSG_PACK_PURCHASE_TOGGLE |}
  // Force BO v1 API
  | {| type: typeof REDUX_MSG_BO_V1_FORCE |};

const toggleDebugMode: () => AppConfigurationAction = () => {
  return { type: REDUX_MSG_DEBUG_MODE_TOGGLE };
};

const togglePackPurchase: () => AppConfigurationAction = () => {
  return { type: REDUX_MSG_PACK_PURCHASE_TOGGLE };
};

const updateDebugMode: (isDebugModeEnabled: boolean) => AppConfigurationAction = (isDebugModeEnabled) => {
  return {
    isDebugModeEnabled,
    type: REDUX_MSG_DEBUG_MODE_UPDATE,
  };
};

const updateDeviceChannels: (channels: ChannelMap) => AppConfigurationAction = (channels) => {
  return {
    channels,
    type: REDUX_MSG_CHANNELS_UPDATE,
  };
};

const updateDeviceOS: (deviceOS: string) => AppConfigurationAction = (deviceOS) => {
  return {
    deviceOS,
    type: REDUX_MSG_DEVICE_OS_UPDATE,
  };
};

const updateDeviceSettings: (settings: DmsSettingMap) => AppConfigurationAction = (settings) => {
  return {
    settings,
    type: REDUX_MSG_DEVICE_SETTINGS_UPDATE,
  };
};

const updateDistributorAppKeys: (distributorAppKeys: KeyValuePair<string>) => AppConfigurationAction = (distributorAppKeys) => {
  return {
    distributorAppKeys,
    type: REDUX_MSG_DISTRIBUTOR_APPKEYS_UPDATE,
  };
};

const updateListAlias: (channelList: Array<string>, listAlias: string, tag: string | null) => AppConfigurationAction = (channelList, listAlias, tag) => {
  return {
    channelList,
    listAlias,
    tag,
    type: REDUX_MSG_LIST_ALIAS_UPDATE,
  };
};

const updateMainDistributor: (distributorId: string) => AppConfigurationAction = (distributorId) => {
  return {
    distributorId,
    type: REDUX_MSG_MAIN_DISTRIBUTOR_UPDATE,
  };
};

const updateMeshState: (isMeshEnabled: boolean | null) => AppConfigurationAction = (isMeshEnabled) => {
  return {
    isMeshEnabled,
    type: REDUX_MSG_MESH_STATE_UPDATE,
  };
};

const updatePlayerStreamPriorities: (priorities: STREAM_PRIORITIES_TYPE | null) => AppConfigurationAction = (priorities) => {
  return {
    priorities,
    type: REDUX_MSG_PLAYER_STREAM_PRIORITIES_UPDATE,
  };
};

const updateConfiguration: (configuration: KeyValuePair<string>, didomi?: DidomiSettings, locales?: LocalesType) => AppConfigurationAction = (configuration, didomi, locales) => {
  return {
    configuration,
    didomi,
    locales,
    type: REDUX_MSG_APP_CONFIGURATION_UPDATE,
  };
};

const updateBOSettings: (settings: KeyValuePair<string>) => AppConfigurationAction = (settings) => {
  return {
    settings,
    type: REDUX_MSG_BO_SETTINGS_UPDATE,
  };
};

const updateBOProductId: (productId: number) => AppConfigurationAction = (productId) => {
  return {
    productId,
    type: REDUX_MSG_BO_PRODUCT_ID_UPDATE,
  };
};

const updateBOState: (isBoRunning: boolean) => AppConfigurationAction = (isBoRunning) => {
  return {
    isBoRunning,
    type: REDUX_MSG_VOD_STATE_UPDATE,
  };
};

const updateBOVersion: (version: string) => AppConfigurationAction = (version) => {
  return {
    type: REDUX_MSG_VERSION_BO_UPDATE,
    version,
  };
};

const updateCrmBackVersion: (version: string) => AppConfigurationAction = (version) => {
  return {
    type: REDUX_MSG_VERSION_CRM_BACK_UPDATE,
    version,
  };
};

const updateCrmFrontVersion: (version: string) => AppConfigurationAction = (version) => {
  return {
    type: REDUX_MSG_VERSION_CRM_FRONT_UPDATE,
    version,
  };
};

const updateCommercialOffers: (commercialOffers: KeyValuePair<Array<string>>) => AppConfigurationAction = (commercialOffers) => {
  return {
    commercialOffers,
    type: REDUX_MSG_COMMERCIAL_OFFERS_UPDATE,
  };
};

const updateDefaultRights: (rights: NETGEM_API_V8_RIGHTS) => AppConfigurationAction = (rights) => {
  return {
    rights,
    type: REDUX_MSG_DEFAULT_RIGHTS_UPDATE,
  };
};

const updateUserRights: (rights: Array<string>) => AppConfigurationAction = (rights) => {
  return {
    rights,
    type: REDUX_MSG_USER_RIGHTS_UPDATE,
  };
};

const updateUtmParameters: (utmParameters: KeyValuePair<string>) => AppConfigurationAction = (utmParameters) => {
  return {
    type: REDUX_MSG_UTM_PARAMETERS_UPDATE,
    utmParameters,
  };
};

const enablePackPurchaseApi: () => AppConfigurationAction = () => {
  return {
    type: REDUX_MSG_ENABLE_PACK_PURCHASE_API,
  };
};

const forceBOV1Api: () => AppConfigurationAction = () => {
  return {
    type: REDUX_MSG_BO_V1_FORCE,
  };
};

export {
  enablePackPurchaseApi,
  forceBOV1Api,
  toggleDebugMode,
  togglePackPurchase,
  updateBOProductId,
  updateBOSettings,
  updateBOState,
  updateBOVersion,
  updateConfiguration,
  updateCommercialOffers,
  updateCrmBackVersion,
  updateCrmFrontVersion,
  updateDebugMode,
  updateDefaultRights,
  updateDeviceChannels,
  updateDeviceOS,
  updateDeviceSettings,
  updateDistributorAppKeys,
  updateListAlias,
  updateMainDistributor,
  updateMeshState,
  updatePlayerStreamPriorities,
  updateUserRights,
  updateUtmParameters,
};
