/* @flow */

import * as jose from 'jose';
import { type UserDeviceInfoType } from '../../redux/appRegistration/types/types';
import aes from 'crypto-js/aes';
import hex from 'crypto-js/enc-hex';
import sha256 from 'crypto-js/sha256';
import utf8 from 'crypto-js/enc-utf8';

const JwtKeyPrefix = `${process.env.REACT_APP_ID ?? 'noid'}-${process.env.NODE_ENV ?? 'noenv'}`;

const buildKey: (deviceId: string) => Uint8Array = (deviceId) => {
  const hash = sha256(JwtKeyPrefix).toString();
  return new TextEncoder().encode(`${hash}/${deviceId}`);
};

const generateToken: (deviceId: string, userDeviceInfo: UserDeviceInfoType) => Promise<UserDeviceInfoType> = (deviceId, userDeviceInfo) =>
  new jose.SignJWT(userDeviceInfo).setProtectedHeader({ alg: 'HS256' }).sign(buildKey(deviceId));

const verifyAndDecryptToken: (deviceId: string, jwt: string) => Promise<UserDeviceInfoType> = async (deviceId, jwt) => {
  const { payload } = await jose.jwtVerify(jwt, buildKey(deviceId));
  return payload;
};

const decryptDidomiKey: (cipherText: string) => string = (cipherText) => {
  const secret = hex.parse('ac5d6ef7b3d15a84d4446155d820ef165bb6b6b25e2056c1744590461e6b4291');
  const iv = hex.parse('8646413c1507ee2a61e528afddf6c35d');
  return aes.decrypt(cipherText, secret, { iv }).toString(utf8);
};

export { decryptDidomiKey, generateToken, verifyAndDecryptToken };
