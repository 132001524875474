/* @flow */

import './ExternalContentModal.css';
import * as React from 'react';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import InfiniteCircleLoaderArc from '../../loader/infiniteCircleLoaderArc';
import { Localizer } from '@ntg/utils/dist/localization';
import Modal from '../modal';
import type { ModalState } from '../../../redux/modal/reducers';

// After 10 seconds, we declare the external content failed to load (in ms)
const LOAD_TIMEOUT = 10_000;

type ExternalContentModalStateType = {|
  isLoading: boolean,
|};

const InitialState = Object.freeze({
  isLoading: true,
});

class ExternalContentModal extends React.PureComponent<ModalState, ExternalContentModalStateType> {
  loadTimer: TimeoutID | null;

  modal: React.ElementRef<any> | null;

  constructor(props: ModalState) {
    super(props);

    this.loadTimer = null;
    this.modal = null;
    this.state = { ...InitialState };
  }

  componentDidMount() {
    window.addEventListener('message', this.handleMessagePosted, {
      capture: true,
      passive: true,
    });
    this.loadTimer = setTimeout(this.loadErrorCallback, LOAD_TIMEOUT);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessagePosted, {
      capture: true,
      passive: true,
    });
    this.resetLoadTimer();
  }

  resetLoadTimer = () => {
    const { loadTimer } = this;

    if (loadTimer) {
      clearTimeout(loadTimer);
      this.loadTimer = null;
    }
  };

  handleMessagePosted = (e: SyntheticInputEvent<HTMLElement>) => {
    const { data } = e;

    if (typeof data !== 'string') {
      return;
    }

    /*
     * Temporarily commented because of too many different domains for myVF
     *
     * if (!isSameDomain(process.env.REACT_APP_IFRAME_DOMAIN_ORIGIN, origin) && !(/^https?:\/\/localhost:3000$/ui).test(origin)) {
     *   // Message does not come from a trusted source
     *   return;
     * }
     */

    if (data === 'loaded') {
      // External content successfully loaded
      this.resetLoadTimer();
      this.setState({ isLoading: false });
    }
  };

  loadErrorCallback = () => {
    const { modal } = this;

    Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div>{Localizer.localize('modal.external_content.load_error')}</div>);

    if (modal) {
      modal.close();
    }
  };

  render(): React.Node {
    const { externalContentUrl, index } = this.props;
    const { isLoading } = this.state;

    /* eslint-disable react/iframe-missing-sandbox */
    const children = (
      <>
        <iframe src={externalContentUrl} />
        {isLoading ? (
          <div className='loader'>
            <InfiniteCircleLoaderArc />
          </div>
        ) : null}
      </>
    );
    /* eslint-enable react/iframe-missing-sandbox */

    return (
      <Modal
        className='externalContent'
        index={index}
        ref={(instance) => {
          this.modal = instance;
        }}
      >
        {children}
      </Modal>
    );
  }
}

export default (ExternalContentModal: React.ComponentType<ModalState>);
