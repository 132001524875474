/* @flow */

// Purchase
import { CustomNetworkError } from '../../helpers/CustomNetworkError';

export const BO_PURCHASE_ALREADY_PURCHASED: 'ALREADY_PURCHASED_VOD_CONTENT_EXCEPTION' = 'ALREADY_PURCHASED_VOD_CONTENT_EXCEPTION';
export const BO_PURCHASE_DEVICE_NOT_FOUND: 'DEVICE_NOT_FOUND' = 'DEVICE_NOT_FOUND';
export const BO_PURCHASE_INVALID_CONTENT: 'INVALID_CONTENT' = 'INVALID_CONTENT';
export const BO_PURCHASE_INVALID_PROMOCODE: 'INVALID_PROMOCODE' = 'INVALID_PROMOCODE';
export const BO_PURCHASE_INVALID_PROMOCODE_FOR_TITLE: 'INVALID_PROMOCODE_FOR_TITLE' = 'INVALID_PROMOCODE_FOR_TITLE';
export const BO_PURCHASE_INVALID_USER_IP: 'INVALID_USER_IP' = 'INVALID_USER_IP';
export const BO_PURCHASE_STATUS_OK: 'OK' = 'OK';
export const BO_PURCHASE_SVOD_CONTENT_RIGHT_NOT_FOUND: 'SVOD_CONTENT_RIGHT_NOT_FOUND' = 'SVOD_CONTENT_RIGHT_NOT_FOUND';
export const BO_PURCHASE_UNKNOWN_ERROR: 'UNKNOWN_ERROR' = 'UNKNOWN_ERROR';

// Create stream
export const BO_STREAM_MAX_CONCURRENT_STREAMS_REACHED = 10002;
export const BO_STREAM_DEVICE_NOT_FOUND = 99999;
export const BO_STREAM_NO_SVOD_CONTENT_RIGHT = 500010;
export const BO_STREAM_INVALID_USER_IP = 500080;

// BO API V2
export const BO_PURCHASE_SVOD_CONTENT_RIGHT_NOT_FOUND_V2 = 11015;
export const BO_PURCHASE_INVALID_USER_IP_V2 = 11080;
export const BO_PURCHASE_INVALID_PROMOCODE_V2 = 30011;
export const BO_PURCHASE_INVALID_PROMOCODE_FOR_TITLE_V2 = 30012;
export const BO_PURCHASE_ALREADY_PURCHASED_V2 = 30020;
export const BO_INVALID_JWT_V2 = 99900;
export const BO_INVALID_APPLICATION_V2 = 99901;
export const BO_INVALID_SUBSCRIBER_V2 = 99902;
export const BO_PURCHASE_UNKNOWN_ERROR_V2 = -1;

export const mapV2ErrorToV1Error = (error: CustomNetworkError): string | null => {
  switch (error.getCustomCode()) {
    case BO_PURCHASE_SVOD_CONTENT_RIGHT_NOT_FOUND_V2:
      return BO_PURCHASE_SVOD_CONTENT_RIGHT_NOT_FOUND;
    case BO_PURCHASE_INVALID_USER_IP_V2:
      return BO_PURCHASE_INVALID_USER_IP;
    case BO_PURCHASE_INVALID_PROMOCODE_V2:
      return BO_PURCHASE_INVALID_PROMOCODE;
    case BO_PURCHASE_INVALID_PROMOCODE_FOR_TITLE_V2:
      return BO_PURCHASE_INVALID_PROMOCODE_FOR_TITLE;
    case BO_PURCHASE_ALREADY_PURCHASED_V2:
      return BO_PURCHASE_ALREADY_PURCHASED;
    case BO_INVALID_JWT_V2:
    case BO_INVALID_APPLICATION_V2:
    case BO_INVALID_SUBSCRIBER_V2:
      return BO_PURCHASE_DEVICE_NOT_FOUND;
    default:
      return null;
  }
};
