/* @flow */

import EventEmitter from 'events';

const MAX_LISTENERS = 200;

class Messenger extends EventEmitter {
  // eslint-disable-next-line no-use-before-define
  static instance: Messenger;

  constructor() {
    super();
    this.setMaxListeners(MAX_LISTENERS);
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'Messenger';
  }

  // eslint-disable-next-line no-use-before-define
  static getInstance: () => Messenger = () => {
    if (!Messenger.instance) {
      Messenger.instance = new Messenger();
    }

    return Messenger.instance;
  };

  static on: (eventName: string, callback: (...args: Array<any>) => void) => void = (eventName, callback) => {
    Messenger.getInstance().on(eventName, callback);
  };

  static once: (eventName: string, callback: (...args: Array<any>) => void) => void = (eventName, callback) => {
    Messenger.getInstance().once(eventName, callback);
  };

  static off: (eventName: string, callback: (...args: Array<any>) => void) => void = (eventName, callback) => {
    Messenger.getInstance().off(eventName, callback);
  };

  static emit: (eventName: string, ...args: Array<any>) => void = (eventName, ...args) => {
    Messenger.getInstance().emit(eventName, ...args);
  };
}

export default Messenger;
