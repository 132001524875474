/* @flow */

import {
  AUTHENTICATION_REQUIRED_MODAL,
  AVENUE_MODAL,
  CARD_MODAL,
  CONFIRMATION_MODAL,
  EXTERNAL_CONTENT_MODAL,
  KEYBOARD_SHORTCUTS_MODAL,
  LEGAL_NOTICE_MODAL,
  NEW_VERSION_MODAL,
  NPVR_HELP_MODAL,
  NPVR_MODAL,
  VOD_PURCHASE_MODAL,
} from '../../../components/modal/modalTypes';
import type { AVENUE_DATA_MODAL_TYPE } from '../../../components/modal/avenueModal/AvenueModal';
import type { CARD_DATA_MODAL_TYPE } from '../../../components/modal/cardModal/CardModalConstsAndTypes';
import { type CONFIRMATION_DATA_MODAL_TYPE } from '../../../components/modal/confirmationModal/ConfirmationModal';
import { ModalActionType } from '../constants';
import type { NEW_VERSION_DATA_MODAL_TYPE } from '../../../components/modal/newVersionModal/NewVersionModal';
import type { NPVR_DATA_MODAL_TYPE } from '../../../components/modal/npvrModal/NpvrModal';
import type { VOD_PURCHASE_DATA_TYPE } from '../../../helpers/rights/pendingOperations';

export type ModalAction =
  // Default action
  | {| type: null |}
  // Hide top most modal
  | {|
      force?: boolean,
      type: typeof ModalActionType.Hide,
    |}
  // Reopen last closed modal
  | {| type: typeof ModalActionType.Reopen |}
  // Save modal for a later reopening then hide it
  | {|
      force?: boolean,
      type: typeof ModalActionType.SaveAndHide,
    |}
  // Show authentication required modal
  | {|
      modalType: typeof AUTHENTICATION_REQUIRED_MODAL,
      type: typeof ModalActionType.Show,
    |}
  // Show avenue modal
  | {|
      avenueData?: AVENUE_DATA_MODAL_TYPE,
      modalType: typeof AVENUE_MODAL,
      type: typeof ModalActionType.Show,
    |}
  // Show card modal
  | {|
      cardData?: CARD_DATA_MODAL_TYPE,
      modalType: typeof CARD_MODAL,
      type: typeof ModalActionType.Show,
    |}
  // Show confirmation modal
  | {|
      confirmationData: CONFIRMATION_DATA_MODAL_TYPE,
      modalType: typeof CONFIRMATION_MODAL,
      type: typeof ModalActionType.Show,
    |}
  // Show external content modal
  | {|
      externalContentUrl: string,
      modalType: typeof EXTERNAL_CONTENT_MODAL,
      type: typeof ModalActionType.Show,
    |}
  // Show keyboard shortcuts modal
  | {|
      modalType: typeof KEYBOARD_SHORTCUTS_MODAL,
      type: typeof ModalActionType.Show,
    |}
  // Show legal notice modal
  | {|
      modalType: typeof LEGAL_NOTICE_MODAL,
      type: typeof ModalActionType.Show,
    |}
  // Show new version modal
  | {|
      newVersionData: NEW_VERSION_DATA_MODAL_TYPE,
      modalType: typeof NEW_VERSION_MODAL,
      type: typeof ModalActionType.Show,
    |}
  // Show nPVR confirmation modal
  | {|
      npvrData: NPVR_DATA_MODAL_TYPE,
      modalType: typeof NPVR_MODAL,
      type: typeof ModalActionType.Show,
    |}
  // Show nPVR help modal
  | {|
      modalType: typeof NPVR_HELP_MODAL,
      type: typeof ModalActionType.Show,
    |}
  // Show VOD purchase modal
  | {|
      modalType: typeof VOD_PURCHASE_MODAL,
      type: typeof ModalActionType.Show,
      vodPurchaseData: VOD_PURCHASE_DATA_TYPE,
    |};

const hideModal: (shouldBeReopenedLater?: boolean, force?: boolean) => ModalAction = (shouldBeReopenedLater, force) =>
  shouldBeReopenedLater
    ? {
        force,
        type: ModalActionType.SaveAndHide,
      }
    : {
        force,
        type: ModalActionType.Hide,
      };

const reopenModalCard: () => ModalAction = () => {
  return { type: ModalActionType.Reopen };
};

const showAuthenticationRequiredModal: () => ModalAction = () => {
  return {
    modalType: AUTHENTICATION_REQUIRED_MODAL,
    type: ModalActionType.Show,
  };
};

const showAvenueModal: (avenueData: AVENUE_DATA_MODAL_TYPE) => ModalAction = (avenueData) => {
  return {
    avenueData,
    modalType: AVENUE_MODAL,
    type: ModalActionType.Show,
  };
};

const showCardModal: (cardData: CARD_DATA_MODAL_TYPE) => ModalAction = (cardData) => {
  return {
    cardData,
    modalType: CARD_MODAL,
    type: ModalActionType.Show,
  };
};

const showConfirmationModal: (confirmationData: CONFIRMATION_DATA_MODAL_TYPE) => ModalAction = (confirmationData) => {
  return {
    confirmationData,
    modalType: CONFIRMATION_MODAL,
    type: ModalActionType.Show,
  };
};

const showExternalContentModal: (externalContentUrl: string) => ModalAction = (externalContentUrl) => {
  return {
    externalContentUrl,
    modalType: EXTERNAL_CONTENT_MODAL,
    type: ModalActionType.Show,
  };
};

const showKeyboardShortcutsModal: () => ModalAction = () => {
  return {
    modalType: KEYBOARD_SHORTCUTS_MODAL,
    type: ModalActionType.Show,
  };
};

const showLegalNoticeModal: () => ModalAction = () => {
  return {
    modalType: LEGAL_NOTICE_MODAL,
    type: ModalActionType.Show,
  };
};

const showNewVersionModal: (newVersionData: NEW_VERSION_DATA_MODAL_TYPE) => ModalAction = (newVersionData) => {
  return {
    modalType: NEW_VERSION_MODAL,
    newVersionData,
    type: ModalActionType.Show,
  };
};

const showNpvrModal: (npvrData: NPVR_DATA_MODAL_TYPE) => ModalAction = (npvrData) => {
  return {
    modalType: NPVR_MODAL,
    npvrData,
    type: ModalActionType.Show,
  };
};

const showNpvrHelpModal: () => ModalAction = () => {
  return {
    modalType: NPVR_HELP_MODAL,
    type: ModalActionType.Show,
  };
};

const showVodPurchaseModal: (vodPurchaseData: VOD_PURCHASE_DATA_TYPE) => ModalAction = (vodPurchaseData) => {
  return {
    modalType: VOD_PURCHASE_MODAL,
    type: ModalActionType.Show,
    vodPurchaseData,
  };
};

export {
  hideModal,
  reopenModalCard,
  showAuthenticationRequiredModal,
  showAvenueModal,
  showCardModal,
  showConfirmationModal,
  showExternalContentModal,
  showKeyboardShortcutsModal,
  showLegalNoticeModal,
  showNewVersionModal,
  showNpvrModal,
  showNpvrHelpModal,
  showVodPurchaseModal,
};
