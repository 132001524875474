/* @flow */

import * as React from 'react';
import {
  AUTHENTICATION_REQUIRED_MODAL,
  AVENUE_MODAL,
  CARD_MODAL,
  CONFIRMATION_MODAL,
  EXTERNAL_CONTENT_MODAL,
  KEYBOARD_SHORTCUTS_MODAL,
  LEGAL_NOTICE_MODAL,
  type MODAL_TYPE,
  NEW_VERSION_MODAL,
  NPVR_HELP_MODAL,
  NPVR_MODAL,
  VOD_PURCHASE_MODAL,
} from './modalTypes';
import AuthenticationRequiredModal from './authenticationRequiredModal/AuthenticationRequiredModal';
import AvenueModal from './avenueModal/AvenueModal';
import CardModal from './cardModal/CardModal';
import type { CombinedReducers } from '../../redux/reducers';
import ConfirmationModal from './confirmationModal/ConfirmationModal';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import ExternalContentModal from './externalContentModal/ExternalContentModal';
import KeyboardShortcutsModal from './keyboardShortcutsModal/KeyboardShortcutsModal';
import LegalNoticeModal from './legalNoticeModal/LegalNoticeModal';
import type { ModalState } from '../../redux/modal/reducers';
import NewVersionModal from './newVersionModal/NewVersionModal';
import NpvrHelpModal from './npvrHelpModal/NpvrHelpModal';
import NpvrModal from './npvrModal/NpvrModal';
import VodPurchaseModal from './vodPurchaseModal/VodPurchaseModal';
import { connect } from 'react-redux';

type ReduxModalContainerReducerStateType = {|
  +openModals: Array<ModalState>,
|};

type ModalContainerPropType = {||};

type CompleteModalContainerPropType = {|
  ...ModalContainerPropType,
  ...ReduxModalContainerReducerStateType,
|};

const MODAL_COMPONENTS: {| [MODAL_TYPE]: any |} = {
  [AUTHENTICATION_REQUIRED_MODAL]: AuthenticationRequiredModal,
  [AVENUE_MODAL]: AvenueModal,
  [CARD_MODAL]: CardModal,
  [CONFIRMATION_MODAL]: ConfirmationModal,
  [EXTERNAL_CONTENT_MODAL]: ExternalContentModal,
  [KEYBOARD_SHORTCUTS_MODAL]: KeyboardShortcutsModal,
  [LEGAL_NOTICE_MODAL]: LegalNoticeModal,
  [NEW_VERSION_MODAL]: NewVersionModal,
  [NPVR_HELP_MODAL]: NpvrHelpModal,
  [NPVR_MODAL]: NpvrModal,
  [VOD_PURCHASE_MODAL]: VodPurchaseModal,
};

const ModalContainerView = (props: CompleteModalContainerPropType) => {
  const { openModals } = props;

  if (openModals.length === 0) {
    // No modal open
    return null;
  }

  return openModals.map((modalProps, index) => {
    const { modalType } = modalProps;
    if (!modalType) {
      return null;
    }

    const SpecificModal = MODAL_COMPONENTS[modalType];
    const fullProps = {
      ...modalProps,
      index,
    };

    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <ErrorBoundary componentName='Modal' isNotificationEnabled key={modalType}>
        <React.Suspense fallback={null}>
          <SpecificModal {...fullProps} />
        </React.Suspense>
      </ErrorBoundary>
    );
    /* eslint-enable react/jsx-props-no-spreading */
  });
};

const mapStateToProps = (state: CombinedReducers) => {
  return {
    openModals: state.modal.openModals,
  };
};

const ModalContainer: React.ComponentType<ModalContainerPropType> = connect(mapStateToProps, null, null, { forwardRef: true })(ModalContainerView);

export default ModalContainer;
