/* @flow */

import {
  type ChannelMap,
  MetricsProperties,
  type NETGEM_API_CHANNEL,
  type NETGEM_API_CHANNEL_INFO,
  type NETGEM_API_CHANNEL_PLAYBACK_PARAMS_ARG_TYPE
} from '../../../../libs/netgemLibrary/v8/types/Channel';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { REDUX_MSG_REQUEST_GENERIC } from '../../constants/';
import { STREAM_TYPE_PRIORITIES } from '../../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { compareDrms } from '../../../../helpers/jsHelpers/Drm';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';
import { logWarning } from '../../../../helpers/debug/debug';
import { updateDeviceChannels } from '../../../appConf/actions';

const compareStreamTypes: (arg1: NETGEM_API_CHANNEL_PLAYBACK_PARAMS_ARG_TYPE, arg2: NETGEM_API_CHANNEL_PLAYBACK_PARAMS_ARG_TYPE) => number = (arg1, arg2) => {
  const index1 = STREAM_TYPE_PRIORITIES.indexOf(arg1.type);
  const index2 = STREAM_TYPE_PRIORITIES.indexOf(arg2.type);

  if (index1 === -1 && index2 > -1) {
    return 1;
  }

  if (index2 === -1 && index1 > -1) {
    return -1;
  }

  return index1 - index2;
};

const sortStreams: (channel: NETGEM_API_CHANNEL_INFO) => void = (channel) => {
  const { channelId, name, urlContentType } = channel;

  if (!urlContentType) {
    logWarning(`No URL for ${name || channelId}. Check that this channel is indeed a FAST channel.`);
    return;
  }

  const { playback, playbacks } = urlContentType;
  let pbs = playbacks ?? [];

  if (playback) {
    if (!playbacks && playback) {
      pbs = [playback];
      urlContentType.playbacks = pbs;
    }
    urlContentType.playback = undefined;
  }

  pbs.forEach((pb) => {
    const {
      start: { params }
    } = pb;
    const videostreamParam = params.find((param) => param.name === 'videostreams');
    if (videostreamParam) {
      const {
        value: { args }
      } = videostreamParam;
      // Sort by stream type
      args.sort(compareStreamTypes);

      args.forEach((stream) => {
        // Sort by DRM
        const { drms } = stream;
        drms.sort((s1, s2) => compareDrms(s1.type, s2.type));
      });
    }
  });
};

const toBoolean: (value: ?string) => boolean = (value) => {
  if (value) {
    return value === '1' || value.toLowerCase() === 'true';
  }
  return false;
};

const consolidateChannel: (dmsChannel: NETGEM_API_CHANNEL, ptfChannel?: NETGEM_API_CHANNEL_INFO) => NETGEM_API_CHANNEL = (dmsChannel, ptfChannel) => {
  if (!ptfChannel || !dmsChannel.data?.npvr || !dmsChannel.data?.stream) {
    return dmsChannel;
  }

  const {
    data: {
      npvr: { hasCatchup, hasKeepFromReplay, hasStartover, isRecordable },
      stream: { isSeekable }
    },
    epgid
  } = dmsChannel;
  const { hasCatchup: channelHasCatchup, hasKeepFromReplay: channelHasKeepFromReplay, images, isRecordable: channelIsRecordable, metrics } = ptfChannel;

  // Removed useless metrics (stb, mobile and tablet)
  if (metrics) {
    const cleanedMetrics = [];
    metrics.forEach((metric) => {
      const { properties } = metric;
      metric.properties = properties.filter((p) => p.device === MetricsProperties.Desktop);
      if (metric.properties.length > 0) {
        cleanedMetrics.push(metric);
      }
    });

    ptfChannel.metrics = cleanedMetrics.length > 0 ? cleanedMetrics : undefined;
  }

  // Channel image: default value is epgid
  let imageId: string | null = epgid;
  if (Array.isArray(images)) {
    if (images.length > 0) {
      // If an id exists in images, use the first one
      imageId = images[0].id ?? null;
    } else {
      // If images is present but empty, no image should be displayed
      imageId = null;
    }
  }

  const consolidatedChannel = JSON.parse(JSON.stringify(dmsChannel));

  consolidatedChannel.info = ptfChannel;
  consolidatedChannel.consolidated = {
    hasCatchup: toBoolean(hasCatchup) && channelHasCatchup,
    hasKeepFromReplay: toBoolean(hasKeepFromReplay) && channelHasKeepFromReplay,
    hasStartover: toBoolean(hasStartover),
    hasTimeshift: toBoolean(isSeekable),
    imageId,
    isRecordable: toBoolean(isRecordable) && channelIsRecordable
  };

  return consolidatedChannel;
};

// Consolidate data received from DMS (deviceChannels) with data received from PTF (channelsInfo)
const consolidateChannels: (dmsChannels: ChannelMap, channelsInfo: Array<NETGEM_API_CHANNEL_INFO>) => ChannelMap = (dmsChannels, channelsInfo) => {
  const channels: ChannelMap = {};

  // Lookup table for channels coming from PTF
  const ptfChannels = new Map<string, NETGEM_API_CHANNEL_INFO>();
  channelsInfo.forEach((channel) => ptfChannels.set(channel.channelId, channel));

  Object.keys(dmsChannels).forEach((channelId) => {
    const { [channelId]: dmsChannel } = dmsChannels;
    const ptfChannel = ptfChannels.get(channelId);
    const { isHidden } = dmsChannel;

    if (ptfChannel && !isHidden) {
      sortStreams(ptfChannel);
    }

    channels[channelId] = consolidateChannel(dmsChannel, ptfChannel);

    if (!ptfChannel) {
      logWarning(`Channel "${channelId}" found in DMS but not in PTF`);
    }
  });

  return channels;
};

const sendV8MetadataChannelsRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
    (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
      const state = getState();
      const {
        appConfiguration: { deviceChannels, listAlias },
        netgemApi: { metadataChannelsUrl }
      } = state;

      if (!listAlias) {
        // No service
        return Promise.resolve();
      }

      if (!metadataChannelsUrl) {
        return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'metadataChannelsUrl' }));
      }

      const { authent, method } = metadataChannelsUrl;
      return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
        authent,
        method,
        signal,
        uri: generateApiUrl(metadataChannelsUrl, {}, state)
      }).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const channelsInfo = ((response.result: any): Array<NETGEM_API_CHANNEL_INFO>);
        dispatch(updateDeviceChannels(consolidateChannels(deviceChannels, channelsInfo)));
        return Promise.resolve(response);
      });
    };

export default sendV8MetadataChannelsRequest;
