/* @flow */

import './Item.css';
import './PlaceholderItem.css';
import * as React from 'react';

type PlaceholderItemPropType = {||};

class PlaceholderItemView extends React.PureComponent<PlaceholderItemPropType> {
  render(): React.Node {
    return (
      <div className='sectionItem placeholder'>
        <div className='tileContainer' />
        <div className='textContainer'>
          <div className='text' />
          <div className='text secondLineInfo' />
        </div>
      </div>
    );
  }
}

export default PlaceholderItemView;
