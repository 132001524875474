/* @flow */

import { ItemType, type NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_CHANNEL } from '../../../libs/netgemLibrary/v8/types/Channel';

const DEFAULT_SCORE = 100;

const createItemFromChannel: (channel: NETGEM_API_CHANNEL) => ?NETGEM_API_V8_FEED_ITEM = (channel) => {
  const { epgid: channelId, isHidden } = channel;

  if (isHidden) {
    return null;
  }

  const selectedLocation = {
    channelId,
    duration: '',
    id: channelId,
    score: [DEFAULT_SCORE],
    startDate: '',
  };

  return {
    endTime: NaN,
    id: channelId,
    locType: null,
    locations: [selectedLocation],
    score: [DEFAULT_SCORE],
    selectedLocation,
    selectedProgramId: channelId,
    seriesId: null,
    startTime: NaN,
    type: ItemType.Channel,
  };
};

export { createItemFromChannel };
