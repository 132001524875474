/* @flow */

import type { NetworkError } from './types/NetworkError';

export class CustomNetworkError extends Error {
  networkError: NetworkError;

  constructor(networkError: NetworkError, ...args: Array<any>) {
    super(...args);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomNetworkError);
    }

    this.name = 'CustomNetworkError';
    this.networkError = networkError;
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'CustomNetworkError';
  }

  getStatus: () => number = () => {
    const { status } = this.networkError;
    return status;
  };

  getCustomStatus: () => number = () => {
    const { result } = this.networkError;
    return result?.status ?? result?.body?.message ?? -1;
  };

  getCustomCode: () => number = () => {
    const { result } = this.networkError;
    return result?.code ?? result?.body?.code ?? -1;
  };
}
