/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import type { NETGEM_API_V8_PARAM } from '../../../../libs/netgemLibrary/v8/types/Param';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { REDUX_MSG_REQUEST_GENERIC } from '../../constants/';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const buildUrlDefinition: (locationEpgUrl: NETGEM_API_V8_URL_DEFINITION, newParams: ?Array<NETGEM_API_V8_PARAM>) => NETGEM_API_V8_URL_DEFINITION = (locationEpgUrl, newParams) => {
  if (!newParams) {
    return locationEpgUrl;
  }

  const { params = [] } = locationEpgUrl;

  const urlDef = {
    ...locationEpgUrl,
    params: [...params],
  };

  const { params: currentParams = [] } = urlDef;

  newParams.forEach((newParam) => {
    const pos = currentParams.findIndex((param) => param.name === newParam.name);
    if (pos === -1) {
      currentParams.push(newParam);
    } else {
      currentParams[pos] = newParam;
    }
  });

  return urlDef;
};

const getChannelList: (channelIds: Array<string> | string | null) => string | null = (channelIds) => {
  if (typeof channelIds === 'string') {
    return channelIds;
  }

  if (Array.isArray(channelIds)) {
    return JSON.stringify(channelIds);
  }

  return null;
};

const sendV8LocationEpgRequest: (
  startDate: number,
  range: number,
  newParams?: Array<NETGEM_API_V8_PARAM>,
  channelIds: Array<string> | string | null,
  signal?: AbortSignal,
) => RequestResponseMethodDefinitionType =
  (startDate, range, newParams, channelIds, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { locationEpgUrl },
    } = state;

    if (!locationEpgUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'locationEpgUrl' }));
    }

    const { authent, method } = locationEpgUrl;
    const channelList = getChannelList(channelIds);

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      method,
      signal,
      uri: generateApiUrl(
        buildUrlDefinition(locationEpgUrl, newParams),
        {
          channelList,
          range,
          startDate,
        },
        state,
      ),
    });
  };

export default sendV8LocationEpgRequest;
