/* @flow */

import { HeaderName } from '../../v8/constants/Headers';
import type { NETGEM_API_V8_REQUEST_HEADERS } from '../../v8/types/Headers';

const createDmsCommonRequestHeader: (authenticationToken: string | null) => NETGEM_API_V8_REQUEST_HEADERS = (authenticationToken) => {
  const requestHeader = [];

  if (authenticationToken) {
    requestHeader.push({
      name: HeaderName.Authorization,
      value: `Bearer ${authenticationToken}`,
    });
  }

  return requestHeader;
};

export default createDmsCommonRequestHeader;
