/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import { BO_API_PURCHASE_LIST } from './types/common';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_BO_PURCHASE_LIST } from '../../constants';
import getRequestDetails from './helpers/requestCommon';

const sendPurchaseListRequest: (distributorId: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (distributorId, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration: { useBOV2Api },
      ui: {
        purchaseListETagCache: { [distributorId]: eTag },
      },
    } = getState();

    return dispatch(getRequestDetails(distributorId, BO_API_PURCHASE_LIST, useBOV2Api, undefined, undefined)).then((requestDetails) =>
      // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
      NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_PURCHASE_LIST, {
        ...requestDetails,
        eTag,
        signal,
      }),
    );
  };

export default sendPurchaseListRequest;
