/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { REDUX_MSG_REQUEST_GENERIC } from '../../constants';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const fixVtiIdType: (response: NETGEM_API_V8_REQUEST_RESPONSE) => NETGEM_API_V8_REQUEST_RESPONSE = (response) => {
  response.result.forEach((r) => {
    const {
      settings: { vtiId },
    } = r;
    if (typeof vtiId === 'string' && vtiId !== '' && !isNaN(vtiId)) {
      r.settings.vtiId = Number(vtiId);
    }
  });

  return response;
};

// Used to retrieve the purchase info for a VOD item (series, season, program)
const getPurchaseInfoPerAsset: (assetId: string, channelId: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (assetId, channelId, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { purchaseInfoPerAssetUrl },
    } = state;

    if (!purchaseInfoPerAssetUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'purchaseInfoPerAssetUrl' }));
    }

    const { authent, method } = purchaseInfoPerAssetUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      method,
      signal,
      state,
      uri: generateApiUrl(
        purchaseInfoPerAssetUrl,
        {
          assetId,
          channelList: [channelId],
        },
        state
      ),
    }).then((response) => fixVtiIdType(response).result);
  };

export { getPurchaseInfoPerAsset };
