/* @flow */

import { type AllSettledPromises, SettledPromiseFulfilled } from '../../../../../helpers/jsHelpers/promise';
import type { CombinedReducers } from '../../../../reducers';
import type { Dispatch } from '../../../../types/types';
import type { Distributor } from '../types/common';
import type { RequestResponseMethodDefinitionType } from '../../emitter';
import sendDiscoveryRequestForDistributor from '../discovery';
import { updateBOState } from '../../../../appConf/actions';

const sendDiscoveryRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      appConfiguration: { distributorAppKeys },
    } = getState();

    const promises = Array.from(Object.keys(distributorAppKeys)).map((distributorId) => dispatch(sendDiscoveryRequestForDistributor(distributorId, signal)));

    return Promise.allSettled(promises).then((results: AllSettledPromises) => {
      for (const result of results) {
        if (result.status === SettledPromiseFulfilled) {
          const { urls } = (result.value: Distributor);
          if (Object.keys(urls).length > 0) {
            dispatch(updateBOState(true));
            break;
          }
        }
      }
    });
  };

export default sendDiscoveryRequest;
