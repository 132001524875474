/* @flow */

import type { KeyValuePair } from '@ntg/utils/dist/types';

export type UserDeviceInfoType = {|
  anonymous: boolean,
  applicationId: string,
  authDeviceUrl: string,
  deviceKey: string,
  error: ?string,
  logoutUrl: string,
  subscriberId: string,
  upgradeDeviceUrl: string,
|};

export enum RegistrationType {
  NotRegistered,
  Registered,
  RegisteredAsGuest,
}

export enum SubscriptionStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Future = 'future',
  InTrial = 'intrial',
  NonRenewing = 'nonrenewing',
  Paused = 'paused',
}

export type SubscriptionList = KeyValuePair<SubscriptionStatus>;

export enum InvoiceStatus {
  NotPaid = 'not_paid',
  Paid = 'paid',
  PaymentDue = 'payment_due',
  Pending = 'pending',
  Posted = 'posted',
  Voided = 'voided',
}

export enum PaymentStatus {
  Expired = 'expired',
  Expiring = 'expiring',
  Valid = 'valid',
}

export enum DunningStatus {
  Exhausted = 'exhausted',
  InProgress = 'inprogress',
  Stopped = 'stopped',
  Success = 'success',
  Unknown = 'unknown',
}

export type PaymentInfo = {|
  dunningStatus?: DunningStatus,
  expirationTime?: number,
  invoiceStatus?: InvoiceStatus,
  status?: PaymentStatus,
|};

export type UserInfo = {|
  id: string,
  email: string,
  newsletter?: boolean,
  paymentInfo: PaymentInfo | null,
  subscriptions: SubscriptionList,
|};

export type RawUserInfo = {|
  id: string,
  email: string,
  newsletter?: boolean,
  paymentInfo?: {
    dunningStatus?: DunningStatus,
    expiry?: string,
    status?: PaymentStatus,
  },
  subscriptions?: Array<{|
    planId: string,
    status: SubscriptionStatus,
  |}>,
|};
