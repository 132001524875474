/* @flow */

import { AVENUE_ID_EXPLORE, AVENUE_ID_MYVIDEOS } from '../../../helpers/ui/avenue/constants';
import type { FOCUSED_AVENUE_TYPE, NAVIGATION_AVENUE_TYPE } from '../../../helpers/ui/avenue/types';
import {
  REDUX_MSG_AVENUE_LIST_UPDATE,
  REDUX_MSG_BACK_TO_FOCUSED_AVENUE,
  REDUX_MSG_BO_FAVORITE_LIST_CLEAR,
  REDUX_MSG_BO_FAVORITE_LIST_UPDATE,
  REDUX_MSG_BO_PURCHASE_LISTS_FOR_ALL_DISTRIBUTORS_UPDATE,
  REDUX_MSG_BO_PURCHASE_LIST_CLEAR,
  REDUX_MSG_BO_PURCHASE_LIST_FOR_DISTRIBUTOR_UPDATE,
  REDUX_MSG_DEFAULT_ACTIONS_UPDATE,
  REDUX_MSG_DELETE_SEARCH_HISTORY,
  REDUX_MSG_DELETE_SEARCH_HISTORY_TERM,
  REDUX_MSG_FOCUSED_AVENUE_UPDATE,
  REDUX_MSG_RESET_SECTION_PAGE_INDICES,
  REDUX_MSG_SETTING_RESET,
  REDUX_MSG_SETTING_UPDATE,
  REDUX_MSG_SET_DEEPLINK,
  REDUX_MSG_THEATER_MODE_UPDATE,
  REDUX_MSG_UPDATE_DISPLAY_PAYWALL_SUBSCRIPTION,
  REDUX_MSG_UPDATE_GRID_SECTION_ID,
  REDUX_MSG_UPDATE_SEARCH_STRING,
  REDUX_MSG_UPDATE_SECTION_PAGE_INDEX,
  REDUX_MSG_VIDEO_CAROUSEL_PLAY_UPDATE,
  REDUX_MSG_VIDEO_CAROUSEL_SOUND_UPDATE,
  REDUX_MSG_VIEWING_HISTORY_ITEM_DELETE,
  REDUX_MSG_VIEWING_HISTORY_ITEM_ETAG_DELETE,
  REDUX_MSG_VIEWING_HISTORY_ITEM_UPDATE,
  REDUX_MSG_VIEWING_HISTORY_RESET,
  REDUX_MSG_VIEWING_HISTORY_SET_ERROR,
  REDUX_MSG_VIEWING_HISTORY_UPDATE,
  REDUX_MSG_WISHLIST_ETAG_DELETE,
  REDUX_MSG_WISHLIST_RESET,
  REDUX_MSG_WISHLIST_SET_ERROR,
  REDUX_MSG_WISHLIST_UPDATE,
} from '../constants';
import { deleteSearchHistory, deleteSearchString, getSearchHistory, saveSearchString } from '../../../helpers/search/search';
import { getAvenueIndexFromId, getMainAvenue, removeKeysWithPrefix } from './helpers';
import { loadSettings, resetSettings, saveSettings } from '../../../helpers/settings/settings';
import type { BO_FAVORITE_LIST_TYPE } from '../../netgemApi/actions/videofutur/types/favorite';
import type { BO_PURCHASE_LIST_TYPE } from '../../netgemApi/actions/videofutur/types/purchase';
import type { ETAG_MAP } from '../../../libs/netgemLibrary/v8/types/ETag';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import type { NETGEM_API_V8_HUB } from '../../../libs/netgemLibrary/v8/types/Hub';
import type { NETGEM_API_V8_WIDGET_ACTIONS_TYPE } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { NETGEM_API_VIEWINGHISTORY_ITEM } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import type { NETGEM_API_WISHLIST } from '../../../libs/netgemLibrary/v8/types/Wishlist';
import type { Settings } from '../../../helpers/settings/types';
import type { UIAction } from '../actions';
import type { VideoCarouselStateType } from '../types/types';
import { produce } from 'immer';

const KEYS = Object.freeze({
  ViewingHistory: 'viewingHistory',
  Wishlist: 'wishlist',
});

export type UIState = {|
  avenueList: NETGEM_API_V8_HUB | null,
  deeplink: string | null,
  defaultActions: NETGEM_API_V8_WIDGET_ACTIONS_TYPE | null,
  displaySubscriptionPaywall: boolean,
  exploreAvenueIndex: number,
  favoriteList: BO_FAVORITE_LIST_TYPE | null,
  favoriteListETag: string | null,
  focusedAvenue: FOCUSED_AVENUE_TYPE | null,
  gridSectionId: string | null,
  hubETag: string | null,
  // In milliseconds
  hubMaxAge: number | null,
  isInTheaterMode: boolean,
  mainAvenue: NAVIGATION_AVENUE_TYPE | null,
  myListsAvenueIndex: number,
  purchaseList: BO_PURCHASE_LIST_TYPE | null,
  purchaseListETagCache: ETAG_MAP,
  searchHistory: Array<string>,
  sectionPageIndices: KeyValuePair<number>,
  settings: Settings,
  videoCarousel: VideoCarouselStateType,
  // Type on next line was KeyValuePair<NETGEM_API_VIEWINGHISTORY_ITEM> but it has stopped working since flow-bin v0.235.1 (see https://github.com/facebook/flow/issues/9141)
  viewingHistory: {| [string]: NETGEM_API_VIEWINGHISTORY_ITEM |},
  viewingHistoryETagCache: ETAG_MAP,
  viewingHistoryStatus: LoadableStatus,
  wishlist: NETGEM_API_WISHLIST,
  wishlistETagCache: ETAG_MAP,
  wishlistStatus: LoadableStatus,
|};

const InitialState: UIState = {
  avenueList: null,
  deeplink: null,
  defaultActions: null,
  displaySubscriptionPaywall: false,
  exploreAvenueIndex: -1,
  favoriteList: null,
  favoriteListETag: null,
  focusedAvenue: null,
  gridSectionId: null,
  hubETag: null,
  hubMaxAge: null,
  isInTheaterMode: false,
  mainAvenue: null,
  myListsAvenueIndex: -1,
  purchaseList: null,
  purchaseListETagCache: {},
  searchHistory: getSearchHistory(),
  sectionPageIndices: {},
  settings: loadSettings(),
  videoCarousel: {
    isMuted: true,
    isPlaying: false,
  },
  viewingHistory: {},
  viewingHistoryETagCache: {},
  viewingHistoryStatus: LoadableStatus.NotInitialized,
  wishlist: [],
  wishlistETagCache: {},
  wishlistStatus: LoadableStatus.NotInitialized,
};

const DefaultAction: UIAction = { type: null };

/* eslint-disable max-lines-per-function, complexity */
const uiReducer: (state: UIState, action: UIAction) => UIState = produce((draft = InitialState, action = DefaultAction) => {
  switch (action.type) {
    case REDUX_MSG_AVENUE_LIST_UPDATE: {
      const mainAvenue = getMainAvenue(action.hub);

      // Automatically focus on main avenue when avenue list is updated for the first time
      const focusedAvenue =
        draft.focusedAvenue ??
        (mainAvenue
          ? {
              index: mainAvenue.index,
              type: mainAvenue.type,
            }
          : null);

      draft.avenueList = action.hub;
      draft.exploreAvenueIndex = getAvenueIndexFromId(action.hub, AVENUE_ID_EXPLORE);
      draft.focusedAvenue = focusedAvenue;
      draft.hubETag = action.hubETag;
      draft.hubMaxAge = action.hubMaxAge;
      draft.mainAvenue = mainAvenue;
      draft.myListsAvenueIndex = getAvenueIndexFromId(action.hub, AVENUE_ID_MYVIDEOS);
      break;
    }

    case REDUX_MSG_FOCUSED_AVENUE_UPDATE: {
      draft.focusedAvenue = {
        hub: action.hub,
        hubItem: action.hubItem,
        index: action.index,
        searchString: action.searchString,
        type: action.avenueType,
      };
      // Save to local storage
      draft.searchHistory = saveSearchString(action.searchString);
      break;
    }

    case REDUX_MSG_UPDATE_SEARCH_STRING: {
      if (!draft.focusedAvenue) {
        draft.focusedAvenue = null;
      } else {
        draft.focusedAvenue.searchString = action.searchString;
        // Save to local storage
        draft.searchHistory = saveSearchString(action.searchString);
      }
      break;
    }

    case REDUX_MSG_DELETE_SEARCH_HISTORY_TERM: {
      // Update local storage
      draft.searchHistory = deleteSearchString(action.searchString);
      break;
    }

    case REDUX_MSG_DELETE_SEARCH_HISTORY: {
      // Delete from local storage
      draft.searchHistory = deleteSearchHistory();
      break;
    }

    case REDUX_MSG_BACK_TO_FOCUSED_AVENUE: {
      if (!draft.focusedAvenue) {
        draft.focusedAvenue = null;
      } else {
        // Clearing these 3 values triggers the exit of the hidden avenue (and the return to the "parent" avenue)
        draft.focusedAvenue.hub = undefined;
        draft.focusedAvenue.hubItem = undefined;
        draft.focusedAvenue.searchString = undefined;
      }
      break;
    }

    case REDUX_MSG_DEFAULT_ACTIONS_UPDATE: {
      draft.defaultActions = action.actions;
      break;
    }

    case REDUX_MSG_SETTING_RESET: {
      draft.settings = resetSettings();
      break;
    }

    case REDUX_MSG_SETTING_UPDATE: {
      draft.settings[action.setting] = action.value;
      saveSettings(draft.settings);
      break;
    }

    case REDUX_MSG_UPDATE_SECTION_PAGE_INDEX: {
      draft.sectionPageIndices[action.sectionId] = action.pageIndex;
      break;
    }

    case REDUX_MSG_RESET_SECTION_PAGE_INDICES: {
      draft.sectionPageIndices = removeKeysWithPrefix(draft.sectionPageIndices, action.prefix);
      break;
    }

    case REDUX_MSG_VIDEO_CAROUSEL_SOUND_UPDATE: {
      draft.videoCarousel.isMuted = action.isMuted;
      break;
    }

    case REDUX_MSG_VIDEO_CAROUSEL_PLAY_UPDATE: {
      draft.videoCarousel.isPlaying = action.isPlaying;
      break;
    }

    case REDUX_MSG_THEATER_MODE_UPDATE: {
      draft.isInTheaterMode = action.isInTheaterMode;
      break;
    }

    case REDUX_MSG_BO_FAVORITE_LIST_CLEAR: {
      draft.favoriteList = null;
      draft.favoriteListETag = null;
      break;
    }

    case REDUX_MSG_BO_FAVORITE_LIST_UPDATE: {
      draft.favoriteList = action.favoriteList;
      draft.favoriteListETag = action.eTag;
      break;
    }

    case REDUX_MSG_BO_PURCHASE_LIST_CLEAR: {
      draft.purchaseList = null;
      draft.purchaseListETagCache = {};
      break;
    }

    case REDUX_MSG_BO_PURCHASE_LIST_FOR_DISTRIBUTOR_UPDATE: {
      if (draft.purchaseList === null) {
        draft.purchaseList = ({}: BO_PURCHASE_LIST_TYPE);
      }

      draft.purchaseList[action.distributorId] = action.purchaseList;
      draft.purchaseListETagCache[action.distributorId] = action.eTag;
      break;
    }

    case REDUX_MSG_BO_PURCHASE_LISTS_FOR_ALL_DISTRIBUTORS_UPDATE: {
      action.purchaseLists?.forEach(({ distributorId, eTag, purchaseList }) => {
        if (draft.purchaseList === null) {
          draft.purchaseList = ({}: BO_PURCHASE_LIST_TYPE);
        }
        draft.purchaseList[distributorId] = purchaseList;
        draft.purchaseListETagCache[distributorId] = eTag;
      });
      break;
    }

    case REDUX_MSG_VIEWING_HISTORY_RESET:
    case REDUX_MSG_VIEWING_HISTORY_SET_ERROR: {
      draft.viewingHistory = {};
      draft.viewingHistoryETagCache = {};
      draft.viewingHistoryStatus = action.type === REDUX_MSG_VIEWING_HISTORY_RESET ? LoadableStatus.Loaded : LoadableStatus.Error;
      break;
    }

    case REDUX_MSG_VIEWING_HISTORY_ITEM_ETAG_DELETE: {
      delete draft.viewingHistoryETagCache[action.itemId];
      delete draft.viewingHistoryETagCache[KEYS.ViewingHistory];
      break;
    }

    case REDUX_MSG_VIEWING_HISTORY_ITEM_DELETE: {
      delete draft.viewingHistory[action.itemId];
      delete draft.viewingHistoryETagCache[action.itemId];
      delete draft.viewingHistoryETagCache[KEYS.ViewingHistory];
      break;
    }

    case REDUX_MSG_VIEWING_HISTORY_ITEM_UPDATE: {
      draft.viewingHistory[action.item.id] = action.item;
      draft.viewingHistoryETagCache[action.item.id] = action.itemETag;
      delete draft.viewingHistoryETagCache[KEYS.ViewingHistory];
      break;
    }

    case REDUX_MSG_VIEWING_HISTORY_UPDATE: {
      draft.viewingHistory = action.viewingHistory;
      draft.viewingHistoryETagCache = { viewingHistory: action.viewingHistoryETag };
      draft.viewingHistoryStatus = action.viewingHistoryETag !== null ? LoadableStatus.Loaded : LoadableStatus.NotInitialized;
      break;
    }

    case REDUX_MSG_WISHLIST_ETAG_DELETE: {
      delete draft.wishlistETagCache[KEYS.Wishlist];
      break;
    }

    case REDUX_MSG_WISHLIST_RESET:
    case REDUX_MSG_WISHLIST_SET_ERROR: {
      draft.wishlist = [];
      draft.wishlistETagCache = {};
      draft.wishlistStatus = action.type === REDUX_MSG_WISHLIST_RESET ? LoadableStatus.Loaded : LoadableStatus.Error;
      break;
    }

    case REDUX_MSG_WISHLIST_UPDATE: {
      draft.wishlist = action.wishlist;
      draft.wishlistETagCache = { wishlist: action.wishlistETag };
      draft.wishlistStatus = action.wishlistETag !== null ? LoadableStatus.Loaded : LoadableStatus.NotInitialized;
      break;
    }

    case REDUX_MSG_UPDATE_GRID_SECTION_ID: {
      draft.gridSectionId = action.gridSectionId;
      break;
    }

    case REDUX_MSG_SET_DEEPLINK: {
      draft.deeplink = action.deeplink;
      break;
    }

    case REDUX_MSG_UPDATE_DISPLAY_PAYWALL_SUBSCRIPTION: {
      draft.displaySubscriptionPaywall = action.isDisplayed;
      break;
    }

    default:
  }
}, InitialState);
/* eslint-enable max-lines-per-function, complexity */

export default uiReducer;
