/* @flow */

const SHOW_DELAY = 150;
const HIDE_DELAY = 150;
const TOOLTIP_DELAY = [SHOW_DELAY, HIDE_DELAY];
const SHOW_DURATION = 250;
const HIDE_DURATION = 175;
const TOOLTIP_DURATION = [SHOW_DURATION, HIDE_DURATION];
const TOOLTIP_MAX_WIDTH = 600;
const TOOLTIP_INTERACTIVE_BORDER = 10;
const TOOLTIP_ZINDEX = 100_000;

export type TippyOptionsType = {|
  animation: string,
  arrow: boolean,
  content?: any,
  delay: Array<number>,
  duration: Array<number>,
  interactiveBorder: number,
  maxWidth: number,
  placement: string,
  theme: string,
  trigger: string,
  zIndex: number,
|};

const TIPPY_DEFAULT_OPTIONS: TippyOptionsType = {
  animation: 'scale',
  arrow: false,
  delay: TOOLTIP_DELAY,
  duration: TOOLTIP_DURATION,
  interactiveBorder: TOOLTIP_INTERACTIVE_BORDER,
  maxWidth: TOOLTIP_MAX_WIDTH,
  placement: 'bottom',
  theme: 'dark',
  trigger: 'mouseenter focus',
  zIndex: TOOLTIP_ZINDEX,
};

export type TippyHTMLElement = {
  ...HTMLElement,
  _tippy: any,
};

module.exports = {
  TIPPY_DEFAULT_OPTIONS,
};
