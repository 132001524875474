/* @flow */
import type { FEATURE_TYPE } from '../constants';
import type { KeyValuePair } from '@ntg/utils/dist/types';

export type AppConfigurationFeatures = {| [FEATURE_TYPE]: boolean |};

export type LocalesType = KeyValuePair<Array<{| lang: string, value: string |}>>;

export type DidomiSettings = {| apiKey: string, webNoticeId: string |};

export enum ExternalContentDisplayType {
  EmbeddedPopup,
  NewTab,
  ExternalPopup,
}

const stringToExternalContentDisplayType = (displayType: ?string): ExternalContentDisplayType => {
  switch (displayType) {
    case 'embedded-popup':
      return ExternalContentDisplayType.EmbeddedPopup;

    case 'external-popup':
      return ExternalContentDisplayType.ExternalPopup;

    case 'new-tab':
    default:
      return ExternalContentDisplayType.NewTab;
  }
};

export { stringToExternalContentDisplayType };
