/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_BO_AUTHENTICATE } from '../../constants/';
import { buildMissingParametersError } from '../../../../helpers/debug/debug';
import { buildUrl } from '../../../../libs/netgemLibrary/videofutur/requests/bo';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { getSettingValueByName } from '../helpers/settings';
import { updateBOState } from '../../../appConf/actions';

const sendAuthenticationRequest: (applicationId: string, deviceKey: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (applicationId, deviceKey, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration,
      appConfiguration: { distributorAppKeys, mainDistributorId },
    } = getState();
    const baseurl = getSettingValueByName('platform.vf', 'baseurl', appConfiguration);
    const authenticationEndpoint = getSettingValueByName('platform.vf', 'authentication', appConfiguration);

    if (!mainDistributorId) {
      dispatch(updateBOState(false));
      return Promise.reject(
        createCustomNetworkErrorFromKey('common.messages.errors.missing_parameters_list', {
          list: 'mainDistributorId',
          name: 'BO authenticate',
        })
      );
    }

    const { [mainDistributorId]: appKey } = distributorAppKeys;

    if (!appKey || !authenticationEndpoint || !baseurl) {
      dispatch(updateBOState(false));
      return Promise.reject(
        buildMissingParametersError(
          {
            appKey,
            authenticationEndpoint,
            baseurl,
          },
          'BO authenticate'
        )
      );
    }

    const authenticationUrl = buildUrl(authenticationEndpoint, { baseurl });
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_AUTHENTICATE, {
      appKey,
      applicationId,
      authenticationUrl,
      deviceKey,
      signal,
    });
  };

export default sendAuthenticationRequest;
