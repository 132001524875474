/* @flow */

import type { ReadOnlyKeyValuePair } from '@ntg/utils/src/types';

// Used with event.keyCode
export const KEY: ReadOnlyKeyValuePair<number> = Object.freeze({
  A: 65,
  ACCEPT: 30,
  ADD: 107,
  ALT: 18,
  ANDROID_VIRTUAL_KEYBOARD_DEAD_KEY: 229,
  B: 66,
  BACK: 461,
  BACK_QUOTE: 192,
  BACK_SLASH: 92,
  BACK_SPACE: 8,
  BALANCE_LEFT: 452,
  BALANCE_RIGHT: 451,
  BASS_BOOST_DOWN: 456,
  BASS_BOOST_UP: 455,
  BLUE: 406,
  BROWN: 408,
  C: 67,
  CANCEL: 3,
  CAPS_LOCK: 20,
  CHANNEL_DOWN: 428,
  CHANNEL_UP: 427,
  CLEAR: 12,
  CLEAR_FAVORITE_0: 437,
  CLEAR_FAVORITE_1: 438,
  CLEAR_FAVORITE_2: 439,
  CLEAR_FAVORITE_3: 440,
  CLOSE_BRACKET: 93,
  COMMA: 44,
  CONTROL: 17,
  CONVERT: 28,
  D: 68,
  DECIMAL: 110,
  DELETE: 127,
  DIMMER: 410,
  DISPLAY_SWAP: 444,
  DIVIDE: 111,
  DOWN: 40,
  E: 69,
  EJECT_TOGGLE: 414,
  END: 35,
  ENTER: 13,
  EQUALS: 61,
  ESCAPE: 27,
  F: 70,
  // Chrome: Help
  F1: 112,
  // Firefox: Menu
  F10: 121,
  // Chrome, Firefox: Fullscreen
  F11: 122,
  // Chrome, Firefox: Inspector
  F12: 123,
  F2: 113,
  // Chrome, Firefox: Open Find-bar
  F3: 114,
  F4: 115,
  // Chrome, Firefox: Reload
  F5: 116,
  // Chrome, Firefox: Rotate focus (address-bar/bookmark-bar/etc)
  F6: 117,
  // Firefox: Caret Browsing
  F7: 118,
  F8: 119,
  F9: 120,
  FADER_FRONT: 453,
  FADER_REAR: 454,
  FAST_FWD: 417,
  FINAL: 24,
  G: 71,
  GO_TO_END: 423,
  GO_TO_START: 422,
  GREEN: 404,
  GREY: 407,
  GUIDE: 458,
  H: 72,
  HELP: 156,
  HOME: 36,
  I: 73,
  INFO: 457,
  INSERT: 155,
  J: 74,
  K: 75,
  KANA: 21,
  KANJI: 25,
  L: 76,
  LEFT: 37,
  LESS_THAN: 226,
  M: 77,
  MENU: 462,
  META: 157,
  MINUS: 54,
  MODECHANGE: 31,
  MULTIPLY: 106,
  MUTE: 449,
  N: 78,
  NEXT: 425,
  NONCONVERT: 29,
  NUMPAD_0: 96,
  NUMPAD_1: 97,
  NUMPAD_2: 98,
  NUMPAD_3: 99,
  NUMPAD_4: 100,
  NUMPAD_5: 101,
  NUMPAD_6: 102,
  NUMPAD_7: 103,
  NUMPAD_8: 104,
  NUMPAD_9: 105,
  NUMPAD_MINUS: 109,
  NUMPAD_PLUS: 107,
  NUM_0: 48,
  NUM_1: 49,
  NUM_2: 50,
  NUM_3: 51,
  NUM_4: 52,
  NUM_5: 53,
  NUM_6: 54,
  NUM_7: 55,
  NUM_8: 56,
  NUM_9: 57,
  NUM_LOCK: 144,
  O: 79,
  OPEN_BRACKET: 91,
  P: 80,
  PAGE_DOWN: 34,
  PAGE_UP: 33,
  PAUSE: 19,
  PERIOD: 46,
  PINP_TOGGLE: 442,
  PLAY: 415,
  PLAY_PAUSE: 463,
  PLAY_SPEED_DOWN: 419,
  PLAY_SPEED_RESET: 420,
  PLAY_SPEED_UP: 418,
  PLUS: 187,
  POWER: 409,
  PREV: 424,
  PRINTSCREEN: 154,
  Q: 81,
  QUOTE: 222,
  R: 82,
  RANDOM_TOGGLE: 426,
  RECALL_FAVORITE_0: 433,
  RECALL_FAVORITE_1: 434,
  RECALL_FAVORITE_2: 435,
  RECALL_FAVORITE_3: 436,
  RECORD: 416,
  RECORD_SPEED_NEXT: 421,
  RED: 403,
  REWIND: 412,
  RIGHT: 39,
  S: 83,
  SCAN_CHANNELS_TOGGLE: 441,
  SCREEN_MODE_NEXT: 445,
  SCROLL_LOCK: 145,
  SEMICOLON: 59,
  SEPARATOR: 108,
  SHIFT: 16,
  SLASH: 47,
  SPACE: 32,
  SPLIT_SCREEN_TOGGLE: 443,
  STOP: 413,
  STORE_FAVORITE_0: 429,
  STORE_FAVORITE_1: 430,
  STORE_FAVORITE_2: 431,
  STORE_FAVORITE_3: 432,
  SUBTITLE: 460,
  SUBTRACT: 109,
  SURROUND_MODE_NEXT: 450,
  T: 84,
  TAB: 9,
  TELETEXT: 459,
  U: 85,
  UNDEFINED: 0,
  UP: 38,
  V: 86,
  VIDEO_MODE_NEXT: 446,
  VOLUME_DOWN: 448,
  VOLUME_UP: 447,
  W: 87,
  WINK: 411,
  X: 88,
  Y: 89,
  YELLOW: 405,
  Z: 90,
});
