/* @flow */

export enum WishlistItemKind {
  Program = 'program',
  Season = 'season',
  Series = 'series',
}

export type NETGEM_API_WISHLIST_ITEM = {|
  channelId?: string,
  date: string,
  id: string,
  type: WishlistItemKind,
|};

export type NETGEM_API_WISHLIST = Array<NETGEM_API_WISHLIST_ITEM>;
