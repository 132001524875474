/* @flow */

import { type CommonPromisedXMLHttpRequestReturnType, commonPromisedXMLHttpRequest } from '../../helpers/CommonPromisedXMLHttpRequest';
import { HTTP_READY_STATE_DONE, HttpStatus, NetgemNetworkCode, NetgemNetworkErrorMessage } from '../constants/NetworkCodesAndMessages';
import { CustomNetworkError } from '../../helpers/CustomNetworkError';
import { HttpMethod } from '../types/HttpMethod';
import { Localizer } from '@ntg/utils/dist/localization';
import type { NETGEM_API_V8_REQUEST_HEADERS } from '../types/Headers';
import { XhrResponseType } from '../../../../helpers/jsHelpers/xhr';
import { createCustomNetworkError } from '../../helpers/CreateCustomNetworkError';

const sendV8PromisedXMLHttpRequest: (
  url: string,
  responseType: XhrResponseType,
  requestHeaderList: ?NETGEM_API_V8_REQUEST_HEADERS,
  bodyParam: ?string,
  requestParam: ?string,
  isAuthenticated: boolean,
  signal: ?AbortSignal,
  method?: HttpMethod,
) => CommonPromisedXMLHttpRequestReturnType = (url, responseType, requestHeaderList, bodyParam, requestParam, isAuthenticated, signal, method) => {
  const v8NetworkErrorCallBack = (request: XMLHttpRequest, message: ?string): CustomNetworkError => {
    const { readyState, response, status, statusText } = request;
    const responseHeaders = request.getAllResponseHeaders();

    if (status === 0 && readyState === HTTP_READY_STATE_DONE) {
      // Request miserably failed
      return createCustomNetworkError(HttpStatus.Error, message ?? Localizer.localize('common.messages.errors.request_failed'), responseHeaders, response);
    }

    // Request failed in a more appropriate way
    let msg = '';
    switch (status) {
      case NetgemNetworkCode.ClientError:
        msg = NetgemNetworkErrorMessage.ClientError;
        break;
      case NetgemNetworkCode.InvalidModalState:
        msg = NetgemNetworkErrorMessage.InvalidModalState;
        break;
      case NetgemNetworkCode.InvalidDimensions:
        msg = NetgemNetworkErrorMessage.InvalidDimensions;
        break;
      case NetgemNetworkCode.AuthenticationFailed:
        msg = NetgemNetworkErrorMessage.AuthenticationFailed;
        break;
      case NetgemNetworkCode.NotFound:
        msg = NetgemNetworkErrorMessage.NotFound;
        break;
      case NetgemNetworkCode.HubNotFound:
        msg = NetgemNetworkErrorMessage.HubNotFound;
        break;
      case NetgemNetworkCode.RealmNotFound:
        msg = NetgemNetworkErrorMessage.RealmNotFound;
        break;
      default:
        msg = statusText ?? (responseType === XhrResponseType.Text ? request.responseText : '');
        break;
    }

    return createCustomNetworkError(status, message ?? msg, responseHeaders, response);
  };

  return commonPromisedXMLHttpRequest(url, method || HttpMethod.GET, responseType, requestHeaderList, bodyParam, requestParam, isAuthenticated, v8NetworkErrorCallBack, signal);
};

export default sendV8PromisedXMLHttpRequest;
