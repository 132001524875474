/* @flow */

const TWO = 2;
const TEN = 10;
const ONE_HUNDRED = 100;

/*
 * Shuffle the given array of numbers in place using a random number generator that gives the same result during a whole day
 * Implementation of Fischer-Yates algorithm
 */
const dailyShuffleArray: (array: Array<number>) => void = (array) => {
  const { length } = array;

  // Initialize RNG with current date so that it's the same during the whole day
  const getRandomNumber = getRandomNumberGeneratorWithSeed(new Date().getDate());

  for (let i = length - 1; i > 0; i--) {
    const j = Math.floor(getRandomNumber() * (i + 1));
    // $FlowFixMe: flow doesn't support swapping with array
    [array[i], array[j]] = [array[j], array[i]];
  }
};

// Return the given value adjusted to the interval [minValue,maxValue] (default interval is [0,100])
const getBoundedValue = (value: number, minValue: number = 0, maxValue: number = ONE_HUNDRED): number => Math.min(maxValue, Math.max(minValue, value));

const getClosestEvenInteger: (value: number) => number = (value) => TWO * Math.round(value / TWO);

// Return an integer in the interval [0,100]
const getIntegerPercentage: (value: number, minValue: number, maxValue: number) => number = (value, minValue, maxValue) => Math.round(ONE_HUNDRED * getRealPercentage(value, minValue, maxValue));

// Return a real in the interval [0,1]
const getRealPercentage: (value: number, minValue: number, maxValue: number) => number = (value, minValue, maxValue) => {
  if (value <= minValue) {
    return 0;
  }

  if (value >= maxValue) {
    return 1;
  }

  return (value - minValue) / (maxValue - minValue);
};

// Return an integer in the interval [minValue,maxValue]
const getRandomInteger: (minValue: number, maxValue: number) => number = (minValue, maxValue) => {
  const localMin = Math.ceil(minValue);
  const localMax = Math.floor(maxValue);
  return Math.floor(Math.random() * (localMax - localMin + 1)) + localMin;
};

// Return a random number generator (like Math.random()) based on the given seed
const getRandomNumberGeneratorWithSeed = (seed: number) => {
  const m = 2 ** 35 - 31; // eslint-disable-line no-magic-numbers
  const a = 185852;
  let s = seed % m;
  return () => (s = (s * a) % m) / m;
};

const round: (value: number, decimalPlaces: number) => number = (value, decimalPlaces) => {
  const p = TEN ** decimalPlaces;

  if (Math.round(value * p) / p === value) {
    return value;
  }

  return Math.round(value * p * (1 + Number.EPSILON)) / p;
};

const roundDownTo: (value: number, rounding: number) => number = (value, rounding) => value - (value % rounding);

const roundUpTo: (value: number, rounding: number) => number = (value, rounding) => {
  const modulo = value % rounding;

  return modulo === 0 ? value : value + rounding - modulo;
};

const getPriceAsFloat: (price: string | null) => number | null = (price) => {
  if (price === null) {
    return null;
  }

  return parseFloat(price.replace(',', '.').replace(/[^\d.]/gu, ''));
};

const getPriceAsInteger: (price: string | null) => number | null = (price) => {
  if (price === null) {
    return null;
  }

  let p = price.replace(',', '.').replace(/[^\d.]/gu, '');
  const dotIndex = p.indexOf('.');
  if (dotIndex === -1) {
    // Price is already an integer, like "3€" > let's convert it to cents
    p = `${p}00`;
  } else {
    // Price has a decimal separator somewhere like ".50€", "1.50€", etc. > let's move this dot 2 characters to the right
    const re = /^(\d*).(\d*)$/u;
    const m = re.exec(p);
    if (!m) {
      return null;
    }

    p = `${m[1]}${m[TWO].padEnd(TWO, '0').substring(0, TWO)}`;
  }

  return parseFloat(p);
};

export { dailyShuffleArray, getBoundedValue, getClosestEvenInteger, getIntegerPercentage, getPriceAsFloat, getPriceAsInteger, getRandomInteger, getRealPercentage, round, roundDownTo, roundUpTo };
