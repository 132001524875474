/* @flow */

export enum XhrResponseType {
  ArrayBuffer = 'arraybuffer',
  Blob = 'blob',
  Json = 'json',
  Text = 'text',
  Xml = 'document',
}

const getXhrResponseTypeFromString = (responseType: string): XhrResponseType => XhrResponseType.cast(responseType) ?? XhrResponseType.Text;

const handleFetchError: (response: any) => any = async (response) => {
  const { ok, status, statusText } = response;

  if (ok) {
    return response;
  }

  // Check statusText first
  if (statusText) {
    throw Error(`${status}:${statusText}`);
  }

  const contentType = response.headers.get('content-type');

  if (!contentType) {
    throw Error(status.toString());
  }

  if (contentType.startsWith('text/plain')) {
    // Read text response
    const textError = await response.text();
    throw Error(`${status}:${textError}`);
  }

  if (contentType.startsWith('application/json')) {
    // Read JSON response
    const { error, errorCode } = await response.json();
    throw Error(`${status}:${error}:${errorCode ?? ''}`);
  }

  throw Error(status.toString());
};

const fetchJsonWithErrorCheck: (url: URL | string, options: any) => any = async (url, options) => {
  const response = await fetch(url, options);
  const checkedResponse = await handleFetchError(response);
  return checkedResponse.json();
};

const fetchTextWithErrorCheck: (url: URL | string, options: any) => any = async (url, options) => {
  const response = await fetch(url, options);
  const checkedResponse = await handleFetchError(response);
  return checkedResponse.text();
};

export { fetchJsonWithErrorCheck, fetchTextWithErrorCheck, getXhrResponseTypeFromString };
