/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE } from '../../constants/';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const sendNtgEntitlementReleaseRequest: (customData: string, service: string, url: string, channelName: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (customData, service, url, channelName, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      appRegistration: { applicationId },
      netgemApi: { entitlementReleaseUrl },
    } = state;

    if (!entitlementReleaseUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'entitlementReleaseUrl' }));
    }

    const { method, authent } = entitlementReleaseUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE, {
      applicationId,
      authent,
      channelName,
      customData,
      method,
      service,
      signal,
      uri: generateApiUrl(entitlementReleaseUrl, {}, state),
      url,
    });
  };

export default sendNtgEntitlementReleaseRequest;
