/* @flow */

import './infiniteCircleLoaderArc.css';
import * as React from 'react';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import clsx from 'clsx';

/* eslint-disable react/require-default-props */
type InfiniteCircleLoaderArcPropType = {|
  +className?: string,
  +theme?: ThemeType,
|};
/* eslint-enable react/require-default-props */

const InfiniteCircleLoaderArc = ({ className, theme = Theme.Dark }: InfiniteCircleLoaderArcPropType): React.Node => <div className={clsx('infiniteCircleLoaderArc', className, theme)} />;

export default InfiniteCircleLoaderArc;
