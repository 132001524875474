/* @flow */

import { ItemType, type NETGEM_API_V8_FEED_ITEM, type NETGEM_API_V8_FEED_RAW_ITEM, NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT } from '../../libs/netgemLibrary/v8/types/FeedItem';
import { getItemType, getLocationStartAndEndTime, getLocationType } from '../../libs/netgemLibrary/v8/helpers/Item';

// Search in a raw item a program matching time (in ms)
const searchInFeedRawItem: (rawItem: NETGEM_API_V8_FEED_RAW_ITEM, channelId: string, time: number) => NETGEM_API_V8_FEED_ITEM | null = (rawItem, channelId, time) => {
  const { id: rawItemId } = rawItem;
  const stack = [rawItem];

  while (stack.length > 0) {
    const item = stack.pop();

    // Since flowjs v0.239.0, "item" is said to be potentially undefined (which is impossible, here)
    if (!item) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { elements, id: itemId, locations } = item;

    if (elements) {
      for (let i = 0; i < elements.length; ++i) {
        stack.unshift(elements[i]);
      }
    } else if (locations) {
      for (let j = 0; j < locations.length; ++j) {
        const { [j]: location } = locations;
        const { channelId: rawChannelId, id, score } = location;

        // First, check if channel Id matches
        if (rawChannelId === channelId) {
          const locType = getLocationType(id);

          // Then, check location type (only scheduled events are wanted)
          if (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT) {
            const { endTime, startTime } = getLocationStartAndEndTime(location);
            const itemType = getItemType(rawItemId);
            const seriesId = itemType === ItemType.Series ? rawItemId : null;
            const type = itemType === ItemType.Series ? ItemType.Series : ItemType.Program;

            // Finally, check the times
            if (startTime <= time && time <= endTime) {
              return {
                ...rawItem,
                endTime,
                locType,
                score,
                selectedLocation: location,
                selectedProgramId: itemId,
                seriesId,
                startTime,
                type,
              };
            }
          }
        }
      }
    }
  }

  return null;
};

export { searchInFeedRawItem };
