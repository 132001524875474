/* @flow */

import * as React from 'react';
import { AVENUE_ID_EXPLORE, AVENUE_ID_MYVIDEOS } from '../../helpers/ui/avenue/constants';
import ResizeDetectorWrapper, { type ResizeType } from '../resizeDetectorWrapper/ResizeDetectorWrapper';
import { AvenueType } from '../../helpers/ui/avenue/types';
import { BURGER_WIDTH } from '../../helpers/ui/constants';
import type { CombinedReducers } from '../../redux/reducers';
import { Localizer } from '@ntg/utils/dist/localization';
import MenuItem from './Item';
import MenuView from '../menu/Menu';
import type { NETGEM_API_V8_HUB } from '../../libs/netgemLibrary/v8/types/Hub';
import { PictoArrowDown } from '@ntg/components/dist/pictos/Element';
import { RegistrationType } from '../../redux/appRegistration/types/types';
import { areHubsDifferent } from '../../helpers/ui/section/comparisons';
import { connect } from 'react-redux';
import getTranslatedText from '../../libs/netgemLibrary/v8/helpers/Lang';

export const NO_AVENUE_INDEX = -1;

type MenuItemType = {|
  index: number,
  text: string,
  type: AvenueType,
|};

type ReduxNavigationMenuReducerStateType = {|
  +avenueList: ?NETGEM_API_V8_HUB,
  +isRegisteredAsGuest: boolean,
|};

type NavigationMenuPropType = {||};

type CompleteNavigationMenuPropType = {|
  ...NavigationMenuPropType,
  ...ReduxNavigationMenuReducerStateType,
|};

type NavigationMenuStateType = {|
  hiddenItems: Array<MenuItemType>,
  visibleItems: Array<MenuItemType>,
|};

const InitialState: $ReadOnly<NavigationMenuStateType> = Object.freeze({
  hiddenItems: [],
  visibleItems: [],
});

class NavigationMenuView extends React.PureComponent<CompleteNavigationMenuPropType, NavigationMenuStateType> {
  itemMapRef: Map<number, React.ElementRef<any>>;

  itemWidths: Array<number>;

  constructor(props: CompleteNavigationMenuPropType) {
    super(props);

    this.itemMapRef = new Map();
    this.itemWidths = [];

    this.state = { ...InitialState };
  }

  componentDidMount() {
    this.createItems();
  }

  componentDidUpdate(prevProps: CompleteNavigationMenuPropType) {
    const { avenueList } = this.props;
    const { avenueList: prevAvenueList } = prevProps;

    if (areHubsDifferent(avenueList, prevAvenueList)) {
      this.createItems();
    }
  }

  computeVisibleItemsWidth = (): number => {
    const { visibleItems } = this.state;
    const { itemMapRef, itemWidths } = this;

    let visibleItemsWidth = 0;
    visibleItems.forEach((_, index) => {
      const itemRef = itemMapRef.get(index);
      if (itemRef) {
        const totalWidth = itemRef.getTotalWidth();
        visibleItemsWidth += totalWidth;
        itemWidths[index] = totalWidth;
      }
    });

    return visibleItemsWidth;
  };

  hideItems = (menuWidth: number, visibleItemsWidth: number) => {
    const { hiddenItems, visibleItems } = this.state;
    const { itemWidths } = this;

    const newHiddenItems: Array<MenuItemType> = [];
    const visibleItemsCopy = [...visibleItems];

    let newVisibleItemsWidth = visibleItemsWidth;
    if (hiddenItems.length === 0) {
      newVisibleItemsWidth += BURGER_WIDTH;
    }

    let lastItemIndex = visibleItemsCopy.length;
    while (newVisibleItemsWidth > menuWidth) {
      lastItemIndex -= 1;
      const lastVisibleItem = visibleItemsCopy.pop();

      // Since flowjs v0.239.0, "lastVisibleItem" is said to be potentially undefined (which is impossible, here)
      if (!lastVisibleItem) {
        // eslint-disable-next-line no-continue
        continue;
      }

      newHiddenItems.unshift(lastVisibleItem);
      newVisibleItemsWidth -= itemWidths[lastItemIndex];
    }

    this.setState({
      hiddenItems: [...newHiddenItems, ...hiddenItems],
      visibleItems: visibleItemsCopy,
    });
  };

  showItemsIfNecessary = (menuWidth: number, visibleItemsWidth: number) => {
    const { hiddenItems, visibleItems } = this.state;
    const { itemWidths } = this;

    const hiddenItemsCopy = [...hiddenItems];
    let { length: hiddenItemCount } = hiddenItems;
    let firstHiddenItemIndex = visibleItems.length;
    let potentialNewWidth = visibleItemsWidth + itemWidths[firstHiddenItemIndex] - (hiddenItemCount === 1 ? BURGER_WIDTH : 0);

    const newVisibleItems = [];
    while (potentialNewWidth < menuWidth) {
      const firstHiddenItem = hiddenItemsCopy.shift();

      // Since flowjs v0.239.0, "firstHiddenItem" is said to be potentially undefined (which is impossible, here)
      if (!firstHiddenItem) {
        // eslint-disable-next-line no-continue
        continue;
      }

      hiddenItemCount -= 1;
      newVisibleItems.push(firstHiddenItem);

      // Try one more item
      firstHiddenItemIndex += 1;
      potentialNewWidth += itemWidths[firstHiddenItemIndex] - (hiddenItemCount === 1 ? BURGER_WIDTH : 0);
    }

    if (newVisibleItems.length > 0) {
      this.setState({
        hiddenItems: hiddenItemsCopy,
        visibleItems: [...visibleItems, ...newVisibleItems],
      });
    }
  };

  handleMenuOnResize = (data: ResizeType) => {
    const { hiddenItems } = this.state;
    const { width: menuWidth } = data;

    if (menuWidth === null) {
      // Component not mounted
      return;
    }

    const visibleItemsWidth = this.computeVisibleItemsWidth();

    if (visibleItemsWidth > menuWidth) {
      // Hide some menu items
      this.hideItems(menuWidth, visibleItemsWidth);
    } else if (hiddenItems.length > 0) {
      // Show some menu items
      this.showItemsIfNecessary(menuWidth, visibleItemsWidth);
    }
  };

  createItems = () => {
    const { avenueList, isRegisteredAsGuest } = this.props;

    this.itemMapRef.clear();
    const visibleItems: Array<MenuItemType> = [];

    // Regular avenues
    if (avenueList) {
      avenueList.forEach((avenue, index) => {
        const { id, title } = avenue;

        const text = getTranslatedText(title, Localizer.language);

        let type: AvenueType = AvenueType.Regular;
        if (id === AVENUE_ID_EXPLORE) {
          type = AvenueType.Explore;
        } else if (id === AVENUE_ID_MYVIDEOS) {
          type = AvenueType.Myvideos;
        }

        if (id !== AVENUE_ID_MYVIDEOS || !isRegisteredAsGuest) {
          visibleItems.push({
            index,
            text,
            type,
          });
        }
      });
    }

    this.setState({
      hiddenItems: [],
      visibleItems,
    });
  };

  renderItems = (items: Array<MenuItemType>): Array<React.ElementRef<any>> =>
    items.map(({ index, text, type }) => (
      <MenuItem
        index={index}
        key={index}
        ref={(instance) => {
          this.itemMapRef.set(index, instance);
        }}
        text={text}
        type={type}
      />
    ));

  render(): React.Node {
    const { hiddenItems, visibleItems } = this.state;

    const burgerIcon =
      hiddenItems.length > 0 ? (
        <div className='burgerIcon'>
          <PictoArrowDown forceHoverEffect />
          <div className='burgerMenu'>{this.renderItems(hiddenItems)}</div>
        </div>
      ) : null;

    return (
      <MenuView>
        <>
          {this.renderItems(visibleItems)}
          {burgerIcon}
        </>
        <ResizeDetectorWrapper handleWidth onResize={this.handleMenuOnResize} />
      </MenuView>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxNavigationMenuReducerStateType => {
  return {
    avenueList: state.ui.avenueList,
    isRegisteredAsGuest: state.appRegistration.registration === RegistrationType.RegisteredAsGuest,
  };
};

const NavigationMenu: React.ComponentType<NavigationMenuPropType> = connect(mapStateToProps, null, null, { forwardRef: true })(NavigationMenuView);

export default NavigationMenu;
