/* @flow */

import { type CommonPromisedXMLHttpRequestReturnType, commonNetworkErrorCallBack, commonPromisedXMLHttpRequest } from '../../helpers/CommonPromisedXMLHttpRequest';
import { HeaderName, HeaderValue } from '../../v8/constants/Headers';
import { type BO_API_AUTHENTICATION_RESPONSE_CHALLENGE } from '../types/RequestResponseAuthenticate';
import { HttpMethod } from '../../v8/types/HttpMethod';
import { XhrResponseType } from '../../../../helpers/jsHelpers/xhr';
import { buildHeaderList } from '../../v8/helpers/RequestHeaders';
import hmacSHA1 from 'crypto-js/hmac-sha1';

const authenticationRequest: (authenticationUrl: string, applicationId: string, deviceKey: string, appKey: string, signal?: AbortSignal) => Promise<any> = (
  authenticationUrl,
  applicationId,
  deviceKey,
  appKey,
  signal,
) => {
  const returnChallengeObject: CommonPromisedXMLHttpRequestReturnType = commonPromisedXMLHttpRequest(
    authenticationUrl,
    HttpMethod.POST,
    XhrResponseType.Json,
    buildHeaderList(HeaderName.ContentType, HeaderValue.ApplicationFormUrlencoded, HeaderName.AppKey, appKey),
    `app_id=${applicationId}`,
    null,
    false,
    commonNetworkErrorCallBack,
    signal,
  );

  return returnChallengeObject.promise.then((jsonChallengeResponse: BO_API_AUTHENTICATION_RESPONSE_CHALLENGE) => {
    const hmac = hmacSHA1(jsonChallengeResponse.challenge, deviceKey).toString();
    const returnAuthenticationObject: CommonPromisedXMLHttpRequestReturnType = commonPromisedXMLHttpRequest(
      authenticationUrl,
      HttpMethod.POST,
      XhrResponseType.Json,
      buildHeaderList(HeaderName.ContentType, HeaderValue.ApplicationFormUrlencoded, HeaderName.AppKey, appKey),
      `app_id=${applicationId}&hmac=${hmac}`,
      null,
      false,
      commonNetworkErrorCallBack,
      signal,
    );

    return returnAuthenticationObject.promise;
  });
};

export default authenticationRequest;
