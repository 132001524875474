/* @flow */

import './ButtonFX.css';
import * as React from 'react';
import { HeightKind, WidthKind } from './types';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import { useCallback, useMemo, useRef, useState } from 'react';
import { BUTTON_HOVER_COLORS } from '../../helpers/ui/constants';
import InfiniteCircleLoader from '../loader/infiniteCircleLoader';
import ProgressBar from '../progressBar/ProgressBar';
import clsx from 'clsx';
import { showDebug } from '../../helpers/debug/debug';
import { useSelector } from 'react-redux';

/* eslint-disable react/require-default-props */
type PropType = {|
  +allowZeroProgress?: boolean,
  +children: React.Node,
  +className?: string,
  +data?: any,
  +hasPadding?: boolean,
  +heightKind?: HeightKind,
  +isDisabled?: boolean,
  +isEmpty?: boolean,
  +isLoading?: boolean,
  +progress?: number | null,
  +onClick?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>, data: any) => Promise<void> | void,
  +theme?: ThemeType,
  +widthKind?: WidthKind,
|};
/* eslint-enable react/require-default-props */

const ButtonFX = ({
  allowZeroProgress = true,
  children,
  className,
  data,
  hasPadding = false,
  heightKind = HeightKind.Large,
  isDisabled = false,
  isEmpty = false,
  isLoading = false,
  onClick,
  progress,
  theme = Theme.Dark,
  widthKind = WidthKind.Large,
}: PropType): React.Node => {
  const isDebugModeEnabled = useSelector((state) => state.appConfiguration.isDebugModeEnabled);
  const [isButtonDown, setIsButtonDown] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const hoverLayerElt = useRef<HTMLElement | null>(null);

  const showDebugInfo = useCallback(() => {
    showDebug('ButtonFX', {
      props: { allowZeroProgress, children, className, data, hasPadding, heightKind, isDisabled, isEmpty, isLoading, onClick: onClick ? '<defined>' : '<undefined>', progress, theme, widthKind },
      propsFields: ['allowZeroProgress', 'children', 'className', 'data', 'hasPadding', 'heightKind', 'isDisabled', 'isEmpty', 'isLoading', 'onClick', 'progress'],
      state: { isButtonDown, isHovered, mousePosition },
      stateFields: ['isButtonDown', 'isHovered', 'mousePosition'],
    });
  }, [allowZeroProgress, children, className, data, hasPadding, heightKind, isButtonDown, isDisabled, isEmpty, isHovered, isLoading, mousePosition, onClick, progress, theme, widthKind]);

  const handleButtonOnClick = useCallback(
    (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
      const { altKey, ctrlKey } = event;

      if (isDebugModeEnabled && (ctrlKey || altKey)) {
        event.preventDefault();
        event.stopPropagation();

        showDebugInfo();
        return;
      }

      if (isDisabled || isLoading || !onClick) {
        return;
      }

      onClick(event, data);
    },
    [isDebugModeEnabled, isDisabled, isLoading, onClick, showDebugInfo],
  );

  const handleMouseDown = useCallback(() => {
    setIsButtonDown(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsButtonDown(false);
  }, []);

  const handleMouseEnter = useCallback((event: SyntheticMouseEvent<HTMLElement>) => {
    setIsButtonDown(event.buttons === 1);
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleMouseMove = useCallback((event: SyntheticMouseEvent<HTMLElement>) => {
    if (!hoverLayerElt.current) {
      return;
    }

    const { clientX, clientY } = event;
    const { left, top } = hoverLayerElt.current.getBoundingClientRect();

    setMousePosition({ x: clientX - left, y: clientY - top });
  }, []);

  const background = useMemo(() => {
    if (isHovered) {
      if (isButtonDown) {
        return BUTTON_HOVER_COLORS.Cursor;
      }

      const { x, y } = mousePosition;
      return `radial-gradient(circle at ${x}px ${y}px, ${BUTTON_HOVER_COLORS.Cursor} 0%, ${BUTTON_HOVER_COLORS.Main} 100%)`;
    }

    return 'none';
  }, [isButtonDown, isHovered, mousePosition]);

  const loaderElt = useMemo(
    () =>
      isLoading ? (
        <div className='loader'>
          <InfiniteCircleLoader />
        </div>
      ) : null,
    [isLoading],
  );

  const disabledLayerElt = useMemo(() => (isDisabled || isLoading ? <div className='disabled' /> : null), [isDisabled, isLoading]);

  return (
    <div className={clsx('buttonFX', className, widthKind !== WidthKind.Content && (widthKind: string), (heightKind: string), isLoading && 'loading')}>
      <div className={clsx('button', theme, isEmpty && 'empty')} onClick={handleButtonOnClick}>
        <div
          className='hoverLayer'
          onMouseDown={handleMouseDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          ref={hoverLayerElt}
          style={{ background }}
        />
        <ProgressBar allowZeroProgress={allowZeroProgress} progress={progress} />
        <div className={clsx('content', hasPadding && 'padding', isHovered && 'hovered')}>{children}</div>
      </div>
      {disabledLayerElt}
      {loaderElt}
    </div>
  );
};

export default ButtonFX;
