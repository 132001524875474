/* @flow */

export const AUTHENTICATION_REQUIRED_MODAL: 'AUTHENTICATION_REQUIRED_MODAL' = 'AUTHENTICATION_REQUIRED_MODAL';
export const AVENUE_MODAL: 'AVENUE_MODAL' = 'AVENUE_MODAL';
export const CARD_MODAL: 'CARD_MODAL' = 'CARD_MODAL';
export const CONFIRMATION_MODAL: 'CONFIRMATION_MODAL' = 'CONFIRMATION_MODAL';
export const EXTERNAL_CONTENT_MODAL: 'EXTERNAL_CONTENT_MODAL' = 'EXTERNAL_CONTENT_MODAL';
export const KEYBOARD_SHORTCUTS_MODAL: 'KEYBOARD_SHORTCUTS_MODAL' = 'KEYBOARD_SHORTCUTS_MODAL';
export const LEGAL_NOTICE_MODAL: 'LEGAL_NOTICE_MODAL' = 'LEGAL_NOTICE_MODAL';
export const NEW_VERSION_MODAL: 'NEW_VERSION_MODAL' = 'NEW_VERSION_MODAL';
export const NPVR_HELP_MODAL: 'NPVR_HELP_MODAL' = 'NPVR_HELP_MODAL';
export const NPVR_MODAL: 'NPVR_MODAL' = 'NPVR_MODAL';
export const VOD_PURCHASE_MODAL: 'VOD_PURCHASE_MODAL' = 'VOD_PURCHASE_MODAL';

export type MODAL_TYPE =
  | typeof AUTHENTICATION_REQUIRED_MODAL
  | typeof AVENUE_MODAL
  | typeof CARD_MODAL
  | typeof CONFIRMATION_MODAL
  | typeof EXTERNAL_CONTENT_MODAL
  | typeof KEYBOARD_SHORTCUTS_MODAL
  | typeof LEGAL_NOTICE_MODAL
  | typeof NEW_VERSION_MODAL
  | typeof NPVR_HELP_MODAL
  | typeof NPVR_MODAL
  | typeof VOD_PURCHASE_MODAL;

export enum ModalIcon {
  None,
  Cart,
  Info,
  Record,
  User,
}
