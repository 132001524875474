/* @flow */

import '../ProgressBar.css';
import * as React from 'react';

type EmptyProgressBarPropType = {||};

class EmptyProgressBarView extends React.PureComponent<EmptyProgressBarPropType> {
  render(): React.Node {
    return (
      <div className='progressBar'>
        <div className='reactiveBackground'>
          <div className='mainContainer' />
        </div>
      </div>
    );
  }
}

export default EmptyProgressBarView;
