/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_PERSONAL_DATA_GET } from '../../constants/';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const sendPersonalDataGetRequest: (key: string, eTag: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (key, eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { personalDataGetUrl },
    } = state;

    if (!personalDataGetUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'personalDataGetUrl' }));
    }

    const { authent, method } = personalDataGetUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_PERSONAL_DATA_GET, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(personalDataGetUrl, { key }, state),
    });
  };

const sendPersonalDataListGetRequest: (listId: string, itemId: ?string, eTag: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (listId, itemId, eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { personalDataListGetUrl },
    } = state;

    if (!personalDataListGetUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'personalDataListGetUrl' }));
    }

    const { authent, method } = personalDataListGetUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_PERSONAL_DATA_GET, {
      authent,
      eTag,
      method,
      signal,
      uri: generateApiUrl(
        personalDataListGetUrl,
        {
          itemId,
          listId,
        },
        state
      ),
    });
  };

export { sendPersonalDataGetRequest, sendPersonalDataListGetRequest };
