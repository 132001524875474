/* @flow */

import type { BO_PURCHASE_LIST_TYPE } from '../../redux/netgemApi/actions/videofutur/types/purchase';
import type { NETGEM_API_V8_FEED_RAW_ITEM } from '../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_PURCHASE_INFO } from '../../libs/netgemLibrary/v8/types/PurchaseInfo';
import type { NETGEM_API_V8_RAW_FEED } from '../../libs/netgemLibrary/v8/types/FeedResult';

const isPackPurchased = (purchaseInfo: NETGEM_API_V8_PURCHASE_INFO, purchaseList: BO_PURCHASE_LIST_TYPE | null): boolean => {
  if (purchaseList === null) {
    return false;
  }

  const [
    {
      settings: { distributorId, vtiId },
    },
  ] = purchaseInfo;
  const { [distributorId]: purchaseListForDistributor } = purchaseList;

  if (typeof purchaseListForDistributor === 'undefined') {
    return false;
  }

  const purchasedItems = Object.values(purchaseListForDistributor);
  for (const item of purchasedItems) {
    if (item.packVtiId === vtiId) {
      return true;
    }
  }

  return false;
};

const getFirstEpisodeLocation = (packElements: NETGEM_API_V8_RAW_FEED): NETGEM_API_V8_FEED_RAW_ITEM | null => {
  const [{ elements }] = packElements;

  if (!elements) {
    return null;
  }

  for (const episode of elements) {
    const { id } = episode;
    const [, , , , episodeNumber] = id.split('/');
    if (Number(episodeNumber) === 1) {
      return episode;
    }
  }

  return null;
};

export { getFirstEpisodeLocation, isPackPurchased };
