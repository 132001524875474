/* @flow */

import './LegalNoticeModal.css';
import * as React from 'react';
import type { KeyValuePair, Undefined } from '@ntg/utils/dist/types';
import type { CombinedReducers } from '../../../redux/reducers';
import type { LocalesType } from '../../../redux/appConf/types/types';
import { Localizer } from '@ntg/utils/dist/localization';
import Modal from '../modal';
import { ModalIcon } from '../modalTypes';
import type { ModalState } from '../../../redux/modal/reducers';
import { connect } from 'react-redux';

type ReduxLegalNoticeModalStateType = {|
  +locales: LocalesType,
  +localesDefault: KeyValuePair<Undefined<string>>,
|};

type CompleteLegalNoticeModalPropType = {|
  ...ModalState,
  ...ReduxLegalNoticeModalStateType,
|};

class LegalNoticeModalView extends React.PureComponent<CompleteLegalNoticeModalPropType> {
  render(): React.Node {
    const {
      index,
      locales,
      localesDefault: { legalInformation: defaultLegalInformation },
    } = this.props;

    const localizedLegalInformation = Localizer.getLocalizedStringFromAppConf(locales, 'legalInformation', defaultLegalInformation);

    const children = (
      <>
        <div className='header'>{Localizer.localize('modal.legal_information.title')}</div>
        <div className='legalContent'>{localizedLegalInformation}</div>
      </>
    );

    return (
      <Modal className='legalNotice' icon={ModalIcon.Info} index={index}>
        {children}
      </Modal>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxLegalNoticeModalStateType => {
  return {
    locales: state.appConfiguration.locales,
    localesDefault: state.appConfiguration.localesDefault,
  };
};

const LegalNoticeModal: React.ComponentType<ModalState> = connect(mapStateToProps, null, null, { forwardRef: true })(LegalNoticeModalView);

export default LegalNoticeModal;
