/* @flow */

import type { DMS_DEVICE_CHANNELS, NETGEM_API_DMS_CHANNEL_LIST } from '../types/ChannelList';
import { type NETGEM_API_DMS_PRIVATE_CHANNEL, type NETGEM_API_DMS_PRIVATE_CHANNEL_STREAM } from '../types/Channel';
import type { ChannelMap } from '../../v8/types/Channel';
import { Drm } from '../../../../helpers/jsHelpers/Drm';
import { HttpMethod } from '../../v8/types/HttpMethod';
import { StreamType } from '../../v8/types/MetadataSchedule';
import createDmsCommonRequestHeader from '../helpers/CreateCommonRequestHeader';
import sendDmsPromisedXMLHttpRequest from '../helpers/PromisedXMLHttpRequest';

const getValueFromNode: (value: any) => string = (value) => {
  if (value) {
    if (typeof value === 'string') {
      return value;
    }

    let v = value['#cdata-section'];
    if (typeof v === 'string') {
      return v;
    }

    v = value['#text'];
    if (typeof v === 'string') {
      return v;
    }
  }

  // No value or wrong format
  return '';
};

const parseResolver: (stream: ?NETGEM_API_DMS_PRIVATE_CHANNEL_STREAM) => {| cmp: boolean, customData: string | null, service: string | null |} | null = (stream) => {
  if (!stream) {
    return null;
  }

  const { '@resolver': resolver } = stream;
  if (!resolver) {
    return null;
  }

  if (resolver === 'cmp') {
    // FAST channel case
    return {
      cmp: true,
      customData: null,
      service: null,
    };
  }

  // Search service and custom data in resolver URL
  const argsPos = resolver.indexOf('?');
  if (argsPos === -1) {
    return null;
  }

  let customData: string | null = null;
  let service: string | null = null;
  const args = resolver.substring(argsPos + 1).split('&');

  for (const arg of args) {
    const [key, value] = arg.split('=');
    if (key === 'customData') {
      customData = decodeURIComponent(value);
    } else if (key === 'service') {
      service = decodeURIComponent(value);
    }
  }

  return {
    cmp: false,
    customData,
    service,
  };
};

const createChannelMap: (channels: Array<NETGEM_API_DMS_PRIVATE_CHANNEL>) => ChannelMap = (channels) => {
  const channelsMap: ChannelMap = {};

  // Sort channels by number
  channels.sort((a, b) => Number(a['@number']) - Number(b['@number']));

  for (let i: number = 0; i < channels.length; i++) {
    const channelId = channels[i]['@epgid'];
    if (channelId) {
      const channel = channels[i];
      const { data } = channel;
      const npvr = data?.npvr;
      const stream = data?.stream;
      const urlLifecycle = data?.urlLifecycle;
      const resolver = parseResolver(stream);

      channelsMap[channelId] = {
        altimg: getValueFromNode(channel.altimg),
        amsnumber: channel['@amsnumber'] ?? '',
        data: {
          npvr: {
            hasCatchup: npvr?.['@catchup'] ?? '0',
            hasKeepFromReplay: npvr?.['@keep'] ?? '0',
            hasStartover: npvr?.['@startover'] ?? '0',
            isRecordable: npvr?.['@record'] ?? '0',
          },
          stream: {
            debug: stream?.['@debug'] ?? '0',
            drmType: Drm.cast(stream?.['@drmType']),
            isSeekable: stream?.['@seekable'] ?? '0',
            laUrl: stream?.['@laUrl'] ?? '',
            msdrmCustomData: resolver?.customData ?? stream?.['@msdrmCustomData'] ?? '',
            notrickmode: stream?.['@notrickmode'] ?? '0',
            service: resolver?.service ?? '',
            type: StreamType.cast(getValueFromNode(stream)),
          },
        },
        epgid: channelId,
        id: channel['@id'],
        img: getValueFromNode(channel.img),
        isFastChannel: resolver?.cmp === true,
        isHidden: channel['@hidden'] === '1',
        name: getValueFromNode(channel.name),
        number: Number(channel['@number']),
        template: getValueFromNode(channel.template),
        url: getValueFromNode(channel.url),
      };

      if (urlLifecycle && typeof urlLifecycle['@heartbeat'] === 'string' && typeof urlLifecycle['@type'] === 'string') {
        channelsMap[channelId].data.urlLifecycle = {
          heartbeat: urlLifecycle['@heartbeat'],
          type: urlLifecycle['@type'],
        };
      }
    }
  }

  return channelsMap;
};

const dmsDeviceChannelsRequest: (baseAuthDeviceUrl: string, authenticationToken: string | null, signal?: AbortSignal) => Promise<DMS_DEVICE_CHANNELS> = (
  baseAuthDeviceUrl,
  authenticationToken,
  signal,
) =>
  sendDmsPromisedXMLHttpRequest(`${baseAuthDeviceUrl}channel-list`, HttpMethod.GET, createDmsCommonRequestHeader(authenticationToken), null, null, false, signal).promise.then(
    (channels: NETGEM_API_DMS_CHANNEL_LIST) => {
      let channelArray: Array<NETGEM_API_DMS_PRIVATE_CHANNEL> = [];
      const channel = channels.setup['channel-list']?.channel;
      if (channel) {
        if (Array.isArray(channel)) {
          channelArray = channel;
        } else {
          channelArray = [channel];
        }
      }

      return Promise.resolve({
        deviceChannelNetworkResponse: channels,
        deviceChannels: createChannelMap(channelArray),
      });
    },
  );

export default dmsDeviceChannelsRequest;
