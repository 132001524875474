/* @flow */

import './Options.css';
import * as React from 'react';
import { PictoArrowLeft, PictoCheck, PictoHearingImpaired, PictoLeaf } from '@ntg/components/dist/pictos/Element';
import { convertToHumanReadable, isHearingImpairedSubtitlesTrack, isVisuallyImpairedAudioTrack } from '../../../../helpers/ui/language';
import type { BasicFunction } from '@ntg/utils/dist/types';
import type { CombinedReducers } from '../../../../redux/reducers';
import type { Dispatch } from '../../../../redux/types/types';
import { ExtendedItemType } from '../../../../helpers/ui/item/types';
import { Localizer } from '@ntg/utils/dist/localization';
import { Setting } from '../../../../helpers/settings/types';
import { type SettingValueType } from '../../../settings/SettingsConstsAndTypes';
import { type VideoPlayerMediaInfo } from '../../implementation/types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { updateSetting } from '../../../../redux/ui/actions';

enum OptionsType {
  Audio,
  Subtitles,
}

type OPTIONS_AND_SELECTION_TYPE = {|
  optionsElement: React.Node,
  selectionElement: React.Node,
|};

type ReduxOptionsDispatchToPropsType = {|
  +localUpdateSetting: (setting?: Setting, value: SettingValueType) => Promise<any>,
|};

type ReduxOptionsReducerStateType = {|
  +isBitrateLimited: boolean,
  +isMaxBitrateAllowed: boolean,
|};

type OptionsPropType = {|
  +audioMediaInfo: Array<VideoPlayerMediaInfo>,
  +itemType: ExtendedItemType,
  +onMouseEnter: BasicFunction,
  +onMouseLeave: BasicFunction,
  +onNewAudioTrackSelected: (index: number) => void,
  +onNewSubtitlesTrackSelected: (index: number) => void,
  +selectedAudioMediaInfo: number,
  +selectedSubtitlesMediaInfo: number,
  +subtitlesMediaInfo: Array<VideoPlayerMediaInfo>,
|};

type CompleteOptionsPropType = {|
  ...OptionsPropType,
  ...ReduxOptionsDispatchToPropsType,
  ...ReduxOptionsReducerStateType,
|};

type OptionsStateType = {|
  displayedOptions: OptionsType | null,
|};

class OptionsView extends React.PureComponent<CompleteOptionsPropType, OptionsStateType> {
  constructor(props: CompleteOptionsPropType) {
    super(props);
    this.state = { displayedOptions: null };
  }

  handleAudioOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    this.toggleOptions(OptionsType.Audio);
  };

  handleSubtitlesOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    this.toggleOptions(OptionsType.Subtitles);
  };

  toggleOptions = (optionsType: OptionsType): void => {
    const { displayedOptions: currentDisplayedOptions } = this.state;
    const displayedOptions = optionsType === currentDisplayedOptions ? null : optionsType;

    this.setState({ displayedOptions });
  };

  handleNewAudioTrackOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const { onNewAudioTrackSelected, selectedAudioMediaInfo } = this.props;
    this.handleNewOptionSelected(event, onNewAudioTrackSelected, selectedAudioMediaInfo);
  };

  handleNewSubtitlesTrackOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const { onNewSubtitlesTrackSelected, selectedSubtitlesMediaInfo } = this.props;
    this.handleNewOptionSelected(event, onNewSubtitlesTrackSelected, selectedSubtitlesMediaInfo);
  };

  handleNewOptionSelected = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>, newOptionSelected: (index: number) => void, currentSelection: number): void => {
    const { currentTarget } = event;

    // Get track index from HTML element or from its parent
    let id = currentTarget.getAttribute('data-id');
    if (id === null || id === '') {
      const { parentElement } = currentTarget;
      if (parentElement) {
        id = parentElement.getAttribute('data-id');
      }
    }

    // Empty string is magically converted to 0
    if (id !== null && id !== '' && !isNaN(id)) {
      const idNumber = Number(id);
      if (idNumber !== currentSelection) {
        newOptionSelected(idNumber);
      }
    }
  };

  getCurrentAudio = (): {| currentAudio: string | null, isVisuallyImpaired: boolean |} => {
    const { audioMediaInfo, selectedAudioMediaInfo } = this.props;

    const currentAudioMediaInfo = audioMediaInfo.find((elt) => elt.index === selectedAudioMediaInfo) ?? null;

    return {
      currentAudio: currentAudioMediaInfo ? convertToHumanReadable(currentAudioMediaInfo.lang) : null,
      isVisuallyImpaired: isVisuallyImpairedAudioTrack(currentAudioMediaInfo),
    };
  };

  getCurrentSubtitles = (): {| currentSubtitles: string | null, isHearingImpaired: boolean |} => {
    const { subtitlesMediaInfo, selectedSubtitlesMediaInfo } = this.props;

    const currentSubtitlesMediaInfo = subtitlesMediaInfo.find((elt) => elt.index === selectedSubtitlesMediaInfo) ?? null;

    return {
      currentSubtitles: currentSubtitlesMediaInfo ? convertToHumanReadable(currentSubtitlesMediaInfo.lang) : null,
      isHearingImpaired: isHearingImpairedSubtitlesTrack(currentSubtitlesMediaInfo),
    };
  };

  buildAudioMediaInfoList = (): Array<React.Node> => {
    const { audioMediaInfo, selectedAudioMediaInfo } = this.props;

    const audioMediaInfoList: Array<React.Node> = [];
    for (let i = 0; i < audioMediaInfo.length; i++) {
      const { [i]: audio } = audioMediaInfo;
      const { index, lang } = audio;
      const audioIndicator = index === selectedAudioMediaInfo ? <PictoCheck className='cell' /> : <div className='cell' />;

      audioMediaInfoList.push(
        <div className='option' data-id={index} key={index} onClick={this.handleNewAudioTrackOnClick}>
          {audioIndicator}
          <div className='cell optionLabel'>{convertToHumanReadable(lang)}</div>
        </div>,
      );
    }

    return audioMediaInfoList;
  };

  buildSubtitlesMediaInfoList = (): Array<React.Node> => {
    const { subtitlesMediaInfo, selectedSubtitlesMediaInfo } = this.props;

    if (subtitlesMediaInfo.length === 0) {
      return [];
    }

    const subtitlesMediaInfoList: Array<React.Node> = [
      <div className='option' data-id={-1} key='-1' onClick={this.handleNewSubtitlesTrackOnClick}>
        {selectedSubtitlesMediaInfo < 0 ? <PictoCheck className='cell' /> : <div className='cell' />}
        <div className='cell optionLabel'>{Localizer.localize('player.subtitles.none')}</div>
      </div>,
    ];

    for (let i = 0; i < subtitlesMediaInfo.length; ++i) {
      const { [i]: subtitles } = subtitlesMediaInfo;
      const { index, lang } = subtitles;
      const subtitlesIndicator = index === selectedSubtitlesMediaInfo ? <PictoCheck className='cell' /> : <div className='cell' />;

      const hearingImpairedElt = isHearingImpairedSubtitlesTrack(subtitles) ? <PictoHearingImpaired className='impaired' /> : null;

      subtitlesMediaInfoList.push(
        <div className='option' data-id={index} key={index} onClick={this.handleNewSubtitlesTrackOnClick}>
          {subtitlesIndicator}
          <div className='cell optionLabel'>
            {convertToHumanReadable(lang)}
            {hearingImpairedElt}
          </div>
        </div>,
      );
    }

    return subtitlesMediaInfoList;
  };

  renderAudio = (): OPTIONS_AND_SELECTION_TYPE => {
    const audioMediaInfoList = this.buildAudioMediaInfoList();

    if (audioMediaInfoList.length <= 1) {
      // 0 or 1 audio stream: no choice for the user
      return {
        optionsElement: null,
        selectionElement: null,
      };
    }

    const { currentAudio } = this.getCurrentAudio();

    return this.renderSelectionAndOptions(OptionsType.Audio, audioMediaInfoList, Localizer.localize('player.options.audio'), currentAudio, null, this.handleAudioOnClick);
  };

  renderSubtitles = (): OPTIONS_AND_SELECTION_TYPE => {
    const subtitlesMediaInfoList = this.buildSubtitlesMediaInfoList();

    if (subtitlesMediaInfoList.length === 0) {
      // 0 subtitles stream: no choice for the user
      return {
        optionsElement: null,
        selectionElement: null,
      };
    }

    const { isHearingImpaired, currentSubtitles } = this.getCurrentSubtitles();
    const hearingImpairedElt = isHearingImpaired ? <PictoHearingImpaired className='impaired' /> : null;

    return this.renderSelectionAndOptions(
      OptionsType.Subtitles,
      subtitlesMediaInfoList,
      Localizer.localize('player.options.subtitles'),
      currentSubtitles ?? Localizer.localize('player.subtitles.none'),
      hearingImpairedElt,
      this.handleSubtitlesOnClick,
    );
  };

  renderSelectionAndOptions = (
    optionsType: OptionsType,
    optionsList: Array<React.Node>,
    label: string,
    selection: string | null,
    impairedPicto: React.Node,
    clickCallback: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  ): OPTIONS_AND_SELECTION_TYPE => {
    const { displayedOptions } = this.state;

    const optionsElement = <div className={clsx('optionsContainer', optionsType === displayedOptions && 'visible')}>{optionsList}</div>;

    const selectionElement = (
      <div className={clsx('selection', optionsType === displayedOptions && 'highlighted')} onClick={clickCallback}>
        <PictoArrowLeft className='cell' forceHoverEffect />
        <div className='cell selectionTitle'>{label}</div>
        <div className='cell selectionInfo'>
          {selection}
          {impairedPicto}
        </div>
      </div>
    );

    return {
      optionsElement,
      selectionElement,
    };
  };

  handleLimitBitrateOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const { isBitrateLimited, localUpdateSetting } = this.props;

    localUpdateSetting(Setting.GreenStreaming, !isBitrateLimited);
  };

  renderGreenStreamingOption = (): React.Node => {
    const { isBitrateLimited, isMaxBitrateAllowed, itemType } = this.props;

    if (!isMaxBitrateAllowed || itemType === ExtendedItemType.OfflineContent) {
      return null;
    }

    return (
      <div className='selection greenStreaming' onClick={this.handleLimitBitrateOnClick}>
        {isBitrateLimited ? <PictoCheck className='cell' /> : <div className='cell' />}
        <div className='cell selectionTitle'>
          {Localizer.localize('settings.audio_video.green_streaming.label')}
          <PictoLeaf />
        </div>
      </div>
    );
  };

  render(): React.Node {
    const { onMouseEnter, onMouseLeave } = this.props;
    const { optionsElement: audioOptions, selectionElement: audioSelection } = this.renderAudio();
    const { optionsElement: subtitlesOptions, selectionElement: subtitlesSelection } = this.renderSubtitles();

    return (
      <div className='options' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {audioOptions}
        {subtitlesOptions}
        {audioSelection}
        {subtitlesSelection}
        {this.renderGreenStreamingOption()}
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxOptionsReducerStateType => {
  return {
    isBitrateLimited: state.ui.settings[Setting.GreenStreaming],
    isMaxBitrateAllowed: state.appConfiguration.isMaxBitrateAllowed,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxOptionsDispatchToPropsType => {
  return {
    localUpdateSetting: (setting?: Setting, value: SettingValueType) => dispatch(updateSetting(setting, value)),
  };
};

const Options: React.ComponentType<OptionsPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OptionsView);

export default Options;
