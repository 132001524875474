/* @flow */

import { SubtitlesMimeType, type VideoPlayerExternalSubtitles } from '../../../../../components/player/implementation/types';
import { type BO_API_SUBTITLE_TRACK_METADATA } from '../types/stream';
import { type Undefined } from '@ntg/utils/dist/types';

const convertSubtitleTrackMetadataToExternalSubtitles = (subtitles?: Array<BO_API_SUBTITLE_TRACK_METADATA>): Undefined<Array<VideoPlayerExternalSubtitles>> =>
  subtitles?.map((subs) => {
    return {
      captions: subs.caption,
      format: subs.format,
      language: subs.language,
      mimeType: SubtitlesMimeType.cast(subs.mimeType),
      url: subs.url,
    };
  });

export { convertSubtitleTrackMetadataToExternalSubtitles };
