/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import { updateAvenueList, updateDefaultActions } from '../../../ui/actions';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { FeedProviderKind } from '../../../../libs/netgemLibrary/v8/types/Feed';
import LocalStorageManager from '../../../../helpers/localStorage/localStorageManager';
import { MILLISECONDS_PER_SECOND } from '../../../../helpers/dateTime/Format';
import { type NETGEM_API_V8_AGGREGATION_FEED } from '../../../../libs/netgemLibrary/v8/types/AggregationFeed';
import { type NETGEM_API_V8_AVENUE } from '../../../../libs/netgemLibrary/v8/types/Avenue';
import { type NETGEM_API_V8_DEFAULT_ACTIONS } from '../../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { NETGEM_API_V8_HUB } from '../../../../libs/netgemLibrary/v8/types/Hub';
import { type NETGEM_API_V8_NTG_VIDEO_FEED } from '../../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { type NETGEM_API_V8_SECTION } from '../../../../libs/netgemLibrary/v8/types/Section';
import { REDUX_MSG_REQUEST_GENERIC } from '../../constants/';
import { StorageKeys } from '../../../../helpers/localStorage/keys';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';
import { getSettingValueByName } from '../helpers/settings';

// Delay before actually doing hub query when a previous one has been found in the local storage (in ms)
const OPTIMISTIC_HUB_REFRESH_DELAY = 1_000;

// Delay before actually doing default actions query when previous ones have been found in the local storage (in ms)
const OPTIMISTIC_DEFAULT_ACTIONS_REFRESH_DELAY = 2_000;

const validateSection: (section: NETGEM_API_V8_SECTION) => boolean = (section) => {
  const { model }: { model: NETGEM_API_V8_NTG_VIDEO_FEED | NETGEM_API_V8_AGGREGATION_FEED } = section;
  const { provider } = model;

  if (provider === FeedProviderKind.Aggregation) {
    const { sources } = ((model: any): NETGEM_API_V8_AGGREGATION_FEED);

    if (!sources) {
      return true;
    }

    for (let i = 0; i < sources.length; i++) {
      const { provider: srcProvider } = sources[i];
      if (srcProvider !== FeedProviderKind.NtgVideo && srcProvider !== FeedProviderKind.Local) {
        return false;
      }
    }

    return true;
  }

  return provider === FeedProviderKind.NtgVideo || provider === FeedProviderKind.Local;
};

const validateAvenue: (avenue: NETGEM_API_V8_AVENUE) => boolean = (avenue) => {
  const { sections } = avenue;

  if (!sections) {
    return false;
  }

  avenue.sections = sections.filter(validateSection);
  return sections.length > 0;
};

const getValidHub: (hub: NETGEM_API_V8_HUB) => NETGEM_API_V8_HUB = (hub) => hub.filter(validateAvenue);

const sendV8HubRequest: (hubKey: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (hubKey, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      appConfiguration,
      netgemApi: { hubUrl },
      ui: { hubETag: initialETag },
    } = state;

    if (!hubUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'hubUrl' }));
    }

    // Startup optimization: get last hub response from local storage and do the actual query in background after a few seconds
    if (!hubKey && !initialETag) {
      const optimisticHubResult = LocalStorageManager.loadObject(StorageKeys.OptimisticHub);
      if (optimisticHubResult) {
        LocalStorageManager.delete(StorageKeys.OptimisticHub);
        dispatch(updateAvenueList(optimisticHubResult, OPTIMISTIC_HUB_REFRESH_DELAY, null));
        return Promise.resolve(optimisticHubResult);
      }
    }

    /*
     * The old 'platform.hubkey' has been replaced by 'platform.hubkeys.home' but the setting has yet to be deployed in all realms and environments
     * So, in the meantime, we fall back to the old setting
     */
    const key = hubKey ?? getSettingValueByName('platform.hubkeys', 'home', appConfiguration) ?? getSettingValueByName('platform', 'hubkey', appConfiguration);
    const { authent, method } = hubUrl;

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      eTag: hubKey ? null : initialETag,
      method,
      signal,
      uri: generateApiUrl(hubUrl, { key }, state),
    }).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { cacheMaxAge, eTag: hubETag, result } = response;
      const hubResult: NETGEM_API_V8_HUB = getValidHub(result);

      if (!hubKey) {
        // General hub: sets all avenues

        if (hubETag !== null && hubETag === initialETag) {
          // Not modified
          return Promise.resolve();
        }

        // Startup optimization: store last hub response in local storage
        LocalStorageManager.save(StorageKeys.OptimisticHub, hubResult);

        const hubMaxAge = cacheMaxAge ? cacheMaxAge * MILLISECONDS_PER_SECOND : null;
        dispatch(updateAvenueList(hubResult, hubMaxAge, hubETag ?? null));
      }

      return Promise.resolve(hubResult);
    });
  };

const sendV8DefaultHubRequest: (signal?: AbortSignal, skipCache?: boolean) => RequestResponseMethodDefinitionType =
  (signal, skipCache) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { defaultHubUrl },
    } = state;

    if (!defaultHubUrl) {
      // Old version
      return Promise.resolve();
    }

    // Startup optimization: get last default actions from local storage and do the actual query in background afterwards
    const optimisticDefaultActions = LocalStorageManager.loadObject(StorageKeys.OptimisticDefaultActions);
    if (optimisticDefaultActions && !skipCache) {
      dispatch(updateDefaultActions(optimisticDefaultActions));
      setTimeout(() => dispatch(sendV8DefaultHubRequest(undefined, true)), OPTIMISTIC_DEFAULT_ACTIONS_REFRESH_DELAY);
      return Promise.resolve();
    }

    const { authent, method } = defaultHubUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      method,
      signal,
      uri: generateApiUrl(defaultHubUrl, {}, state),
    }).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { actions } = (response.result: NETGEM_API_V8_DEFAULT_ACTIONS);

      LocalStorageManager.save(StorageKeys.OptimisticDefaultActions, actions);
      dispatch(updateDefaultActions(actions));
      return Promise.resolve();
    });
  };

export { getValidHub, sendV8DefaultHubRequest, sendV8HubRequest };
