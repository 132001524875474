/* @flow */

import { AVENUE_ID_EXPLORE, AVENUE_ID_MYVIDEOS } from '../../../helpers/ui/avenue/constants';
import { AvenueType, type NAVIGATION_AVENUE_TYPE } from '../../../helpers/ui/avenue/types';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_V8_HUB } from '../../../libs/netgemLibrary/v8/types/Hub';

const getAvenueIndexFromId: (avenueList: NETGEM_API_V8_HUB | null, id: string) => number = (avenueList, id) => {
  if (avenueList) {
    return avenueList.findIndex((avenue) => avenue.id === id);
  }

  return -1;
};

const getAvenueFromIndex: (avenueList: NETGEM_API_V8_HUB, index: number) => NAVIGATION_AVENUE_TYPE = (avenueList, index) => {
  const {
    [index]: { id, title },
  } = avenueList;

  let type: AvenueType = AvenueType.Regular;
  if (id === AVENUE_ID_EXPLORE) {
    type = AvenueType.Explore;
  } else if (id === AVENUE_ID_MYVIDEOS) {
    type = AvenueType.Myvideos;
  }

  return {
    index,
    title,
    type,
  };
};

const getMainAvenue: (avenueList: NETGEM_API_V8_HUB | null) => NAVIGATION_AVENUE_TYPE | null = (avenueList) => {
  if (!avenueList || avenueList.length === 0) {
    return null;
  }

  const index = avenueList.findIndex((avenue) => avenue.isMain);

  // If main avenue is not defined, the first one is used
  return getAvenueFromIndex(avenueList, index > -1 ? index : 0);
};

const removeKeysWithPrefix: (obj: KeyValuePair<number>, prefix?: string) => KeyValuePair<number> = (obj, prefix) => {
  if (typeof prefix !== 'string' || prefix === '') {
    return {};
  }

  Object.keys(obj).forEach((key) => {
    if (key.startsWith(prefix)) {
      delete obj[key];
    }
  });

  return obj;
};

export { getAvenueIndexFromId, getMainAvenue, removeKeysWithPrefix };
