/* @flow */

import { WikipediaResultStatusType, type WikipediaResultType, type WikipediaSummary } from './Types';
import { logError, logInfo } from '../debug/debug';
import { HttpStatus } from '../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import { fetchJsonWithErrorCheck } from '../jsHelpers/xhr';

const WIKI_PAGE_URL = `${process.env.REACT_APP_WIKIPEDIA_URL ?? 'https://en.wikipedia.org'}/api/rest_v1/page/summary/{title}`;

class Wikipedia {
  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'Wikipedia';
  }

  static buildSummary: (rawData: any) => WikipediaSummary = (rawData) => {
    const {
      content_urls: {
        desktop: { page: pageUrl, revisions: authorsUrl },
      },
      description,
      extract,
      extract_html: extractHtml,
      originalimage,
      timestamp,
      title,
    } = rawData;

    let imageUrl: ?string = null;
    let imageHeight: ?number = null;
    let imageWidth: ?number = null;
    if (originalimage) {
      ({ height: imageHeight, source: imageUrl, width: imageWidth } = originalimage);
    }

    return {
      authorsUrl,
      description,
      extract,
      extractHtml,
      imageHeight,
      imageUrl,
      imageWidth,
      pageUrl,
      timestamp,
      title,
    };
  };

  static getData: (title: string, signal: AbortSignal) => Promise<WikipediaResultType> = async (title, signal) => {
    const url = WIKI_PAGE_URL.replace('{title}', title);

    if (signal.aborted) {
      // Component unmounted
      throw new Error('Signal aborted');
    }

    try {
      const rawData = await fetchJsonWithErrorCheck(url, { signal });
      const { type, title: resultTitle } = rawData;

      const out: WikipediaResultType = {
        rawData,
        status: WikipediaResultStatusType.Success,
        summary: null,
        url,
      };

      if (resultTitle.toLowerCase().indexOf('not found') > -1 || type.endsWith('/not_found')) {
        // Page not found
        out.status = WikipediaResultStatusType.Notfound;
      } else {
        // Page found or disambiguation needed
        out.summary = this.buildSummary(rawData);

        if (type === 'disambiguation') {
          // Title matches more than one page
          out.status = WikipediaResultStatusType.Disambiguation;
        }
      }

      return out;
    } catch (error) {
      const { message } = error;
      const [status] = message.split(':');

      const isNotFound = status === HttpStatus.NotFound.toString();

      // $FlowFixMe: flow doesn't know DOMException
      if (!signal.aborted && (!(error instanceof DOMException) || error.name !== 'AbortError')) {
        // Only report non-abort errors
        if (isNotFound) {
          logInfo('No Wikipedia entry for this person');
        } else {
          logError(error);
        }
      }

      return {
        rawData: null,
        status: isNotFound ? WikipediaResultStatusType.Notfound : WikipediaResultStatusType.Error,
        summary: null,
        url,
      };
    }
  };
}

export default Wikipedia;
