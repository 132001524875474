/* @flow */

import './Credits.css';
import * as React from 'react';
import {
  NETGEM_API_V8_CREDIT_ROLE_ACTOR,
  NETGEM_API_V8_CREDIT_ROLE_DIRECTOR,
  NETGEM_API_V8_CREDIT_ROLE_GUEST,
  NETGEM_API_V8_CREDIT_ROLE_PRESENTER,
  type NETGEM_API_V8_CREDIT_ROLE_TYPE,
} from '../../libs/netgemLibrary/v8/types/Credit';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../libs/netgemLibrary/v8/types/MetadataProgram';
import { useCallback, useMemo, useState } from 'react';
import CreditsByRole from './CreditsByRole';
import type { ReadOnlyKeyValuePair } from '@ntg/utils/dist/types';

const MAX_PEOPLE_BY_ROLE: ReadOnlyKeyValuePair<number> = Object.freeze({
  [NETGEM_API_V8_CREDIT_ROLE_ACTOR]: 10,
  [NETGEM_API_V8_CREDIT_ROLE_DIRECTOR]: 3,
  [NETGEM_API_V8_CREDIT_ROLE_GUEST]: 3,
  [NETGEM_API_V8_CREDIT_ROLE_PRESENTER]: 3,
});

type CreditsPropType = {|
  +metadata: NETGEM_API_V8_METADATA_PROGRAM | NETGEM_API_V8_METADATA_SERIES | null,
|};

const keyByRole: ReadOnlyKeyValuePair<string> = Object.freeze({
  [NETGEM_API_V8_CREDIT_ROLE_ACTOR]: 'credits.with',
  [NETGEM_API_V8_CREDIT_ROLE_DIRECTOR]: 'credits.by',
  [NETGEM_API_V8_CREDIT_ROLE_GUEST]: 'credits.with',
  [NETGEM_API_V8_CREDIT_ROLE_PRESENTER]: 'credits.presented',
});

const orderedRoles: Array<NETGEM_API_V8_CREDIT_ROLE_TYPE> = Object.freeze([
  NETGEM_API_V8_CREDIT_ROLE_DIRECTOR,
  NETGEM_API_V8_CREDIT_ROLE_PRESENTER,
  NETGEM_API_V8_CREDIT_ROLE_GUEST,
  NETGEM_API_V8_CREDIT_ROLE_ACTOR,
]);

const Credits = ({ metadata }: CreditsPropType): React.Node => {
  const [openTooltips, setOpenTooltips] = useState<any>(new Set());

  const handleOnHidden = useCallback(
    (tip: any): void => {
      const newOpenTooltips = new Set(openTooltips);
      newOpenTooltips.delete(tip);
      setOpenTooltips(newOpenTooltips);
    },
    [openTooltips, setOpenTooltips],
  );

  const handleOnTrigger = useCallback(
    (tip: any): void => {
      openTooltips.forEach((t) => t.hide());
      setOpenTooltips(new Set([tip]));
    },
    [openTooltips, setOpenTooltips],
  );

  const listByRole = useMemo(() => {
    const obj: {| [NETGEM_API_V8_CREDIT_ROLE_TYPE]: Array<string> |} = {};
    orderedRoles.forEach((role) => (obj[role] = []));

    if (!metadata) {
      return obj;
    }

    const { credits } = metadata;
    if (!credits) {
      // All lists are empty
      return obj;
    }

    for (let i: number = 0; i < credits.length; i++) {
      const { name, role } = credits[i];

      if (
        (role === NETGEM_API_V8_CREDIT_ROLE_ACTOR || role === NETGEM_API_V8_CREDIT_ROLE_DIRECTOR || role === NETGEM_API_V8_CREDIT_ROLE_GUEST || role === NETGEM_API_V8_CREDIT_ROLE_PRESENTER) &&
        obj[role].length < MAX_PEOPLE_BY_ROLE[role]
      ) {
        obj[role].push(name);
      }
    }

    return obj;
  }, [metadata]);

  if (!metadata) {
    return null;
  }

  return (
    <div className='credits'>
      {orderedRoles.map((role) => (
        <CreditsByRole key={role} labelKey={keyByRole[role]} names={listByRole[role]} onHidden={handleOnHidden} onTrigger={handleOnTrigger} />
      ))}
    </div>
  );
};

export default Credits;
