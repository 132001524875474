/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { type NETGEM_API_V8_SECTION } from '../../../../libs/netgemLibrary/v8/types/Section';
import { REDUX_MSG_REQUEST_SECTION_FEED } from '../../constants/';

const sendV8SectionFeedRequest: (section: NETGEM_API_V8_SECTION, searchString: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (section, searchString, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> =>
    NetgemApiEmitter.emit(REDUX_MSG_REQUEST_SECTION_FEED, {
      searchString,
      section,
      signal,
      state: getState(),
    });

export default sendV8SectionFeedRequest;
