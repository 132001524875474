/* @flow */

export const NETGEM_API_V8_CREDIT_ROLE_ACTOR: 'Actor' = 'Actor';
export const NETGEM_API_V8_CREDIT_ROLE_DIRECTOR: 'Director' = 'Director';
export const NETGEM_API_V8_CREDIT_ROLE_GUEST: 'Guest' = 'Guest';
export const NETGEM_API_V8_CREDIT_ROLE_PRESENTER: 'Presenter' = 'Presenter';

// Cannot convert to Flow enum because it's used as an object's key
export type NETGEM_API_V8_CREDIT_ROLE_TYPE =
  | typeof NETGEM_API_V8_CREDIT_ROLE_ACTOR
  | typeof NETGEM_API_V8_CREDIT_ROLE_DIRECTOR
  | typeof NETGEM_API_V8_CREDIT_ROLE_GUEST
  | typeof NETGEM_API_V8_CREDIT_ROLE_PRESENTER;

export type NETGEM_API_V8_CREDIT = {|
  id: string,
  name: string,
  role: NETGEM_API_V8_CREDIT_ROLE_TYPE,
|};
