/* @flow */

import { DunningStatus, InvoiceStatus, PaymentStatus, type SubscriptionList, SubscriptionStatus, type UserInfo } from '../../redux/appRegistration/types/types';
import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import LocalStorageManager from '../localStorage/localStorageManager';
import { MIN_DATE } from '../dateTime/Format';
import { MessengerEvents } from '@ntg/utils/dist/messenger';
import { StorageKeys } from '../localStorage/keys';
import { logError } from '../debug/debug';

const hasPaymentExpirationPoppedToday = () => {
  const lastNotification = LocalStorageManager.loadIsoDate(StorageKeys.LastPaymentExpirationNotification, MIN_DATE);
  const now = AccurateTimestamp.nowAsDate();
  return lastNotification.getMonth() === now.getMonth() && lastNotification.getDate() === now.getDate();
};

const hasActiveSubscription = (subscriptions: SubscriptionList): boolean =>
  Object.values(subscriptions).some((status) => status === SubscriptionStatus.Active || status === SubscriptionStatus.InTrial || status === SubscriptionStatus.NonRenewing);

const getUserInfoStatuses: (userInfo: ?UserInfo) => Array<{| key: string, notificationType: string |}> | null = (userInfo) => {
  if (!userInfo?.paymentInfo) {
    logError('No user or payment info');
    return null;
  }

  const {
    paymentInfo: { dunningStatus, invoiceStatus, status },
    subscriptions,
  } = userInfo;

  const statuses = [];

  if (status === PaymentStatus.Expiring && !hasPaymentExpirationPoppedToday()) {
    // Payment method will expire in the current month
    LocalStorageManager.save(StorageKeys.LastPaymentExpirationNotification, AccurateTimestamp.nowAsDate());
    statuses.push({
      key: 'subscription.payment.expire_soon',
      notificationType: MessengerEvents.NOTIFY_WARNING,
    });
  }

  if (invoiceStatus === InvoiceStatus.Paid || invoiceStatus === InvoiceStatus.Voided) {
    // Invoice status is OK: no need to check dunning status
    return null;
  }

  if (hasActiveSubscription(subscriptions)) {
    // An active subscription exists: no need to check dunning status
    return null;
  }

  if (dunningStatus === DunningStatus.InProgress) {
    // Dunning in progress
    statuses.push({
      key: 'subscription.payment.dunning_in_progress',
      notificationType: MessengerEvents.NOTIFY_WARNING,
    });
  } else if (dunningStatus === DunningStatus.Exhausted) {
    // Dunning has expired as well
    statuses.push({
      key: 'subscription.payment.dunning_expired',
      notificationType: MessengerEvents.NOTIFY_ERROR,
    });
  }

  // All is well: nothing to report
  return statuses;
};

const hasActiveSubscriptionForPlan: (userInfo: UserInfo, planId: string) => boolean = (userInfo, planId) => {
  const { subscriptions } = userInfo;

  if (!subscriptions) {
    return false;
  }

  const { [planId]: status } = subscriptions;

  return status === SubscriptionStatus.Active || status === SubscriptionStatus.InTrial || status === SubscriptionStatus.NonRenewing;
};

const hasPods: (userInfo: ?UserInfo) => boolean = (userInfo) => {
  if (!userInfo) {
    logError('No user info');
    return false;
  }

  return hasActiveSubscriptionForPlan(userInfo, 'super-stream-2-pods') || hasActiveSubscriptionForPlan(userInfo, 'super-stream-3-pods');
};

export { getUserInfoStatuses, hasPods };
