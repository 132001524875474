/* @flow */

import {
  type NETGEM_API_V8_FEED_RAW_ITEM,
  type NETGEM_API_V8_ITEM_LOCATION,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_EST,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD,
  type NETGEM_API_V8_ITEM_SCORE,
} from '../types/FeedItem';
import { getAvailabilityEndTimeOrDefault, getAvailabilityStartTimeOrDefault } from '../../../../helpers/videofutur/metadata';
import AccurateTimestamp from '../../../../helpers/dateTime/AccurateTimestamp';
import type { ChannelMap } from '../types/Channel';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import { type NETGEM_API_V8_FEED_OP_FILTER } from '../types/Feed';
import { RecordingOutcome } from '../types/Npvr';
import { addISO8601Duration } from './Date';
import { getLocationType } from './Item';

type FilterContextType = {|
  channels: ChannelMap,
  now: number,
|};

// eslint-disable-next-line no-use-before-define
type ArgsType = Array<FilterStructureType | number | string>;

type FilterStructureType = {|
  op: (location: NETGEM_API_V8_ITEM_LOCATION, item: NETGEM_API_V8_FEED_RAW_ITEM, args: ArgsType, ctx: FilterContextType) => boolean,
  args: ArgsType,
|};

type FilterOperatorFunctionType = (location: NETGEM_API_V8_ITEM_LOCATION, item: NETGEM_API_V8_FEED_RAW_ITEM, args: Array<FilterStructureType | number | string>, ctx: FilterContextType) => boolean;

type ItemFilterType = (location: NETGEM_API_V8_ITEM_LOCATION, item: NETGEM_API_V8_FEED_RAW_ITEM) => boolean;

const compareScore: (score1?: NETGEM_API_V8_ITEM_SCORE, score2?: NETGEM_API_V8_ITEM_SCORE) => number = (score1, score2) => {
  if (typeof score1 === 'undefined' && typeof score2 === 'undefined') {
    return 0;
  }

  if (typeof score1 === 'undefined') {
    return 1;
  }

  if (typeof score2 === 'undefined') {
    return -1;
  }

  const len = Math.min(score1.length, score2.length);
  for (let i = 0; i < len; ++i) {
    const s1: number | string = score1[i];
    const s2: number | string = score2[i];

    if (typeof s1 === 'number' && typeof s2 === 'number') {
      if (s1 < s2) {
        return -1;
      }
      if (s1 > s2) {
        return 1;
      }
    } else {
      const str1 = s1.toString();
      const str2 = s2.toString();
      if (str1 < str2) {
        return -1;
      }
      if (str1 > str2) {
        return 1;
      }
    }
  }

  if (score1.length !== score2.length) {
    // Same score for starting elements but one item as a longer score array (better score)
    return score1.length > score2.length ? -1 : 1;
  }

  // Same scores
  return 0;
};

const compareElementScore: (elt1: { score?: NETGEM_API_V8_ITEM_SCORE, ... }, elt2: { score?: NETGEM_API_V8_ITEM_SCORE, ... }) => number = (elt1, elt2) => compareScore(elt1.score, elt2.score);

const compareLocationScore: (loc1: NETGEM_API_V8_ITEM_LOCATION, loc2: NETGEM_API_V8_ITEM_LOCATION) => number = (loc1, loc2) => compareScore(loc1.score, loc2.score);

const filterOps: KeyValuePair<FilterOperatorFunctionType> = {
  all: (location, item, args, ctx) => Boolean(location) || Boolean(args) || Boolean(ctx),

  and: (location, item, args, ctx) => {
    const len = args.length;
    for (let i = 0; i < len; i++) {
      const arg = args[i];
      if (typeof arg === 'object') {
        if (!arg.op(location, item, arg.args, ctx)) {
          return false;
        }
      }
    }
    return true;
  },

  assetId: (location, item, args) => {
    const { id } = item;
    if (id && args.length === 1) {
      const [assetId] = args;
      return assetId === id;
    }
    return false;
  },

  // True if considered scheduled event is broadcasted on a channel that is part of channel list
  channelList: (location, item, args, ctx) => {
    const { channelId } = location;
    const { channels } = ctx;

    const channelListInternal = (ids: any) => (Array.isArray(ids) ? ids.includes(channelId) : false);

    if (!Array.isArray(args) || args.length === 0) {
      return channelListInternal(Object.keys(channels));
    }

    const [channelIds] = args;
    return channelListInternal(channelIds);
  },

  // Return true if scheduled event is not started yet (i.e. now < startDate)
  future: (location, item, args, ctx) => {
    const { now } = ctx;
    const { startDate } = location;

    if (!startDate) {
      return false;
    }

    const startTime = new Date(startDate).getTime();

    return now < startTime;
  },

  // Return true is currently live (i.e. startDate <= now < startDate + duration)
  live: (location, item, args, ctx) => {
    const { now } = ctx;
    const { duration, startDate } = location;

    if (!startDate || !duration) {
      return false;
    }

    const startTime = new Date(startDate).getTime();
    const endTime = addISO8601Duration(startDate, duration).getTime();

    return startTime <= now && now < endTime;
  },

  locationType: (location, item, args) => {
    const { id } = location;
    const locType = getLocationType(id);
    return args.indexOf(locType) > -1;
  },

  not: (location, item, args, ctx) => {
    const [arg] = args;
    if (typeof arg === 'object') {
      return !arg.op(location, item, arg.args, ctx);
    }
    return true;
  },

  or: (location, item, args, ctx) => {
    const len = args.length;
    for (let i = 0; i < len; i++) {
      const arg = args[i];
      if (typeof arg === 'object') {
        if (arg.op(location, item, arg.args, ctx)) {
          return true;
        }
      }
    }
    return false;
  },

  parentalRating: (location, item, args) => {
    /*
     * Minimum age   none   6   12   16   18
     * args: [-16]     v    v    v    x    x
     * args: [ 16]     x    x    x    v    v
     * args: [  7]     x    x    v    v    v
     */

    if (args.length === 0) {
      return true;
    }

    const [threshold] = args;
    if (typeof threshold !== 'number') {
      return true;
    }

    const { parentalGuidance } = item;

    if (!parentalGuidance) {
      // No minimum age for this program, i.e. 0
      return threshold < 0;
    }

    const { minimumAge } = parentalGuidance;

    if (threshold < 0) {
      // Filter out programs for people older than -threshold
      return minimumAge < -threshold;
    }

    // Filter out programs for people younger than threshold
    return minimumAge >= threshold;
  },

  playable: (location, item, args, ctx) => {
    const { now } = ctx;
    const { availabilityStartDate, availabilityEndDate, id, scheduledEventDuration, scheduledEventStartDate, recordOutcome } = location;
    const delay = args.length > 0 && typeof args[0] === 'number' ? args[0] : 0;
    const startTime = getAvailabilityStartTimeOrDefault(availabilityStartDate);
    const endTime = getAvailabilityEndTimeOrDefault(availabilityEndDate);
    const locType = getLocationType(id);
    const isVod =
      locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD ||
      locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD ||
      locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD ||
      locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_EST;

    // Recording, catchup and VOD
    if ((locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING && recordOutcome) || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP || isVod) {
      return (!recordOutcome || recordOutcome === RecordingOutcome.Recorded) && (isVod || startTime - delay <= now) && now < endTime;
    }

    // Scheduled event
    if (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT && scheduledEventDuration && scheduledEventStartDate) {
      const scheduledEventStartTime = new Date(scheduledEventStartDate).getTime() - delay;
      const scheduledEventEndTime = addISO8601Duration(scheduledEventStartDate, scheduledEventDuration).getTime();
      return scheduledEventStartTime < now && now < scheduledEventEndTime;
    }

    return false;
  },

  previewAvailability: (location, item, args, ctx) => {
    const { now } = ctx;
    const { previewAvailabilityStartDate } = location;

    return getAvailabilityStartTimeOrDefault(previewAvailabilityStartDate) < now;
  },
};

const filterItem: (item: NETGEM_API_V8_FEED_RAW_ITEM, filter: ItemFilterType, rootItem?: NETGEM_API_V8_FEED_RAW_ITEM) => ?NETGEM_API_V8_FEED_RAW_ITEM = (item, filter, rootItem) => {
  const { elements, locations } = item;
  const eltsOut = [];
  const locsOut = [];
  let eltsOutScore: ?NETGEM_API_V8_ITEM_SCORE = null;
  let locsOutScore: ?NETGEM_API_V8_ITEM_SCORE = null;
  const root = rootItem || item;

  if (elements) {
    for (let i = 0; i < elements.length; i++) {
      const el = filterItem(elements[i], filter, root);
      if (el) {
        eltsOut.push(el);
      }
    }
    if (eltsOut.length > 0) {
      eltsOut.sort(compareElementScore);
      eltsOutScore = eltsOut[0].score;
    }
  }

  if (locations) {
    for (let k = 0; k < locations.length; k++) {
      const { [k]: loc } = locations;

      if (filter(loc, root)) {
        locsOut.push(loc);
      }
    }
    if (locsOut.length > 0) {
      locsOut.sort(compareLocationScore);
      locsOutScore = locsOut[0].score;
    }
  }

  if (eltsOut.length + locsOut.length === 0) {
    return null;
  }

  let score = eltsOutScore || locsOutScore || [0];
  if (eltsOutScore && locsOutScore) {
    score = compareScore(eltsOutScore, locsOutScore) <= 0 ? eltsOutScore : locsOutScore;
  }

  const filteredItem: NETGEM_API_V8_FEED_RAW_ITEM = { id: item.id };

  if (item.parentalGuidance) {
    filteredItem.parentalGuidance = item.parentalGuidance;
  }

  if (item.purchasable) {
    filteredItem.purchasable = item.purchasable;
  }

  if (score) {
    filteredItem.score = score;
  }

  if (eltsOut.length > 0) {
    filteredItem.elements = eltsOut;
  }

  if (locsOut.length > 0) {
    filteredItem.locations = locsOut;
  }

  return filteredItem;
};

/*
 * Format filter function according to filter object provided by section feed
 *
 * @private
 * @param  {Object} filterObj   [description]
 * @param  {Object} ops         [description]
 * @return {[type]}             [description]
 */
const formatFilterOperator: (filterObj: NETGEM_API_V8_FEED_OP_FILTER) => FilterStructureType = (filterObj) => {
  const op = filterOps[filterObj.op];
  const out: FilterStructureType = {
    args: [],
    op: op || filterOps.all,
  };

  if (op) {
    const { args = [] } = filterObj;
    const { length = 0 } = args;

    if (length > 0) {
      for (let i = 0; i < length; i++) {
        const arg = args[i];
        out.args = [...out.args, typeof arg === 'object' && !Array.isArray(arg) ? formatFilterOperator(arg) : arg];
      }
    } else if (op === filterOps.or || op === filterOps.and || op === filterOps.not) {
      // Error of filter operator formatting (or/and/not should have non-null args) > assign 'all' to accept all items
      out.op = filterOps.all;
    }
  }

  return out;
};

const createFilterOperator: (filterObj: NETGEM_API_V8_FEED_OP_FILTER, channels: ChannelMap) => ItemFilterType = (filterObj, channels) => {
  const ctx = {
    channels,
    now: AccurateTimestamp.now(),
  };
  const filter = formatFilterOperator(filterObj);

  return (location, item) => filter.op(location, item, filter.args, ctx);
};

export { compareElementScore, compareLocationScore, compareScore, createFilterOperator, filterItem };
