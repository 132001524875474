/* @flow */

import { HeaderName, HeaderValue } from '../constants/Headers';
import { HttpStatus, NetgemNetworkCode } from '../constants/NetworkCodesAndMessages';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { addAuthenticationHeader, addETagHeader, getResponseHeader } from '../helpers/RequestHeaders';
import { getETag, setETag } from '../../../../helpers/request/eTag';
import AccurateTimestamp from '../../../../helpers/dateTime/AccurateTimestamp';
import type { CombinedReducers } from '../../../../redux/reducers';
import { CustomNetworkError } from '../../helpers/CustomNetworkError';
import { HttpMethod } from '../types/HttpMethod';
import { NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR } from '../types/Realm';
import type { NETGEM_API_V8_REQUEST_HEADERS } from '../types/Headers';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../types/RequestResponse';
import { type NETGEM_API_V8_REQUEST_RESPONSE_BASE } from '../types/RequestResponseBase';
import { XhrResponseType } from '../../../../helpers/jsHelpers/xhr';
import { getBOSetting } from '../../../../redux/netgemApi/actions/helpers/boSettings';
import { getCacheExpirationTime } from '../../../../helpers/request/cache';
import { getETagFromRequest } from '../../helpers/CommonPromisedXMLHttpRequest';
import { logError } from '../../../../helpers/debug/debug';
import sendV8PromisedXMLHttpRequest from '../helpers/PromisedXMLHttpRequest';

type CACHE_VALUE = {|
  expiration: number,
  requestResponse: string,
|};

// Key is request's URL
type RESPONSE_CACHE = {|
  [string]: CACHE_VALUE,
|};

const cache: RESPONSE_CACHE = {};

// Expired cached requests are removed every 20 minutes (in ms)
const CACHE_GARBAGE_COLLECTION_TIMEOUT = 1_200_000;

// Delay before actually executing GC (in ms)
const GARBAGE_COLLECTION_TIMEOUT = 100;

let nextGarbageCollectionDate = AccurateTimestamp.now() + CACHE_GARBAGE_COLLECTION_TIMEOUT;
let gcTimer: TimeoutID | null = null;

const garbageCollectCache: () => void = () => {
  const now = AccurateTimestamp.now();

  Object.entries(cache).forEach(([key, value]) => {
    const { expiration } = ((value: any): CACHE_VALUE);
    if (now >= expiration) {
      // Response expired
      delete cache[key];
    }
  });

  nextGarbageCollectionDate = now + CACHE_GARBAGE_COLLECTION_TIMEOUT;

  gcTimer = null;
};

const getCacheMaxAge: (req: XMLHttpRequest) => number | null = (req) => {
  const responseHeaders = req.getAllResponseHeaders();
  const result = /\bcache-control:\s*(.+)\s*,\s*max-age=(\d+)/giu.exec(responseHeaders);
  const requiredLength = 2;

  if (result && result.length > requiredLength) {
    const [, directive, maxAge] = result;
    if (directive !== 'no-cache' && !isNaN(maxAge)) {
      return Number(maxAge);
    }
  }

  return null;
};

const retryIfUnauthorized: (
  url: string,
  method: HttpMethod,
  authenticationToken: string | null,
  bodyParam: ?string,
  requestParam: ?string,
  requestHeaderList: NETGEM_API_V8_REQUEST_HEADERS,
  state: ?CombinedReducers,
  signal: ?AbortSignal,
  error: Error,
  status: number,
) => Promise<any> = (url, method, authenticationToken, bodyParam, requestParam, requestHeaderList, state, signal, error, status) => {
  if (status !== HttpStatus.Unauthorized || !state) {
    return Promise.reject(error);
  }

  const {
    appConfiguration,
    appConfiguration: { distributorAppKeys, mainDistributorId },
  } = state;

  try {
    if (!(error instanceof CustomNetworkError)) {
      return Promise.reject(error);
    }

    const {
      networkError: { responseHeaders },
    } = error;
    const authent = getResponseHeader(responseHeaders, HeaderName.WwwAuthenticate);

    if (!authent) {
      // Authentication token probably expired: let's renew it
      Messenger.emit(MessengerEvents.REFRESH_AUTHENTICATION_TOKEN);
      return Promise.reject(error);
    }

    /*
     * Examples of authent headers:
     *   www-authenticate: Bearer realm="videofutur"
     *   www-authenticate: Bearer realm="videofutur", scope="distributor=vitis-vno-fibre-v8"
     *   www-authenticate: Bearer realm="videofutur", scope="distributor=vitis-vno-fibre-v8&foo=bar"
     *
     * NOTE: Additional parameters are not currently used
     */
    const m = authent.match(/^Bearer\s+realm="(.+?)"(?:\s*,\s*scope="distributor=(.+?)(&.+?=.+)?")?$/iu);

    if (!m || m.length <= 1) {
      return Promise.reject(error);
    }

    // Retry request with right token and app key, as specified in the www-authenticate header

    const [, realm, distributorId] = m;
    const distributor = distributorId ?? mainDistributorId;

    if (!distributor) {
      return Promise.reject(error);
    }

    const { [distributor]: appKey } = distributorAppKeys;

    if (!appKey) {
      return Promise.reject(error);
    }

    requestHeaderList.push({
      name: HeaderName.AppKey,
      value: appKey,
    });

    const token = realm.toLowerCase() === NETGEM_API_V8_AUTHENT_REALM_VIDEOFUTUR ? getBOSetting('identity', appConfiguration) : authenticationToken;

    return sendV8Request(url, token, bodyParam, requestHeaderList, null, signal, requestParam, method);
  } catch {
    return Promise.reject(error);
  }
};

const getResponseSettings: (requestHeaderList: NETGEM_API_V8_REQUEST_HEADERS) => { mustBeTransformedToImage: boolean, mustBeTransformedToJson: boolean, responseType: XhrResponseType } = (
  requestHeaderList,
) => {
  let mustBeTransformedToImage = false;
  let mustBeTransformedToJson = false;
  let responseType = XhrResponseType.Text;

  for (let i: number = 0; i < requestHeaderList.length; i++) {
    const {
      [i]: { name, value },
    } = requestHeaderList;
    if (name === HeaderName.Accept && value.startsWith(HeaderValue.ApplicationJson)) {
      mustBeTransformedToJson = true;
      responseType = XhrResponseType.Text;
      break;
    } else if (name === HeaderName.Accept && value === HeaderValue.Image) {
      mustBeTransformedToImage = true;
      responseType = XhrResponseType.Blob;
      break;
    }
  }

  return {
    mustBeTransformedToImage,
    mustBeTransformedToJson,
    responseType,
  };
};

const parseJsonResponse: (url: string, responseText: string) => Promise<any> = (url, responseText) => {
  try {
    return Promise.resolve(JSON.parse(responseText));
  } catch (error) {
    logError(`Error parsing JSON response from ${url}`);
    logError(responseText);
    return Promise.reject(error);
  }
};

const sendV8Request: (
  url: string,
  authenticationToken: string | null,
  bodyParam: ?string,
  requestHeaderList: NETGEM_API_V8_REQUEST_HEADERS,
  state: ?CombinedReducers,
  signal: ?AbortSignal,
  requestParam: ?string,
  method?: HttpMethod,
  isPersonalData?: boolean,
) => Promise<any> = (url, authenticationToken, bodyParam, requestHeaderList, state, signal, requestParam, method, isPersonalData) => {
  const { mustBeTransformedToImage, mustBeTransformedToJson, responseType } = getResponseSettings(requestHeaderList);
  const localMethod = method || HttpMethod.GET;
  const now = AccurateTimestamp.now();

  // Cache garbage collection
  if (now >= nextGarbageCollectionDate && !gcTimer) {
    gcTimer = setTimeout(garbageCollectCache, GARBAGE_COLLECTION_TIMEOUT);
  }

  let isCachedItemOnProbation = false;
  const { [url]: cachedItem } = cache;
  if (localMethod === HttpMethod.GET && cachedItem) {
    const { expiration } = cachedItem;
    if (now < expiration) {
      // Use response from cache
      const { requestResponse } = cachedItem;
      return Promise.resolve(JSON.parse(requestResponse));
    }

    // Cached item has expired but if PTF returns 304, it means it's still valid
    isCachedItemOnProbation = true;
  }

  addAuthenticationHeader(requestHeaderList, authenticationToken);

  const forceETagUsage = url.indexOf('GetHistory') > -1 || url.indexOf('GetFavorite') > -1;

  // Add e-tag for specific GET requests
  if (forceETagUsage && cachedItem) {
    const previousETag = getETag(url);
    addETagHeader(requestHeaderList, previousETag);
  }

  const returnObject = sendV8PromisedXMLHttpRequest(url, responseType, requestHeaderList, bodyParam, requestParam, Boolean(authenticationToken), signal, localMethod);

  return returnObject.promise
    .then((responseText: string) => {
      if (mustBeTransformedToJson) {
        return parseJsonResponse(url, responseText);
      }
      return responseText;
    })
    .then((response: NETGEM_API_V8_REQUEST_RESPONSE_BASE | string) => {
      const { xhr } = returnObject;

      const eTag = getETagFromRequest(xhr);
      const cacheMaxAge = getCacheMaxAge(xhr);
      let result: any = null;

      if (forceETagUsage) {
        // Store e-tag for next call
        setETag(url, eTag);
      }

      if (mustBeTransformedToJson && typeof response !== 'string') {
        ({ result } = response);
      } else if (mustBeTransformedToImage) {
        result = (window.URL || window.webkitURL).createObjectURL(response);
      } else {
        result = response;
      }

      const requestResponse: NETGEM_API_V8_REQUEST_RESPONSE = {
        cacheMaxAge,
        eTag,
        message: 'The HTTP request was successful',
        result,
        status: NetgemNetworkCode.OK,
      };

      if (localMethod === HttpMethod.GET) {
        // Store response in cache along with its expiration date (this will overwrite any existing cached response)
        cache[url] = {
          expiration: getCacheExpirationTime(cacheMaxAge),
          requestResponse: JSON.stringify(requestResponse),
        };
      }

      return Promise.resolve(requestResponse);
    })
    .catch((error: Error) => {
      const {
        xhr,
        xhr: { responseURL, status },
      } = returnObject;

      // $FlowFixMe: flow doesn't know DOMException
      if (error instanceof DOMException && error.name === 'AbortError') {
        xhr.abort();
        return Promise.reject(error);
      }

      if (status === HttpStatus.NotModified && !isPersonalData && cachedItem) {
        /*
         * Use response from cache for not modified response, except for personal data requests (wishlist & viewing history)
         * since content could have been modified by a POST request, thus invalidating the cached response
         */
        const { requestResponse } = cachedItem;
        return Promise.resolve(JSON.parse(requestResponse));
      }

      // Cached response has expired and an error occurred: better remove it from cache
      if (isCachedItemOnProbation) {
        delete cache[url];
      }

      return retryIfUnauthorized(responseURL, localMethod, authenticationToken, bodyParam, requestParam, requestHeaderList, state, signal, error, status);
    });
};

export default sendV8Request;
