/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { ItemType } from '../../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { REDUX_MSG_REQUEST_GENERIC } from '../../constants';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const sendV8AssetIdForDeeplinkRequestV1: (
  type: ItemType,
  cncProgramGetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  cncSeriesGetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  cncParameter: string,
  state: CombinedReducers,
  NetgemApiEmitter: NetgemApiEmitterType,
  signal?: AbortSignal,
) => Promise<any> = (type, cncProgramGetUrl, cncSeriesGetUrl, cncParameter, state, NetgemApiEmitter, signal) => {
  const urlDef = type === ItemType.Program ? cncProgramGetUrl : cncSeriesGetUrl;

  if (!urlDef) {
    return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: type === ItemType.Program ? 'cncProgramGetUrl' : 'cncSeriesGetUrl' }));
  }

  const { authent, method } = urlDef;

  return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
    authent,
    method,
    signal,
    state,
    uri: generateApiUrl(urlDef, { cncParameter }, state),
  });
};

const sendV8AssetIdForDeeplinkRequestV2: (
  type: ItemType,
  deeplinkProgramGetUrl: NETGEM_API_V8_URL_DEFINITION,
  deeplinkSeriesGetUrl: NETGEM_API_V8_URL_DEFINITION,
  deeplinkParameter: string,
  state: CombinedReducers,
  NetgemApiEmitter: NetgemApiEmitterType,
  signal?: AbortSignal,
) => Promise<any> = (type, deeplinkProgramGetUrl, deeplinkSeriesGetUrl, deeplinkParameter, state, NetgemApiEmitter, signal) => {
  const urlDef = type === ItemType.Program ? deeplinkProgramGetUrl : deeplinkSeriesGetUrl;

  if (!urlDef) {
    return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: type === ItemType.Program ? 'deeplinkProgramGetUrl' : 'deeplinkSeriesGetUrl' }));
  }

  const { authent, method } = urlDef;

  return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
    authent,
    method,
    signal,
    state,
    uri: generateApiUrl(urlDef, { deeplinkParameter }, state),
  });
};

const sendV8AssetIdForDeeplinkRequest: (type: ItemType, deeplinkParameter: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (type, deeplinkParameter, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { cncProgramGetUrl, cncSeriesGetUrl, deeplinkProgramGetUrl, deeplinkSeriesGetUrl },
    } = state;

    // Backward compatibility: deeplink > CNC
    if (deeplinkProgramGetUrl && deeplinkSeriesGetUrl) {
      return sendV8AssetIdForDeeplinkRequestV2(type, deeplinkProgramGetUrl, deeplinkSeriesGetUrl, deeplinkParameter, state, NetgemApiEmitter, signal);
    }

    return sendV8AssetIdForDeeplinkRequestV1(type, cncProgramGetUrl, cncSeriesGetUrl, deeplinkParameter, state, NetgemApiEmitter, signal);
  };

export default sendV8AssetIdForDeeplinkRequest;
