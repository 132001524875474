/* @flow */

import { HeaderName, HeaderValue } from '../constants/Headers';
import { HttpMethod } from '../types/HttpMethod';
import type { NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND } from '../types/Npvr';
import { buildHeaderList } from '../helpers/RequestHeaders';
import sendV8Request from './v8';

const sendV8ScheduledRecordingCreateRequest: (
  url: string,
  scheduledRecordKind: NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND,
  target: string,
  fromBeginning: boolean,
  startMargin: number,
  endMargin: number,
  channelId: ?string,
  fromUtc: ?string,
  toUtc: ?string,
  increasingEpisodes: ?boolean,
  recordsToKeep: ?number,
  method: HttpMethod,
  authenticationToken: string | null,
  signal: ?AbortSignal,
) => Promise<any> = (url, scheduledRecordKind, target, fromBeginning, startMargin, endMargin, channelId, fromUtc, toUtc, increasingEpisodes, recordsToKeep, method, authenticationToken, signal) => {
  const headerList = buildHeaderList(HeaderName.ContentType, HeaderValue.ApplicationJsonUtf8, HeaderName.Accept, HeaderValue.ApplicationJson);

  const body = JSON.stringify({
    channelId,
    endMargin,
    fromBeginning,
    fromUtc,
    increasingEpisodes,
    recordsToKeep,
    scheduledRecordKind,
    startMargin,
    target,
    toUtc,
  });

  return sendV8Request(url, authenticationToken, body, headerList, null, signal, null, method);
};

const sendV8ScheduledRecordingUpdateRequest: (
  url: string,
  scheduledRecordId: string,
  fromUtc: ?string,
  toUtc: ?string,
  increasingEpisodes: ?boolean,
  recordsToKeep: ?number,
  method: HttpMethod,
  eTag: string,
  authenticationToken: string | null,
  signal: ?AbortSignal,
) => Promise<any> = (url, scheduledRecordId, fromUtc, toUtc, increasingEpisodes, recordsToKeep, method, eTag, authenticationToken, signal) => {
  const headerList = buildHeaderList(HeaderName.ContentType, HeaderValue.ApplicationJsonUtf8, HeaderName.IfMatch, eTag);

  const body = JSON.stringify({
    fromUtc,
    increasingEpisodes,
    recordsToKeep,
    scheduledRecordId,
    toUtc,
  });

  return sendV8Request(url, authenticationToken, body, headerList, null, signal, null, method);
};

export { sendV8ScheduledRecordingCreateRequest, sendV8ScheduledRecordingUpdateRequest };
