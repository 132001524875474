/* @flow */

import '@tippyjs/react/node_modules/tippy.js/dist/tippy.css';
import '@tippyjs/react/node_modules/tippy.js/animations/scale.css';
import './themes/tippy-core.css';
import './themes/toastify-core.css';
import './themes/measurements-core.css';
import './index.css';
import * as React from 'react';
import { parseBoolean, parseStringArray } from './helpers/jsHelpers/parser';
import App from './App';
import { Localizer } from '@ntg/utils/dist/localization';
import { createRoot } from 'react-dom/client';
import { isRunningOnLocalhost } from './helpers/jsHelpers/environment';
import log from 'loglevel';
import { logError } from './helpers/debug/debug';
import translationDe from './locales/de/translation.json';
import translationEn from './locales/en/translation.json';
import translationFr from './locales/fr/translation.json';

// Redirect HTTP to HTTPS and remove '/index.html' if no query string is present
const {
  location: { hostname, pathname, protocol, search },
} = window;
if (!isRunningOnLocalhost() && (protocol !== 'https:' || pathname === '/index.html')) {
  window.location.replace(`https://${hostname}${search}`);
}

const rootElement: HTMLElement | null = document.getElementById('root');
if (!rootElement) {
  logError('No "root" element to render application');
}

// eslint-disable-next-line no-use-before-define
type TranslationItem = Translation | string;
type Translation = {| [string]: TranslationItem |};

const translations: Translation = {
  de: translationDe,
  en: translationEn,
  fr: translationFr,
};

// Languages supported by application
export const APP_SUPPORTED_LANGUAGES: Array<string> = parseStringArray(process.env.REACT_APP_LANGUAGES, ',', ['fr', 'en']);

// Get the best from supported languages and browser's languages
export const APP_LANGUAGES: Array<string> = Localizer.getAppLanguages(APP_SUPPORTED_LANGUAGES);
export const APP_LANGUAGES_AS_STRING: string = JSON.stringify(APP_LANGUAGES);

// First app language wins jackpot and is promoted official language for this session
export const [APP_LANGUAGE] = APP_LANGUAGES;

// Setting default log level to TRACE in debug mode, otherwise to WARN (production/staging) or TRACE (test)
log.setDefaultLevel(parseBoolean(process.env.REACT_APP_DEBUG_MODE) || process.env.REACT_APP_ENV_NAME !== '' ? log.levels.TRACE : log.levels.WARN);

// Initialize the localization module
Localizer.initialize(APP_LANGUAGE, { [APP_LANGUAGE]: translations[APP_LANGUAGE] }).then(() => {
  createRoot(rootElement).render(<App />);
});
