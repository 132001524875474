/* @flow */

export class PlayerError extends Error {
  code: ?string;

  constructor(code: string, message?: string) {
    super(message);

    this.code = code;
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'PlayerError';
  }
}
