/* @flow */

import type { VideoPlayerCommonMetrics } from './types';
import { round } from '../../../helpers/maths/maths';

const getCommonMetrics = (video: HTMLVideoElement): VideoPlayerCommonMetrics => {
  const buffer = video.buffered;
  const { length: bufferChunkCount } = buffer;

  let bufferLength = 0;
  for (let i = 0; i < bufferChunkCount; ++i) {
    bufferLength += buffer.end(i) - buffer.start(i);
  }

  const { height, width } = video.getBoundingClientRect();

  return {
    bufferChunkCount,
    bufferLength: Math.round(bufferLength),
    elementHeight: height,
    elementWidth: width,
    playbackRate: round(video.playbackRate, 1),
    videoHeight: video.videoHeight,
    videoWidth: video.videoWidth,
  };
};

export { getCommonMetrics };
