/* @flow */

import './UserMenu.css';
import * as React from 'react';
import { HeightKind, WidthKind } from '../buttons/types';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import ButtonFX from '../buttons/ButtonFX';
import { Localizer } from '@ntg/utils/dist/localization';
import { PictoUser } from '@ntg/components/dist/pictos/Element';
import { RegistrationType } from '../../redux/appRegistration/types/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const UserMenu = (): React.Node => {
  const isRegisteredAsGuest = useSelector((state) => state.appRegistration.registration === RegistrationType.RegisteredAsGuest);

  const handleGlobalSettingsOnClick = useCallback(() => Messenger.emit(MessengerEvents.OPEN_GLOBAL_SETTINGS), []);

  // Guest mode: let's open the sign-in page
  const handleSignInOnClick = useCallback(() => Messenger.emit(MessengerEvents.SHOW_SIGN_IN), []);

  if (isRegisteredAsGuest) {
    // Sign-in button
    return (
      <ButtonFX hasPadding heightKind={HeightKind.Medium} onClick={handleSignInOnClick} widthKind={WidthKind.Content}>
        {Localizer.localize('authentication.connection')}
      </ButtonFX>
    );
  }

  return <PictoUser onClick={handleGlobalSettingsOnClick} />;
};

export default UserMenu;
