/* @flow */

export enum SkipDirection {
  Backward,
  Forward,
}

export type ScrollOptions = {|
  behavior?: 'auto' | 'instant' | 'smooth',
  block?: 'start' | 'center' | 'end' | 'nearest',
  inline?: 'start' | 'center' | 'end' | 'nearest',
|};
