/* @flow */

import './Item.css';
import * as React from 'react';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { AvenueType } from '../../helpers/ui/avenue/types';
import type { CombinedReducers } from '../../redux/reducers';
import type { Dispatch } from '../../redux/types/types';
import { NO_AVENUE_INDEX } from './NavigationMenu';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { updateFocusedAvenue } from '../../redux/ui/actions';

type MenuItemPropType = {|
  +index: number,
  +text: string,
  +type: AvenueType,
|};

type ReduxMenuItemReducerStateType = {|
  +focusedAvenueIndex: number,
|};

type ReduxMenuItemDispatchToPropsType = {|
  +localUpdateFocusedAvenue: (index: number, type: AvenueType) => void,
|};

type CompleteMenuItemPropType = {|
  ...MenuItemPropType,
  ...ReduxMenuItemReducerStateType,
  ...ReduxMenuItemDispatchToPropsType,
|};

type MenuItemStateType = {||};

class MenuItemView extends React.PureComponent<CompleteMenuItemPropType, MenuItemStateType> {
  itemElement: HTMLElement | null;

  totalWidth: number | null;

  constructor(props: CompleteMenuItemPropType) {
    super(props);

    this.itemElement = null;
    this.totalWidth = null;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  getTotalWidth = (): number => {
    const { itemElement, totalWidth } = this;

    if (!itemElement) {
      return 0;
    }

    if (totalWidth) {
      return totalWidth;
    }

    const { marginLeft, marginRight } = window.getComputedStyle(itemElement);

    this.totalWidth = Math.ceil(itemElement.offsetWidth) + Math.ceil(parseFloat(marginLeft)) + Math.ceil(parseFloat(marginRight));

    return this.totalWidth;
  };

  handleOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { focusedAvenueIndex, index, localUpdateFocusedAvenue, type } = this.props;

    if (type === AvenueType.Explore) {
      Messenger.emit(MessengerEvents.OPEN_EXPLORE_MODAL, index);
      return;
    }

    if (index === focusedAvenueIndex) {
      // Focused menu item has been clicked again: reset avenue
      Messenger.emit(MessengerEvents.AVENUE_RESET);
      return;
    }

    // Update focused avenue in store (which will trigger the actual loading of the avenue)
    localUpdateFocusedAvenue(index, type);
  };

  render(): React.Node {
    const { focusedAvenueIndex, index, text, type } = this.props;

    return (
      <div
        className={clsx('avenueElement', index === focusedAvenueIndex && 'focused', (type === AvenueType.Myvideos || type === AvenueType.Explore) && 'separator')}
        ref={(instance) => {
          this.itemElement = instance;
        }}
      >
        <div className='text' data-text={text} onClick={this.handleOnClick}>
          {text}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxMenuItemReducerStateType => {
  return {
    focusedAvenueIndex: state.ui.focusedAvenue?.index ?? NO_AVENUE_INDEX,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxMenuItemDispatchToPropsType => {
  return {
    localUpdateFocusedAvenue: (index: number, type: AvenueType) => dispatch(updateFocusedAvenue(index, type)),
  };
};

const MenuItem: React.ComponentType<MenuItemPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MenuItemView);

export default MenuItem;
