/* @flow */

const openFooterPage: (urlStr: string, windowName: string) => void = (urlStr, windowName) => {
  const url = new URL(urlStr);
  url.searchParams.set('webview', 'false');

  window.open(url.toString(), windowName);
};

export { openFooterPage };
