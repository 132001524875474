/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_PERSONAL_DATA_POST } from '../../constants/';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const sendPersonalDataPostRequest: (key: string, data: any, eTag: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (key, data, eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { personalDataPostUrl },
    } = state;

    if (!personalDataPostUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'personalDataPostUrl' }));
    }

    const { authent, method } = personalDataPostUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_PERSONAL_DATA_POST, {
      authent,
      data,
      eTag,
      method,
      signal,
      uri: generateApiUrl(personalDataPostUrl, { key }, state),
    });
  };

const sendPersonalDataListPostRequest: (listId: string, itemId: string, data: any, eTag: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (listId, itemId, data, eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { personalDataListPostUrl },
    } = state;

    if (!personalDataListPostUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'personalDataListPostUrl' }));
    }

    const { authent, method } = personalDataListPostUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_PERSONAL_DATA_POST, {
      authent,
      data,
      eTag,
      method,
      signal,
      uri: generateApiUrl(
        personalDataListPostUrl,
        {
          itemId,
          listId,
        },
        state
      ),
    });
  };

export { sendPersonalDataListPostRequest, sendPersonalDataPostRequest };
