/* @flow */

import { HttpMethod } from '../../v8/types/HttpMethod';
import createDmsCommonRequestHeader from '../helpers/CreateCommonRequestHeader';
import sendDmsPromisedXMLHttpRequest from '../helpers/PromisedXMLHttpRequest';

const dmsAssociatedDeviceRequest: (baseAuthDeviceUrl: string, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (baseAuthDeviceUrl, authenticationToken, signal) =>
  sendDmsPromisedXMLHttpRequest(`${baseAuthDeviceUrl}list`, HttpMethod.GET, createDmsCommonRequestHeader(authenticationToken), null, null, false, signal).promise;

export default dmsAssociatedDeviceRequest;
