/* @flow */

import type { ApplicationInfo, NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import AccurateTimestamp from '../../../../helpers/dateTime/AccurateTimestamp';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_GET_DEVICE_SETTINGS } from '../../constants/';
import { getBrowserAndOS } from '../../../../helpers/jsHelpers/environment';

const UNKNOWN = 'unknown';

const sendGetDeviceSettingsRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const { browserName, browserVersion, osName, osVersion } = getBrowserAndOS();
    const {
      appConfiguration: { versionApp },
    } = getState();

    const {
      devicePixelRatio,
      screen: { height, width },
    } = window;

    const appInfo: ApplicationInfo = {
      appVersion: versionApp,
      browserName: browserName ?? UNKNOWN,
      browserVersion: browserVersion ?? UNKNOWN,
      displayResolution: `${width}x${height}`,
      osName: osName ?? UNKNOWN,
      osVersion: osVersion ?? UNKNOWN,
      screenResolution: `${width * devicePixelRatio}x${height * devicePixelRatio}`,
      timestamp: AccurateTimestamp.nowAsIsoString(),
    };

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GET_DEVICE_SETTINGS, { appInfo, signal });
  };

export default sendGetDeviceSettingsRequest;
