/* eslint-disable no-return-assign, no-magic-numbers */
const titles = [
  'Licorne, une légende vivante',
  'Licornes et chatons',
  'Licorne vs dauphin : le grand combat',
  "La licorne de l'amour",
  'Pégase, ce mytho',
  'La licorne écarlate',
  'Licornofulgure',
  'Des licornes et des hommes',
  'En couple avec une licorne',
  'Licorne : un job à plein temps',
  'Fashion licorne',
  'Plus belle la licorne',
  'Double licorne',
  'Total unicorn',
  'The dark unicorn',
  'Super licorne',
  'Licorne des temps modernes',
  'La planète des licornes',
  'Harry Potter et la licorne dorée',
  'Le meilleur de la licorne',
  'Humour de licorne',
  'Licorne+Centaure, amour impossible',
  'La licorne dépasse les bornes',
  'Une licorne peut en cacher une autre',
  "La licorne de l'impossible",
  'Bien entretenir la corne de sa licorne',
  'Crinière arc-en-ciel : fashion faux pas ?',
  'La licorne en folie',
  'La licorne se rebiffe',
  'La neuvième licorne',
  'Oui-Oui et la licorne magique',
  'Indiana Jones et la licorne maudite',
  'Les Castors Juniors et la licorne oubliée',
  'Game of Unicorns',
  'Une licorne pour la vie',
  'Jamais sans ma licorne',
  'La licorne démystifiée',
  'Licorne, entre mythe et réalité',
  "Les licornes de l'extrême",
  "L'invasion des licornes nazies",
  'Coupe du monde de licorne',
  'Histoire de licornes',
  'La licorne à travers les âges',
  'La licorne verte',
  'La licorne de Schindler',
  '12 licornes en colère',
  'Le seigneur des licornes',
  'Le silence des licornes',
  'The princess unicorn',
  'Shaun of the unicorns',
  'Reservoir unicorns',
  'Inglourious unikorns',
  'Unicorn park',
  'American Unicorn',
  'The unicorn strikes back',
  'The return of the unicorn',
  'No country for old unicorns',
  'Unicorn fiction',
  'Unicorn Club',
  'Le bon, la brute et la licorne',
  'Raging unicorn',
  'Les 7 licornes',
  'The Unicorn',
  'La unicorno',
  'La unicórnio',
  'Das Einhorn',
  'Enhjørning',
  'Yksisarvinen',
  'Yedinorog',
  'Yunikōn',
  'Dú jiǎo shòu',
  'Jednorožec',
  'Einhyrningsins',
  'Ilgagsu',
  'Unikanga',
];

// ⬆️⬆️⬇️⬇️⬅️➡️➡️➡️️ba
const konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];

const htmlUnicorn =
  '<div class="container bg{i}"><div class="unicorn"><div class="face">' +
  '<div class="cheek"></div><div class="snoot"><div class="nose"><div class="nostril"></div></div></div><div class="eye"><div class="eyelashes"></div></div>' +
  '<div class="ear"></div><div class="horn"></div><div class="mane top"></div><div class="mane mid"></div></div><div class="neck"><div class="mane end"></div>' +
  '</div></div></div>';

let rootElement = null;
let unicornIndex = 0;
let isAnimating = false;
let isUnicornized = false;

const getRootElement = () => {
  if (!rootElement) {
    rootElement = document.querySelector('body > div#root');
  }
  return rootElement;
};

const deunicornize = () => {
  const root = getRootElement();
  root.removeChild(root.querySelector('div.unicornized'));
  isAnimating = false;
};

const animate = () => {
  const u = document.createElement('div');
  u.classList.add('unicornized');
  [3, 2, 1, 'Licornisation&nbsp;!'].forEach((v, i) => {
    const c = document.createElement('div');
    c.classList.add('countDown', `number${i}`);
    c.innerHTML = v.toString();
    u.append(c);
  });
  getRootElement().append(u);
};

const unicornize = () => {
  if (isUnicornized) {
    return;
  }

  isUnicornized = true;
  isAnimating = true;

  animate();

  setTimeout(() => {
    document.querySelectorAll('.sectionItem .tileContainer').forEach((e) => {
      e.insertAdjacentHTML('beforeend', htmlUnicorn.replace('{i}', 1 + Math.floor(Math.random() * 4)));
    });

    [...document.getElementsByClassName('backgroundImage visible')].forEach((e) => (e.className = 'backgroundImage hidden'));

    document.querySelectorAll('.sectionItem .textContainer .text:not(.secondLineInfo)').forEach((e) => (e.innerHTML = titles[Math.floor(Math.random() * titles.length)]));

    document.querySelectorAll('.sectionItem .textContainer .text.secondLineInfo').forEach((e) => (e.innerHTML = e.innerHTML.replace(/^.+ - (.+)$/u, '$1')));
  }, 1_000);
  setTimeout(deunicornize, 7_000);
};

window.addEventListener(
  'keydown',
  (e) => {
    if (!isAnimating && e.keyCode === konamiCode[unicornIndex]) {
      unicornIndex += 1;
      if (unicornIndex === konamiCode.length) {
        unicornIndex = 0;
        unicornize();
      }
    } else {
      unicornIndex = 0;
    }
  },
  {
    capture: true,
    passive: true,
  }
);
/* eslint-enable no-return-assign, no-magic-numbers */
