/* @flow */

import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import type { RequestResponseMethodDefinitionType } from '../emitter';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { getSettingValueByName } from '../helpers/settings';
import { sendV8HubRequest } from './hub';

const search: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const { appConfiguration } = getState();
    const key = getSettingValueByName('platform.hubkeys', 'search', appConfiguration);

    if (!key) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_search_key'));
    }

    return dispatch(sendV8HubRequest(key, signal));
  };

export default search;
