/* @flow */

import { CustomNetworkError } from './CustomNetworkError';
import { Localizer } from '@ntg/utils/dist/localization';
import { parseResponseHeaders } from '../v8/helpers/RequestHeaders';

const tryParseResult: (result: any) => any = (result) => {
  if (!result) {
    return null;
  }

  try {
    return JSON.parse(result);
  } catch {
    return result;
  }
};

const createCustomNetworkError: (status: number, message: ?string, responseHeaders: ?string, result?: any) => CustomNetworkError = (status, message, responseHeaders, result) =>
  new CustomNetworkError(
    {
      message,
      responseHeaders: parseResponseHeaders(responseHeaders),
      result: tryParseResult(result),
      status,
    },
    message
  );

const createCustomNetworkErrorFromKey: (key: string, options: any) => CustomNetworkError = (key, options) => {
  const message = Localizer.localize(key, options);

  return new CustomNetworkError(
    {
      message,
      status: -1,
    },
    message
  );
};

export { createCustomNetworkError, createCustomNetworkErrorFromKey };
