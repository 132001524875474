/* @flow */

import { BO_API_START_STREAM, type BO_RESPONSE_WITH_ETAG } from './types/common';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_BO_STREAM_START } from '../../constants';
import getRequestDetails from './helpers/requestCommon';

const sendStreamStartRequest: (distributorId: string, streamId: number, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (distributorId, streamId, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration: { useBOV2Api },
    } = getState();

    return dispatch(getRequestDetails(distributorId, BO_API_START_STREAM, useBOV2Api))
      .then((requestDetails) =>
        // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
        NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_STREAM_START, {
          ...requestDetails,
          signal,
          streamId,
          useBOV2Api,
        }),
      )
      .then(({ response }: BO_RESPONSE_WITH_ETAG) => response);
  };

export default sendStreamStartRequest;
