/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import { BO_API_DELETE_FAVORITE } from './types/common';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_BO_FAVORITE_DELETE } from '../../constants';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import getFavoriteList from './favoriteList';
import getRequestDetails from './helpers/requestCommon';

const removeFromFavoriteList: (titId: string, signal?: AbortSignal, skipRefresh?: boolean) => RequestResponseMethodDefinitionType =
  (titId, signal, skipRefresh) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration: { mainDistributorId, useBOV2Api },
    } = getState();

    if (!mainDistributorId) {
      return Promise.reject(
        createCustomNetworkErrorFromKey('common.messages.errors.missing_parameters_list', {
          list: 'mainDistributorId',
          name: 'Remove from favorite list',
        }),
      );
    }

    if (useBOV2Api) {
      // BO API v2
      return dispatch(getRequestDetails(mainDistributorId, BO_API_DELETE_FAVORITE, useBOV2Api))
        .then((requestDetails) =>
          // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
          NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_FAVORITE_DELETE, {
            ...requestDetails,
            signal,
            titId,
          }),
        )
        .then(() => {
          if (!skipRefresh) {
            dispatch(getFavoriteList(signal));
          }
        });
    }

    // Old API
    return dispatch(getRequestDetails(mainDistributorId, BO_API_DELETE_FAVORITE, useBOV2Api, { '<TIT_ID>': titId }))
      .then((requestDetails) =>
        // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
        NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_FAVORITE_DELETE, {
          ...requestDetails,
          signal,
        }),
      )
      .then(() => {
        if (!skipRefresh) {
          dispatch(getFavoriteList(signal));
        }
      });
  };

export default removeFromFavoriteList;
