/* @flow */

import type { NETGEM_API_V8_MATCHING_SCHEDULED_RECORDING, RecordingOutcome } from './Npvr';
import type { NETGEM_API_V8_PARENTAL_GUIDANCE } from './ParentalGuidance';
import type { NETGEM_API_V8_RAW_FEED } from './FeedResult';

// Raw item description (as received from platform)

export type NETGEM_API_V8_ITEM_SCORE = Array<number | string>;

export type NETGEM_API_V8_ITEM_LOCATION = {|
  availabilityEndDate?: string,
  availabilityStartDate?: string,
  channelId?: string,
  duration?: string,
  id: string,
  matchingScheduledRecordings?: Array<NETGEM_API_V8_MATCHING_SCHEDULED_RECORDING>,
  parentalGuidance?: NETGEM_API_V8_PARENTAL_GUIDANCE,
  previewAvailabilityStartDate?: string,
  quotaUsed?: string,
  recordOutcome?: RecordingOutcome,
  scheduledEventDuration?: string,
  scheduledEventStartDate?: string,
  score: NETGEM_API_V8_ITEM_SCORE,
  startDate?: string,
|};

export type NETGEM_API_V8_FEED_RAW_ITEM = {|
  elements?: NETGEM_API_V8_RAW_FEED,
  id: string,
  locations?: Array<NETGEM_API_V8_ITEM_LOCATION>,
  parentalGuidance?: NETGEM_API_V8_PARENTAL_GUIDANCE,
  purchasable?: boolean,
  score?: NETGEM_API_V8_ITEM_SCORE,
|};

// Filtered item description (usable by UI)
export enum ItemLocationType {}

export const NETGEM_API_V8_ITEM_LOCATION_TYPE_APP: 'app' = 'app';
export const NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP: 'catchup' = 'catchup';
export const NETGEM_API_V8_ITEM_LOCATION_TYPE_EST: 'est' = 'est';
export const NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING: 'recording' = 'recording';
export const NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT: 'scheduledevent' = 'scheduledevent';
export const NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD: 'svod' = 'svod';
export const NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD: 'tvod' = 'tvod';
export const NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD: 'vod' = 'vod';

// Cannot convert to Flow enum because it's used as an object's key
export type NETGEM_API_V8_ITEM_LOCATION_TYPE =
  | typeof NETGEM_API_V8_ITEM_LOCATION_TYPE_APP
  | typeof NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP
  | typeof NETGEM_API_V8_ITEM_LOCATION_TYPE_EST
  | typeof NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING
  | typeof NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT
  | typeof NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD
  | typeof NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD
  | typeof NETGEM_API_V8_ITEM_LOCATION_TYPE_VOD;

export enum ItemContent {
  VODOrDeeplink = 'vod_or_deeplink',
  NetgemSVOD = 'netgem_svod',
  TV = 'tv',
  Unknown = 'unknown',
}

export enum VodKind {
  EST = 'est',
  SVOD = 'svod',
  TVOD = 'tvod',
  VOD = 'vod',
}

// Specify if item is related to a 'grouped' content (ex: series, season, ...) or not (ex: single program)
export enum ItemType {
  Channel = 'channel',
  ChannelGroup = 'channelgr',
  FakeItem = 'fakeitem',
  Program = 'program',
  Series = 'series',
  Universe = 'univers',
}

export type NETGEM_API_V8_FEED_ITEM = {|
  ...NETGEM_API_V8_FEED_RAW_ITEM,
  endTime: number,
  locType: ?NETGEM_API_V8_ITEM_LOCATION_TYPE,
  selectedLocation: NETGEM_API_V8_ITEM_LOCATION,
  selectedProgramId: string,
  seriesId: ?string,
  startTime: number,
  type: ?ItemType,
|};

export type NETGEM_API_V8_FEED = Array<NETGEM_API_V8_FEED_ITEM>;

export type NETGEM_API_V8_MIXED_FEED = Array<NETGEM_API_V8_FEED_ITEM | NETGEM_API_V8_FEED_RAW_ITEM>;

export type NETGEM_API_V8_MINIMAL_FEED_ITEM = { ...NETGEM_API_V8_FEED_RAW_ITEM };
