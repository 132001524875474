/* @flow */

export enum Setting {
  AutoOpenPlayerDebugPanel = 'autoOpenPlayerDebugPanel',
  AutoResume = 'autoResume',
  AutoSelectAudioTrack = 'autoSelectAudioTrack',
  AutoSelectSubtitlesTrack = 'autoSelectSubtitlesTrack',
  BufferBehind = 'bufferBehind',
  BufferingGoal = 'bufferingGoal',
  GreenStreaming = 'greenStreaming',
  LastAudioTrack = 'lastAudioTrack',
  LastSubtitlesTrack = 'lastSubtitlesTrack',
  RebufferingGoal = 'rebufferingGoal',
  SubtitlesBGColor = 'subtitlesBgColor',
  SubtitlesSize = 'subtitlesSize',
  Volume = 'volume',
}

export type Settings = {| [Setting]: any |};

/*
 * Available subtitles colors (defined in CSS):
 *   - whiteOnTransparent
 *   - whiteOnTranslucentBlack
 *   - whiteOnBlack
 *   - yellowOnTransparent
 *   - yellowOnTranslucentBlack
 *   - yellowOnBlack
 *   - blackOnWhite
 *   - blackOnTranslucentWhite
 */
