/* @flow */

import './Credits.css';
import * as React from 'react';
import type { CommonPropType } from './helper';
import Credit from './Credit';
import { Localizer } from '@ntg/utils/dist/localization';

type CreditsByRolePropType = {|
  ...CommonPropType,
  +labelKey: string,
  +names: Array<string>,
|};

const CreditsByRole = ({ labelKey, names, onHidden, onTrigger }: CreditsByRolePropType): React.Node => {
  if (names.length === 0) {
    return null;
  }

  /* eslint-disable react/no-array-index-key */
  return (
    <>
      <span className='label'>{Localizer.localize(labelKey)}</span>
      {names.map((name, index) => (
        <Credit key={`${index}-${name}`} name={name} onHidden={onHidden} onTrigger={onTrigger} />
      ))}
    </>
  );
  /* eslint-enable react/no-array-index-key */
};

export default CreditsByRole;
