/* @flow */

import * as React from 'react';
import { MILLISECONDS_PER_SECOND, SECONDS_PER_HOUR, SECONDS_PER_MINUTE, formatSecondsToHHMMSS } from '../dateTime/Format';
import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import { Localizer } from '@ntg/utils/dist/localization';
import type { NETGEM_API_V8_FEED } from '../../libs/netgemLibrary/v8/types/FeedItem';
import { type NOTIFICATION_MESSAGE } from '../notification/types';
import { isUndefinedOrNull } from '@ntg/utils/dist/types';
import log from 'loglevel';

// Without any parameter, live program debug function will show programs finishing in less than 5 minutes
const DEFAULT_DEBUG_LIVE_PROGRAMS_THRESHOLD = 300;

// Live program debug function will not show more than 5 programs
const DEFAULT_DEBUG_LIVE_PROGRAMS_MAX_COUNT = 5;

const DEBUG_LIVE_PROGRAMS_INDEX_PAD = 2;

export type DEBUG_DATA = {|
  instance?: Object,
  instanceFields?: Array<string>,
  misc?: Object,
  props?: Object,
  propsFields?: Array<string>,
  state?: Object,
  stateFields?: Array<string>,
|};

const showItem: (data?: Object, field: string) => void = (data, field) => {
  if (!data) {
    return;
  }

  const { [field]: value } = data;

  let displayedValue: ?string = null;

  if (typeof value === 'undefined') {
    displayedValue = '<undefined>';
  } else if (value === null) {
    displayedValue = '<null>';
  } else if (typeof value === 'object') {
    log.debug(field, value);
    return;
  } else {
    displayedValue = value.toString();
  }

  log.debug(`${field}: ${displayedValue}`);
};

const showGroup: (label: string, data?: Object, fields?: Array<string>) => void = (label, data, fields) => {
  if (data && fields) {
    log.debug(`--[ ${label} ]--`);
    fields.forEach((field) => showItem(data, field));
  }
};

const showDebug: (title: string, data: DEBUG_DATA) => void = (title, data) => {
  const { instance, instanceFields, misc, props, propsFields, state, stateFields } = data;

  log.debug(`---------- ${title} Debug Info`);
  showGroup('props', props, propsFields);
  showGroup('state', state, stateFields);
  showGroup('this', instance, instanceFields);
  showGroup('misc', misc, misc && Object.keys(misc));
  log.debug('----------');
};

const showObjectDiff: (type: string, obj: any, prevObj: any) => void = (type, obj, prevObj) => {
  if (!obj && !prevObj) {
    return;
  }

  if (obj && !prevObj) {
    log.debug(`${type}: null => not null`);
    return;
  }

  if (!obj && prevObj) {
    log.debug(`${type}: not null => null`);
    return;
  }

  for (const key of Object.keys(obj)) {
    if (typeof key !== 'undefined') {
      const { [key]: value } = obj;
      const { [key]: prevValue } = prevObj;
      if (value !== prevValue) {
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
          log.debug(`[${type}] ${key}: ${prevValue} => ${value.toString()}`);
        } else {
          log.debug(`[${type}] ${key}:`);
          log.debug(prevValue);
          log.debug(value);
        }
      }
    }
  }
};

const showUpdateDiff: (componentName: string, component: React.PureComponent<any, any>, prevProps: any, prevState: any) => void = (componentName, component, prevProps, prevState) => {
  log.debug(`Component update diff for: ${componentName}`);
  showObjectDiff('props', component.props, prevProps);
  showObjectDiff('state', component.state, prevState);
};

// Parameter maxTime is in minutes
const showFinishingLivePrograms: (feed: NETGEM_API_V8_FEED, itemCountPerPage?: number, maxTime?: number, maxItems?: number) => void = (feed, itemCountPerPage, maxTime, maxItems) => {
  const threshold = maxTime ? maxTime * SECONDS_PER_MINUTE : DEFAULT_DEBUG_LIVE_PROGRAMS_THRESHOLD;
  const maxCount = maxItems ? maxItems : DEFAULT_DEBUG_LIVE_PROGRAMS_MAX_COUNT;

  const now = AccurateTimestamp.nowInSeconds();
  const items = [];

  feed.forEach((item, index) => {
    const {
      endTime,
      selectedLocation: { channelId },
    } = item;
    const remainingTime = endTime - now;

    if (remainingTime > 0 && remainingTime <= threshold) {
      items.push({
        channelId,
        index: index + 1,
        remainingTime,
        remainingTimeStr: formatSecondsToHHMMSS(remainingTime),
      });
    }
  });

  items.sort((a, b) => a.remainingTime - b.remainingTime);
  const { length } = items;

  for (let i = 0; i < Math.min(maxCount, length); ++i) {
    const {
      [i]: { channelId, index, remainingTimeStr },
    } = items;
    const posInSection = index.toString().padStart(DEBUG_LIVE_PROGRAMS_INDEX_PAD);
    const page =
      itemCountPerPage && itemCountPerPage > 0
        ? ` (p. ${Math.ceil(index / itemCountPerPage)
            .toString()
            .padStart(DEBUG_LIVE_PROGRAMS_INDEX_PAD)})`
        : '';
    const pos = channelId?.lastIndexOf('_') ?? -1;
    const channel = pos > -1 && channelId ? channelId.substring(pos + 1) : channelId;
    log.debug(`${remainingTimeStr} | ${posInSection}${page} | ${channel ?? '<no channel id>'}`);
  }
};

const buildMissingParametersMessage: (parameters: any, name: string) => string = (parameters, name) => {
  const missingParameters = [];

  Object.entries(parameters).forEach(([key, value]) => {
    if (isUndefinedOrNull(value)) {
      missingParameters.push(key);
    }
  });

  return Localizer.localize('common.messages.errors.missing_parameters_list', {
    list: missingParameters.join(', '),
    name,
  });
};

const buildMissingParametersError: (parameters: any, name: string) => Error = (parameters, name) => new Error(buildMissingParametersMessage(parameters, name));

const logTrace = (...args: Array<any>): void => {
  log.trace(...args);
};

const logDebug = (...args: Array<any>): void => {
  log.debug(...args);
};

const logInfo = (...args: Array<any>): void => {
  log.info(...args);
};

const logWarning = (...args: Array<any>): void => {
  log.warn(...args);
};

const logError = (...args: Array<any>): void => {
  log.error(...args);
};

const flattenNotificationMessage = (message: NOTIFICATION_MESSAGE, flattenText?: string): string => {
  const addPrefixIfDefined = (s: string): string => (flattenText ? `${flattenText} | ${s}` : s);

  if (typeof message === 'string') {
    return addPrefixIfDefined(message);
  }

  const {
    // $FlowFixMe: Flow says props is missing in React.Element (which is wrong)
    props: { children },
  } = message;

  if (Array.isArray(children)) {
    return addPrefixIfDefined(children.map((child) => flattenNotificationMessage(child)).join(' | '));
  }

  return flattenNotificationMessage(children);
};

const getTimeRangesAsString = (timeRanges: TimeRanges | null): string => {
  if (timeRanges === null) {
    return '<null>';
  }

  const result = [];

  for (let i = 0; i < timeRanges.length; ++i) {
    result.push(`[${timeRanges.start(i).toFixed(1)},${timeRanges.end(i).toFixed(1)}]`);
  }

  return result.join(' ');
};

const TIME_PADDING_LENGTH = 2;

const pad2 = (n: number) => n.toString().padStart(TIME_PADDING_LENGTH, '0');

// Parameter duration is in seconds
const getDurationAsString = (duration: number): string => {
  const h = Math.floor(duration / SECONDS_PER_HOUR);
  const remainder = duration % SECONDS_PER_HOUR;
  const m = Math.floor(remainder / SECONDS_PER_MINUTE);
  // Sometimes there are fractions of seconds
  const s = Math.floor(remainder) % SECONDS_PER_MINUTE;
  return `${pad2(h)}:${pad2(m)}:${pad2(s)}`;
};

const getTimestampAsLocalTime = (t: number): string => new Date(t * MILLISECONDS_PER_SECOND).toLocaleTimeString();

const getDurationDisplay = (duration: ?number): string => {
  if (duration === null) {
    return '<null>';
  }

  if (typeof duration !== 'number') {
    return '<undefined>';
  }

  return `${duration} (${getDurationAsString(duration)})`;
};

const getTimestampDisplay = (timestamp: number): string => `${timestamp} (${getTimestampAsLocalTime(timestamp)})`;

export {
  buildMissingParametersError,
  buildMissingParametersMessage,
  showFinishingLivePrograms,
  flattenNotificationMessage,
  getDurationDisplay,
  getTimeRangesAsString,
  getTimestampDisplay,
  logDebug,
  logError,
  logInfo,
  logTrace,
  logWarning,
  showDebug,
  showUpdateDiff,
};
