/* @flow */

import './ErrorBoundary.css';
import * as React from 'react';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { BasicFunction } from '@ntg/utils/dist/types';

type DefaultProps = {|
  +errorCallback?: BasicFunction,
  +isNotificationEnabled?: boolean,
|};

type ErrorBoundaryPropType = {|
  ...DefaultProps,
  +children: React.Node,
  +componentName: string,
|};

type ErrorBoundaryStateType = {|
  +error: ?Error,
|};

const InitialState = Object.freeze({
  error: null,
});

class ErrorBoundary extends React.PureComponent<ErrorBoundaryPropType, ErrorBoundaryStateType> {
  static getDerivedStateFromError(error: Error): any {
    return { error };
  }

  static defaultProps: DefaultProps = {
    errorCallback: undefined,
    isNotificationEnabled: false,
  };

  constructor(props: ErrorBoundaryPropType) {
    super(props);
    this.state = { ...InitialState };
  }

  componentDidCatch(error: Error) {
    const { componentName, errorCallback, isNotificationEnabled } = this.props;
    const { name: errorName } = error;

    if (isNotificationEnabled) {
      Messenger.emit(
        MessengerEvents.NOTIFY_ERROR,
        <div>
          {errorName} in &quot;{componentName}&quot;
        </div>,
      );
    }

    if (errorCallback) {
      errorCallback();
    }
  }

  render(): React.Node {
    const { children, componentName } = this.props;
    const { error } = this.state;

    if (error) {
      const { name: errorName } = error;
      return (
        <div className='errorBoundary'>
          {errorName} ({componentName})
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
