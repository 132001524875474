/* @flow */

import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { type NETGEM_API_V8_RIGHTS, NETGEM_API_V8_RIGHTS_WISHLIST } from '../../libs/netgemLibrary/v8/types/Rights';
import { type PENDING_OPERATION_DATA_TYPE, PendingOperationKind, PendingOperationReason, storePendingOperation } from './pendingOperations';
import { getRequiredRights, isAccessGranted, showMissingSubscription } from './rights';
import type { CARD_DATA_MODAL_TYPE } from '../../components/modal/cardModal/CardModalConstsAndTypes';
import type { ChannelMap } from '../../libs/netgemLibrary/v8/types/Channel';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import { type NETGEM_API_V8_METADATA_SCHEDULE_LOCATION } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { getChannelRights } from '../channel/helper';
import { logError } from '../debug/debug';

const isWishlistGrantedInternal: (
  isRegisteredAsGuest: boolean,
  isSubscriptionFeatureEnabled: boolean,
  cardData: CARD_DATA_MODAL_TYPE,
  location: ?NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
  channels: ChannelMap,
  commercialOffers: ?KeyValuePair<Array<string>>,
  defaultRights: ?NETGEM_API_V8_RIGHTS,
  userRights: Array<string> | null,
  pendingOperation: PENDING_OPERATION_DATA_TYPE
) => boolean = (isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, location, channels, commercialOffers, defaultRights, userRights, pendingOperation) => {
  const { item, seriesMetadata, tileType } = cardData;

  if (!location && !seriesMetadata?.id) {
    // Location is null and item is not a series
    logError('Wishlist error: no location');
    return false;
  }

  const data = {
    ...cardData,
    item,
    pendingOperation,
    tileType,
  };

  if (isRegisteredAsGuest) {
    // Guest mode: let's open the sign-in page
    storePendingOperation(data);
    Messenger.emit(MessengerEvents.SHOW_SIGN_IN, PendingOperationKind.AddToWishlist);
    return false;
  }

  if (!isSubscriptionFeatureEnabled) {
    // Subscription feature not enabled: no restriction
    return true;
  }

  const channelRights = getChannelRights(channels, location?.channelId);
  const requiredRights = getRequiredRights(NETGEM_API_V8_RIGHTS_WISHLIST, location, channelRights, defaultRights);
  if (isAccessGranted(requiredRights, userRights)) {
    // User has required rights
    return true;
  }

  // User rights do not meet required rights: open subscribe page and highlight matching commercial offer
  showMissingSubscription(data, requiredRights, commercialOffers);
  return false;
};

const isWishlistGranted: (
  isRegisteredAsGuest: boolean,
  isSubscriptionFeatureEnabled: boolean,
  cardData: CARD_DATA_MODAL_TYPE,
  location: ?NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
  channels: ChannelMap,
  commercialOffers: ?KeyValuePair<Array<string>>,
  defaultRights: ?NETGEM_API_V8_RIGHTS,
  userRights: Array<string> | null
) => boolean = (isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, location, channels, commercialOffers, defaultRights, userRights) => {
  const { seriesMetadata } = cardData;
  const {
    item,
    item: {
      selectedLocation: { channelId },
    },
  } = cardData;
  const assetId = seriesMetadata?.id ?? item.selectedProgramId;

  if (!channelId) {
    // Channel Id is null
    logError('Wishlist error: no channel Id');
    return false;
  }

  const pendingOperation = {
    addToWishlistData: {
      assetId,
      channelId,
    },
    operationType: PendingOperationKind.AddToWishlist,
    reason: PendingOperationReason.RequireAccount,
  };

  return isWishlistGrantedInternal(isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, location, channels, commercialOffers, defaultRights, userRights, pendingOperation);
};

const isFavoriteGranted: (
  isRegisteredAsGuest: boolean,
  isSubscriptionFeatureEnabled: boolean,
  cardData: CARD_DATA_MODAL_TYPE,
  titId: string,
  location: ?NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
  channels: ChannelMap,
  commercialOffers: ?KeyValuePair<Array<string>>,
  defaultRights: ?NETGEM_API_V8_RIGHTS,
  userRights: Array<string> | null
) => boolean = (isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, titId, location, channels, commercialOffers, defaultRights, userRights) => {
  const pendingOperation = {
    addToFavoriteData: { titId },
    operationType: PendingOperationKind.AddToFavorite,
    reason: PendingOperationReason.RequireAccount,
  };

  return isWishlistGrantedInternal(isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, location, channels, commercialOffers, defaultRights, userRights, pendingOperation);
};

export { isFavoriteGranted, isWishlistGranted };
