/* @flow */

import type { KeyValuePair, ReadOnlyKeyValuePair } from '@ntg/utils/dist/types';
import { BOVodAssetStatus } from '../../videofutur/metadata';
import type { NETGEM_API_V8_METADATA_SCHEDULE_LOCATION } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';

export type VOD_STATUS_TYPE = {|
  availabilityTime?: number,
  expirationTime?: number,
  status: BOVodAssetStatus,
  viewingHistoryId?: string,
  vtiId?: number,
|};

export const DEFINITION_PRIORITY = Object.freeze({
  Definition4K: 2,
  DefinitionHD: 1,
  DefinitionSD: 0,
});

export enum Definition {
  FourK = '4K',
  HD = 'HD',
  SD = 'SD',
}

export type STREAM_PRIORITIES_BY_TYPE_TYPE = KeyValuePair<number>;

export type STREAM_PRIORITIES_TYPE = {|
  [string]: STREAM_PRIORITIES_BY_TYPE_TYPE,
  default: STREAM_PRIORITIES_BY_TYPE_TYPE,
|};

export const LANGUAGE = Object.freeze({
  FR: 'FR',
  OriginalVersion: 'QAA',
  VisuallyImpaired: 'QAD',
  VisuallyImpairedAlt: 'QTZ',
});

export const LANGUAGE_VARIANTS: ReadOnlyKeyValuePair<Array<string>> = Object.freeze({
  DE: Object.freeze(['DE', 'DEU', 'DEUTSCH', 'GERMAN', 'ALLEMAND']),
  EN: Object.freeze(['EN', 'ENG', 'ENGLISH', 'ANGLAIS', 'ENGLISCH']),
  FR: Object.freeze(['FR', 'FRA', 'FRE', 'FRENCH', 'FRANCAIS', 'FRANÇAIS', 'FRANZÖSISCH', 'FRANZOSISCH']),
  Undetermined: Object.freeze(['UND', 'UNDETERMINED', 'INDÉTERMINÉ']),
});

export const PROGRAM_INFO: ReadOnlyKeyValuePair<number> = Object.freeze({
  Duration: 1,
  Genre: 2,
  Language: 4,
  ParentalGuidance: 8,
  ProductionCountry: 16,
  ReleaseDate: 32,
});

export enum Currency {
  Euro = 'EUR',
}

export const getCurrencySymbol = (currency: Currency): string => (currency === Currency.Euro ? '€' : '?');

export type BASE_PURCHASE_DETAILS_TYPE = {|
  // In ms
  availabilityStartTime: number,
  definition: Definition,
  displayPrice: string,
  distributorId: string,
  licenseDuration?: string,
  vtiId: number,
|};

export type PURCHASE_DETAILS_TYPE = {|
  ...BASE_PURCHASE_DETAILS_TYPE,
  itemCount: number,
  locationId?: string,
  vodLocationMetadata?: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
|};
