/* @flow */

import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import { MILLISECONDS_PER_SECOND } from '../dateTime/Format';

export type CACHE_TYPE = {|
  [string]: {|
    input: any,
    output: any,
  |},
|};

const getHashFromDate: (items: $ReadOnlyArray<{ date: string }>) => string | null = (items) => {
  if (!items) {
    return null;
  }

  return items.map((item) => item.date).join();
};

const getCacheExpirationTime: (maxAge: ?number) => number = (maxAge) => {
  const now = AccurateTimestamp.now();
  return maxAge ? now + maxAge * MILLISECONDS_PER_SECOND : now;
};

export { getCacheExpirationTime, getHashFromDate };
