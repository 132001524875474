/* @flow */

import { HeaderName, HeaderValue } from '../../v8/constants/Headers';
import { addAuthenticationHeader, buildHeaderList } from '../../v8/helpers/RequestHeaders';
import { commonNetworkErrorCallBack, commonPromisedXMLHttpRequest } from '../../helpers/CommonPromisedXMLHttpRequest';
import { HttpMethod } from '../../v8/types/HttpMethod';
import { XhrResponseType } from '../../../../helpers/jsHelpers/xhr';

const request: (
  uri: string,
  applicationId: string,
  channelName: string,
  customData: string,
  service: string,
  url: string,
  method: HttpMethod,
  authenticationToken: string | null,
  signal?: AbortSignal,
) => Promise<any> = (uri, applicationId, channelName, customData, service, url, method, authenticationToken, signal) => {
  const headerList = buildHeaderList(HeaderName.ContentType, HeaderValue.ApplicationJsonUtf8, HeaderName.Accept, HeaderValue.ApplicationJson);
  addAuthenticationHeader(headerList, authenticationToken);

  // NetgemTV hack
  const withCredentials = service !== 'anonymous';

  return commonPromisedXMLHttpRequest(
    uri,
    method,
    XhrResponseType.Text,
    headerList,
    JSON.stringify({
      applicationId,
      channelName,
      customData,
      service,
      url,
    }),
    null,
    withCredentials,
    commonNetworkErrorCallBack,
    signal,
  ).promise.then((response: string) => (response ? JSON.parse(response) : {}));
};

export default request;
