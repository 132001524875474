/* @flow */

import type { AppConfigurationState } from '../../../appConf/reducers';

const getBOSetting: (settingName: string, appConfiguration: AppConfigurationState) => string | null = (settingName, appConfiguration) => {
  const {
    boSettings: { [settingName]: settingValue },
  } = appConfiguration;
  return settingValue ?? null;
};

export { getBOSetting };
