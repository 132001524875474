/* @flow */

import {
  REDUX_MSG_CNC_PROGRAM_GET_URL,
  REDUX_MSG_CNC_SERIES_GET_URL,
  REDUX_MSG_DATA_COLLECTION_COLD_POST_URL,
  REDUX_MSG_DATA_COLLECTION_HOT_POST_URL,
  REDUX_MSG_DEEPLINK_PROGRAM_GET_URL,
  REDUX_MSG_DEEPLINK_SERIES_GET_URL,
  REDUX_MSG_DEFAULT_HUB_URL,
  REDUX_MSG_DISTRIBUTOR_ADD,
  REDUX_MSG_GAMESTREAM_TOKEN_GET_URL,
  REDUX_MSG_HUB_URL,
  REDUX_MSG_LIST_ALIAS_MULTI_URL,
  REDUX_MSG_LIST_ALIAS_URL,
  REDUX_MSG_LOCATION_CATCHUP_FOR_ASSET_URL,
  REDUX_MSG_LOCATION_EPG_FOR_ASSET_URL,
  REDUX_MSG_LOCATION_EPG_URL,
  REDUX_MSG_LOCATION_VOD_FOR_ASSET_URL,
  REDUX_MSG_METADATA_CHANNELS_URL,
  REDUX_MSG_METADATA_IMAGE_WITH_VARIANT_URL,
  REDUX_MSG_METADATA_PROGRAM_URL,
  REDUX_MSG_METADATA_SCHEDULE_URL,
  REDUX_MSG_NPVR_RECORDINGS_DELETE_URL,
  REDUX_MSG_NPVR_RECORDINGS_FUTURE_URL,
  REDUX_MSG_NPVR_RECORDINGS_LIST_URL,
  REDUX_MSG_NPVR_RECORDINGS_METADATA_URL,
  REDUX_MSG_NPVR_RECORDINGS_QUOTA_URL,
  REDUX_MSG_NPVR_RECORDINGS_RETRY_URL,
  REDUX_MSG_NPVR_RECORDINGS_STOP_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_CREATE_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_DELETE_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_METADATA_URL,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_UPDATE_URL,
  REDUX_MSG_PERSONAL_DATA_DELETE_URL,
  REDUX_MSG_PERSONAL_DATA_GET_URL,
  REDUX_MSG_PERSONAL_DATA_LIST_DELETE_URL,
  REDUX_MSG_PERSONAL_DATA_LIST_GET_URL,
  REDUX_MSG_PERSONAL_DATA_LIST_POST_URL,
  REDUX_MSG_PERSONAL_DATA_POST_URL,
  REDUX_MSG_PURCHASE_INFO_PER_ASSET_URL,
  REDUX_MSG_REQUEST_ENTITLEMENT_GET_URL,
  REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE_URL,
  REDUX_MSG_RIGHT_COMMERCIAL_OFFERS_URL,
  REDUX_MSG_RIGHT_DEFAULT_RIGHTS_URL,
  REDUX_MSG_RIGHT_USER_RIGHTS_URL,
} from '../constants/';
import type { Distributor } from '../actions/videofutur/types/common';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import { type NETGEM_API_V8_URL_DEFINITION } from '../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import type { NetgemApiAction } from '../actions';
import { produce } from 'immer';

export type NetgemApiState = {|
  cncProgramGetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  cncSeriesGetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  dataCollectionColdUrl: ?NETGEM_API_V8_URL_DEFINITION,
  dataCollectionHotUrl: ?NETGEM_API_V8_URL_DEFINITION,
  deeplinkProgramGetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  deeplinkSeriesGetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  defaultHubUrl: ?NETGEM_API_V8_URL_DEFINITION,
  // Keys are distributor Ids
  distributors: KeyValuePair<Distributor>,
  entitlementGetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  entitlementReleaseUrl: ?NETGEM_API_V8_URL_DEFINITION,
  getGamestreamTokenUrl: ?NETGEM_API_V8_URL_DEFINITION,
  hubUrl: ?NETGEM_API_V8_URL_DEFINITION,
  listAliasMultiUrl: ?NETGEM_API_V8_URL_DEFINITION,
  listAliasPostUrl: ?NETGEM_API_V8_URL_DEFINITION,
  locationCatchupForAssetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  locationEpgForAssetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  locationEpgUrl: ?NETGEM_API_V8_URL_DEFINITION,
  locationVodForAssetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  metadataChannelsUrl: ?NETGEM_API_V8_URL_DEFINITION,
  metadataImageWithVariantUrl: ?NETGEM_API_V8_URL_DEFINITION,
  metadataProgramUrl: ?NETGEM_API_V8_URL_DEFINITION,
  metadataScheduleUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrRecordingsDeleteUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrRecordingsFutureUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrRecordingsListUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrRecordingsMetadataUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrRecordingsQuotaUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrRecordingsRetryUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrRecordingsStopUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrScheduledRecordingsCreateUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrScheduledRecordingsDeleteUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrScheduledRecordingsListUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrScheduledRecordingsMetadataUrl: ?NETGEM_API_V8_URL_DEFINITION,
  npvrScheduledRecordingsUpdateUrl: ?NETGEM_API_V8_URL_DEFINITION,
  personalDataDeleteUrl: ?NETGEM_API_V8_URL_DEFINITION,
  personalDataGetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  personalDataListDeleteUrl: ?NETGEM_API_V8_URL_DEFINITION,
  personalDataListGetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  personalDataListPostUrl: ?NETGEM_API_V8_URL_DEFINITION,
  personalDataPostUrl: ?NETGEM_API_V8_URL_DEFINITION,
  purchaseInfoPerAssetUrl: ?NETGEM_API_V8_URL_DEFINITION,
  rightsCommercialOffersUrl: ?NETGEM_API_V8_URL_DEFINITION,
  rightsDefaultUrl: ?NETGEM_API_V8_URL_DEFINITION,
  rightsUserUrl: ?NETGEM_API_V8_URL_DEFINITION,
|};

export const InitialState: NetgemApiState = {
  cncProgramGetUrl: null,
  cncSeriesGetUrl: null,
  dataCollectionColdUrl: null,
  dataCollectionHotUrl: null,
  deeplinkProgramGetUrl: null,
  deeplinkSeriesGetUrl: null,
  defaultHubUrl: null,
  distributors: {},
  entitlementGetUrl: null,
  entitlementReleaseUrl: null,
  getGamestreamTokenUrl: null,
  hubUrl: null,
  listAliasMultiUrl: null,
  listAliasPostUrl: null,
  locationCatchupForAssetUrl: null,
  locationEpgForAssetUrl: null,
  locationEpgUrl: null,
  locationVodForAssetUrl: null,
  metadataChannelsUrl: null,
  metadataImageWithVariantUrl: null,
  metadataProgramUrl: null,
  metadataScheduleUrl: null,
  npvrRecordingsDeleteUrl: null,
  npvrRecordingsFutureUrl: null,
  npvrRecordingsListUrl: null,
  npvrRecordingsMetadataUrl: null,
  npvrRecordingsQuotaUrl: null,
  npvrRecordingsRetryUrl: null,
  npvrRecordingsStopUrl: null,
  npvrScheduledRecordingsCreateUrl: null,
  npvrScheduledRecordingsDeleteUrl: null,
  npvrScheduledRecordingsListUrl: null,
  npvrScheduledRecordingsMetadataUrl: null,
  npvrScheduledRecordingsUpdateUrl: null,
  personalDataDeleteUrl: null,
  personalDataGetUrl: null,
  personalDataListDeleteUrl: null,
  personalDataListGetUrl: null,
  personalDataListPostUrl: null,
  personalDataPostUrl: null,
  purchaseInfoPerAssetUrl: null,
  rightsCommercialOffersUrl: null,
  rightsDefaultUrl: null,
  rightsUserUrl: null,
};

const DefaultAction: NetgemApiAction = { type: null };

/* eslint-disable complexity, max-lines-per-function */
const netgemApiReducer: (state: NetgemApiState, action: NetgemApiAction) => NetgemApiState = produce((draft = InitialState, action = DefaultAction) => {
  switch (action.type) {
    case REDUX_MSG_LIST_ALIAS_MULTI_URL: {
      draft.listAliasMultiUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_LIST_ALIAS_URL: {
      draft.listAliasPostUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_METADATA_CHANNELS_URL: {
      draft.metadataChannelsUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_METADATA_IMAGE_WITH_VARIANT_URL: {
      draft.metadataImageWithVariantUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_METADATA_PROGRAM_URL: {
      draft.metadataProgramUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_METADATA_SCHEDULE_URL: {
      draft.metadataScheduleUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_RECORDINGS_DELETE_URL: {
      draft.npvrRecordingsDeleteUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_RECORDINGS_FUTURE_URL: {
      draft.npvrRecordingsFutureUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_RECORDINGS_LIST_URL: {
      draft.npvrRecordingsListUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_RECORDINGS_METADATA_URL: {
      draft.npvrRecordingsMetadataUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_RECORDINGS_QUOTA_URL: {
      draft.npvrRecordingsQuotaUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_RECORDINGS_RETRY_URL: {
      draft.npvrRecordingsRetryUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_RECORDINGS_STOP_URL: {
      draft.npvrRecordingsStopUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_CREATE_URL: {
      draft.npvrScheduledRecordingsCreateUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_DELETE_URL: {
      draft.npvrScheduledRecordingsDeleteUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_URL: {
      draft.npvrScheduledRecordingsListUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_METADATA_URL: {
      draft.npvrScheduledRecordingsMetadataUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_UPDATE_URL: {
      draft.npvrScheduledRecordingsUpdateUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_GAMESTREAM_TOKEN_GET_URL: {
      draft.getGamestreamTokenUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_HUB_URL: {
      draft.hubUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_DEFAULT_HUB_URL: {
      draft.defaultHubUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_LOCATION_CATCHUP_FOR_ASSET_URL: {
      draft.locationCatchupForAssetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_LOCATION_EPG_URL: {
      draft.locationEpgUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_LOCATION_VOD_FOR_ASSET_URL: {
      draft.locationVodForAssetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_LOCATION_EPG_FOR_ASSET_URL: {
      draft.locationEpgForAssetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_CNC_PROGRAM_GET_URL: {
      draft.cncProgramGetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_CNC_SERIES_GET_URL: {
      draft.cncSeriesGetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_DEEPLINK_PROGRAM_GET_URL: {
      draft.deeplinkProgramGetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_DEEPLINK_SERIES_GET_URL: {
      draft.deeplinkSeriesGetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_PERSONAL_DATA_DELETE_URL: {
      draft.personalDataDeleteUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_PERSONAL_DATA_GET_URL: {
      draft.personalDataGetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_PERSONAL_DATA_POST_URL: {
      draft.personalDataPostUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_PERSONAL_DATA_LIST_DELETE_URL: {
      draft.personalDataListDeleteUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_PERSONAL_DATA_LIST_GET_URL: {
      draft.personalDataListGetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_PERSONAL_DATA_LIST_POST_URL: {
      draft.personalDataListPostUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_PURCHASE_INFO_PER_ASSET_URL: {
      draft.purchaseInfoPerAssetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_REQUEST_ENTITLEMENT_GET_URL: {
      draft.entitlementGetUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE_URL: {
      draft.entitlementReleaseUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_DATA_COLLECTION_COLD_POST_URL: {
      draft.dataCollectionColdUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_DATA_COLLECTION_HOT_POST_URL: {
      draft.dataCollectionHotUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_RIGHT_COMMERCIAL_OFFERS_URL: {
      draft.rightsCommercialOffersUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_RIGHT_DEFAULT_RIGHTS_URL: {
      draft.rightsDefaultUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_RIGHT_USER_RIGHTS_URL: {
      draft.rightsUserUrl = action.urlDefinition;
      break;
    }

    case REDUX_MSG_DISTRIBUTOR_ADD: {
      draft.distributors[action.distributorId] = action.distributorDefinition;
      break;
    }

    default:
  }
}, InitialState);
/* eslint-enable complexity, max-lines-per-function */

export default netgemApiReducer;
