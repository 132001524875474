/* @flow */

import LocalStorageManager from '../localStorage/localStorageManager';
import { SEARCH_HISTORY_MAX_COUNT } from './constants';
import { StorageKeys } from '../localStorage/keys';

const deleteSearchHistory: () => Array<string> = () => {
  LocalStorageManager.delete(StorageKeys.SearchHistory);
  return [];
};

const getSearchHistory: () => Array<string> = () => LocalStorageManager.loadObject(StorageKeys.SearchHistory, []);

const deleteTermInternal: (term: string) => Array<string> = (term) => {
  const history = LocalStorageManager.loadObject(StorageKeys.SearchHistory, []);

  if (!Array.isArray(history) || history.length === 0) {
    return [];
  }

  const trimmedTerm = term.trim();
  const pos = history.indexOf(trimmedTerm);
  if (pos > -1) {
    history.splice(pos, 1);
  }

  return history;
};

const deleteSearchString: (term: string) => Array<string> = (term) => {
  const history = deleteTermInternal(term);
  LocalStorageManager.save(StorageKeys.SearchHistory, history);
  return history;
};

const saveSearchString: (term?: string) => Array<string> = (term) => {
  if (!term) {
    return getSearchHistory();
  }

  const history = deleteTermInternal(term);

  const trimmedTerm = term.trim();
  if (trimmedTerm === '') {
    return history;
  }

  history.unshift(trimmedTerm);

  if (history.length > SEARCH_HISTORY_MAX_COUNT) {
    history.length = SEARCH_HISTORY_MAX_COUNT;
  }

  LocalStorageManager.save(StorageKeys.SearchHistory, history);

  return history;
};

export { deleteSearchHistory, deleteSearchString, getSearchHistory, saveSearchString };
