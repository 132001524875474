/* @flow */

import { HttpMethod } from '../../../../../libs/netgemLibrary/v8/types/HttpMethod';
import type { KeyValuePair } from '@ntg/utils/dist/types';

export type BO_API_BASE_TYPE = {|
  status: string,
|};

export type BO_API_ERROR_TYPE = {|
  ...BO_API_BASE_TYPE,
  errorMsg: string,
|};

export type BO_API_ERROR_TYPE_NEW = {|
  body: {|
    code: number,
    message: string,
    variables: KeyValuePair<string>,
  |},
|};

export type BO_RESPONSE_WITH_ETAG = {|
  eTag: string | null,
  response: any,
|};

export const BO_API_ADD_FAVORITE: 'addFavorite' = 'addFavorite';
export const BO_API_CREATE_STREAM2: 'createStreamV2' = 'createStreamV2';
export const BO_API_DELETE_FAVORITE: 'deleteFavorite' = 'deleteFavorite';
export const BO_API_FAVORITE_LIST: 'favoriteListJson' = 'favoriteListJson';
export const BO_API_GET_STREAMS: 'getStreams' = 'getStreams';
export const BO_API_PERSONAL_DATA: 'personalData' = 'personalData';
export const BO_API_PURCHASE: 'purchase' = 'purchase';
export const BO_API_PURCHASE_LIST: 'purchaseList' = 'purchaseList';
export const BO_API_PURCHASE_STATUS: 'purchaseStatus' = 'purchaseStatus';
export const BO_API_START_STREAM: 'streamStart' = 'streamStart';
export const BO_API_STOP_STREAM: 'streamStop' = 'streamStop';

// Cannot convert to Flow enum because it's used as an object's key
export type BOApi =
  | typeof BO_API_ADD_FAVORITE
  | typeof BO_API_CREATE_STREAM2
  | typeof BO_API_DELETE_FAVORITE
  | typeof BO_API_FAVORITE_LIST
  | typeof BO_API_GET_STREAMS
  | typeof BO_API_PERSONAL_DATA
  | typeof BO_API_PURCHASE
  | typeof BO_API_PURCHASE_LIST
  | typeof BO_API_PURCHASE_STATUS
  | typeof BO_API_START_STREAM
  | typeof BO_API_STOP_STREAM;

export type BOApiUrlDefinition = {|
  authent: boolean,
  method?: HttpMethod,
  params: Array<string>,
  uri: string,
|};

export type Distributor = {|
  urls: {| [BOApi]: BOApiUrlDefinition | null |},
|};
