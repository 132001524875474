/* @flow */

import { MILLISECONDS_PER_SECOND } from './Format';

export default class AccurateTimestamp {
  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'AccurateTimestamp';
  }

  static nowInternal: () => number =
    // $FlowFixMe: Flow doesn't know 'timeOrigin' property
    () => performance.timeOrigin + performance.now();

  // $FlowFixMe: Flow doesn't know 'timeOrigin' property
  static origin: () => number = () => performance.timeOrigin;

  // Returns current time in milliseconds
  static now: () => number = () => Math.trunc(AccurateTimestamp.nowInternal());

  // Returns current time in seconds
  static nowInSeconds: () => number = () => Math.trunc(AccurateTimestamp.nowInternal() / MILLISECONDS_PER_SECOND);

  // Returns current time as Date
  static nowAsDate: () => Date = () => new Date(AccurateTimestamp.nowInternal());

  // Returns current time as a UTC ISO string where milliseconds have been removed
  static nowAsIsoString: () => string = () =>
    AccurateTimestamp.nowAsDate()
      .toISOString()
      .replace(/\.\d\d\d/u, '');

  static startOfDayInSeconds: () => number = () => {
    const d = AccurateTimestamp.nowAsDate();
    d.setHours(0, 0, 0, 0);
    return Math.round(d.getTime() / MILLISECONDS_PER_SECOND);
  };
}
