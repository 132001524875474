/* @flow */

class Timer {
  callback: (error: Error) => void;

  timedOut: boolean;

  timer: TimeoutID;

  constructor(delay: number, callback: (error: Error) => void, error: Error) {
    this.callback = callback;
    this.timedOut = false;
    this.timer = setTimeout(this.timeOutCallback, delay, error);
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'Timer';
  }

  clear: () => void = () => {
    clearTimeout(this.timer);
    this.timedOut = false;
  };

  timeOutCallback: (error: Error) => void = (error) => {
    this.timedOut = true;
    this.callback(error);
  };

  hasTimedOut: () => boolean = () => this.timedOut;
}

module.exports = {
  Timer,
};
