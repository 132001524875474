/* @flow */

import './Volume.css';
import * as React from 'react';
import { PictoVolume, PictoVolumeDown, PictoVolumeMute, PictoVolumeUp } from '@ntg/components/dist/pictos/Element';
import type { BasicFunction } from '@ntg/utils/dist/types';
import { getBoundedValue } from '../../../../helpers/maths/maths';

const ONE_HUNDRED = 100;

/* eslint-disable no-magic-numbers */
const VOLUME = Object.freeze({
  BarCount: 5,
  BarLimits: Object.freeze([0, 0.25, 0.45, 0.7, 0.9]),
  MiddleValue: 0.5,
});
/* eslint-enable no-magic-numbers */

type DefaultProps = {|
  +onVolumeClickCallback?: BasicFunction,
  +onVolumeControllerClickCallback?: (volume: number) => void,
|};

type PlayerVolumePropType = {|
  ...DefaultProps,
  +isDisabled: boolean,
  +isMuted: boolean,
  +volume: number,
|};

type PlayerVolumeStateType = {||};

class PlayerVolume extends React.PureComponent<PlayerVolumePropType, PlayerVolumeStateType> {
  volumeController: HTMLElement | null;

  static defaultProps: DefaultProps = {
    onVolumeClickCallback: undefined,
    onVolumeControllerClickCallback: undefined,
  };

  constructor(...args: Array<any>) {
    super(...args);

    this.volumeController = null;
  }

  handleVolumeControllerOnClick = (event: SyntheticMouseEvent<HTMLElement>): void => {
    const { isDisabled, onVolumeControllerClickCallback } = this.props;
    const { volumeController } = this;

    if (isDisabled || !onVolumeControllerClickCallback || !volumeController) {
      return;
    }

    const { clientX } = event;
    const { left, width } = volumeController.getBoundingClientRect();
    const mousePosX = clientX - left;
    const newVolumeValue = getBoundedValue(Math.round((mousePosX * ONE_HUNDRED) / width) / ONE_HUNDRED, 0, 1);
    onVolumeControllerClickCallback(newVolumeValue);
  };

  handleVolumeOnClick: () => void = () => {
    const { isDisabled, onVolumeClickCallback } = this.props;

    if (isDisabled || !onVolumeClickCallback) {
      return;
    }

    onVolumeClickCallback();
  };

  render(): React.Node {
    const { isDisabled, isMuted, volume } = this.props;

    const volumeBarClasses = Array(VOLUME.BarCount).fill('volumeBar');

    if (!isMuted && !isDisabled) {
      VOLUME.BarLimits.forEach((limit, i) => {
        if (volume >= limit) {
          volumeBarClasses[i] += ' active';
        }
      });
    }

    let pictoVolumeDown = null;
    let pictoVolumeUp = null;
    let pictoVolumeMuted = null;

    if (!isMuted) {
      if (volume > 0 && volume < VOLUME.MiddleValue) {
        // Display picto with 1 sound wave
        pictoVolumeDown = <PictoVolumeDown className='pictoVolumeDown' forceHoverEffect isDisabled={isDisabled} />;
      } else if (volume >= VOLUME.MiddleValue) {
        // Display picto with 2 sound waves
        pictoVolumeUp = <PictoVolumeUp className='pictoVolumeUp' forceHoverEffect isDisabled={isDisabled} />;
      }
    } else {
      // Display picto with cross
      pictoVolumeMuted = <PictoVolumeMute className='pictoVolumeMuted' forceHoverEffect isDisabled={isDisabled} />;
    }

    /* eslint-disable react/no-array-index-key */
    return (
      <div className='playerVolumeContainer'>
        {pictoVolumeDown}
        {pictoVolumeUp}
        {pictoVolumeMuted}
        <PictoVolume isDisabled={isDisabled} onClick={this.handleVolumeOnClick} />
        <div
          className='volumeController'
          onClick={this.handleVolumeControllerOnClick}
          ref={(instance) => {
            this.volumeController = instance;
          }}
        >
          {volumeBarClasses.map((barClass, i) => (
            <div className={barClass} key={i} />
          ))}
        </div>
      </div>
    );
    /* eslint-enable react/no-array-index-key */
  }
}

export default (PlayerVolume: React.ComponentType<PlayerVolumePropType>);
