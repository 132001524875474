/* @flow */

import './ChannelZapper.css';
import * as React from 'react';
import type { BasicFunction, Undefined } from '@ntg/utils/dist/types';
import type { ChannelMap, NETGEM_API_CHANNEL } from '../../../../libs/netgemLibrary/v8/types/Channel';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { PictoArrowDown, PictoArrowUp } from '@ntg/components/dist/pictos/Element';
import Channel from './Channel';
import type { CombinedReducers } from '../../../../redux/reducers';
import { FEATURE_SUBSCRIPTION } from '../../../../redux/appConf/constants';
import type { NETGEM_API_V8_RIGHTS } from '../../../../libs/netgemLibrary/v8/types/Rights';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { isChannelPlaybackGranted } from '../../../../helpers/rights/playback';

const CHANNEL = Object.freeze({
  // Channel change will be effective after this time (in milliseconds). This allows not to send multiple channel changes in a short time
  ChangeDebounceTimeout: 300,
  // If there are strictly fewer channels than this number, the channel list cannot be opened
  CountThreshold: 2,
  // Same value as in CSS
  ImageContainerHeight: 60,
  // Channel list will stay open for that long after the mouse cursor left (in milliseconds)
  ListTimeout: 500,
});

type ReduxChannelZapperDispatchToPropsType = {||};

type ReduxChannelZapperReducerStateType = {|
  +channels: ChannelMap,
  +defaultRights: ?NETGEM_API_V8_RIGHTS,
  +isSubscriptionFeatureEnabled: boolean,
  +userRights: Array<string> | null,
|};

type ChannelZapperPropType = {|
  +channelId: ?string,
  +onChannelChangedCallback: (channel: NETGEM_API_CHANNEL) => void,
|};

type CompleteChannelZapperPropType = {|
  ...ChannelZapperPropType,
  ...ReduxChannelZapperDispatchToPropsType,
  ...ReduxChannelZapperReducerStateType,
|};

type ChannelZapperStateType = {|
  channelElementsArray: Array<React.Node>,
  channelIndex: number,
  isOpen: boolean,
  translateY: number,
|};

const InitialState: $ReadOnly<ChannelZapperStateType> = Object.freeze({
  channelElementsArray: [],
  channelIndex: -1,
  isOpen: false,
  translateY: 0,
});

class ChannelZapperView extends React.PureComponent<CompleteChannelZapperPropType, ChannelZapperStateType> {
  channelChangeDebounceTimer: TimeoutID | null;

  channelCount: number;

  channelIds: Array<string>;

  channelIndices: Map<string, number>;

  channelListTimer: TimeoutID | null;

  isUIDisabled: boolean;

  savedChannelIndex: ?number;

  constructor(props: CompleteChannelZapperPropType) {
    super(props);

    this.channelChangeDebounceTimer = null;
    this.channelCount = 0;
    this.channelIds = [];
    this.channelIndices = new Map();
    this.channelListTimer = null;
    this.isUIDisabled = true;
    this.savedChannelIndex = null;

    this.state = { ...InitialState };
  }

  componentDidMount() {
    const { channelId, channels } = this.props;

    this.channelCount = Object.keys(channels)
      .map((key) => channels[key])
      .filter((channel) => !channel.isHidden).length;
    this.isUIDisabled = this.channelCount < CHANNEL.CountThreshold;

    this.createChannelElements();
    Messenger.on(MessengerEvents.CLOSE_CHANNEL_LIST, this.closeChannelList);

    if (channelId) {
      // A channel Id was passed to the component: use it to initialize
      this.setChannelById(channelId, true);
    } else {
      // No channel Id was passed to the component: select the first channel
      this.setChannelByIndex(0, true);
    }
  }

  componentWillUnmount() {
    this.resetChannelChangeDebounceTimer();
    this.resetChannelListTimer();
    Messenger.off(MessengerEvents.CLOSE_CHANNEL_LIST, this.closeChannelList);
  }

  resetChannelChangeDebounceTimer = () => {
    if (this.channelChangeDebounceTimer) {
      clearTimeout(this.channelChangeDebounceTimer);
      this.channelChangeDebounceTimer = null;
    }
  };

  resetChannelListTimer = () => {
    if (this.channelListTimer) {
      clearTimeout(this.channelListTimer);
      this.channelListTimer = null;
    }
  };

  openChannelList = () => {
    const { channelIndex } = this.state;
    const { isUIDisabled } = this;

    if (isUIDisabled) {
      return;
    }

    this.savedChannelIndex = channelIndex;
    this.setState({ isOpen: true });
  };

  closeChannelList = (id?: string) => {
    const { channelIds, savedChannelIndex } = this;
    const savedChannelId = typeof savedChannelIndex === 'number' ? channelIds[savedChannelIndex] : null;

    let callback: Undefined<BasicFunction> = undefined;

    if (id && id !== savedChannelId) {
      callback = () => this.setChannelById(id, false);
    } else if (typeof savedChannelIndex === 'number') {
      callback = () => this.setChannelByIndex(savedChannelIndex, true);
    }

    this.savedChannelIndex = null;
    this.setState({ isOpen: false }, callback);
  };

  createChannelElements = () => {
    const { channels, defaultRights, isSubscriptionFeatureEnabled, userRights } = this.props;
    const { channelIds, channelIndices, isUIDisabled } = this;
    const channelElementsArray: Array<React.Node> = [];
    let i = 0;

    Object.keys(channels)
      .map((key) => channels[key])
      .sort((c1, c2) => c1.number - c2.number)
      .forEach((channel) => {
        const { consolidated, epgid, info, isHidden, name } = channel;

        if (!isHidden && isChannelPlaybackGranted(isSubscriptionFeatureEnabled, info?.rights, defaultRights, userRights)) {
          channelIds.push(epgid);
          channelIndices.set(epgid, i);
          i += 1;

          channelElementsArray.push(<Channel id={epgid} imageId={consolidated?.imageId} isUIDisabled={isUIDisabled} key={epgid} name={name} onClick={this.handleChannelOnClick} />);
        }
      });

    this.setState({ channelElementsArray });
  };

  setChannelByIndex = (newIndex: number, skipNotification: boolean) => {
    this.setState({ channelIndex: newIndex }, () => {
      if (!skipNotification) {
        this.debounceChannelChange(newIndex);
      }
      this.slide();
    });
  };

  setChannelById = (id: string, skipNotification: boolean) => {
    const { channelIndices } = this;

    const index = channelIndices.get(id);
    if (typeof index === 'number') {
      this.setChannelByIndex(index, skipNotification);
    }
  };

  debounceChannelChange = (newIndex: number) => {
    this.resetChannelChangeDebounceTimer();
    this.channelChangeDebounceTimer = setTimeout(this.notifyChannelChange, CHANNEL.ChangeDebounceTimeout, newIndex);
  };

  notifyChannelChange = (newIndex: number) => {
    const { channels, onChannelChangedCallback } = this.props;
    const { channelIds } = this;

    const channelId = channelIds[newIndex];
    const { [channelId]: channel } = channels;

    if (channel) {
      onChannelChangedCallback(channel);
    }
  };

  slide = () => {
    const { channelIndex } = this.state;

    const translateY = channelIndex * CHANNEL.ImageContainerHeight;

    this.setState({ translateY });
  };

  handleArrowUpOnClick = () => {
    const { channelIndex, isOpen } = this.state;
    const { channelCount, isUIDisabled } = this;

    if (isUIDisabled) {
      return;
    }

    this.setChannelByIndex((channelIndex + 1) % channelCount, isOpen);
  };

  handleArrowDownOnClick = () => {
    const { channelIndex, isOpen } = this.state;
    const { channelCount, isUIDisabled } = this;

    if (isUIDisabled) {
      return;
    }

    this.setChannelByIndex((channelIndex + channelCount - 1) % channelCount, isOpen);
  };

  handleChannelZapperOnMouseEnter = () => {
    this.resetChannelListTimer();
  };

  handleChannelZapperOnMouseLeave = () => {
    const { isOpen } = this.state;

    this.resetChannelListTimer();

    if (!isOpen) {
      return;
    }

    this.channelListTimer = setTimeout(() => {
      this.channelListTimer = null;
      this.closeChannelList();
    }, CHANNEL.ListTimeout);
  };

  handleOnWheel = (event: SyntheticWheelEvent<HTMLElement>) => {
    const { deltaY } = event;
    const { isUIDisabled } = this;

    if (isUIDisabled) {
      return;
    }

    if (deltaY < 0) {
      this.goToNextChannel();
    } else {
      this.goToPreviousChannel();
    }
  };

  handleChannelOnClick = (id: string) => {
    const { isOpen } = this.state;

    if (isOpen) {
      this.closeChannelList(id);
    } else {
      this.openChannelList();
    }
  };

  handleChannelListOpenerOnMouseEnter: () => void = this.openChannelList;

  goToPreviousChannel: () => void = this.handleArrowDownOnClick;

  goToNextChannel: () => void = this.handleArrowUpOnClick;

  render(): React.Node {
    const { channelElementsArray, isOpen, translateY } = this.state;
    const { isUIDisabled } = this;

    return (
      <div className={clsx('channelZapper', isOpen && 'open')} onMouseEnter={this.handleChannelZapperOnMouseEnter} onMouseLeave={this.handleChannelZapperOnMouseLeave} onWheel={this.handleOnWheel}>
        <div className={clsx('channelListOpener', isUIDisabled && 'disabled')} onMouseEnter={this.handleChannelListOpenerOnMouseEnter} />
        <div className='channelList'>
          {isOpen ? null : <PictoArrowUp className={clsx(isUIDisabled && 'disabled')} onClick={this.handleArrowUpOnClick} />}
          <div className='channelContainer'>
            <div className='channelSlider' style={{ transform: `translateY(${translateY}px)` }}>
              {channelElementsArray}
            </div>
          </div>
          {isOpen ? null : <PictoArrowDown className={clsx(isUIDisabled && 'disabled')} onClick={this.handleArrowDownOnClick} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxChannelZapperReducerStateType => {
  return {
    channels: state.appConfiguration.deviceChannels,
    defaultRights: state.appConfiguration.rightsDefault,
    isSubscriptionFeatureEnabled: state.appConfiguration.features[FEATURE_SUBSCRIPTION],
    userRights: state.appConfiguration.rightsUser,
  };
};

const ChannelZapper: React.ComponentType<ChannelZapperPropType> = connect(mapStateToProps, null, null, { forwardRef: true })(ChannelZapperView);

export default ChannelZapper;
