/* @flow */

import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { type NETGEM_API_V8_RIGHTS, NETGEM_API_V8_RIGHTS_RECORD } from '../../libs/netgemLibrary/v8/types/Rights';
import { PendingOperationKind, PendingOperationReason, storePendingOperation } from './pendingOperations';
import { getRequiredRights, isAccessGranted, showMissingSubscription } from './rights';
import type { CARD_DATA_MODAL_TYPE } from '../../components/modal/cardModal/CardModalConstsAndTypes';
import type { ChannelMap } from '../../libs/netgemLibrary/v8/types/Channel';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_V8_METADATA_SCHEDULE_LOCATION } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { getChannelRights } from '../channel/helper';

const isRecordGranted: (
  isRegisteredAsGuest: boolean,
  isSubscriptionFeatureEnabled: boolean,
  cardData: CARD_DATA_MODAL_TYPE,
  location: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
  channels: ChannelMap,
  commercialOffers: ?KeyValuePair<Array<string>>,
  defaultRights: ?NETGEM_API_V8_RIGHTS,
  userRights: Array<string> | null,
) => boolean = (isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, location, channels, commercialOffers, defaultRights, userRights) => {
  const { channelId } = location;

  const data = {
    ...cardData,
    pendingOperation: {
      operationType: PendingOperationKind.Record,
      reason: PendingOperationReason.RequireSubscription,
    },
  };

  if (isRegisteredAsGuest) {
    // Guest mode: let's open the sign-in page
    storePendingOperation(data);
    Messenger.emit(MessengerEvents.SHOW_SIGN_IN, PendingOperationKind.OpenCard);
    return false;
  }

  if (!isSubscriptionFeatureEnabled) {
    // Subscription feature not enabled: no restriction
    return true;
  }

  const channelRights = getChannelRights(channels, channelId);
  const requiredRights = getRequiredRights(NETGEM_API_V8_RIGHTS_RECORD, location, channelRights, defaultRights);
  if (isAccessGranted(requiredRights, userRights)) {
    // User has required rights
    return true;
  }

  // User rights do not meet required rights: open subscribe page and highlight matching commercial offer
  showMissingSubscription(data, requiredRights, commercialOffers);
  return false;
};

export { isRecordGranted };
