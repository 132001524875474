/* @flow */

import './SearchHistory.css';
import * as React from 'react';
import type { CombinedReducers } from '../../redux/reducers';
import SearchHistoryTerm from './SearchHistoryTerm';
import { connect } from 'react-redux';

type ReduxSearchHistoryReducerStateType = {|
  +searchHistory: Array<string>,
|};

type SearchHistoryPropType = {|
  +onSearchStringClick: (searchString: string) => void,
|};

type CompleteSearchHistoryPropType = {|
  ...SearchHistoryPropType,
  ...ReduxSearchHistoryReducerStateType,
|};

class SearchHistoryView extends React.PureComponent<CompleteSearchHistoryPropType> {
  render(): React.Node {
    const { onSearchStringClick, searchHistory } = this.props;

    if (searchHistory.length === 0) {
      // Only display non-empty history
      return null;
    }

    return (
      <div className='searchHistory'>
        {searchHistory.map((term) => (
          <SearchHistoryTerm key={term} onClick={onSearchStringClick} term={term} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxSearchHistoryReducerStateType => {
  return {
    searchHistory: state.ui.searchHistory,
  };
};

const SearchHistory: React.ComponentType<SearchHistoryPropType> = connect(mapStateToProps, null, null, { forwardRef: true })(SearchHistoryView);

export default SearchHistory;
