/* @flow */

import { HeaderName, HeaderValue } from '../constants/Headers';
import { HttpMethod } from '../types/HttpMethod';
import { buildHeaderList } from '../helpers/RequestHeaders';
import sendV8Request from './v8';

const sendV8ListAliasPostRequest: (url: string, bodyParam: string, method: HttpMethod, authenticationToken: string | null, signal: ?AbortSignal) => Promise<any> = (
  url,
  bodyParam,
  method,
  authenticationToken,
  signal,
) => {
  const headerList = buildHeaderList(HeaderName.ContentType, HeaderValue.ApplicationJsonUtf8, HeaderName.Accept, HeaderValue.ApplicationJson);
  return sendV8Request(url, authenticationToken, bodyParam, headerList, null, signal, null, method);
};

export default sendV8ListAliasPostRequest;
