/* @flow */

import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_PROVIDER_INFO, NETGEM_API_V8_METADATA_SERIES } from '../../libs/netgemLibrary/v8/types/MetadataProgram';
import type {
  NETGEM_API_VIEWINGHISTORY,
  NETGEM_API_VIEWINGHISTORY_ITEM,
  NETGEM_API_VIEWINGHISTORY_LOCATION,
  NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS,
  VIEWING_HISTORY_TYPE,
} from '../../libs/netgemLibrary/v8/types/ViewingHistory';
import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import { type NETGEM_API_V8_FEED_ITEM } from '../../libs/netgemLibrary/v8/types/FeedItem';
import type { Undefined } from '@ntg/utils/dist/types';
import type { VOD_STATUS_TYPE } from '../ui/metadata/Types';
import { getIntegerPercentage } from '../maths/maths';

// Any program not watched more than 1 minute is considered not watched at all (in seconds)
const BOOKMARK_POSITION_THRESHOLD = 60;

const ONE_HUNDRED = 100;

const deepCopyViewingHistoryItem: (item: NETGEM_API_VIEWINGHISTORY_ITEM) => NETGEM_API_VIEWINGHISTORY_ITEM = (item) => {
  const { date, episodes: episodesOriginal, id, playeditems: playeditemsOriginal, rating, watchingstatus } = item;

  // Copy simple values
  const clone: NETGEM_API_VIEWINGHISTORY_ITEM = {
    date,
    episodes: undefined,
    id,
    playeditems: undefined,
    rating,
    watchingstatus,
  };

  // Copy episodes array
  if (episodesOriginal) {
    clone.episodes = episodesOriginal.map((episode) => deepCopyViewingHistoryItem(episode));
  }

  // Copy playeditems object
  const playeditems: NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS = {};
  if (playeditemsOriginal) {
    Object.keys(playeditemsOriginal).forEach((locationId) => {
      const playedItem = playeditemsOriginal[locationId];
      playeditems[locationId] = { ...playedItem };
    });
    clone.playeditems = playeditems;
  }

  return clone;
};

// Returns an integer in the interval [0,100]
const computeProgress: (duration: number, position: number) => number = (duration, position) => getIntegerPercentage(position, 0, duration);

const getMostRecentlyPlayedEpisode: (episodes: VIEWING_HISTORY_TYPE) => NETGEM_API_VIEWINGHISTORY_ITEM = (episodes) =>
  episodes.reduce((mostRecentEp, ep) => (ep.playeditems && (!mostRecentEp || ep.date > mostRecentEp.date) ? ep : mostRecentEp));

const getHighestProgress: (item: NETGEM_API_VIEWINGHISTORY_ITEM, defaultValue: number, vodViewingHistoryId?: string) => number = (item, defaultValue, vodViewingHistoryId) => {
  const { playeditems } = item;

  if (!playeditems) {
    return defaultValue;
  }

  if (vodViewingHistoryId) {
    // VOD item
    const { [vodViewingHistoryId]: historyItem } = playeditems;
    return historyItem?.percent ?? defaultValue;
  }

  // TV item
  let highestProgress: number | null = null;
  Object.values(playeditems).forEach((i) => {
    const { percent } = ((i: any): NETGEM_API_VIEWINGHISTORY_LOCATION);
    if (typeof percent === 'number' && (highestProgress === null || percent > highestProgress)) {
      highestProgress = percent;
    }
  });

  return typeof highestProgress === 'number' ? highestProgress : defaultValue;
};

// Returns a number between 0 and 100, or null if not found
const getProgress: (
  viewingHistory: NETGEM_API_VIEWINGHISTORY,
  item: NETGEM_API_V8_FEED_ITEM,
  programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
  isSeries: boolean,
  vodStatus: VOD_STATUS_TYPE | null,
) => number | null = (viewingHistory, item, programMetadata, seriesMetadata, isSeries, vodStatus) => {
  if (programMetadata) {
    const { providerInfo } = programMetadata;
    const vodViewingHistoryId = vodStatus?.viewingHistoryId;

    if (providerInfo) {
      // Search VOD items
      return getVodProgress(viewingHistory, item, providerInfo, seriesMetadata?.providerInfo, isSeries, vodViewingHistoryId);
    }
  }

  // Search TV items
  return getTVProgress(viewingHistory, item, isSeries, seriesMetadata?.id);
};

const getVodProgress: (
  viewingHistory: NETGEM_API_VIEWINGHISTORY,
  item: NETGEM_API_V8_FEED_ITEM,
  programProviderInfo: NETGEM_API_V8_METADATA_PROVIDER_INFO,
  seriesProviderInfo?: NETGEM_API_V8_METADATA_PROVIDER_INFO,
  isSeries: boolean,
  vodViewingHistoryId?: string,
) => number | null = (viewingHistory, item, programProviderInfo, seriesProviderInfo, isSeries, vodViewingHistoryId) => {
  // New version: using universal Ids for VOD items
  const { viewingHistoryId: programId } = programProviderInfo;

  const { [programId]: programHistory } = viewingHistory;
  if (programHistory) {
    // Single program
    return getHighestProgress(programHistory, programHistory.watchingstatus, vodViewingHistoryId);
  }

  if (seriesProviderInfo) {
    // Program is part of a series
    const { viewingHistoryId: seriesId } = seriesProviderInfo;
    if (seriesId) {
      const { [seriesId]: seriesHistory } = viewingHistory;

      if (seriesHistory) {
        const { episodes } = seriesHistory;
        if (episodes) {
          if (isSeries) {
            // Series tile: display the progress of the most recently played episode
            const mostRecentEpisode = getMostRecentlyPlayedEpisode(episodes);
            return getHighestProgress(mostRecentEpisode, mostRecentEpisode.watchingstatus);
          }

          // Episode tile: display the progress of the episode
          const watchedEpisode = episodes.find((ep) => ep.id === programId);

          if (watchedEpisode) {
            return getHighestProgress(watchedEpisode, watchedEpisode.watchingstatus);
          }
        }
      }
    }
  }

  // Backward compatibility version: using device-specific Ids for VOD items
  return getTVProgress(viewingHistory, item, isSeries);
};

const getTVProgress: (viewingHistory: NETGEM_API_VIEWINGHISTORY, item: NETGEM_API_V8_FEED_ITEM, isSeries: boolean, seriesId?: string) => number | null = (viewingHistory, item, isSeries, seriesId) => {
  const { selectedProgramId } = item;
  const { [selectedProgramId]: programHistory } = viewingHistory;

  if (programHistory) {
    // Single program
    return getHighestProgress(programHistory, programHistory.watchingstatus);
  }

  if (seriesId) {
    // Program is part of a series
    const { [seriesId]: seriesHistory } = viewingHistory;

    if (seriesHistory) {
      const { episodes } = seriesHistory;
      if (episodes) {
        if (isSeries) {
          // Series tile: display the progress of the most recently played episode
          const mostRecentEpisode = getMostRecentlyPlayedEpisode(episodes);
          return getHighestProgress(mostRecentEpisode, mostRecentEpisode.watchingstatus);
        }

        // Episode tile: display the progress of the episode
        const watchedEpisode = episodes.find((ep) => ep.id === selectedProgramId);

        if (watchedEpisode) {
          return getHighestProgress(watchedEpisode, watchedEpisode.watchingstatus);
        }
      }
    }
  }

  return null;
};

const buildViewingHistoryItem: (
  viewingHistory: NETGEM_API_VIEWINGHISTORY,
  locationId: string,
  programId: string,
  seriesId: ?string,
  position: number,
  duration: number,
  start: number,
  end: number,
  channelId?: string,
) => NETGEM_API_VIEWINGHISTORY_ITEM | null = (viewingHistory, locationId, programId, seriesId, position, duration, start, end, channelId) => {
  const date = AccurateTimestamp.nowAsIsoString();

  let episodeItem: ?NETGEM_API_VIEWINGHISTORY_ITEM = null;
  let seriesItem: ?NETGEM_API_VIEWINGHISTORY_ITEM = null;

  if (seriesId) {
    // Series

    // Search series in the viewing history and clone it
    const { [seriesId]: series } = viewingHistory;
    if (series) {
      seriesItem = deepCopyViewingHistoryItem(series);
    }

    if (!seriesItem) {
      seriesItem = {
        date,
        episodes: [],
        id: seriesId,
        rating: 0,
        watchingstatus: 0,
      };
    } else {
      const { episodes } = seriesItem;
      if (episodes) {
        episodeItem = episodes.find((ep) => ep.id === programId);
      }
    }
  } else {
    // Single

    // Search episode in the viewing history and clone it
    const { [programId]: episode } = viewingHistory;
    if (episode) {
      episodeItem = deepCopyViewingHistoryItem(episode);
    }
  }

  const shouldEpisodeBeAdded = !episodeItem;

  let playeditems: Undefined<NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS> = {};

  if (!episodeItem) {
    episodeItem = {
      date: '',
      id: programId,
      playeditems,
      rating: 0,
      watchingstatus: 0,
    };
  }

  ({ playeditems } = episodeItem);

  if (!playeditems) {
    playeditems = {};
    episodeItem.playeditems = playeditems;
  }

  let relativePosition = position - start;
  if (relativePosition < 0 || relativePosition < BOOKMARK_POSITION_THRESHOLD) {
    relativePosition = 0;
  }

  const programDuration = duration ? duration - start - end : 0;
  const percent = computeProgress(programDuration, relativePosition);

  // $FlowFixMe: prop-missing
  playeditems[locationId] = {
    channelId,
    date,
    percent,
    position: relativePosition,
  };

  const { watchingstatus } = episodeItem;

  if (watchingstatus < ONE_HUNDRED) {
    const maxPosition = Object.values(playeditems).reduce((acc, val) => Math.max(acc, ((val: any): NETGEM_API_VIEWINGHISTORY_LOCATION).position), 0);
    const newWatchingstatus = computeProgress(programDuration, maxPosition);

    if (newWatchingstatus > watchingstatus) {
      episodeItem.watchingstatus = newWatchingstatus;
    }
  }

  // Update episode
  episodeItem.date = date;

  if (!seriesId || !seriesItem) {
    // Single
    return episodeItem;
  }

  // Series

  if (!seriesItem.episodes) {
    seriesItem.episodes = [];
  }

  if (shouldEpisodeBeAdded) {
    seriesItem.episodes.push(episodeItem);
  }

  // Update at series level
  seriesItem.date = date;

  return seriesItem;
};

export { buildViewingHistoryItem, getMostRecentlyPlayedEpisode, getProgress };
