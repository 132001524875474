/* @flow */

import './ImageCarousel.css';
import * as React from 'react';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { CombinedReducers } from '../../redux/reducers';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { showDebug } from '../../helpers/debug/debug';

type ImageCarouselPropType = {||};

type ReduxImageCarouselReducerStateType = {|
  +gridSectionId: string | null,
  +isDebugModeEnabled: boolean,
|};

type CompleteImageCarouselPropType = {|
  ...ImageCarouselPropType,
  ...ReduxImageCarouselReducerStateType,
|};

type ImageCarouselStateType = {|
  currentIndex: number,
  imageUrlList: Array<string> | null,
  isHidden: boolean,
|};

const InitialState = Object.freeze({
  currentIndex: -1,
  imageUrlList: null,
  isHidden: false,
});

class ImageCarouselView extends React.PureComponent<CompleteImageCarouselPropType, ImageCarouselStateType> {
  constructor(props: CompleteImageCarouselPropType) {
    super(props);

    const { gridSectionId } = props;

    this.state = {
      ...InitialState,
      isHidden: gridSectionId !== null,
    };
  }

  componentDidMount() {
    Messenger.on(MessengerEvents.CAROUSEL_SHOW_DEBUG, this.showDebug);
    Messenger.on(MessengerEvents.IMAGE_CAROUSEL_SET, this.setImages);
    Messenger.on(MessengerEvents.CAROUSEL_SET_CURRENT_INDEX, this.setCurrentIndex);
    Messenger.on(MessengerEvents.CAROUSEL_SHOW, this.show);
    Messenger.on(MessengerEvents.CAROUSEL_HIDE, this.hide);
  }

  componentWillUnmount() {
    Messenger.off(MessengerEvents.CAROUSEL_SHOW_DEBUG, this.showDebug);
    Messenger.off(MessengerEvents.IMAGE_CAROUSEL_SET, this.setImages);
    Messenger.off(MessengerEvents.CAROUSEL_SET_CURRENT_INDEX, this.setCurrentIndex);
    Messenger.off(MessengerEvents.CAROUSEL_SHOW, this.show);
    Messenger.off(MessengerEvents.CAROUSEL_HIDE, this.hide);
  }

  showDebug: () => void = () => {
    const { props, state } = this;
    const { isDebugModeEnabled } = props;

    if (!isDebugModeEnabled) {
      return;
    }

    showDebug('Background Images', {
      state,
      stateFields: ['currentIndex', 'imageUrlList', 'isHidden'],
    });
  };

  setImages = (imageUrlList: Array<string>): void => {
    this.setState({
      currentIndex: 0,
      imageUrlList,
    });
  };

  setCurrentIndex = (currentIndex: number): void => {
    this.setState({ currentIndex });
  };

  show: () => void = () => {
    this.setState({ isHidden: false });
  };

  hide: () => void = () => {
    this.setState({ isHidden: true });
  };

  render(): React.Node {
    const { currentIndex, imageUrlList, isHidden } = this.state;

    if (isHidden || !imageUrlList || currentIndex < 0 || currentIndex >= imageUrlList.length) {
      return null;
    }

    return (
      <div className='imageCarousel'>
        {imageUrlList.map((url, i) => (
          <img alt='' className={clsx('imageContainer', i === currentIndex && 'selected')} data-index={i} key={url} src={url} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxImageCarouselReducerStateType => {
  return {
    gridSectionId: state.ui.gridSectionId,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
  };
};

const ImageCarousel: React.ComponentType<ImageCarouselPropType> = connect(mapStateToProps, null)(ImageCarouselView);

export default ImageCarousel;
