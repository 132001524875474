/* @flow */

const capitalizeFirstLetter: (s: ?string) => string = (s) => {
  if (!s) {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const replaceUnderscoreByNbsp: (s: ?string) => string = (s) => {
  if (!s) {
    return '';
  }
  return s.replace(/_/giu, '\u00A0');
};

module.exports = {
  capitalizeFirstLetter,
  replaceUnderscoreByNbsp,
};
