/* @flow */

import * as React from 'react';
import ApplicationContainer, { ApplicationState, ErrorType } from './components/mainView/ApplicationContainer';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { RegistrationType, type UserDeviceInfoType } from './redux/appRegistration/types/types';
import { emit, initialize as ntgApiInitialize } from './redux/netgemApi/actions/emitter';
import { forceBOV1Api, updateDebugMode, updateDeviceOS, updateUtmParameters } from './redux/appConf/actions';
import { registerApplication, setDeviceId } from './redux/appRegistration/actions';
import AccurateTimestamp from './helpers/dateTime/AccurateTimestamp';
import EpgManager from './helpers/epg/epgManager';
import { I18nextProvider } from 'react-i18next';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import LocalStorageManager from './helpers/localStorage/localStorageManager';
import { Localizer } from '@ntg/utils/dist/localization';
import { InitialState as NetgemApiDefaultState } from './redux/netgemApi/reducers';
import { Provider } from 'react-redux';
import { StorageKeys } from './helpers/localStorage/keys';
import { ToastContainer } from 'react-toastify';
import allReducers from './redux/reducers';
import { configureStore } from '@reduxjs/toolkit';
import { deleteOptimisticData } from './helpers/localStorage/optimisticData';
import { detect as detectBrowser } from 'detect-browser';
import { detectDrm } from './helpers/jsHelpers/Drm';
import { enableMapSet } from 'immer';
import { getDeviceOS } from './helpers/dms/helper';
import { isRunningOnMobileBrowser } from './helpers/jsHelpers/environment';
import { isWhitelisted } from './helpers/jsHelpers/robots';
import sendV8AppConfigurationRequest from './redux/netgemApi/actions/v8/appConfiguration';
import { updateDisplayPaywallSubscription } from './redux/ui/actions';
import { verifyAndDecryptToken } from './helpers/crypto/crypto';

// Using Map and Set with immer require initialization
enableMapSet();

// How long data cached in local storage can be considered as valid (30 days in milliseconds)
const CACHED_DATA_TTL = 2_592_000_000;

// Max number of notifications displayed at the same time
const MAX_DISPLAYED_NOTIFICATION_COUNT = 5;

const initialState = Object.freeze({
  netgemApi: NetgemApiDefaultState,
});

// Create Redux store
const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: { extraArgument: { emit } },
    }),
  preloadedState: initialState,
  reducer: allReducers,
});

ntgApiInitialize(store);

EpgManager.initialize(store);

type AppPropType = {||};

type AppStateType = {|
  +initialAppError?: ErrorType,
  +initialAppState?: ApplicationState,
|};

const InitialState = Object.freeze({
  initialAppError: undefined,
  initialAppState: undefined,
});

class App extends React.PureComponent<AppPropType, AppStateType> {
  constructor(props: AppPropType) {
    super(props);

    this.state = { ...InitialState };

    // Clean up temporary data in local storage
    LocalStorageManager.delete(
      // Any pending operation (play, add to wishlist, etc.)
      StorageKeys.PendingOperation,
      // Saved expanded item index from series card
      StorageKeys.CardExpandedItemIndex,
      // CRM data (mainly used on paywalls)
      StorageKeys.CrmData,
    );
  }

  componentDidMount() {
    if (isWhitelisted()) {
      // Google or PageSpeed bot detected: allow it to render app without checking DRM or anything else
      this.initialize(ApplicationState.Initializing);
      return;
    }

    this.checkCompatibleDevice().then(this.initialize);
  }

  checkCompatibleDevice: () => Promise<ApplicationState> = () => {
    if (isRunningOnMobileBrowser()) {
      // Mobile/tablet detected
      return Promise.resolve(ApplicationState.RunningOnMobile);
    }

    // Check is browser supports our DRMs
    return detectDrm().catch(() => Promise.resolve(ApplicationState.BrowserNotCompatible));
  };

  initialize: (appState?: ApplicationState) => void = (appState) => {
    if (appState === ApplicationState.RunningOnMobile || appState === ApplicationState.BrowserNotCompatible) {
      // App will not work here
      this.getAppConfiguration().then(() => this.setState({ initialAppState: appState }));
      return;
    }

    // Check for cached data reset
    if (this.isResetRequested()) {
      this.cleanUpQueryString();
      LocalStorageManager.clear();
    }

    // Store device OS
    store.dispatch(updateDeviceOS(getDeviceOS()));

    // Check for debug mode setting
    this.checkDebugMode();

    // Check if BO API v1 should be forced
    this.checkBOV1Api();

    // Clean up if stored data is too old
    const now = AccurateTimestamp.now();
    const lastStartup = LocalStorageManager.loadNumber(StorageKeys.LastStartup, now);
    if (lastStartup + CACHED_DATA_TTL < now) {
      // Too old
      deleteOptimisticData();
    }

    LocalStorageManager.save(StorageKeys.LastStartup, now);

    // Store in local storage data collection messages before exiting
    window.addEventListener('beforeunload', () => Messenger.emit(MessengerEvents.STASH_COLLECTOR), { passive: true });

    Promise.all([this.checkDeviceIdAndUserInfo(), this.getAppConfiguration()]).then((results) => {
      const [deviceAndUser, isAppConfigurationLoaded] = results;

      if (!isAppConfigurationLoaded) {
        // Major problem
        this.setState({
          initialAppError: ErrorType.AppConfFailure,
          initialAppState: ApplicationState.Error,
        });
        return;
      }

      const {
        appConfiguration: { isGuestModeAllowed },
        appRegistration: { registration },
      } = store.getState();

      // Store UTM parameters for later use in CRM front
      store.dispatch(updateUtmParameters(this.getUtmParameters()));

      // Check if subscription paywall should be displayed
      if (this.isSubscribeRequested()) {
        this.cleanUpQueryString();
        store.dispatch(updateDisplayPaywallSubscription(true));
      }

      if (registration !== RegistrationType.Registered && this.isSigninRequested()) {
        // User not registered or registered as guest and display of sign-in page has been requested through URL
        this.cleanUpQueryString();
        this.setState({ initialAppState: ApplicationState.SignInDisplayed });
      } else if (registration !== RegistrationType.Registered && this.isSignupRequested()) {
        // User not registered or registered as guest and display of sign-up page has been requested through URL
        this.cleanUpQueryString();
        this.setState({ initialAppState: ApplicationState.SignUpDisplayed });
      } else if (isGuestModeAllowed && (this.isRegistrationRequested() || !deviceAndUser)) {
        // Force guest registration (for FrCh, it's "user or anonymous" and it will retrieve user's data if they logged in from landing page)
        this.cleanUpStorageAndRegisterAsGuest();
      } else {
        // User is either registered, registered as guest or not registered (for apps without guest mode)
        this.setState({ initialAppState: registration === RegistrationType.NotRegistered ? ApplicationState.NotRegistered : ApplicationState.Registered });

        // Remove data from local storage, so it's not reused later
        LocalStorageManager.delete(StorageKeys.CrmData);
      }
    });
  };

  checkDebugMode: () => void = () => {
    const debug = this.getQueryStringParameter('debug');
    if (typeof debug === 'undefined' || debug === null) {
      return;
    }

    store.dispatch(updateDebugMode(!['false', 'off', 'disabled', '0'].includes(debug.toLowerCase())));
  };

  checkBOV1Api: () => void = () => {
    const boV1 = this.getQueryStringParameter('bov1');
    if (typeof boV1 === 'undefined' || boV1 === null) {
      return;
    }

    store.dispatch(forceBOV1Api());
  };

  isResetRequested: () => boolean = () => this.getQueryStringParameter('reset') !== null;

  isRegistrationRequested: () => boolean = () => this.getQueryStringParameter('id') === 'app';

  isSigninRequested: () => boolean = () => this.getQueryStringParameter('signin') !== null;

  isSignupRequested: () => boolean = () => this.getQueryStringParameter('signup') !== null;

  isSubscribeRequested: () => boolean = () => this.getQueryStringParameter('subscribe') !== null;

  getQueryStringParameter: (name: string) => ?string = (name) => {
    const url = new URL(location.href);
    return url.searchParams.get(name);
  };

  cleanUpQueryString: () => void = () => {
    const {
      location: { origin },
    } = window;
    window.history.pushState({ path: origin }, '', origin);
  };

  getUtmParameters: () => KeyValuePair<string> = () => {
    const utmParameters: KeyValuePair<string> = {};

    const url = new URL(location.href);

    for (const [parameter, value] of url.searchParams.entries()) {
      if (parameter.toLowerCase().startsWith('utm_')) {
        utmParameters[parameter] = value;
      }
    }

    return utmParameters;
  };

  cleanUpStorageAndRegisterAsGuest: () => void = () => {
    // Not registered or invalid information
    LocalStorageManager.delete(StorageKeys.UserDeviceInfo);
    LocalStorageManager.delete(StorageKeys.RegisteredAsGuest);

    // Delete optimistic data when switching to or from (France Channel case, here) guest mode
    deleteOptimisticData();

    // Register as guest
    this.setState({ initialAppState: ApplicationState.RegisterAsGuest });
  };

  getAppConfiguration: () => Promise<boolean> = () => {
    const overriddenAppConf = LocalStorageManager.loadString(StorageKeys.OverriddenAppConf, '');
    return store.dispatch(sendV8AppConfigurationRequest(overriddenAppConf));
  };

  checkDeviceIdAndUserInfo: () => Promise<boolean> = () =>
    // Fetch cached device Id or create a new one
    this.fetchDeviceId().then((deviceId) => {
      const registeredAsGuest = LocalStorageManager.loadBoolean(StorageKeys.RegisteredAsGuest, false);

      store.dispatch(setDeviceId(deviceId));

      // Fetch user and device info stored in local storage
      return this.fetchStoredUserDeviceInfo(deviceId)
        .then((userDeviceInfo) => {
          const { applicationId, authDeviceUrl, deviceKey, subscriberId, upgradeDeviceUrl } = userDeviceInfo;

          if (!applicationId || !authDeviceUrl || !deviceKey || !subscriberId || !upgradeDeviceUrl) {
            return Promise.resolve(false);
          }

          store.dispatch(registerApplication(userDeviceInfo, registeredAsGuest));
          return Promise.resolve(true);
        })
        .catch(() => Promise.resolve(false));
    });

  // If device Id is missing, create a new one
  fetchDeviceId: () => Promise<string> = () => {
    // Retrieve device Id from local storage
    const storedDeviceId = LocalStorageManager.loadString(StorageKeys.DeviceIdentifier, '');

    if (storedDeviceId) {
      return Promise.resolve(storedDeviceId);
    }

    const browser = detectBrowser();
    const newDeviceId = `${crypto.randomUUID()}-${browser?.name ?? 'unknown-browser'}`;

    LocalStorageManager.save(StorageKeys.DeviceIdentifier, newDeviceId);
    return Promise.resolve(newDeviceId);
  };

  fetchStoredUserDeviceInfo: (deviceId: string) => Promise<UserDeviceInfoType> = (deviceId) => {
    // Fetch data from previous session (from local storage)
    const token = LocalStorageManager.loadString(StorageKeys.UserDeviceInfo, '');

    if (token === '') {
      return Promise.reject(new Error('No cached user info'));
    }

    // Verify data and set default state
    return verifyAndDecryptToken(deviceId, token);
  };

  render(): React.Node {
    const { initialAppError, initialAppState } = this.state;

    return (
      <I18nextProvider i18n={Localizer.getInstance()}>
        <Provider store={store}>
          <ApplicationContainer initialAppError={initialAppError} initialAppState={initialAppState} />
          <ToastContainer autoClose={8_000} closeOnClick draggablePercent={40} icon={false} limit={MAX_DISPLAYED_NOTIFICATION_COUNT} newestOnTop />
        </Provider>
      </I18nextProvider>
    );
  }
}

export default App;
