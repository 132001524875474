/* @flow */

import './StatusPicto.css';
import * as React from 'react';
import { BroadcastStatus, WebAppHelpersLocationStatus, getBroadcastStatus, getLocationStatus } from '../../helpers/ui/location/Format';
import { Localizer } from '@ntg/utils/dist/localization';
import { type NETGEM_API_V8_FEED_ITEM } from '../../libs/netgemLibrary/v8/types/FeedItem';
import clsx from 'clsx';

// Picto status is refreshed every 30 seconds
const REFRESH_TIMEOUT: number = 30_000;

type DefaultProps = {|
  +className?: string,
  +forcedText?: string,
  +onClick?: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => Promise<void> | void,
|};

export type StatusPictoPropType = {|
  ...DefaultProps,
  +item: NETGEM_API_V8_FEED_ITEM,
|};

type ReduxStatusPictoReducerStateType = {||};

export type CompleteStatusPictoPropType = {|
  ...StatusPictoPropType,
  ...ReduxStatusPictoReducerStateType,
|};

type StatusPictoStateType = {|
  broadcastStatus: BroadcastStatus,
|};

class StatusPicto extends React.PureComponent<CompleteStatusPictoPropType, StatusPictoStateType> {
  refreshTimer: TimeoutID | null;

  static defaultProps: DefaultProps = {
    className: '',
    forcedText: undefined,
    onClick: undefined,
  };

  constructor(props: CompleteStatusPictoPropType) {
    super(props);

    this.refreshTimer = null;

    this.state = {
      broadcastStatus: BroadcastStatus.Unknown,
    };
  }

  componentDidMount() {
    this.updateBroadcastStatus();
  }

  componentDidUpdate(prevProps: CompleteStatusPictoPropType) {
    const { item } = this.props;
    const { item: prevItem } = prevProps;

    if (item !== prevItem) {
      this.updateBroadcastStatus();
    }
  }

  componentWillUnmount() {
    this.resetRefreshTimer();
  }

  startRefreshTimer: () => void = () => {
    const { broadcastStatus } = this.state;

    this.resetRefreshTimer();

    if (broadcastStatus === BroadcastStatus.Past) {
      // A past item will never change, no need to check again
      return;
    }

    this.refreshTimer = setTimeout(this.updateBroadcastStatus, REFRESH_TIMEOUT);
  };

  resetRefreshTimer: () => void = () => {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  };

  updateBroadcastStatus: () => void = () => {
    const { item } = this.props;
    this.setState({ broadcastStatus: getBroadcastStatus(item) }, this.startRefreshTimer);
  };

  handleOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(event);
    }
  };

  render(): React.Node {
    const { className = '', forcedText, item, onClick } = this.props;
    const { broadcastStatus } = this.state;

    let status = getLocationStatus(item);
    if (!status || status === WebAppHelpersLocationStatus.Vod || status === WebAppHelpersLocationStatus.App || broadcastStatus === BroadcastStatus.Unknown) {
      return null;
    }

    if (broadcastStatus === BroadcastStatus.Live) {
      status = WebAppHelpersLocationStatus.Live;
    } else if (broadcastStatus === BroadcastStatus.Preview) {
      status = WebAppHelpersLocationStatus.Preview;
    }

    return (
      <div className={clsx('statusPicto', (status: string), className, onClick && 'clickable')} onClick={this.handleOnClick}>
        {forcedText || Localizer.localize(`common.status_picto.${(status: string)}`)}
      </div>
    );
  }
}

export default (StatusPicto: React.ComponentType<StatusPictoPropType>);
