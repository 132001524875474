/* @flow */

import { type NETGEM_API_V8_LANG } from '../types/Lang';

const getTranslatedText: (langArray: ?Array<NETGEM_API_V8_LANG>, language: ?string) => string = (langArray, language) => {
  if (!langArray || !language) {
    return '';
  }

  let translatedText = '';
  const baseLang = (language.includes('-') ? language.substring(0, language.indexOf('-')) : language).toUpperCase();

  for (let i: number = 0; i < langArray.length; i++) {
    const {
      [i]: { lang, value },
    } = langArray;

    if (lang.toUpperCase() === baseLang) {
      translatedText = value;
      break;
    } else if (translatedText === '') {
      translatedText = value;
    }
  }

  return translatedText;
};

export default getTranslatedText;
