/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import { REDUX_MSG_REQUEST_LIST_ALIAS_MULTI, REDUX_MSG_REQUEST_LIST_ALIAS_POST } from '../../constants/';
import type { ChannelMap } from '../../../../libs/netgemLibrary/v8/types/Channel';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';
import { getAllTags } from '../../../../helpers/channel/helper';
import { updateListAlias } from '../../../appConf/actions';

const getChannelsByTag: (deviceChannels: ChannelMap, tag: ?string) => Array<string> = (deviceChannels, tag) => {
  const list = [];

  for (const { epgid, info } of Object.keys(deviceChannels).map((key) => deviceChannels[key])) {
    if (!tag || (info && info.tags && info.tags.indexOf(tag) > -1)) {
      list.push(epgid);
    }
  }

  // Sort by number (LCN)
  list.sort((channel1, channel2) => deviceChannels[channel1].number - deviceChannels[channel2].number);

  return list;
};

const sendV8ListAliasMultiRequest: (tags: Array<string>, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (tags, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      appConfiguration: { deviceChannels },
      netgemApi: { listAliasMultiUrl },
    } = state;
    const list = [];

    tags.forEach((tag) => {
      const channels = getChannelsByTag(deviceChannels, tag);
      if (channels.length > 0) {
        list.push(channels);
      }
    });

    if (list.length === 0) {
      // No service
      return Promise.resolve();
    }

    if (!listAliasMultiUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'listAliasMultiUrl' }));
    }

    const { authent, method } = listAliasMultiUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_LIST_ALIAS_MULTI, {
      authent,
      bodyParam: JSON.stringify({ list }),
      method,
      signal,
      uri: generateApiUrl(listAliasMultiUrl, {}, state),
    }).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { result } = response;
      result.forEach((alias, index) => dispatch(updateListAlias(list[index], alias, tags[index])));
      return Promise.resolve(response);
    });
  };

const sendV8ListAliasPostRequest: (tag: string | null, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (tag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      appConfiguration: { deviceChannels },
      netgemApi: { listAliasPostUrl },
    } = state;
    const list = getChannelsByTag(deviceChannels, tag);

    if (list.length === 0) {
      // No service
      return Promise.resolve();
    }

    if (!listAliasPostUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'listAliasPostUrl' }));
    }

    const { authent, method } = listAliasPostUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_LIST_ALIAS_POST, {
      authent,
      bodyParam: JSON.stringify({ list }),
      method,
      signal,
      uri: generateApiUrl(listAliasPostUrl, {}, state),
    }).then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
      const { result } = response;
      dispatch(updateListAlias(list, result, tag));
      return Promise.resolve(response);
    });
  };

const createTagListAliases: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const state = getState();
    const {
      appConfiguration: { deviceChannels },
    } = state;

    return dispatch(sendV8ListAliasMultiRequest(getAllTags(deviceChannels), signal));
  };

export { createTagListAliases, sendV8ListAliasPostRequest };
