/* @flow */

import { HttpMethod } from '../../v8/types/HttpMethod';
import type { NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD } from '../../v8/types/DataCollection';
import type { NETGEM_API_V8_REQUEST_HEADERS } from '../../v8/types/Headers';
import sendV8Request from '../../v8/requests/v8';

const sendV8DataCollectionRequest: (
  url: string,
  content: NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD,
  method: HttpMethod,
  headers: NETGEM_API_V8_REQUEST_HEADERS,
  signal?: AbortSignal,
) => Promise<any> = (url, content, method, headers, signal) => sendV8Request(url, null, JSON.stringify(content), headers, null, signal, null, method);

const sendV8DataCollectionCollectorIdRequest: (url: string, authenticationToken: string | null, signal?: AbortSignal) => Promise<any> = (url, authenticationToken, signal) =>
  sendV8Request(url, authenticationToken, null, [], null, signal);

export { sendV8DataCollectionCollectorIdRequest, sendV8DataCollectionRequest };
