/* @flow */

import { type PaymentInfo, type RawUserInfo, type SubscriptionList, SubscriptionStatus, type UserInfo } from '../../../appRegistration/types/types';
import { createCustomNetworkError, createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { resetUserInfo, updateUserInfo } from '../../../appRegistration/actions';
import type { CombinedReducers } from '../../../reducers';
import type { RequestResponseMethodDefinitionType } from '../emitter';
import { type Undefined } from '@ntg/utils/dist/types';
import getUserInfoFromCrm from '../../../../libs/crm/userInfo';
import { logError } from '../../../../helpers/debug/debug';

const TWO = 2;

const getSubscriptionStatusPriority: (status: SubscriptionStatus) => number =
  // eslint-disable-next-line consistent-return
  (status) => {
    switch (status) {
      case SubscriptionStatus.Active:
        return TWO;

      case SubscriptionStatus.InTrial:
      case SubscriptionStatus.NonRenewing:
        return 1;

      case SubscriptionStatus.Cancelled:
      case SubscriptionStatus.Future:
      case SubscriptionStatus.Paused:
        return 0;

      // No default
    }
  };

const sendUserInfoRequest: () => RequestResponseMethodDefinitionType =
  () =>
  (dispatch, getState: () => CombinedReducers): Promise<any> => {
    const state = getState();
    const {
      appConfiguration: {
        configuration: { getUserInfoUrl },
      },
      appRegistration: { authenticationToken },
    } = state;

    if (!getUserInfoUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'getUserInfoUrl' }));
    }

    if (!authenticationToken) {
      return Promise.reject(createCustomNetworkError(-1, 'Missing authentication token'));
    }

    return getUserInfoFromCrm(getUserInfoUrl, authenticationToken);
  };

const parseUserInfo: (data: RawUserInfo) => ?UserInfo = (data) => {
  const { email, id, newsletter, paymentInfo: rawPaymentInfo, subscriptions: rawSubscriptions } = data;

  const subscriptions: SubscriptionList = {};
  rawSubscriptions?.forEach((sub) => {
    const { planId, status } = sub;

    // Check if same plan Id has already been found
    const { [planId]: currentStatus } = subscriptions;

    /*
     * Following test ensures that if the same plan is encountered more than once, the most relevant status is used
     */
    if (!currentStatus || getSubscriptionStatusPriority(status) > getSubscriptionStatusPriority(currentStatus)) {
      subscriptions[planId] = status;
    }
  });

  let paymentInfo: PaymentInfo | null = null;
  if (rawPaymentInfo) {
    const { expiry, status, dunningStatus } = rawPaymentInfo;

    let expirationTime: Undefined<number> = undefined;

    if (expiry) {
      // Expiry is a string like "12/2030", which means the card will expire at the end of the month (so we set the date to the first day of the following month)
      const monthLength = 2;
      const expirationDate = new Date(`${expiry.substring(0, monthLength)}/01/${expiry.substring(monthLength + 1)}`);
      if (!isNaN(expirationDate)) {
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        expirationTime = expirationDate.getTime();
      }
    }

    paymentInfo = {
      dunningStatus,
      expirationTime,
      status,
    };
  }

  return {
    email,
    id,
    newsletter,
    paymentInfo,
    subscriptions,
  };
};

const getUserInfo: () => RequestResponseMethodDefinitionType =
  () =>
  (dispatch): Promise<any> =>
    dispatch(sendUserInfoRequest())
      .then((data) => {
        const userInfo = parseUserInfo(data);

        if (!userInfo) {
          // Something went wrong
          dispatch(resetUserInfo());
        } else {
          // OK
          dispatch(updateUserInfo(userInfo));
        }

        return Promise.resolve(userInfo);
      })
      .catch((error) => {
        logError(error);
      });

export { getUserInfo, sendUserInfoRequest };
