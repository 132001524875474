/* @flow */

import './NewVersionModal.css';
import * as React from 'react';
import ButtonFX from '../../buttons/ButtonFX';
import { Localizer } from '@ntg/utils/dist/localization';
import Modal from '../modal';
import { ModalIcon } from '../modalTypes';
import type { ModalState } from '../../../redux/modal/reducers';
import { WidthKind } from '../../buttons/types';

export type NEW_VERSION_DATA_MODAL_TYPE = {|
  currentVersion: string,
  newVersion: string,
|};

class NewVersionModal extends React.PureComponent<ModalState> {
  handleOnClick: () => void = () => {
    window.location.reload();
  };

  render(): React.Node {
    const { index } = this.props;

    return (
      <Modal
        canCloseOnEnter={false}
        canCloseOnEscape={false}
        canCloseOnOverlayClick={false}
        className='newVersion'
        header={Localizer.localize('modal.new_version.title')}
        icon={ModalIcon.Info}
        index={index}
        isCloseButtonDisplayed={false}
      >
        <div className='newVersionContent'>
          <div>{Localizer.localize('modal.new_version.click_below')}</div>
          <ButtonFX onClick={this.handleOnClick} widthKind={WidthKind.Stretched}>
            {Localizer.localize('modal.new_version.refresh')}
          </ButtonFX>
        </div>
      </Modal>
    );
  }
}

export default (NewVersionModal: React.ComponentType<ModalState>);
