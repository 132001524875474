/* @flow */

import { logError, logInfo, logWarning } from '../debug/debug';
import { KEY } from './keysDefinition';
import type { KeyValuePair } from '@ntg/utils/dist/types';

const MODIFIER_PADDING = 3;

type ListenerOptions = {|
  allowEditableContext?: boolean,
  disableOthers?: boolean,
  name?: string,
|};

type ListenerType = {|
  ...ListenerOptions,
  handler: (event: SyntheticKeyboardEvent<HTMLElement>) => void | Promise<void>,
|};

export default class HotKeys {
  listeners: {|
    [string]: Array<ListenerType>,
  |};

  // eslint-disable-next-line no-use-before-define
  static instance: HotKeys;

  constructor() {
    this.listeners = {};
    window.addEventListener('keydown', this.handleOnKeyDown, { capture: true });
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'HotKeys';
  }

  // eslint-disable-next-line no-use-before-define
  static getInstance: () => HotKeys = () => {
    if (!HotKeys.instance) {
      HotKeys.instance = new HotKeys();
    }

    return HotKeys.instance;
  };

  static listHotKeys: () => void = () => {
    logInfo('Registered listeners:');
    Object.entries(HotKeys.getInstance().listeners).forEach(([key, listeners]) => {
      const listenerDescriptions = ((listeners: any): Array<ListenerType>).map((l) => {
        const { allowEditableContext, disableOthers, name } = l;
        const parts = [name ?? '<no name>'];
        if (disableOthers) {
          parts.push('disableOthers');
        }
        if (allowEditableContext) {
          parts.push('editable');
        }

        return `(${parts.join(',')})`;
      });

      logInfo(`${HotKeys.toHumanReadable(key)}: [${listenerDescriptions.join(' ')}]`);
    });
  };

  /*
   * Takes a string and returns a normalized shortcut (string) of the form: 'a|c|s|k'
   * where a, c, s mean Alt, Control, Shift and can be 0 or 1
   *       k is the shortcut key code
   *
   * Examples:
   *  'a'                will return '0|0|0|65'
   *  'ctrl+f'           will return '0|1|0|70'
   *  'alt+shift+escape' will return '1|0|1|27'
   */
  static normalizeHotkey: (key: string) => string | null = (key) => {
    if (key === '') {
      return null;
    }

    const parts = key.toUpperCase().split('+');
    const { length } = parts;

    const keyName = parts[length - 1];
    if (!(keyName in KEY)) {
      return null;
    }

    const modifiers: KeyValuePair<boolean | 0 | 1> = {};
    for (let i = 0; i < length - 1; ++i) {
      const { [i]: modifier } = parts;
      if (modifier in modifiers) {
        logWarning(`Duplicate modifier ${modifier} in shortcut: ${key}`);
      }
      modifiers[parts[i]] = 1;
    }

    return HotKeys.buildHotKey(KEY[keyName], modifiers.ALT, modifiers.CTRL, modifiers.SHIFT);
  };

  static buildHotKey: (keyCode: number, alt?: boolean | 0 | 1, ctrl?: boolean | 0 | 1, shift?: boolean | 0 | 1) => string = (keyCode, alt, ctrl, shift) =>
    `${alt ? 1 : 0}|${ctrl ? 1 : 0}|${shift ? 1 : 0}|${keyCode}`;

  static toHumanReadable: (hotKey: string) => string = (hotKey) => {
    const modifiers = ['Alt', 'Ctrl', 'Shift'];
    const parts = hotKey.split('|');
    const keyCode = Number(parts.splice(parts.length - 1, 1));
    const modifiersString = parts.map((part, index) => (part === '1' ? modifiers[index] : ''.padStart(MODIFIER_PADDING + index))).join(' ');
    return `${modifiersString} ${HotKeys.getKeyNameFromCode(keyCode)}`;
  };

  static getKeyNameFromCode: (code: number) => string = (code) => {
    for (const keyName in KEY) {
      if (KEY[keyName] === code) {
        return keyName;
      }
    }

    return `<missing key for ${code}>`;
  };

  static register: (key: string | Array<string>, handler: (event: SyntheticKeyboardEvent<HTMLElement>) => void | Promise<void>, options?: ListenerOptions) => void = (key, handler, options) => {
    if (Array.isArray(key)) {
      key.forEach((k) => HotKeys.register(k, handler, options));
      return;
    }

    const hotKey = HotKeys.normalizeHotkey(key);

    if (!hotKey) {
      logError(`Cannot register unknown shortcut: ${key}`);
      return;
    }

    let { [hotKey]: listeners } = HotKeys.getInstance().listeners;
    if (!listeners) {
      listeners = [];
      HotKeys.getInstance().listeners[hotKey] = listeners;
    }

    const item = {
      ...options,
      handler,
    };

    if (options?.disableOthers) {
      listeners.unshift(item);
    } else {
      listeners.push(item);
    }
  };

  static unregister: (key: string | Array<string>, handler: (event: SyntheticKeyboardEvent<HTMLElement>) => void | Promise<void>) => void = (key, handler) => {
    if (Array.isArray(key)) {
      key.forEach((k) => HotKeys.unregister(k, handler));
      return;
    }

    const hotKey = HotKeys.normalizeHotkey(key);

    if (!hotKey) {
      logError(`Cannot unregister unknown shortcut: ${key}`);
      return;
    }

    const { [hotKey]: listeners } = HotKeys.getInstance().listeners;
    if (!listeners) {
      return;
    }

    const index = listeners.findIndex((item) => item.handler === handler);
    if (index > -1) {
      listeners.splice(index, 1);
      if (listeners.length === 0) {
        // No more listeners registered for this shortcut
        delete HotKeys.getInstance().listeners[hotKey];
      }
    }
  };

  static isInEditableContext: (event: SyntheticKeyboardEvent<HTMLElement>) => boolean = (event) => {
    const {
      // $FlowFixMe: tagName does exist in target
      target: { tagName },
    } = event;

    return ['INPUT', 'SELECT', 'TEXTAREA'].includes(tagName.toUpperCase());
  };

  handleOnKeyDown: (event: SyntheticKeyboardEvent<HTMLElement>) => void = (event) => {
    const { altKey, keyCode, ctrlKey, shiftKey } = event;

    const hotKey = HotKeys.buildHotKey(keyCode, altKey, ctrlKey, shiftKey);

    // Check listeners for this shortcut
    const { [hotKey]: listeners } = HotKeys.getInstance().listeners;
    if (!listeners) {
      // Nobody is listening
      return;
    }

    const isInEditableContext = HotKeys.isInEditableContext(event);

    for (let i = 0; i < listeners.length; ++i) {
      const { disableOthers, handler, allowEditableContext } = listeners[i];

      if (!isInEditableContext || allowEditableContext) {
        handler(event);
      }

      if (disableOthers) {
        // First listener is the only one notified
        break;
      }
    }
  };
}
