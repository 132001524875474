/* @flow */

import * as React from 'react';
import { useSwipeable } from 'react-swipeable';

type SwipeablePropType = {
  +children: React.Node,
};

const Swipeable: React.ComponentType<SwipeablePropType> = ({ children, ...props }: SwipeablePropType) => {
  const handlers = useSwipeable(props);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className='swipeable' {...handlers}>
      {children}
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export default Swipeable;
