/* @flow */

export const REDUX_MSG_AVENUE_LIST_UPDATE: 'NTG_RDX_MSG_AVENUE_LIST' = 'NTG_RDX_MSG_AVENUE_LIST';
export const REDUX_MSG_BACK_TO_FOCUSED_AVENUE: 'NTG_RDX_MSG_BACK_TO_FOCUSED_AVENUE' = 'NTG_RDX_MSG_BACK_TO_FOCUSED_AVENUE';
export const REDUX_MSG_BO_FAVORITE_LIST_CLEAR: 'BO_RDX_MSG_FAVORITE_LIST_CLEAR' = 'BO_RDX_MSG_FAVORITE_LIST_CLEAR';
export const REDUX_MSG_BO_PURCHASE_LIST_CLEAR: 'BO_RDX_MSG_PURCHASE_LIST_CLEAR' = 'BO_RDX_MSG_PURCHASE_LIST_CLEAR';
export const REDUX_MSG_DEFAULT_ACTIONS_UPDATE: 'NTG_RDX_MSG_UPDATE_DEFAULT_ACTIONS' = 'NTG_RDX_MSG_UPDATE_DEFAULT_ACTIONS';
export const REDUX_MSG_DELETE_SEARCH_HISTORY: 'NTG_RDX_MSG_DELETE_SEARCH_HISTORY' = 'NTG_RDX_MSG_DELETE_SEARCH_HISTORY';
export const REDUX_MSG_DELETE_SEARCH_HISTORY_TERM: 'NTG_RDX_MSG_DELETE_SEARCH_HISTORY_TERM' = 'NTG_RDX_MSG_DELETE_SEARCH_HISTORY_TERM';
export const REDUX_MSG_FOCUSED_AVENUE_UPDATE: 'NTG_RDX_MSG_FOCUSED_AVENUE_UPDATE' = 'NTG_RDX_MSG_FOCUSED_AVENUE_UPDATE';
export const REDUX_MSG_UPDATE_SECTION_PAGE_INDEX: 'NTG_RDX_MSG_SECTION_PAGE_INDEX_UPDATE' = 'NTG_RDX_MSG_SECTION_PAGE_INDEX_UPDATE';
export const REDUX_MSG_RESET_SECTION_PAGE_INDICES: 'NTG_RDX_MSG_SECTION_PAGE_INDICES_RESET' = 'NTG_RDX_MSG_SECTION_PAGE_INDICES_RESET';
export const REDUX_MSG_SET_DEEPLINK: 'NTG_RDX_MSG_SET_DEEPLINK' = 'NTG_RDX_MSG_SET_DEEPLINK';
export const REDUX_MSG_SETTING_RESET: 'NTG_RDX_MSG_RESET_SETTING' = 'NTG_RDX_MSG_RESET_SETTING';
export const REDUX_MSG_SETTING_UPDATE: 'NTG_RDX_MSG_UPDATE_SETTING' = 'NTG_RDX_MSG_UPDATE_SETTING';
export const REDUX_MSG_THEATER_MODE_UPDATE: 'NTG_RDX_MSG_THEATER_MODE_UPDATE' = 'NTG_RDX_MSG_THEATER_MODE_UPDATE';
export const REDUX_MSG_UPDATE_DISPLAY_PAYWALL_SUBSCRIPTION: 'NTG_RDX_DISPLAY_PAYWALL_SUBSCRIPTION_UPDATE' = 'NTG_RDX_DISPLAY_PAYWALL_SUBSCRIPTION_UPDATE';
export const REDUX_MSG_UPDATE_GRID_SECTION_ID: 'NTG_RDX_MSG_UPDATE_GRIDSECTIONID' = 'NTG_RDX_MSG_UPDATE_GRIDSECTIONID';
export const REDUX_MSG_UPDATE_SEARCH_STRING: 'NTG_RDX_MSG_UPDATE_SEARCH_STRING' = 'NTG_RDX_MSG_UPDATE_SEARCH_STRING';
export const REDUX_MSG_BO_FAVORITE_LIST_UPDATE: 'BO_RDX_MSG_FAVORITE_LIST_UPDATE' = 'BO_RDX_MSG_FAVORITE_LIST_UPDATE';
export const REDUX_MSG_BO_PURCHASE_LIST_FOR_DISTRIBUTOR_UPDATE: 'BO_RDX_MSG_PURCHASE_LIST_FOR_DISTRIBUTOR_UPDATE' = 'BO_RDX_MSG_PURCHASE_LIST_FOR_DISTRIBUTOR_UPDATE';
export const REDUX_MSG_BO_PURCHASE_LISTS_FOR_ALL_DISTRIBUTORS_UPDATE: 'BO_RDX_MSG_PURCHASE_LISTS_FOR_ALL_DISTRIBUTORS_UPDATE' = 'BO_RDX_MSG_PURCHASE_LISTS_FOR_ALL_DISTRIBUTORS_UPDATE';
export const REDUX_MSG_VIDEO_CAROUSEL_PLAY_UPDATE: 'NTG_RDX_MSG_VIDEO_CAROUSEL_SOUND_UPDATE' = 'NTG_RDX_MSG_VIDEO_CAROUSEL_SOUND_UPDATE';
export const REDUX_MSG_VIDEO_CAROUSEL_SOUND_UPDATE: 'NTG_RDX_MSG_VIDEO_CAROUSEL_PLAY_UPDATE' = 'NTG_RDX_MSG_VIDEO_CAROUSEL_PLAY_UPDATE';
export const REDUX_MSG_VIEWING_HISTORY_ITEM_DELETE: 'NTG_RDX_MSG_VIEWING_HISTORY_ITEM_DELETE' = 'NTG_RDX_MSG_VIEWING_HISTORY_ITEM_DELETE';
export const REDUX_MSG_VIEWING_HISTORY_ITEM_ETAG_DELETE: 'REDUX_MSG_VIEWING_HISTORY_ITEM_ETAG_DELETE' = 'REDUX_MSG_VIEWING_HISTORY_ITEM_ETAG_DELETE';
export const REDUX_MSG_VIEWING_HISTORY_ITEM_UPDATE: 'NTG_RDX_MSG_VIEWING_HISTORY_ITEM_UPDATE' = 'NTG_RDX_MSG_VIEWING_HISTORY_ITEM_UPDATE';
export const REDUX_MSG_VIEWING_HISTORY_RESET: 'NTG_RDX_MSG_VIEWING_HISTORY_RESET' = 'NTG_RDX_MSG_VIEWING_HISTORY_RESET';
export const REDUX_MSG_VIEWING_HISTORY_SET_ERROR: 'NTG_RDX_MSG_VIEWING_HISTORY_SET_ERROR' = 'NTG_RDX_MSG_VIEWING_HISTORY_SET_ERROR';
export const REDUX_MSG_VIEWING_HISTORY_UPDATE: 'NTG_RDX_MSG_VIEWING_HISTORY_UPDATE' = 'NTG_RDX_MSG_VIEWING_HISTORY_UPDATE';
export const REDUX_MSG_WISHLIST_ETAG_DELETE: 'NTG_RDX_MSG_WISHLIST_ETAG_DELETE' = 'NTG_RDX_MSG_WISHLIST_ETAG_DELETE';
export const REDUX_MSG_WISHLIST_RESET: 'NTG_RDX_MSG_WISHLIST_RESET' = 'NTG_RDX_MSG_WISHLIST_RESET';
export const REDUX_MSG_WISHLIST_SET_ERROR: 'NTG_RDX_MSG_WISHLIST_SET_ERROR' = 'NTG_RDX_MSG_WISHLIST_SET_ERROR';
export const REDUX_MSG_WISHLIST_UPDATE: 'NTG_RDX_MSG_WISHLIST_UPDATE' = 'NTG_RDX_MSG_WISHLIST_UPDATE';
