/* @flow */

import type { ChannelMap } from './Channel';
import type { NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS } from './ViewingHistory';

export enum FeedProviderKind {
  Aggregation,
  Local,
  NtgVideo,
}

export type NETGEM_API_V8_FEED_OP_FILTER = {|
  args?: Array<string | number | NETGEM_API_V8_FEED_OP_FILTER>,
  op: string,
|};

export type NETGEM_API_V8_FEED_SCORING_OP = {|
  args?: Array<string | number>,
  op: string,
  order?: 'asc' | 'desc',
|};

export type NETGEM_API_V8_FEED_SCORING = Array<NETGEM_API_V8_FEED_SCORING_OP>;

export type NETGEM_API_V8_FEED_OP_SLICE = [number, number];

export type NETGEM_API_V8_FEED_DESCRIPTION = {|
  filter?: NETGEM_API_V8_FEED_OP_FILTER | null,
  scoring?: NETGEM_API_V8_FEED_SCORING,
  provider: FeedProviderKind,
  queryType?: string,
  slice?: NETGEM_API_V8_FEED_OP_SLICE,
|};

export type ScoreContextType = {|
  channels?: ChannelMap,
  playedItemsMap?: NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS,
  sessionId?: string,
|};

export type FeedRequestFunction = (assetId: string, startDate: number, range: number, channelIds?: Array<string>, signal?: AbortSignal) => Promise<any>;
