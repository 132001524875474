/* @flow */

import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { type NETGEM_API_V8_RIGHTS, NETGEM_API_V8_RIGHTS_PLAYBACK } from '../../libs/netgemLibrary/v8/types/Rights';
import { PendingOperationKind, PendingOperationReason, storePendingOperation } from './pendingOperations';
import { getRequiredRights, isAccessGranted, showMissingSubscription } from './rights';
import type { CARD_DATA_MODAL_TYPE } from '../../components/modal/cardModal/CardModalConstsAndTypes';
import type { ChannelMap } from '../../libs/netgemLibrary/v8/types/Channel';
import { ItemContent } from '../../libs/netgemLibrary/v8/types/FeedItem';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_V8_AUTHENT_REALM } from '../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_METADATA_SCHEDULE_LOCATION } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { getChannelRights } from '../channel/helper';
import { logError } from '../debug/debug';

const isChannelPlaybackGranted: (isSubscriptionFeatureEnabled: boolean, channelRights: ?NETGEM_API_V8_RIGHTS, defaultRights: ?NETGEM_API_V8_RIGHTS, userRights: Array<string> | null) => boolean = (
  isSubscriptionFeatureEnabled,
  channelRights,
  defaultRights,
  userRights,
) => {
  if (!isSubscriptionFeatureEnabled) {
    // Subscription feature not enabled: no restriction
    return true;
  }

  const requiredRights = getRequiredRights(NETGEM_API_V8_RIGHTS_PLAYBACK, null, channelRights, defaultRights);
  return isAccessGranted(requiredRights, userRights);
};

const isPlaybackGranted: (
  isRegisteredAsGuest: boolean,
  isSubscriptionFeatureEnabled: boolean,
  cardData: CARD_DATA_MODAL_TYPE,
  authority?: NETGEM_API_V8_AUTHENT_REALM,
  contentType: ItemContent,
  location: ?NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
  channels: ChannelMap,
  commercialOffers: ?KeyValuePair<Array<string>>,
  defaultRights: ?NETGEM_API_V8_RIGHTS,
  userRights: Array<string> | null,
) => boolean = (isRegisteredAsGuest, isSubscriptionFeatureEnabled, cardData, authority, contentType, location, channels, commercialOffers, defaultRights, userRights) => {
  const data = {
    ...cardData,
    pendingOperation: {
      authority,
      contentType,
      operationType: PendingOperationKind.Play,
      reason: PendingOperationReason.RequireAccount,
    },
  };

  if (!location) {
    // Location is null
    logError('Playback error: no location');
    return false;
  }

  if (isRegisteredAsGuest) {
    // Guest mode: let's open the sign-in page
    storePendingOperation(data);
    Messenger.emit(MessengerEvents.SHOW_SIGN_IN, PendingOperationKind.Play);
    return false;
  }

  if (!isSubscriptionFeatureEnabled) {
    // Subscription feature not enabled: no restriction
    return true;
  }

  const { channelId } = location;
  const channelRights = getChannelRights(channels, channelId);
  const requiredRights = getRequiredRights(NETGEM_API_V8_RIGHTS_PLAYBACK, location, channelRights, defaultRights);
  if (isAccessGranted(requiredRights, userRights)) {
    // User has required rights
    return true;
  }

  // User rights do not meet required rights: open subscribe page and highlight matching commercial offer
  showMissingSubscription(data, requiredRights, commercialOffers);
  return false;
};

export { isChannelPlaybackGranted, isPlaybackGranted };
