/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_LOGIN } from '../../constants/';

const sendLoginRequest: (
  authDeviceUrl: string,
  upgradeDeviceUrl: string,
  applicationId: string,
  subscriberId: string,
  deviceKey: string,
  oem: ?string,
  version: ?string,
  isFirstToken: ?boolean,
  signal?: AbortSignal
) => RequestResponseMethodDefinitionType =
  (authDeviceUrl, upgradeDeviceUrl, applicationId, subscriberId, deviceKey, oem, version, isFirstToken, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> =>
    NetgemApiEmitter.emit(REDUX_MSG_REQUEST_LOGIN, {
      applicationId,
      authDeviceUrl,
      deviceKey,
      isFirstToken,
      oem,
      signal,
      subscriberId,
      upgradeDeviceUrl,
      version,
    });

export default sendLoginRequest;
