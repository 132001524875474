/* @flow */

import type { NETGEM_API_V8_AUTHENT_REALM } from './Realm';
import type { NETGEM_API_V8_CREDIT } from './Credit';
import type { NETGEM_API_V8_EPISODE_OF } from './EpisodeOf';
import type { NETGEM_API_V8_LANG } from './Lang';
import type { NETGEM_API_V8_PARENTAL_GUIDANCE } from './ParentalGuidance';
import type { NETGEM_API_V8_PLAYBACK_URL } from './MetadataSchedule';

export const METADATA_KIND_PROGRAM: 'program' = 'program';
export const METADATA_KIND_SERIES: 'series' = 'series';

// Cannot convert to Flow enum because it's used to refine types
export type MetadataKind = typeof METADATA_KIND_PROGRAM | typeof METADATA_KIND_SERIES;

export type NETGEM_API_V8_METADATA_PROVIDER_INFO = {|
  distributorId?: string,
  // TIT Id or VTI Id
  id: string,
  viewingHistoryId: string,
|};

export type NETGEM_API_V8_METADATA_IMAGE = {|
  id: string,
  size: {|
    height: number,
    width: number,
  |},
  tags?: Array<string>,
|};

type NETGEM_API_V8_METADATA_BASE = {|
  category: {|
    lang: string,
    value: string,
  |},
  credits?: Array<NETGEM_API_V8_CREDIT>,
  genres: {|
    lang: string,
    values: Array<string>,
  |},
  id: string,
  images: Array<NETGEM_API_V8_METADATA_IMAGE>,
  productionCountry: string,
  providerInfo: NETGEM_API_V8_METADATA_PROVIDER_INFO,
  releaseDate: string,
  synopses: Array<NETGEM_API_V8_LANG>,
  titles: Array<NETGEM_API_V8_LANG>,
  trailerUrl?: NETGEM_API_V8_PLAYBACK_URL,
|};

export type NETGEM_API_V8_METADATA_PROGRAM = {|
  ...NETGEM_API_V8_METADATA_BASE,
  authority?: NETGEM_API_V8_AUTHENT_REALM,
  duration: string,
  episodeOf?: NETGEM_API_V8_EPISODE_OF,
  memberOf: Array<any>,
  parentalGuidance?: NETGEM_API_V8_PARENTAL_GUIDANCE,
  type: typeof METADATA_KIND_PROGRAM,
|};

export type NETGEM_API_V8_METADATA_SERIES = {|
  ...NETGEM_API_V8_METADATA_BASE,
  relatedMaterial: Array<{|
    howRelated: string,
    url: string,
  |}>,
  type: typeof METADATA_KIND_SERIES,
|};

export type NETGEM_API_V8_METADATA = NETGEM_API_V8_METADATA_PROGRAM | NETGEM_API_V8_METADATA_SERIES;
