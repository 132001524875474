/* @flow */

export const SettledPromiseFulfilled = 'fulfilled';
export const SettledPromiseRejected = 'rejected';

export type SettledPromise =
  | {|
      +status: typeof SettledPromiseFulfilled,
      +value: any,
    |}
  | {|
      +reason: any,
      +status: typeof SettledPromiseRejected,
    |};

export type AllSettledPromises = Array<SettledPromise>;

// Used to ignore any promise return (success or failure) without any lint warning
const fireAndForget: (promise: Promise<void>) => void = (promise) => {
  promise
    .then(() => {
      /* Nothing to do */
    })
    .catch(() => {
      /* Nothing to do */
    });
};

export { fireAndForget };
