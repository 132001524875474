/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_PERSONAL_DATA_DELETE } from '../../constants';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

export const sendPersonalDataListDeleteRequest: (listId: string, itemId: ?string, eTag: ?string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (listId, itemId, eTag, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { personalDataListDeleteUrl },
    } = state;

    if (!personalDataListDeleteUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'personalDataListDeleteUrl' }));
    }

    const { authent, method } = personalDataListDeleteUrl;
    const uri = generateApiUrl(
      personalDataListDeleteUrl,
      {
        itemId,
        listId,
      },
      state
    );

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_PERSONAL_DATA_DELETE, {
      authent,
      eTag,
      method,
      signal,
      uri,
    });
  };
