/* @flow */

import type { NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_ENTITLEMENT_PARAM, StreamType } from './MetadataSchedule';
import { Drm } from '../../../../helpers/jsHelpers/Drm';
import { HttpMethod } from './HttpMethod';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_URL_LIFECYCLE } from '../../ntgEntitlement/types';
import type { NETGEM_API_V8_RIGHTS } from './Rights';

export const METRICS_ESTAT = 'Estat';
export type METRICS_TYPE = 'Estat';

export enum MetricsStreamType {
  Catchup = 'catchup',
  Live = 'live',
}

export enum MetricsProperties {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Stb = 'stb',
  Tablet = 'tablet',
}

export type METRICS_PARAMS = {|
  chId: string,
  cmsGR: ?string,
  cmsS1: ?string,
  cmsS2: ?string,
  cmsS3: ?string,
  cmsS4: ?string,
  cmsS5: ?string,
  cmsSN: ?string,
  dom: ?string,
  miCh: ?string,
  ml1: ?string,
  ml10: ?string,
  ml11: ?string,
  ml2: ?string,
  ml3: ?string,
  ml4: ?string,
  ml5: ?string,
  ml6: ?string,
  ml7: ?string,
  ml8: ?string,
  ml9: ?string,
  msCh: ?string,
  msCid: ?string,
  // MediametrieDiffusionMode
  msDm: ?string,
|};

export type METRICS_PROPERTIES = {|
  type: MetricsStreamType,
  device: MetricsProperties,
  serial: string,
  params: METRICS_PARAMS,
|};

type NETGEM_API_CHANNEL_INFO_METRICS = {|
  type: METRICS_TYPE,
  properties: Array<METRICS_PROPERTIES>,
|};

type NETGEM_API_CHANNEL_DRM_TYPE = {|
  ntgEntitlement: NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_ENTITLEMENT_PARAM,
  type: Drm,
|};

export type NETGEM_API_CHANNEL_PLAYBACK_PARAMS_ARG_TYPE = {|
  drms: Array<NETGEM_API_CHANNEL_DRM_TYPE>,
  path: string,
  type: StreamType,
|};

type NETGEM_API_CHANNEL_PLAYBACK_PARAMS_TYPE = {|
  name: string,
  value: {|
    args: Array<NETGEM_API_CHANNEL_PLAYBACK_PARAMS_ARG_TYPE>,
    op: string,
  |},
|};

type NETGEM_API_CHANNEL_PLAYBACK_TYPE = {|
  player: string,
  start: {|
    method: HttpMethod,
    params: Array<NETGEM_API_CHANNEL_PLAYBACK_PARAMS_TYPE>,
    redirect?: boolean,
    uri: string,
  |},
|};

type NETGEM_API_CHANNEL_URL_CONTENT_TYPE = {|
  playback?: NETGEM_API_CHANNEL_PLAYBACK_TYPE,
  playbacks?: Array<NETGEM_API_CHANNEL_PLAYBACK_TYPE>,
|};

export type NETGEM_API_CHANNEL_INFO = {|
  baseline?: string,
  channelGrId?: string,
  channelId: string,
  hasCatchup?: boolean,
  hasKeepFromReplay?: boolean,
  images?: Array<{ id: string }>,
  isRecordable?: boolean,
  languages?: Array<string>,
  metrics?: Array<NETGEM_API_CHANNEL_INFO_METRICS>,
  name?: string,
  owner?: string,
  rights?: NETGEM_API_V8_RIGHTS,
  tags?: Array<string>,
  urlContentType?: NETGEM_API_CHANNEL_URL_CONTENT_TYPE,
|};

export type NETGEM_API_CHANNEL = {|
  altimg: string,
  amsnumber: string,
  consolidated?: {|
    hasCatchup?: boolean,
    hasKeepFromReplay?: boolean,
    hasStartover?: boolean,
    hasTimeshift?: boolean,
    imageId: ?string,
    isRecordable?: boolean,
  |},
  data: {|
    npvr: {|
      hasCatchup: string,
      hasKeepFromReplay: string,
      hasStartover: string,
      isRecordable: string,
    |},
    stream: {|
      debug: string,
      drmType?: Drm,
      isSeekable: string,
      laUrl: string,
      msdrmCustomData: string,
      notrickmode: string,
      service: string,
      type?: StreamType,
    |},
    urlLifecycle?: NETGEM_API_URL_LIFECYCLE,
  |},
  epgid: string,
  id: string,
  img: string,
  info?: NETGEM_API_CHANNEL_INFO,
  isFastChannel: boolean,
  isHidden: boolean,
  name: string,
  number: number,
  template: string,
  url: string,
|};

export type ChannelMap = KeyValuePair<NETGEM_API_CHANNEL>;
