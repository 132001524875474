/* @flow */

export enum MediametrieDiffusion {
  Live = 'live',
  Replay = 'replay',
  Timeshifting = 'timeshifting',
}

/* eslint-disable no-magic-numbers */
export enum MediametrieState {
  Pause = 7,
  Play = 5,
  Stop = 12,
}
/* eslint-enable no-magic-numbers */

export enum MediametrieDiffusionMode {
  Aod = 'AOD',
  Live = 'LIVE',
  Svod = 'SVOD',
  Timeshifting = 'TIMESHIFTING',
  Tvod = 'TVOD',
  Vod = 'VOD',
}

export enum MediametrieStateEvent {
  Pause = 'pause',
  Play = 'play',
  Stop = 'stop',
}

export const DEFAULT_MEASURE: 'streaming' = 'streaming';

export type STREAM_ANALYTICS_CONFIGURATION_TYPE = {|
  consentString: ?string,
  consentType: ?string,
  domain: ?string,
  levels?: {|
    level_1?: string,
    level_2?: string,
    level_3?: string,
    level_4?: string,
    level_5?: string,
  |},
  measure: typeof DEFAULT_MEASURE,
  mediaInfo: {|
    mediaChannel: string,
    mediaContentId: ?string,
    // MediametrieDiffusionMode
    mediaDiffMode: string,
  |},
  netMeasurement?: string,
  newLevels?: {|
    newLevel_1?: string,
    newLevel_10?: string,
    newLevel_11?: string,
    newLevel_2?: string,
    newLevel_3?: string,
    newLevel_4?: string,
    newLevel_5?: string,
    newLevel_6?: string,
    newLevel_7?: string,
    newLevel_8?: string,
    newLevel_9?: string,
  |},
  serial: string,
  streaming: {|
    callbackPosition: () => number,
    // Returns a MediametrieState
    callbackState: () => number,
    // MediametrieDiffusion
    diffusion: string,
    duration?: number,
    playerName: string,
    playerVersion: string,
    probe: boolean,
    streamGenre?: string,
    streamName: string,
  |},
|};
