/* @flow */

import './AuthenticationRequiredModal.css';
import * as React from 'react';
import ButtonFX from '../../buttons/ButtonFX';
import { Localizer } from '@ntg/utils/dist/localization';
import Modal from '../modal';
import { ModalIcon } from '../modalTypes';
import type { ModalState } from '../../../redux/modal/reducers';
import { WidthKind } from '../../buttons/types';

class AuthenticationRequiredModal extends React.PureComponent<ModalState> {
  modal: React.ElementRef<any> | null;

  constructor(...args: Array<any>) {
    super(...args);

    this.modal = null;
  }

  handleOnClick: () => void = () => {
    const { modal } = this;

    if (modal) {
      modal.close();
    }
  };

  render(): React.Node {
    const { index } = this.props;

    return (
      <Modal
        canCloseOnEnter={false}
        canCloseOnEscape={false}
        canCloseOnOverlayClick={false}
        className='authenticationRequired'
        header={Localizer.localize('modal.authentication_required.title')}
        icon={ModalIcon.Info}
        index={index}
        isCloseButtonDisplayed={false}
        ref={(instance) => {
          this.modal = instance;
        }}
      >
        <div className='authenticationRequiredContent'>
          <div>{Localizer.localize('modal.authentication_required.message')}</div>
          <ButtonFX onClick={this.handleOnClick} widthKind={WidthKind.Stretched}>
            {Localizer.localize('common.actions.ok')}
          </ButtonFX>
        </div>
      </Modal>
    );
  }
}

export default (AuthenticationRequiredModal: React.ComponentType<ModalState>);
