/* @flow */

import { ItemType, type NETGEM_API_V8_FEED, type NETGEM_API_V8_FEED_ITEM, type NETGEM_API_V8_FEED_RAW_ITEM, type NETGEM_API_V8_MIXED_FEED } from '../types/FeedItem';
import { LIVE_START_THRESHOLD, getIso8601DateInSeconds, getIso8601DurationInSeconds } from '../../../../helpers/dateTime/Format';
import { type NETGEM_API_V8_FEED_OP_FILTER, type NETGEM_API_V8_FEED_OP_SLICE, type NETGEM_API_V8_FEED_SCORING, type ScoreContextType } from '../types/Feed';
import { buildFeedItem, deepCopyItem, deepCopyRawItem, getLocationType, splitFeedItemByChannels } from './Item';
import { calculateLocationScore, getBestLocationForItem, setLocationsScore, updateSelectedLocation } from './Score';
import { compareElementScore, createFilterOperator, filterItem } from './Filter';
import AccurateTimestamp from '../../../../helpers/dateTime/AccurateTimestamp';
import { type ChannelMap } from '../types/Channel';
import { GroupItemsBy } from '../types/WidgetConfig';
import { type NETGEM_API_V8_RAW_FEED } from '../types/FeedResult';
import { type NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS } from '../types/ViewingHistory';

// Max items per section, after merge, in case no slice directive was given by PTF
const MAX_MERGED_FEED_ITEM = 500;

const deepCopyRawFeed: (feed: NETGEM_API_V8_RAW_FEED) => NETGEM_API_V8_RAW_FEED = (feed) => feed.map((item) => deepCopyRawItem(item));

const deepCopyFeed: (feed: $ReadOnlyArray<NETGEM_API_V8_FEED_ITEM>) => NETGEM_API_V8_FEED = (feed) => feed.map((item) => deepCopyItem(item));

const splitFeedByChannels: (feedIn: NETGEM_API_V8_RAW_FEED) => Map<string, NETGEM_API_V8_RAW_FEED> = (feedIn) => {
  const feedByChannel: Map<string, NETGEM_API_V8_RAW_FEED> = new Map();

  feedIn.forEach((item) => {
    splitFeedItemByChannels(item).forEach(({ channelId, rawItem }) => {
      let f = feedByChannel.get(channelId);

      if (!f) {
        f = [];
        feedByChannel.set(channelId, f);
      }

      f.push(rawItem);
    });
  });

  return feedByChannel;
};

const filterChannels: (feed: NETGEM_API_V8_FEED, allChannels: ChannelMap, sectionChannels: Set<string>) => void = (feed, allChannels, sectionChannels) => {
  for (let i = feed.length - 1; i >= 0; --i) {
    const {
      [i]: {
        selectedLocation: { channelId },
      },
    } = feed;

    if (!channelId || !sectionChannels.has(channelId)) {
      feed.splice(i, 1);
    }
  }
};

const filterLiveFeed: (feedIn: NETGEM_API_V8_RAW_FEED, channels: ChannelMap, sliceParams?: NETGEM_API_V8_FEED_OP_SLICE) => NETGEM_API_V8_FEED = (feedIn, channels, sliceParams) => {
  const feedOut = deepCopyRawFeed(feedIn);
  return filterFeed(feedOut, channels, { op: 'live' }, sliceParams);
};

const filterFeed: (feedIn: NETGEM_API_V8_RAW_FEED, channels: ChannelMap, filterParams: ?NETGEM_API_V8_FEED_OP_FILTER, sliceParams?: NETGEM_API_V8_FEED_OP_SLICE) => NETGEM_API_V8_FEED = (
  feedIn,
  channels,
  filterParams,
  sliceParams,
) => {
  const { length: feedCount = 0 } = feedIn;
  if (feedCount === 0) {
    return [];
  }

  // Prepare filtering
  const filterOperator = filterParams ? createFilterOperator(filterParams, channels) : null;
  const processItem = filterOperator ? (item: NETGEM_API_V8_FEED_RAW_ITEM) => filterItem(item, filterOperator) : (item) => item;

  const feedOut: NETGEM_API_V8_FEED = [];

  for (let i = 0; i < feedCount; i++) {
    const { [i]: item } = feedIn;
    const { elements, locations } = item;

    if (elements || locations) {
      const filteredItem = processItem(item);

      if (filteredItem) {
        const feedItem = buildFeedItem(filteredItem);

        if (feedItem) {
          feedOut.push(feedItem);
        }
      }
    }
  }

  sortFeed(feedOut);

  if (Array.isArray(sliceParams)) {
    const [min, max] = sliceParams;
    if (typeof min === 'number' && typeof max === 'number') {
      return feedOut.slice(min, max);
    }
  }

  return feedOut;
};

const filterViewingHistoryFeed: (feed: NETGEM_API_V8_FEED, playedItemsMap: NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS, playedSeries: Set<string>) => NETGEM_API_V8_FEED = (
  feed,
  playedItemsMap,
  playedSeries,
) => {
  const validFeed = [];

  feed.forEach((item) => {
    const {
      selectedLocation: { id = '' },
      seriesId,
    } = item;

    if ((id && seriesId && playedSeries.has(seriesId)) || playedItemsMap[id]) {
      // Item found
      validFeed.push(item);
    }
  });

  return validFeed;
};

const sliceFeed: (feed: NETGEM_API_V8_FEED, sliceOp: ?NETGEM_API_V8_FEED_OP_SLICE) => NETGEM_API_V8_FEED = (feed, sliceOp) => {
  const slicedFeed = sliceOp ? feed.slice(...sliceOp) : feed;
  return slicedFeed.length > MAX_MERGED_FEED_ITEM ? slicedFeed.slice(0, MAX_MERGED_FEED_ITEM) : slicedFeed;
};

/*
 * Merge items of various feeds, per score. And keep only N items if limit is given.
 * /!\ This function assumes items are already sorted per score inside feeds and locations.
 */
const mergeFeeds: (feeds: Array<NETGEM_API_V8_FEED>, scoring?: NETGEM_API_V8_FEED_SCORING, context?: ScoreContextType, sliceOp: ?NETGEM_API_V8_FEED_OP_SLICE) => NETGEM_API_V8_FEED = (
  feeds,
  scoring,
  context,
  sliceOp,
) => {
  const { length: feedsCount } = feeds;

  if (feedsCount === 0) {
    // No feed
    return [];
  }

  // Only one feed
  if (feedsCount === 1) {
    return sliceFeed(feeds[0], sliceOp);
  }

  // More than one feed: let's merge them

  // Aggregate all feeds
  const aggregatedFeed: NETGEM_API_V8_FEED = [].concat(...feeds);

  // Sort items since several feeds have been merged
  sortFeed(aggregatedFeed, scoring, context);

  // Remove duplicates (keep only first occurrences)
  const map: Map<string, NETGEM_API_V8_FEED_ITEM> = aggregatedFeed.reduce((m, item) => {
    const key = item.id;
    return m.has(key) ? m : m.set(key, item);
  }, new Map());

  return sliceFeed([...map.values()], sliceOp);
};

const setFeedScore: (feed: NETGEM_API_V8_FEED, scoring: NETGEM_API_V8_FEED_SCORING, context?: ScoreContextType) => NETGEM_API_V8_FEED = (feed, scoring, context) => {
  const clonedFeed: NETGEM_API_V8_FEED = deepCopyFeed(feed);
  const stack: NETGEM_API_V8_MIXED_FEED = [...clonedFeed];

  while (stack.length > 0) {
    const rawItem = stack.pop();

    // Since flowjs v0.239.0, "rawItem" is said to be potentially undefined (which is impossible, here)
    if (!rawItem) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { elements } = rawItem;

    if (elements) {
      elements.forEach((e) => stack.unshift(e));
    } else {
      const { locations } = rawItem;
      if (locations) {
        setLocationsScore(locations, scoring, context);
      }
    }
  }

  updateSelectedLocation(clonedFeed);
  sortFeed(clonedFeed);

  return clonedFeed;
};

const sortFeed: (feed: NETGEM_API_V8_FEED, scoring?: NETGEM_API_V8_FEED_SCORING, context?: ScoreContextType) => void = (feed, scoring, context) => {
  for (let i = 0; i < feed.length; i++) {
    const item = feed[i];
    const { selectedLocation } = item;
    if (selectedLocation.id !== '') {
      if (scoring) {
        selectedLocation.score = calculateLocationScore(selectedLocation, scoring, context);
      }
      item.score = selectedLocation.score;
    }
  }
  feed.sort(compareElementScore);
};

const isItemLiveOrAboutToStart: (item: NETGEM_API_V8_FEED_ITEM) => boolean = (item) => {
  const now = AccurateTimestamp.nowInSeconds();
  return item.startTime <= now + LIVE_START_THRESHOLD && now < item.endTime;
};

const flattenFeedIfRequested: (inFeed: NETGEM_API_V8_FEED, groupItemsBy: ?GroupItemsBy) => NETGEM_API_V8_FEED = (inFeed, groupItemsBy) => {
  if (!groupItemsBy || groupItemsBy !== GroupItemsBy.Program || inFeed.length === 0) {
    // Series or season
    return inFeed;
  }

  // Program: let's flatten the list
  const outFeed: NETGEM_API_V8_FEED = [];
  let queue: NETGEM_API_V8_MIXED_FEED = [...inFeed];

  while (queue.length > 0) {
    const item = queue.shift();

    // Since flowjs v0.239.0, "item" is said to be potentially undefined (which is impossible, here)
    if (!item) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { elements, seriesId } = item;

    if (elements) {
      // Enqueue elements' items in order to treat them later
      queue = [...elements, ...queue];
    } else {
      // No more elements: treat locations
      const { location, programId } = getBestLocationForItem(item);

      if (location) {
        const { duration, id, score, startDate } = location;
        const startTime = getIso8601DateInSeconds(startDate);
        const endTime = startTime + getIso8601DurationInSeconds(duration);

        const newItem: NETGEM_API_V8_FEED_ITEM = {
          endTime,
          id: item.id,
          locType: getLocationType(id),
          selectedLocation: location,
          selectedProgramId: programId,
          seriesId,
          startTime,
          type: ItemType.Program,
        };

        const { locations, purchasable, score: itemScore } = item;

        if (locations) {
          newItem.locations = locations;
        }

        if (purchasable) {
          newItem.purchasable = purchasable;
        }

        if (itemScore) {
          newItem.score = score;
        }

        outFeed.push(newItem);
      }
    }
  }

  // Each season is made of a sorted feed but flattening all episodes does not keep order
  sortFeed(outFeed);

  return outFeed;
};

const getAllLocationIds: (feed: NETGEM_API_V8_FEED) => Set<string> = (feed) => {
  const locationIds = new Set<string>();
  const stack: NETGEM_API_V8_MIXED_FEED = [...feed];

  while (stack.length > 0) {
    const rawItem = stack.pop();

    // Since flowjs v0.239.0, "rawItem" is said to be potentially undefined (which is impossible, here)
    if (!rawItem) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { elements, locations } = rawItem;

    if (elements) {
      elements.forEach((element) => stack.unshift(element));
    } else if (locations) {
      locations.forEach((location) => {
        if (location.id) {
          locationIds.add(location.id);
        }
      });
    }
  }

  return locationIds;
};

export {
  filterChannels,
  filterFeed,
  filterLiveFeed,
  filterViewingHistoryFeed,
  flattenFeedIfRequested,
  getAllLocationIds,
  isItemLiveOrAboutToStart,
  mergeFeeds,
  setFeedScore,
  sortFeed,
  splitFeedByChannels,
};
