/* @flow */

import './PlaceholderVodEpisode.css';
import * as React from 'react';
import { PictoArrowDown } from '@ntg/components/dist/pictos/Element';

type PlaceholderVodEpisodePropType = {|
  +index: number,
|};

class PlaceholderVodEpisode extends React.PureComponent<PlaceholderVodEpisodePropType> {
  render(): React.Node {
    const { index } = this.props;

    return (
      <div className='episode vod'>
        <div className='header'>
          <div className='title'>
            {index + 1} - <div className='empty' />
          </div>
          <div className='actions'>
            <div className='pricingLink' />
            <div className='pricingLink' />
            <PictoArrowDown className='arrow' />
          </div>
        </div>
      </div>
    );
  }
}

export default PlaceholderVodEpisode;
