/* @flow */

import { ItemType, type NETGEM_API_V8_FEED_ITEM } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { type NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE, TileConfigTileType } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { CARD_DATA_MODAL_TYPE } from '../../../components/modal/cardModal/CardModalConstsAndTypes';
import type { NETGEM_API_V8_AUTHENT_REALM } from '../../../libs/netgemLibrary/v8/types/Realm';

export enum ExtendedItemType {
  OfflineContent,
  Trailer,
  TV,
  VOD,
}

export type EXTENDED_ITEM = {|
  authority?: NETGEM_API_V8_AUTHENT_REALM,
  cardData?: CARD_DATA_MODAL_TYPE | null,
  distributorId?: string | null,
  item: NETGEM_API_V8_FEED_ITEM,
  locationId?: string,
  onItemClick?: NETGEM_API_V8_WIDGET_ITEM_CLICK_TYPE,
  openFromCard?: boolean,
  previewCatchupScheduledEventId?: string | null,
  programMetadata?: NETGEM_API_V8_METADATA_PROGRAM | null,
  seriesMetadata?: NETGEM_API_V8_METADATA_SERIES | null,
  tileType?: TileConfigTileType,
  type: ExtendedItemType,
  viewingHistoryId?: string | null,
  vtiId?: number | null,
|};

export const FAKE_EPG_LIVE_ITEM_TYPE: ItemType = ItemType.FakeItem;
export const FAKE_LIVE_CHANNEL_ITEM_TYPE: ItemType = ItemType.Channel;

export const FAKE_EPG_LIVE_PREFIX = `${(FAKE_EPG_LIVE_ITEM_TYPE: string)}://`;
export const FAKE_LIVE_CHANNEL_PREFIX = `${(FAKE_LIVE_CHANNEL_ITEM_TYPE: string)}://`;
