/* @flow */

const SMART_BANNER_TAG_NAME = 'apple-itunes-app';

const updateSmartBannerTag: (url: string) => void = (url) => {
  const currentTag = document.querySelector(`head > meta[name=${SMART_BANNER_TAG_NAME}]`);
  if (!currentTag) {
    return;
  }

  const content = currentTag.getAttribute('content');
  if (!content) {
    return;
  }

  const tag = document.createElement('meta');
  tag.name = SMART_BANNER_TAG_NAME;
  tag.content = `${content}, app-argument=${url}`;

  // Remove current meta tag
  currentTag.remove();

  // Create meta tag with deeplink as argument
  document.head?.appendChild(tag);
};

export { updateSmartBannerTag };
