/* @flow */

import type { NETGEM_API_V8_ITEM_LOCATION_TYPE } from './FeedItem';

export const NETGEM_API_V8_RIGHTS_PLAYBACK: 'playback' = 'playback';
export const NETGEM_API_V8_RIGHTS_RECORD: 'record' = 'record';
export const NETGEM_API_V8_RIGHTS_VIEWINGHISTORY: 'viewingHistory' = 'viewingHistory';
export const NETGEM_API_V8_RIGHTS_WISHLIST: 'wishList' = 'wishList';

export type NETGEM_API_V8_RIGHTS_KIND = typeof NETGEM_API_V8_RIGHTS_PLAYBACK | typeof NETGEM_API_V8_RIGHTS_RECORD | typeof NETGEM_API_V8_RIGHTS_VIEWINGHISTORY | typeof NETGEM_API_V8_RIGHTS_WISHLIST;

export type NETGEM_API_V8_RIGHTS_LIST = ?Array<string>;

export type NETGEM_API_V8_LOCATION_RIGHTS = {|
  [NETGEM_API_V8_RIGHTS_KIND]: NETGEM_API_V8_RIGHTS_LIST,
|};

export type NETGEM_API_V8_RIGHTS = {|
  playback: ?{|
    default?: NETGEM_API_V8_RIGHTS_LIST,
    [NETGEM_API_V8_ITEM_LOCATION_TYPE]: NETGEM_API_V8_RIGHTS_LIST,
  |},
  record?: NETGEM_API_V8_RIGHTS_LIST,
  viewingHistory?: NETGEM_API_V8_RIGHTS_LIST,
  wishList?: NETGEM_API_V8_RIGHTS_LIST,
|};
