/* @flow */

import type { KeyValuePair } from '@ntg/utils/dist/types';

export enum SentryLevel {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export enum SentryTagName {
  Component = 'component',
  Session = 'session',
  SubscriberId = 'subscriberId',
}

export enum SentryTagValue {
  DataCollect = 'dataCollect',
  PaymentSystem = 'paymentSystem',
  Player = 'player',
  Recorder = 'recorder',
  VodPurchase = 'vodPurchase',
  Xhr = 'xhr',
}

export type SentryDataType = {|
  breadcrumbs?: Array<string>,
  context?: KeyValuePair<any>,
  error?: Error,
  message?: string,
  tagName?: SentryTagName,
  tagValue?: SentryTagValue,
|};
