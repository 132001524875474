/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_GET_DEVICE_CHANNELS } from '../../constants/';

const sendGetDeviceChannelListRequest: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> =>
    NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GET_DEVICE_CHANNELS, { signal });

export default sendGetDeviceChannelListRequest;
