/* @flow */

import { DAYS_PER_MONTH, HOURS_PER_DAY, MILLISECONDS_PER_SECOND, MINUTES_PER_HOUR, MONTHS_PER_YEAR, SECONDS_PER_MINUTE } from '../../../../helpers/dateTime/Format';
import { roundDownTo, roundUpTo } from '../../../../helpers/maths/maths';
import AccurateTimestamp from '../../../../helpers/dateTime/AccurateTimestamp';

/*
 * Return rounded date ISO8601 string
 * @param timeRoundValue (in seconds)
 * @param [optionalDate] (in milliseconds)
 * @return string
 */
const getRoundedDateToISOString: (rounding?: number, date?: number, offset?: number) => string = (rounding, date, offset) => {
  let time = date ?? AccurateTimestamp.now();

  if (rounding && rounding > 0) {
    time = roundDownTo(time, rounding * MILLISECONDS_PER_SECOND);
  }

  if (offset && offset > 0) {
    time += offset * MILLISECONDS_PER_SECOND;
  }

  const nbCharToSlice = 5;
  return `${new Date(time).toISOString().slice(0, -nbCharToSlice)}Z`;
};

const getRoundedNowToISOString: (rounding?: number, offset?: number) => string = (rounding, offset) => getRoundedDateToISOString(rounding, undefined, offset);

const stringToInt = (s: string) => parseInt(s, 10) || 0;

const addISO8601Duration: (date: string, iso8601Duration: string) => Date = (date, iso8601Duration) => {
  const regExpResult: Array<string> | null = /^P([0-9]+Y)?([0-9]+M)?([0-9]+D)?T?([0-9]+H)?([0-9]+M)?([0-9]+(?:\.[0-9]+)?S)?$/u.exec(iso8601Duration);

  const d = new Date(date);

  if (regExpResult) {
    const [, years, months, days, hours, minutes, seconds] = regExpResult;

    d.setSeconds(d.getSeconds() + stringToInt(seconds));
    d.setMinutes(d.getMinutes() + stringToInt(minutes));
    d.setHours(d.getHours() + stringToInt(hours));
    d.setFullYear(d.getFullYear() + stringToInt(years), d.getMonth() + stringToInt(months), d.getDate() + stringToInt(days));
  }

  return d;
};

/*
 * Return rounded duration ISO8601 string
 * @param duration (in milliseconds)
 * @param timeRoundValue (in seconds)
 * @return string
 */
const getRoundedDurationToISOString: (duration: number, timeRoundValue: number) => string = (duration, timeRoundValue) => {
  let d = Math.floor(duration / MILLISECONDS_PER_SECOND);
  let sign = '';

  if (d < 0) {
    sign = '-';
    d = -d;
  }

  if (timeRoundValue > 0) {
    d = roundUpTo(d, timeRoundValue);
  }

  const seconds = d % SECONDS_PER_MINUTE;
  const S = `${seconds}S`;
  d = (d - seconds) / SECONDS_PER_MINUTE;

  const minutes = d % MINUTES_PER_HOUR;
  const MI = minutes ? `${minutes}M` : '';
  d = (d - minutes) / MINUTES_PER_HOUR;

  const hours = d % HOURS_PER_DAY;
  const H = hours ? `${hours}H` : '';
  d = (d - hours) / HOURS_PER_DAY;

  const days = d % DAYS_PER_MONTH;
  const D = days ? `${days}D` : '';
  d = (d - days) / DAYS_PER_MONTH;

  const months = d % MONTHS_PER_YEAR;
  const MO = months ? `${months}M` : '';

  const years = (d - months) / MONTHS_PER_YEAR;
  const Y = years ? `${years}Y` : '';

  let HMS = `${H}${MI}${S}`;
  if (HMS !== '') HMS = `T${HMS}`;
  return `${sign}P${Y}${MO}${D}${HMS}`;
};

export { addISO8601Duration, getRoundedDateToISOString, getRoundedDurationToISOString, getRoundedNowToISOString };
