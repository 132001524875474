/* @flow */

import type { ShakaOfflineContent, ShakaOfflineExtraMetadata, ShakaOfflineMetadata, ShakaOfflineProgressUpdate, ShakaOfflineTrackSelection } from './shakaTypes';
import { logError } from '../../../helpers/debug/debug';
import shaka from 'shaka-player/dist/shaka-player.compiled';

class ShakaStorage {
  // $FlowFixMe: not Flow-typed
  static storage: shaka.offline.Storage | null = null;

  static inProgress: Map<string, any> = new Map();

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'ShakaStorage';
  }

  // $FlowFixMe: not Flow-typed
  static getInstance: () => shaka.offline.Storage = () => {
    if (ShakaStorage.storage === null) {
      ShakaStorage.storage = new shaka.offline.Storage();
    }

    return ShakaStorage.storage;
  };

  static listContent: () => Promise<Array<ShakaOfflineContent> | null> = async () => {
    try {
      return await ShakaStorage.getInstance().list();
    } catch (error) {
      logError(`Error retrieving offline content: ${error.message}`);
      return null;
    }
  };

  static exists: (manifestUrl: string) => Promise<boolean> = async (manifestUrl) => {
    const allContent = await ShakaStorage.listContent();
    if (allContent === null) {
      return false;
    }

    return allContent.some(({ originalManifestUri }) => originalManifestUri === manifestUrl);
  };

  // $FlowFixMe: not Flow-typed
  static loadContent: (player: shaka.Player, offlineUri: string) => Promise<boolean> = async (player, offlineUri) => {
    try {
      await player.load(offlineUri);
      return true;
    } catch (error) {
      logError(`Error playing offline content: ${error.message}`);
      return false;
    }
  };

  static removeContent: (offlineUri: string) => Promise<boolean> = async (offlineUri) => {
    try {
      await ShakaStorage.getInstance().remove(offlineUri);
      return true;
    } catch (error) {
      logError(`Error removing offline content: ${error.message}`);
      return false;
    }
  };

  static removeAllContent: () => Promise<boolean> = async () => {
    try {
      const allContent = await ShakaStorage.listContent();
      if (allContent === null) {
        return false;
      }

      await Promise.all(allContent.map(async ({ offlineUri }) => await ShakaStorage.removeContent(offlineUri)));
      return true;
    } catch (error) {
      logError(`Error removing all offline content: ${error.message}`);
      return false;
    }
  };

  // Passing the current player allows the storage to use the same network engine, hence correctly handling DRM-protected content
  static downloadContent: (
    // $FlowFixMe: not Flow-typed
    player: shaka.Player,
    id: string,
    manifestUri: string,
    metadata: ShakaOfflineExtraMetadata,
    selectTracks: ShakaOfflineTrackSelection,
    updateProgress: ShakaOfflineProgressUpdate,
  ) => Promise<any> = (player, id, manifestUri, metadata, selectTracks, updateProgress) => {
    const storage = new shaka.offline.Storage(player);

    storage.configure({
      offline: {
        progressCallback: updateProgress,
        trackSelectionCallback: selectTracks,
      },
    });

    ShakaStorage.storage = storage;

    const appMetadata: ShakaOfflineMetadata = {
      ...metadata,
      downloadTime: Date.now(),
      id,
    };

    const downloadOp = storage.store(manifestUri, appMetadata);
    ShakaStorage.inProgress.set(id, downloadOp);

    return downloadOp.promise.finally(() => ShakaStorage.inProgress.delete(id));
  };

  static cancelDownload: (id: string) => void = (id) => {
    const downloadOp = ShakaStorage.inProgress.get(id);

    if (downloadOp) {
      downloadOp.abort();
    }
  };
}

export default ShakaStorage;
