/* @flow */

import {
  type BOApiUrlDefinition,
  BO_API_ADD_FAVORITE,
  BO_API_CREATE_STREAM2,
  BO_API_DELETE_FAVORITE,
  BO_API_FAVORITE_LIST,
  BO_API_GET_STREAMS,
  BO_API_PERSONAL_DATA,
  BO_API_PURCHASE,
  BO_API_PURCHASE_LIST,
  BO_API_PURCHASE_STATUS,
  BO_API_START_STREAM,
  BO_API_STOP_STREAM,
} from './types/common';
import type { BO_API_DISCOVERY, BO_API_SETTING } from '../../../../libs/netgemLibrary/videofutur/types/Discovery';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import { buildMissingParametersError, logError } from '../../../../helpers/debug/debug';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { HttpMethod } from '../../../../libs/netgemLibrary/v8/types/HttpMethod';
import { REDUX_MSG_REQUEST_BO_DISCOVERY } from '../../constants';
import { addDistributor } from '../index';
import { buildUrl } from '../../../../libs/netgemLibrary/videofutur/requests/bo';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { getBOSetting } from '../helpers/boSettings';
import { getSettingValueByName } from '../helpers/settings';

const mapResponseToService: (response: any) => ?BO_API_DISCOVERY = (response) => {
  if (!response) {
    return null;
  }

  const { service } = response;

  if (!service) {
    return null;
  }

  const {
    customerId: { _text: customerId },
    product_id: product,
    settings: { url: urlList },
  } = service;

  let productId = '';
  if (product) {
    ({ _text: productId } = product);
  }

  return {
    customerId,
    productId,
    settings: urlList.map((u) => {
      const { type, _text: url } = u;
      return {
        type,
        url,
      };
    }),
  };
};

const createApiUrl: (baseUrl: string, identity: string | null, queryStringParameters: ?Array<string>) => string = (baseUrl, identity, queryStringParameters) => {
  let url: string = `${baseUrl}?identity=${identity ?? ''}`;

  if (queryStringParameters) {
    queryStringParameters.forEach((param) => {
      url += `&${param}={${param}}`;
    });
  }

  return url;
};

const buildUrlDefinition: (
  identity: string | null,
  settings: Array<BO_API_SETTING>,
  apiName: string,
  method: HttpMethod,
  useBOV2Api: boolean,
  queryStringParameters?: Array<string>,
  pathParameters?: Array<string>,
) => BOApiUrlDefinition | null = (identity, settings, apiName, method, useBOV2Api, queryStringParameters, pathParameters) => {
  const setting = settings.find((s) => s.type === apiName);
  if (!setting) {
    return null;
  }

  let { url } = setting;

  // Add parameters in path
  if (!useBOV2Api && pathParameters) {
    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }

    pathParameters.forEach((p) => {
      url += `/{${p}}`;
    });
  }

  return {
    authent: useBOV2Api,
    method,
    params: queryStringParameters ?? [],
    uri: useBOV2Api ? url : createApiUrl(url, identity, queryStringParameters),
  };
};

const sendDiscoveryRequestForDistributor: (distributor: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (distributor, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration,
      appConfiguration: {
        configuration: { boV2ServiceInfoUrl },
        useBOV2Api,
      },
    } = getState();
    const baseurl = getSettingValueByName('platform.vf', 'baseurl', appConfiguration);
    const discoveryEndpoint = getSettingValueByName('platform.vf', 'discovery', appConfiguration);
    const device = getSettingValueByName('platform.vf', 'device', appConfiguration);
    const identity = getBOSetting('identity', appConfiguration);

    // Check parameters
    if (!baseurl || !device || !discoveryEndpoint || (!identity && !useBOV2Api)) {
      return Promise.reject(
        buildMissingParametersError(
          {
            baseurl,
            device,
            discoveryEndpoint,
            identity,
          },
          'BO discovery',
        ),
      );
    }

    const discoveryUrl = useBOV2Api && boV2ServiceInfoUrl ? boV2ServiceInfoUrl : buildUrl(discoveryEndpoint, { baseurl });

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_DISCOVERY, {
      device,
      discoveryUrl,
      distributor,
      identity,
      signal,
      useBOV2Api,
    })
      .then((response) => {
        const service = useBOV2Api ? response : mapResponseToService(response);

        if (!service) {
          return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definitions_for_distributor', { distributorId: distributor }));
        }

        const { settings } = service;

        const distributorDef = {
          urls: {
            [BO_API_ADD_FAVORITE]: buildUrlDefinition(identity, settings, BO_API_ADD_FAVORITE, useBOV2Api ? HttpMethod.POST : HttpMethod.PUT, useBOV2Api),
            [BO_API_CREATE_STREAM2]: buildUrlDefinition(identity, settings, BO_API_CREATE_STREAM2, useBOV2Api ? HttpMethod.POST : HttpMethod.PUT, useBOV2Api, ['vtiId']),
            [BO_API_DELETE_FAVORITE]: buildUrlDefinition(identity, settings, BO_API_DELETE_FAVORITE, HttpMethod.DELETE, useBOV2Api),
            [BO_API_FAVORITE_LIST]: buildUrlDefinition(identity, settings, BO_API_FAVORITE_LIST, HttpMethod.GET, useBOV2Api),
            [BO_API_GET_STREAMS]: buildUrlDefinition(identity, settings, BO_API_GET_STREAMS, HttpMethod.GET, useBOV2Api),
            [BO_API_PERSONAL_DATA]: buildUrlDefinition(identity, settings, BO_API_PERSONAL_DATA, HttpMethod.GET, useBOV2Api, []),
            [BO_API_PURCHASE]: buildUrlDefinition(identity, settings, BO_API_PURCHASE, useBOV2Api ? HttpMethod.POST : HttpMethod.GET, useBOV2Api, ['vtiId']),
            [BO_API_PURCHASE_LIST]: buildUrlDefinition(identity, settings, BO_API_PURCHASE_LIST, HttpMethod.GET, useBOV2Api),
            [BO_API_PURCHASE_STATUS]: buildUrlDefinition(identity, settings, BO_API_PURCHASE_STATUS, useBOV2Api ? HttpMethod.POST : HttpMethod.GET, useBOV2Api, ['vtiId']),
            [BO_API_START_STREAM]: buildUrlDefinition(identity, settings, BO_API_START_STREAM, HttpMethod.POST, useBOV2Api, undefined, ['streamId']),
            [BO_API_STOP_STREAM]: buildUrlDefinition(identity, settings, BO_API_STOP_STREAM, useBOV2Api ? HttpMethod.POST : HttpMethod.DELETE, useBOV2Api, undefined, ['streamId']),
          },
        };

        dispatch(addDistributor(distributor, distributorDef));
        return Promise.resolve(distributorDef);
      })
      .catch(() => logError(`Unknown distributor: ${distributor}`));
  };

export default sendDiscoveryRequestForDistributor;
