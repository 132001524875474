/* @flow */

import type { DmsSettingMap, NETGEM_API_DMS_DEVICE_INFO_SETTINGS } from '../../../../libs/netgemLibrary/dms/types/DeviceInfoSettings';
import type { AppConfigurationState } from '../../../appConf/reducers';

const getSettingValueByName: (settingId: string, settingName: string, appConfiguration: AppConfigurationState) => string | null = (settingId, settingName, appConfiguration) => {
  const { deviceSettings } = appConfiguration;
  return getSettingValueByNameFromDeviceSettings(deviceSettings, settingId, settingName);
};

const getSettingValueByNameFromDeviceSettings: (deviceSettings: DmsSettingMap, settingId: string, settingName: string) => string | null = (deviceSettings, settingId, settingName) => {
  const { [settingId]: settings } = deviceSettings;

  if (settings) {
    const setting = settings.find((s) => s.name === settingName);
    if (setting) {
      return setting.value;
    }
  }

  return null;
};

const getSettingSection: (settingId: string, appConfiguration: AppConfigurationState) => ?Array<NETGEM_API_DMS_DEVICE_INFO_SETTINGS> = (settingId, appConfiguration) => {
  const { deviceSettings } = appConfiguration;

  return deviceSettings[settingId];
};

export { getSettingSection, getSettingValueByName, getSettingValueByNameFromDeviceSettings };
