/* @flow */

import type { NETGEM_FUTURE_RECORDINGS_STATUS, NETGEM_RECORDINGS_MAP, NETGEM_SCHEDULED_RECORDINGS_MAP } from '../../../libs/netgemLibrary/v8/types/Npvr';
import {
  REDUX_MSG_NPVR_RECORDINGS_FUTURE_UPDATE,
  REDUX_MSG_NPVR_RECORDINGS_LIST_UPDATE,
  REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_UPDATE,
  REDUX_MSG_NPVR_STATE_UPDATE,
  REDUX_MSG_QUOTA_PVR_UPDATE,
} from '../constants';
import type { ETAG_MAP } from '../../../libs/netgemLibrary/v8/types/ETag';
import type { NETGEM_API_DMS_QUOTA_PVR } from '../../../libs/netgemLibrary/dms/types/QuotaPvr';
import type { NETGEM_API_V8_RAW_FEED } from '../../../libs/netgemLibrary/v8/types/FeedResult';
import type { NpvrAction } from '../actions';
import { produce } from 'immer';

export type NpvrState = {|
  eTagsCache: ETAG_MAP,
  endMargin: number,
  isEnabled: boolean,
  keepFromReplay: boolean,
  npvrRecordingsFuture: NETGEM_RECORDINGS_MAP,
  npvrRecordingsFutureFeed: NETGEM_API_V8_RAW_FEED,
  npvrRecordingsFutureStatus: ?NETGEM_FUTURE_RECORDINGS_STATUS,
  npvrRecordingsList: NETGEM_RECORDINGS_MAP,
  npvrRecordingsListFeed: NETGEM_API_V8_RAW_FEED,
  npvrScheduledRecordingsList: NETGEM_SCHEDULED_RECORDINGS_MAP,
  quotaPvr: ?NETGEM_API_DMS_QUOTA_PVR,
  recFromBeginning: boolean,
  startMargin: number,
|};

const InitialState: NpvrState = {
  eTagsCache: {},
  endMargin: 0,
  isEnabled: false,
  keepFromReplay: false,
  npvrRecordingsFuture: {},
  npvrRecordingsFutureFeed: [],
  npvrRecordingsFutureStatus: null,
  npvrRecordingsList: {},
  npvrRecordingsListFeed: [],
  npvrScheduledRecordingsList: {},
  quotaPvr: null,
  recFromBeginning: false,
  startMargin: 0,
};

const DefaultAction: NpvrAction = { type: null };

const NpvrReducer: (state: NpvrState, action: NpvrAction) => NpvrState = produce((draft = InitialState, action = DefaultAction) => {
  switch (action.type) {
    case REDUX_MSG_NPVR_RECORDINGS_LIST_UPDATE: {
      draft.eTagsCache.npvrRecordingsList = action.npvrRecordingsListETag;
      draft.npvrRecordingsList = action.npvrRecordingsList;
      draft.npvrRecordingsListFeed = action.npvrRecordingsListFeed;
      break;
    }

    case REDUX_MSG_NPVR_RECORDINGS_FUTURE_UPDATE: {
      draft.eTagsCache.npvrRecordingsFuture = action.npvrRecordingsFutureETag;
      draft.npvrRecordingsFuture = action.npvrRecordingsFuture;
      draft.npvrRecordingsFutureFeed = action.npvrRecordingsFutureFeed;
      draft.npvrRecordingsFutureStatus = action.npvrRecordingsFutureStatus;
      break;
    }

    case REDUX_MSG_NPVR_SCHEDULED_RECORDINGS_LIST_UPDATE: {
      draft.npvrScheduledRecordingsList = action.scheduledRecordingList;
      draft.eTagsCache.npvrScheduledRecordingsList = action.scheduledRecordingListETag;
      Object.entries(action.scheduledRecordingETagList).forEach(([key, value]) => (draft.eTagsCache[key] = value));
      break;
    }

    case REDUX_MSG_NPVR_STATE_UPDATE: {
      draft.endMargin = action.endMargin;
      draft.isEnabled = action.isEnabled;
      draft.keepFromReplay = action.keepFromReplay;
      draft.recFromBeginning = action.recFromBeginning;
      draft.startMargin = action.startMargin;
      break;
    }

    case REDUX_MSG_QUOTA_PVR_UPDATE: {
      draft.quotaPvr = action.quotaPvr;
      break;
    }

    default:
  }
}, InitialState);

export default NpvrReducer;
