/* @flow */

import appConfigurationReducer, { type AppConfigurationState } from '../appConf/reducers';
import appRegistrationReducer, { type AppRegistrationState } from '../appRegistration/reducers';
import modalReducer, { type AllModalsState } from '../modal/reducers';
import netgemApiReducer, { type NetgemApiState } from '../netgemApi/reducers';
import npvrReducer, { type NpvrState } from '../npvr/reducers';
import uiReducer, { type UIState } from '../ui/reducers';
import { combineReducers } from '@reduxjs/toolkit';

export type CombinedReducers = {|
  appConfiguration: AppConfigurationState,
  appRegistration: AppRegistrationState,
  modal: AllModalsState,
  netgemApi: NetgemApiState,
  npvr: NpvrState,
  ui: UIState,
|};

const allReducers: CombinedReducers = combineReducers({
  appConfiguration: appConfigurationReducer,
  appRegistration: appRegistrationReducer,
  modal: modalReducer,
  netgemApi: netgemApiReducer,
  npvr: npvrReducer,
  ui: uiReducer,
});

export default allReducers;
