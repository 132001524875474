/* @flow */

import type { BasicFunction, BasicPromiseFunction } from '@ntg/utils/dist/types';
import type { BO_API_ERROR_TYPE } from '../../../../redux/netgemApi/actions/videofutur/types/common';
import { CustomNetworkError } from '../CustomNetworkError';
import { PlayerError } from '../../../../helpers/playerError/PlayerError';
import { logError } from '../../../../helpers/debug/debug';

/*
 * Called from a catch() block to filter out cancelled promises (i.e. aborted signals)
 * If promise was cancelled, nothing is done
 * Otherwise, error is logged and given callback is called
 */
const ignoreIfAborted: (signal: AbortSignal, error: Error | PlayerError | CustomNetworkError | BO_API_ERROR_TYPE, callback?: BasicFunction | BasicPromiseFunction) => Promise<void> = (
  signal,
  error,
  callback,
) => {
  // $FlowFixMe: flow doesn't know DOMException
  if (signal.aborted || (error instanceof DOMException && error.name === 'AbortError')) {
    return Promise.resolve();
  }

  logError('Rejected promise:');
  logError(error instanceof CustomNetworkError ? error.networkError : error);

  if (!callback) {
    return Promise.resolve();
  }

  // Callback is only executed if promise has been rejected, but not cancelled
  const result = callback();

  if (result instanceof Promise) {
    // BasicPromiseFunction
    return result;
  }

  return Promise.resolve();
};

export { ignoreIfAborted };
