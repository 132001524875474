/* @flow */

import type { NETGEM_API_V8_METADATA_PROGRAM } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import type { NETGEM_API_V8_METADATA_SCHEDULE } from '../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_PURCHASE_INFO } from '../../../libs/netgemLibrary/v8/types/PurchaseInfo';

const getDistributorId = (
  vodLocations: Array<NETGEM_API_V8_METADATA_SCHEDULE> | null,
  purchaseInfo?: NETGEM_API_V8_PURCHASE_INFO | null,
  programMetadata?: NETGEM_API_V8_METADATA_PROGRAM | null
): string | null => {
  if (purchaseInfo && purchaseInfo.length > 0) {
    return purchaseInfo[0].settings.distributorId;
  }

  if (programMetadata?.providerInfo?.distributorId) {
    return programMetadata?.providerInfo?.distributorId ?? null;
  }

  if (vodLocations && vodLocations.length > 0) {
    return vodLocations[0].location.providerInfo?.distributorId ?? null;
  }

  return null;
};

export { getDistributorId };
