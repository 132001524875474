/* @flow */

import './PricingVod.css';
import * as React from 'react';
import {
  BOVodAssetStatus,
  formatDisplayPrice,
  getAvailabilityStartTimeOrDefault,
  getCurrentOrNextPriceOption,
  getDefinitionPriority,
  getDisplayPriceAndDefinition,
  getPrice,
  getPurchaseType,
  getTimeLeftFromExpirationTime,
  getVodKind,
  isBetterPriceOption,
  renderPurchaseSummary,
} from '../../helpers/videofutur/metadata';
import { type BO_PURCHASE_LIST_TYPE, Purchase, type PurchaseType } from '../../redux/netgemApi/actions/videofutur/types/purchase';
import { Currency, Definition, type PURCHASE_DETAILS_TYPE } from '../../helpers/ui/metadata/Types';
import { HeightKind, WidthKind } from '../buttons/types';
import type { NETGEM_API_V8_METADATA_SCHEDULE, NETGEM_API_V8_METADATA_SCHEDULE_LOCATION } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_V8_PURCHASE_INFO, NETGEM_API_V8_PURCHASE_INFO_ITEM, NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION } from '../../libs/netgemLibrary/v8/types/PurchaseInfo';
import { Theme, type ThemeType } from '@ntg/ui/dist/theme';
import { getFirstEpisodeLocation, isPackPurchased } from '../../helpers/videofutur/pack';
import AccurateTimestamp from '../../helpers/dateTime/AccurateTimestamp';
import type { BASE_VOD_PURCHASE_DATA_TYPE } from '../../helpers/rights/pendingOperations';
import ButtonFXBicolor from '../buttons/ButtonFXBicolor';
import type { CombinedReducers } from '../../redux/reducers';
import { Localizer } from '@ntg/utils/dist/localization';
import type { NETGEM_API_V8_RAW_FEED } from '../../libs/netgemLibrary/v8/types/FeedResult';
import { RegistrationType } from '../../redux/appRegistration/types/types';
import type { Undefined } from '@ntg/utils/dist/types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { formatAvailabilityTimestamp } from '../../helpers/dateTime/Format';
import { showDebug } from '../../helpers/debug/debug';

export enum ContextKind {
  Card,
  LandscapeTile,
  RegularTile,
  VodEpisode,
}

type AllPurchaseDetails = {|
  [PurchaseType]: PURCHASE_DETAILS_TYPE | null,
|};

type DefaultProps = {|
  +expirationTime?: number,
  +isVodSeries?: boolean,
  +purchaseInfo?: NETGEM_API_V8_PURCHASE_INFO | null,
  +theme?: ThemeType,
|};

type PricingVodPropType = {|
  ...DefaultProps,
  +context: ContextKind,
  +locationsMetadata: ?Array<NETGEM_API_V8_METADATA_SCHEDULE>,
  +onClick: (data: BASE_VOD_PURCHASE_DATA_TYPE) => void,
  +status: BOVodAssetStatus,
|};

type PricingVodReducerStateType = {|
  +isDebugModeEnabled: boolean,
  +purchaseList: BO_PURCHASE_LIST_TYPE | null,
|};

type CompletePricingVodPropType = {|
  ...PricingVodPropType,
  ...PricingVodReducerStateType,
|};

type PricingVodStateType = {|
  minEpisodePrice: ?string,
  purchaseDetails: AllPurchaseDetails,
|};

const InitialState = Object.freeze({
  minEpisodePrice: null,
  purchaseDetails: {
    [Purchase.Buy]: null,
    [Purchase.BuyPack]: null,
    [Purchase.Rent]: null,
  },
});

class PricingVodView extends React.PureComponent<CompletePricingVodPropType, PricingVodStateType> {
  static defaultProps: DefaultProps = {
    expirationTime: 0,
    isVodSeries: false,
    purchaseInfo: null,
    theme: Theme.Dark,
  };

  constructor(props: CompletePricingVodPropType) {
    super(props);

    this.state = { ...InitialState };
  }

  componentDidMount() {
    const { locationsMetadata, purchaseInfo } = this.props;

    if (locationsMetadata || purchaseInfo) {
      this.updatePurchaseDetails();
    }
  }

  componentDidUpdate(prevProps: CompletePricingVodPropType) {
    const { locationsMetadata, purchaseInfo } = this.props;
    const { locationsMetadata: prevLocationsMetadata, purchaseInfo: prevPurchaseInfo } = prevProps;

    if ((locationsMetadata && locationsMetadata !== prevLocationsMetadata) || (purchaseInfo && purchaseInfo !== prevPurchaseInfo)) {
      this.updatePurchaseDetails();
    }
  }

  handleOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    const { isDebugModeEnabled } = this.props;
    const { altKey, ctrlKey } = event;

    if (isDebugModeEnabled && (altKey || ctrlKey)) {
      event.preventDefault();
      event.stopPropagation();
      this.showDebugInfo();
    }
  };

  showDebugInfo = () => {
    const { props, state } = this;

    showDebug('Pricing VOD', {
      props,
      propsFields: ['context', 'expirationTime', 'isVodSeries', 'locationsMetadata', 'purchaseInfo', 'status', 'theme'],
      state,
      stateFields: ['minEpisodePrice', 'purchaseDetails'],
    });
  };

  getPurchaseDetails = (purchaseType: PurchaseType): PURCHASE_DETAILS_TYPE | null => {
    const { locationsMetadata } = this.props;
    const sortedLocations: Array<NETGEM_API_V8_METADATA_SCHEDULE_LOCATION> = [];

    if (!locationsMetadata) {
      return null;
    }

    const now = AccurateTimestamp.now();

    for (let i = 0; i < locationsMetadata.length; i++) {
      const {
        [i]: {
          location,
          location: { availabilityStartDate, id, purchaseInfo },
        },
      } = locationsMetadata;
      if (getPurchaseType(getVodKind(id)) === purchaseType && (purchaseInfo || getAvailabilityStartTimeOrDefault(availabilityStartDate) > now)) {
        sortedLocations.push(location);
      }
    }

    if (sortedLocations.length === 0) {
      // No info found
      return null;
    }

    // Sort accordingly to definitions (4K > HD > SD > [unknown])
    sortedLocations.sort(this.compareDefinitions);

    const [vodLocationMetadata] = sortedLocations;
    const priceAndDefinition = getDisplayPriceAndDefinition(vodLocationMetadata);

    return {
      ...priceAndDefinition,
      itemCount: 1,
      locationId: vodLocationMetadata.id,
      vodLocationMetadata,
    };
  };

  countEpisodes = (elements: NETGEM_API_V8_RAW_FEED): number => elements[0].elements?.length ?? 0;

  getLocationId = (elements: NETGEM_API_V8_RAW_FEED): Undefined<string> => (elements[0].elements?.[0] ?? elements[0]).locations?.[0].id;

  // Pack purchase
  setPackPurchaseDetailsNew = (): void => {
    const { locationsMetadata, purchaseInfo } = this.props;

    if (!purchaseInfo) {
      return;
    }

    let selectedPurchase: NETGEM_API_V8_PURCHASE_INFO_ITEM | null = null;
    let selectedPriceOption: NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION | null = null;

    purchaseInfo.forEach((purchase) => {
      const { priceOptions } = purchase;
      const priceOption = getCurrentOrNextPriceOption(priceOptions);

      if (priceOption !== null && (selectedPurchase === null || selectedPriceOption === null || isBetterPriceOption(selectedPriceOption, priceOption))) {
        selectedPurchase = purchase;
        selectedPriceOption = priceOption;
      }
    });

    if (selectedPurchase === null || selectedPriceOption === null) {
      // No current or next purchase window
      return;
    }

    const {
      condition: { definition },
      elements,
      settings: { distributorId, vtiId },
    } = selectedPurchase;
    const { availabilityStartDate, display } = selectedPriceOption;
    const availabilityStartTime = getAvailabilityStartTimeOrDefault(availabilityStartDate);
    const displayPrice = formatDisplayPrice(display, Currency.Euro);
    const locationId = getFirstEpisodeLocation(elements)?.locations?.[0].id;
    const vodLocationMetadata = locationsMetadata?.find((loc) => loc.location.id === locationId)?.location;
    const itemCount = this.countEpisodes(elements);

    this.setState({
      purchaseDetails: {
        [Purchase.Buy]: null,
        [Purchase.BuyPack]: {
          availabilityStartTime,
          definition,
          displayPrice,
          distributorId,
          itemCount,
          locationId,
          vodLocationMetadata,
          vtiId,
        },
        [Purchase.Rent]: null,
      },
    });
  };

  // Single item buy/rent
  setPurchaseDetailsNew = (): void => {
    const { locationsMetadata, purchaseInfo } = this.props;

    if (!purchaseInfo) {
      return;
    }

    const selection: {| [PurchaseType]: { priceOption: NETGEM_API_V8_PURCHASE_INFO_PRICE_OPTION, purchase: NETGEM_API_V8_PURCHASE_INFO_ITEM } | null |} = {
      [Purchase.Buy]: null,
      [Purchase.Rent]: null,
    };

    purchaseInfo.forEach((purchase) => {
      const { condition, priceOptions } = purchase;
      const { licenseDuration } = condition;
      const purchaseType = typeof licenseDuration === 'undefined' ? Purchase.Buy : Purchase.Rent;
      const priceOption = getCurrentOrNextPriceOption(priceOptions);
      const selectedPurchase = selection[purchaseType];

      if (priceOption !== null && (selectedPurchase === null || isBetterPriceOption(selectedPurchase.priceOption, priceOption))) {
        selection[purchaseType] = { priceOption, purchase };
      }
    });

    const buildPurchaseDetails = (purchaseType: PurchaseType, vodLocationsMetadata: ?Array<NETGEM_API_V8_METADATA_SCHEDULE>): PURCHASE_DETAILS_TYPE | null => {
      const { [purchaseType]: selectedPurchase } = selection;

      if (!selectedPurchase) {
        return null;
      }

      const { purchase, priceOption } = selectedPurchase;
      const {
        condition: { definition, licenseDuration },
        elements,
        settings: { distributorId, vtiId },
      } = purchase;
      const { availabilityStartDate, display } = priceOption;
      const availabilityStartTime = getAvailabilityStartTimeOrDefault(availabilityStartDate);
      const displayPrice = formatDisplayPrice(display, Currency.Euro);
      const locationId = this.getLocationId(elements);
      const vodLocationMetadata = vodLocationsMetadata?.find((loc) => loc.location.id === locationId)?.location;

      return {
        availabilityStartTime,
        definition,
        displayPrice,
        distributorId,
        itemCount: 1,
        licenseDuration,
        locationId,
        vodLocationMetadata,
        vtiId,
      };
    };

    this.setState({
      purchaseDetails: {
        [Purchase.Buy]: buildPurchaseDetails(Purchase.Buy, locationsMetadata),
        [Purchase.BuyPack]: null,
        [Purchase.Rent]: buildPurchaseDetails(Purchase.Rent, locationsMetadata),
      },
    });
  };

  updatePurchaseDetails = () => {
    const { isVodSeries, purchaseInfo, status } = this.props;

    if (purchaseInfo) {
      // TODO: Clean up code when BO API v2 if fully deployed
      if (isVodSeries) {
        this.setPackPurchaseDetailsNew();
      } else {
        this.setPurchaseDetailsNew();
      }
      return;
    }

    if (status === BOVodAssetStatus.Free || status === BOVodAssetStatus.FreeButAvailableInFuture) {
      return;
    }

    if (isVodSeries) {
      this.setState({
        ...InitialState,
        minEpisodePrice: this.getMinEpisodePrice(),
      });
    } else {
      this.setState({
        ...InitialState,
        purchaseDetails: {
          [Purchase.Buy]: this.getPurchaseDetails(Purchase.Buy),
          [Purchase.Rent]: this.getPurchaseDetails(Purchase.Rent),
        },
      });
    }
  };

  getMinEpisodePrice = (): string | null => {
    const { locationsMetadata } = this.props;

    if (!locationsMetadata) {
      return null;
    }

    let definition: Definition | null = null;
    let minDisplayPrice: string | null = null;
    let minPrice = Number.MAX_SAFE_INTEGER;

    locationsMetadata.forEach((metadata) => {
      const {
        location: { purchaseInfo },
      } = metadata;

      if (purchaseInfo) {
        const price = getPrice(purchaseInfo, Currency.Euro);
        if (price !== null) {
          const { amount, display } = price;
          if (amount < minPrice) {
            ({ definition } = purchaseInfo);
            minDisplayPrice = display;
            minPrice = amount;
          }
        }
      }
    });

    if (minPrice === Number.MAX_SAFE_INTEGER || !minDisplayPrice) {
      return null;
    }

    return Localizer.localize('vod.pricing.episode_min_price', {
      definition: definition !== null ? (definition: string) : '',
      price: formatDisplayPrice(minDisplayPrice, Currency.Euro),
    });
  };

  onBuyClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => {
    const {
      purchaseDetails: { [Purchase.BuyPack]: packPurchaseDetails },
    } = this.state;

    this.onPurchaseClick(event, packPurchaseDetails ? Purchase.BuyPack : Purchase.Buy);
  };

  onRentClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): void => this.onPurchaseClick(event, Purchase.Rent);

  onPurchaseClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>, purchaseType: PurchaseType) => {
    const { isDebugModeEnabled } = this.props;
    const { altKey, ctrlKey } = event;

    if (isDebugModeEnabled && (altKey || ctrlKey)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const { onClick } = this.props;
    const { purchaseDetails } = this.state;
    const { [purchaseType]: details } = purchaseDetails;

    // Needed to forbid buying (resp. renting) when item is already rented (resp. bought)
    let otherVtiId: Undefined<number> = undefined;
    if (purchaseType !== Purchase.BuyPack) {
      otherVtiId = purchaseDetails[purchaseType === Purchase.Buy ? Purchase.Rent : Purchase.Buy]?.vtiId;
    }
    if (details) {
      const { definition, displayPrice, distributorId, itemCount, licenseDuration, locationId, vodLocationMetadata, vtiId } = details;

      onClick({
        definition,
        displayPrice,
        distributorId,
        itemCount,
        licenseDuration,
        locationId,
        otherVtiId,
        purchaseType,
        vodLocationMetadata,
        vtiId,
      });
    }
  };

  compareDefinitions = (location1: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION, location2: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION): number => {
    const { purchaseInfo: info1 } = location1;
    const { purchaseInfo: info2 } = location2;

    if (!info1 && !info2) {
      return 0;
    }

    if (!info1) {
      return 1;
    }

    if (!info2) {
      return -1;
    }

    const { definition: def1 } = info1;
    const { definition: def2 } = info2;
    const v1 = getDefinitionPriority(def1);
    const v2 = getDefinitionPriority(def2);

    return v1 - v2;
  };

  getDisplayData = (
    purchaseType: PurchaseType,
    clickCallback: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => void,
  ): {|
    element: React.Node,
    isTextOnly: boolean,
  |} => {
    const { context, theme } = this.props;
    const {
      purchaseDetails: { [purchaseType]: details },
    } = this.state;

    if (!details) {
      return { element: null, isTextOnly: false };
    }

    const { availabilityStartTime, definition, displayPrice } = details;

    if (availabilityStartTime > AccurateTimestamp.now()) {
      // Not yet open to this kind of purchase (BUY or RENT)
      const localizationKey = purchaseType === Purchase.Rent ? 'vod.pricing.availability.tvod' : 'vod.pricing.availability.est';
      return { element: <div className='availabilityDate'>{Localizer.localize(localizationKey, { date: formatAvailabilityTimestamp(availabilityStartTime) })}</div>, isTextOnly: true };
    }

    if (context === ContextKind.VodEpisode) {
      // VOD episode: display rent/buy actions as links
      return {
        element: (
          <div className='pricingLink' onClick={clickCallback}>
            {renderPurchaseSummary(purchaseType, definition, displayPrice)}
          </div>
        ),
        isTextOnly: true,
      };
    }

    // Regular case: display rent/buy actions as buttons
    return {
      element: (
        <ButtonFXBicolor
          heightKind={context === ContextKind.Card ? HeightKind.Large : HeightKind.Small}
          leftPart={`${Localizer.localize(purchaseType === Purchase.Rent ? 'vod.pricing.actions.rent' : 'vod.pricing.actions.buy')} ${(definition: string)}`}
          onClick={clickCallback}
          rightPart={displayPrice}
          theme={theme}
          widthKind={context === ContextKind.LandscapeTile ? WidthKind.Large : WidthKind.Stretched}
        />
      ),
      isTextOnly: false,
    };
  };

  renderPackPurchaseButton = (context: ContextKind): React.Node => {
    const { theme } = this.props;
    const {
      purchaseDetails: { [Purchase.BuyPack]: packPurchaseDetails },
    } = this.state;

    if (packPurchaseDetails === null) {
      return null;
    }

    const { availabilityStartTime, definition, displayPrice } = packPurchaseDetails;

    if (availabilityStartTime > AccurateTimestamp.now()) {
      // Not yet open to purchase
      return <div className='availabilityDate'>{Localizer.localize('vod.pricing.availability.est', { date: formatAvailabilityTimestamp(availabilityStartTime) })}</div>;
    }

    /* eslint-disable react/jsx-handler-names */
    return (
      <div className='pricing purchasable spaced' onClick={this.handleOnClick}>
        <ButtonFXBicolor
          heightKind={context === ContextKind.Card ? HeightKind.Large : HeightKind.Small}
          leftPart={`${Localizer.localize('vod.pricing.actions.buy')} ${(definition: string)}`}
          onClick={this.onBuyClick}
          rightPart={displayPrice}
          theme={theme}
          widthKind={context === ContextKind.LandscapeTile ? WidthKind.Large : WidthKind.Stretched}
        />
      </div>
    );
    /* eslint-enable react/jsx-handler-names */
  };

  renderButtons = (context: ContextKind, isVodSeries?: boolean): React.Node => {
    const { element: rentElement, isTextOnly: isRentTextOnly } = this.getDisplayData(Purchase.Rent, this.onRentClick);
    const { element: buyElement, isTextOnly: isBuyTextOnly } = this.getDisplayData(Purchase.Buy, this.onBuyClick);

    if (!rentElement && !buyElement) {
      return null;
    }

    return (
      <div
        className={clsx(
          context !== ContextKind.VodEpisode && !isVodSeries && 'pricing purchasable spaced',
          context === ContextKind.VodEpisode && 'episodePricing',
          isBuyTextOnly && !isRentTextOnly && 'reversed',
        )}
        onClick={this.handleOnClick}
      >
        {rentElement}
        {buyElement}
      </div>
    );
  };

  render(): React.Node {
    const { context, expirationTime, isVodSeries, locationsMetadata, purchaseInfo, purchaseList, status } = this.props;
    const {
      minEpisodePrice,
      purchaseDetails: { [Purchase.BuyPack]: packPurchaseDetails },
    } = this.state;
    const lines = [];

    if (status === BOVodAssetStatus.Free) {
      // SVOD: asset is part of the subscription
      lines.push(Localizer.localize('vod.pricing.svod'));
    } else if (status === BOVodAssetStatus.Rented) {
      // TVOD: asset already rented
      lines.push(Localizer.localize('vod.pricing.tvod.rented'));
      if (typeof expirationTime === 'number' && expirationTime > 0) {
        lines.push(Localizer.localize('vod.pricing.tvod.expire', { expiration: getTimeLeftFromExpirationTime(expirationTime) }));
      }
    } else if (status === BOVodAssetStatus.Bought) {
      // EST: asset already bought
      lines.push(Localizer.localize('vod.pricing.est.bought'));
    } else if (packPurchaseDetails && purchaseInfo && purchaseInfo.length > 0) {
      // Purchasable VOD pack
      if (isPackPurchased(purchaseInfo, purchaseList)) {
        return (
          <div className='pricing' onClick={this.handleOnClick}>
            {Localizer.localize('vod.pricing.est.bought_season')}
          </div>
        );
      }

      return this.renderPackPurchaseButton(context);
    } else if (minEpisodePrice) {
      // TVOD or EST series
      lines.push(minEpisodePrice);
    } else if (locationsMetadata && locationsMetadata.length > 0) {
      // TVOD or EST not purchased
      const rentAndBuyButtons = this.renderButtons(context, isVodSeries);
      if (rentAndBuyButtons) {
        return rentAndBuyButtons;
      }
    }

    if (context === ContextKind.VodEpisode) {
      // Purchase status is not displayed here for episodes of VOD series
      return null;
    }

    if (lines.length === 0) {
      // Still loading
      return null;
    }

    return (
      <div className='pricing' onClick={this.handleOnClick}>
        {lines.map((l) => (
          <div key={l}>{l}</div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): PricingVodReducerStateType => {
  return {
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    purchaseList: state.appRegistration.registration === RegistrationType.RegisteredAsGuest ? {} : state.ui.purchaseList,
  };
};

const PricingVod: React.ComponentType<PricingVodPropType> = connect(mapStateToProps, null, null, { forwardRef: true })(PricingVodView);

export default PricingVod;
