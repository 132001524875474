/* @flow */

import type { ETAG_MAP } from './ETag';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_V8_FEED_RESULT } from './FeedResult';
import type { NETGEM_API_V8_ITEM_LOCATION } from './FeedItem';

export const NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_KEEP_FROM_REPLAY: 'KeepFromReplay' = 'KeepFromReplay';
export const NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES: 'Series' = 'Series';
export const NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE: 'Single' = 'Single';

// Cannot convert to Flow enum because it's used to refine types
export type NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND =
  | typeof NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_KEEP_FROM_REPLAY
  | typeof NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES
  | typeof NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE;

export type NETGEM_API_V8_RECORDINGS_STATUS = {|
  count: number,
  duration: string,
|};

export type NETGEM_API_V8_RECORDINGS_FUTURE = {|
  ...NETGEM_API_V8_FEED_RESULT,
  current: NETGEM_API_V8_RECORDINGS_STATUS,
  expectedError: NETGEM_API_V8_RECORDINGS_STATUS,
  expectedSuccess: NETGEM_API_V8_RECORDINGS_STATUS,
  maxQuota: NETGEM_API_V8_RECORDINGS_STATUS,
|};

export enum RecordingOutcome {
  ExceededConcurrency = 'ExceededConcurrency',
  HistoryOverflow = 'HistoryOverflow',
  OutdatedEpisode = 'OutdatedEpisode',
  OutOfQuota = 'OutOfQuota',
  Rebroadcast = 'Rebroadcast',
  Recorded = 'Recorded',
  ServerError = 'ServerError',
  Unknown = 'Unknown',
  WillBeRecorded = 'WillBeRecorded',
}

export type NETGEM_API_V8_MATCHING_SCHEDULED_RECORDING = {|
  conflict: Array<string>,
  id: string,
  quotaUsed: string,
  recordOutcome: RecordingOutcome,
|};

// Keys are program Ids
export type NETGEM_RECORDINGS_MAP = KeyValuePair<Array<NETGEM_API_V8_ITEM_LOCATION>>;

export type NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION_SINGLE = {|
  kind: typeof NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SINGLE,
  requestedScheduledEventId: string,
  targetScheduledEventId: string,
|};

export type NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION_SERIES = {|
  fromUtc: string,
  increasingEpisodes?: boolean,
  kind: typeof NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND_SERIES,
  recordsToKeep: ?number,
  seriesId: string,
  toUtc?: string,
|};

type NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION_ANY = NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION_SINGLE | NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION_SERIES;

type NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION_COMMON = {|
  startMargin: string,
  endMargin: string,
  channelId: string,
|};

export type NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION = {|
  ...NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION_COMMON,
  ...NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION_ANY,
|};

export type ID_TYPE = {|
  id: string,
|};

export type NETGEM_API_V8_SCHEDULED_RECORDING = {|
  description: NETGEM_API_V8_SCHEDULED_RECORDING_DESCRIPTION,
  id: string,
  records: Array<ID_TYPE>,
|};

// Keys are series or scheduled event Ids
export type NETGEM_SCHEDULED_RECORDINGS_MAP = KeyValuePair<NETGEM_API_V8_SCHEDULED_RECORDING>;

export type NETGEM_SCHEDULED_RECORDINGS_MAP_AND_ETAGS = {|
  eTags: ETAG_MAP,
  scheduledRecordings: NETGEM_SCHEDULED_RECORDINGS_MAP,
|};

export type NETGEM_API_V8_SCHEDULED_RECORDINGS_LIST_RESULT = Array<ID_TYPE>;

// Duration in seconds
export type NETGEM_RECORDINGS_STATUS_ITEM = {|
  count: number,
  duration: number,
|};

export type NETGEM_FUTURE_RECORDINGS_STATUS = {|
  current: NETGEM_RECORDINGS_STATUS_ITEM,
  expectedError: NETGEM_RECORDINGS_STATUS_ITEM,
  expectedSuccess: NETGEM_RECORDINGS_STATUS_ITEM,
  maxQuota: NETGEM_RECORDINGS_STATUS_ITEM,
|};

export const DEFAULT_RECORDINGS_STATUS_ITEM: NETGEM_RECORDINGS_STATUS_ITEM = { count: 0, duration: 0 };
