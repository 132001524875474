/* @flow */

import { type LuminosityType, type ThemeType, Variant, getVariant } from '@ntg/ui/dist/theme';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import AccurateTimestamp from '../../../../helpers/dateTime/AccurateTimestamp';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { HeaderValue } from '../../../../libs/netgemLibrary/v8/constants/Headers';
import { MILLISECONDS_PER_HOUR } from '../../../../helpers/dateTime/Format';
import { type NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { REDUX_MSG_REQUEST_GENERIC } from '../../constants';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';
import { getCacheExpirationTime } from '../../../../helpers/request/cache';

export type ImageUrlType = {|
  assetId: string,
  forcedVariant?: string,
  height: number,
  luminosity?: LuminosityType,
  theme?: ThemeType,
  width: number,
|};

// Key is formed with Id (program, series, etc.), width and height
type IMAGE_CACHE = {|
  [string]: {|
    expiration: number,
    imageUrl: string,
  |},
|};

type IMAGE_REQUEST = {|
  [string]: Promise<any>,
|};

const cache: IMAGE_CACHE = {};
const pendingRequests: IMAGE_REQUEST = {};

// Not found images are cached for 1 hour (in ms)
const NOT_FOUND_MAX_AGE = MILLISECONDS_PER_HOUR;

const IMAGE_MODULO: number = 8;
const getSize: (s: number) => number = (s) => {
  // Adjust width and height to the greatest and nearest power of 8
  const m: number = s % IMAGE_MODULO;
  return m === 0 ? s : s - m + IMAGE_MODULO;
};

const getImageUrlFromDefinition: (urlDefinition: NETGEM_API_V8_URL_DEFINITION) => RequestResponseMethodDefinitionType =
  (urlDefinition) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> =>
    Promise.resolve(generateApiUrl(urlDefinition, null, getState()));

const sendV8MetadataImageRequest: (urlDefinition: NETGEM_API_V8_URL_DEFINITION, uri: string, signal?: AbortSignal, key: string) => RequestResponseMethodDefinitionType =
  (urlDefinition, uri, signal, key) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const { authent, method } = urlDefinition;

    const promise = NetgemApiEmitter.emit(REDUX_MSG_REQUEST_GENERIC, {
      authent,
      method,
      responseType: HeaderValue.Image,
      signal,
      state,
      uri,
    })
      .then((response: NETGEM_API_V8_REQUEST_RESPONSE) => {
        const { cacheMaxAge, result: imageUrl } = response;

        if (cacheMaxAge) {
          cache[key] = {
            expiration: getCacheExpirationTime(cacheMaxAge),
            imageUrl,
          };
        }

        return Promise.resolve(imageUrl);
      })
      .catch((error) => {
        // $FlowFixMe: flow doesn't know DOMException
        if (!(error instanceof DOMException) || error.name !== 'AbortError') {
          // Don't cache cancelled requests' results but cache 404's ones
          cache[key] = {
            expiration: getCacheExpirationTime(NOT_FOUND_MAX_AGE),
            imageUrl: '',
          };
        }

        return Promise.resolve('');
      })
      .finally(() => delete pendingRequests[key]);

    pendingRequests[key] = promise;
    return promise;
  };

const getUri: (metadataImageUrl: NETGEM_API_V8_URL_DEFINITION, assetId: string, width: number, height: number, imageVariant: string, state: CombinedReducers) => string = (
  metadataImageUrl,
  assetId,
  width,
  height,
  imageVariant,
  state,
) => {
  const imageWidth = getSize(width);
  const imageHeight = getSize(height);

  return generateApiUrl(
    metadataImageUrl,
    {
      assetId,
      imageHeight,
      imageVariant,
      imageWidth,
    },
    state,
  );
};

const getImageUrl: (data: ImageUrlType, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (data, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const state = getState();
    const {
      netgemApi: { metadataImageWithVariantUrl },
    } = state;

    if (!metadataImageWithVariantUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'metadataImageWithVariantUrl' }));
    }

    const { assetId, forcedVariant, height, luminosity, theme, width } = data;

    // Get correct image variant ('dk', 'lt' or 'default')
    const variant = forcedVariant ?? getVariant(luminosity, theme);

    const imageWidth = getSize(width);
    const imageHeight = getSize(height);

    const key = `${assetId}#${imageWidth}x${imageHeight}x${variant}`;
    const { [key]: cachedImage } = cache;
    const { [key]: pendingRequest } = pendingRequests;

    if (cachedImage) {
      const { expiration, imageUrl } = cachedImage;
      // Keep using cached images until max-age
      if (AccurateTimestamp.now() <= expiration) {
        return Promise.resolve(imageUrl);
      }

      // Image in cache has expired
      delete cache[key];
    }

    if (pendingRequest) {
      return pendingRequest;
    }

    const uri = getUri(metadataImageWithVariantUrl, assetId, width, height, variant, state);
    return dispatch(sendV8MetadataImageRequest(metadataImageWithVariantUrl, uri, signal, key));
  };

const getImageSourceUrl: (assetId: string, width: number, height: number) => RequestResponseMethodDefinitionType =
  (assetId, width, height) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<?string> => {
    const state = getState();
    const {
      netgemApi: { metadataImageWithVariantUrl },
    } = state;

    if (!metadataImageWithVariantUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'metadataImageWithVariantUrl' }));
    }

    return Promise.resolve(getUri(metadataImageWithVariantUrl, assetId, width, height, Variant.Default, state));
  };

export { getImageSourceUrl, getImageUrl, getImageUrlFromDefinition };
