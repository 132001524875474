/* @flow */

import 'core-js/features/string/pad-start';
import AccurateTimestamp from './AccurateTimestamp';
import { Localizer } from '@ntg/utils/dist/localization';
import { capitalizeFirstLetter } from '@ntg/utils/dist/string';

const MONTH_KEYS = Object.freeze(['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']);

const DAY_KEYS = Object.freeze(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']);

const TIME_PADDING_LENGTH = 2;

// For calculation regarding month duration below, we assume a 30-day month

export const MILLISECONDS_PER_SECOND = 1_000;
export const MILLISECONDS_PER_MINUTE = 60_000;
export const MILLISECONDS_PER_HOUR = 3_600_000;
export const MILLISECONDS_PER_DAY = 86_400_000;
export const MILLISECONDS_PER_WEEK = 604_800_000;
export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = 3_600;
export const SECONDS_PER_DAY = 86_400;
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const DAYS_PER_MONTH = 30;
export const MONTHS_PER_YEAR = 12;

const SECONDS_PER_WEEK = 604_800;
const SECONDS_PER_MONTH = 2_592_000;
const ONE_YEAR_IN_SECONDS = 31_536_000;
const THREE_MONTHS_IN_SECONDS = 7_776_000;

const JANUARY_INDEX = 0;
const DECEMBER_INDEX = 11;

export const ISO8601_DURATION_ZERO = 'PT0S';

// Minimum value for a JS date (as Date)
export const MIN_DATE: Date = new Date(0);

// Minimum value for a JS date (as ISO8601 string)
export const MIN_DATE_ISO = '1970-01-01T00:00:00.000Z';

// Maximum value for a JS date (in seconds)
export const MAX_DATE_IN_SECONDS = 8_640_000_000_000;

// Maximum value for a JS date (as ISO8601 string)
export const MAX_DATE_ISO = '275760-09-13T00:00:00.000Z';

// Display change between before and after 50% of the program duration
const DISPLAY_CHANGE_THRESHOLD = 0.5;

// Different display when program starts in less than 10 minutes
export const LIVE_START_THRESHOLD = 600;

const pad2 = (n: number) => n.toString().padStart(TIME_PADDING_LENGTH, '0');

const formatTime = (d: Date): string => `${pad2(d.getHours())}:${pad2(d.getMinutes())}`;

// Argument 't' is a time in seconds
const formatTimeFromSeconds: (t: number) => string = (t) => formatTime(new Date(t * MILLISECONDS_PER_SECOND));

const buildSpecificDate: (dDay: number, dDate: number, dMonth: number, time: string) => string = (dDay, dDate, dMonth, time) =>
  Localizer.localize('common.datetime.scheduled_event_status.specific_date', {
    date: Localizer.localize(`common.datetime.date_names.${dDate}`),
    day: Localizer.localize(`common.datetime.day_names.short.${DAY_KEYS[dDay]}`),
    month: Localizer.localize(`common.datetime.month_names.${MONTH_KEYS[dMonth]}`),
    time,
  });

const formatDateInternal: (startTime: number, endTime: number, now: number) => string = (startTime, endTime, now) => {
  const itemDate = new Date(startTime * MILLISECONDS_PER_SECOND);
  const itemDateMidnight = new Date(itemDate);
  itemDateMidnight.setHours(0, 0, 0, 0);
  const itemTimeMidnight = itemDateMidnight.getTime();
  const dDate = itemDate.getDate();
  const dDay = itemDate.getDay();
  const dMonth = itemDate.getMonth();
  const formattedTime = formatTime(itemDate);

  const currentDate = new Date(now * MILLISECONDS_PER_SECOND);
  const currentDateMidnight = new Date(currentDate);
  currentDateMidnight.setHours(0, 0, 0, 0);
  const currentTimeMidnight = currentDateMidnight.getTime();

  const isToday = itemTimeMidnight === currentTimeMidnight;

  if (startTime > now) {
    // Future date
    const timeBeforeStart = startTime - now;

    if (timeBeforeStart <= LIVE_START_THRESHOLD) {
      // Program starts in less than 10 minutes
      const timeBeforeStartMin = Math.floor(timeBeforeStart / SECONDS_PER_MINUTE);
      if (timeBeforeStartMin > 0) {
        if (timeBeforeStartMin >= MINUTES_PER_HOUR) {
          // Display hours and minutes
          const timeBeforeStartH = Math.floor(timeBeforeStartMin / MINUTES_PER_HOUR);
          const timeBeforeStartM = timeBeforeStartMin % MINUTES_PER_HOUR;
          return Localizer.localize('common.datetime.scheduled_event_status.start_in_hour_minute', {
            hours: timeBeforeStartH,
            minutes: pad2(timeBeforeStartM),
          });
        }

        // Display minutes only
        return Localizer.localize('common.datetime.scheduled_event_status.start_in_minute', { minutes: timeBeforeStartMin });
      }

      return Localizer.localize('common.datetime.scheduled_event_status.about_to_start');
    }

    // Program starts in more than 10 minutes
    const currentWeek = Math.floor(now / SECONDS_PER_WEEK);
    const week = Math.floor(startTime / SECONDS_PER_WEEK);

    if (isToday) {
      // Program starts today
      return Localizer.localize('common.datetime.scheduled_event_status.today', { time: formattedTime });
    } else if (itemTimeMidnight === new Date(currentDateMidnight).setDate(currentDateMidnight.getDate() + 1)) {
      // Program starts tomorrow
      return Localizer.localize('common.datetime.scheduled_event_status.tomorrow', { time: formattedTime });
    } else if (week === currentWeek) {
      // Program starts this week
      return Localizer.localize('common.datetime.scheduled_event_status.this_week', {
        date: Localizer.localize(`common.datetime.date_names.${dDate}`),
        day: Localizer.localize(`common.datetime.day_names.long.${DAY_KEYS[dDay]}`),
        time: formattedTime,
      });
    }

    // Program starts later in the future
    return buildSpecificDate(dDay, dDate, dMonth, formattedTime);
  } else if (now < endTime) {
    // Live
    const elapsed = now - startTime;
    const elapsedMin = Math.floor(elapsed / SECONDS_PER_MINUTE);
    const remainingMin = Math.floor((endTime - now) / SECONDS_PER_MINUTE);

    if (elapsedMin === 0) {
      // Program is just starting
      return Localizer.localize('common.datetime.scheduled_event_status.starting');
    } else if (remainingMin === 0) {
      // Program is about to finish
      return Localizer.localize('common.datetime.scheduled_event_status.finishing');
    } else if (elapsed <= (endTime - startTime) * DISPLAY_CHANGE_THRESHOLD) {
      // Program has been on for less than 50% of its duration
      if (elapsedMin > MINUTES_PER_HOUR) {
        const elapsedH = Math.floor(elapsedMin / MINUTES_PER_HOUR);
        const elapsedM = elapsedMin % MINUTES_PER_HOUR;
        return Localizer.localize('common.datetime.scheduled_event_status.since_hour_minute', {
          hours: elapsedH,
          minutes: pad2(elapsedM),
        });
      }

      return Localizer.localize('common.datetime.scheduled_event_status.since_minute', { minutes: elapsedMin });
    }

    // Program has been on for more than 50% of its duration
    if (remainingMin > MINUTES_PER_HOUR) {
      const remainingH = Math.floor(remainingMin / MINUTES_PER_HOUR);
      const remainingM = remainingMin % MINUTES_PER_HOUR;
      return Localizer.localize('common.datetime.scheduled_event_status.remaining_hour_minute', {
        hours: remainingH,
        minutes: pad2(remainingM),
      });
    }

    return Localizer.localize('common.datetime.scheduled_event_status.remaining_minute', { minutes: remainingMin });
  }

  // Past date
  if (isToday) {
    // Program was broadcasted today
    return Localizer.localize('common.datetime.scheduled_event_status.today', { time: formattedTime });
  } else if (itemTimeMidnight === new Date(currentDateMidnight).setDate(currentDateMidnight.getDate() - 1)) {
    // Program was broadcasted yesterday
    return Localizer.localize('common.datetime.scheduled_event_status.yesterday', { time: formattedTime });
  }

  const dYear = itemDate.getFullYear();
  const yearDiff = currentDate.getFullYear() - dYear;

  if (yearDiff > 1 || (yearDiff === 1 && (dMonth !== DECEMBER_INDEX || currentDate.getMonth() !== JANUARY_INDEX))) {
    // Last year (or before) but not the previous month (case of a program broadcasted in December and today is January)
    return Localizer.localize('common.datetime.scheduled_event_status.long_past_date', {
      month: capitalizeFirstLetter(Localizer.localize(`common.datetime.month_names.${MONTH_KEYS[dMonth]}`)),
      year: dYear,
    });
  }

  // Earlier than yesterday
  return buildSpecificDate(dDay, dDate, dMonth, formattedTime);
};

/*
 * Format date information depending on program dates from the current time
 *  and depending on language code.
 *
 * case FUTURE program (startTime > now):
 *  - today date                                -> Today, hh:mm am/pm
 *  - tomorrow date                             -> Tomorrow, hh:mm am/pm
 *  - next day after tomorrow and in same week of current time:
 *                                              -> ex: Monday, hh:mm am/pm
 *  - date of next week and after:              -> ex: Monday 18 August, hh:mm am/pm
 *
 * case LIVE program: (startTime <= now < endTime):
 *  - program playing from 75% of its duration: -> ex: Since xxx min
 *  - program finishing to 25% of its duration: -> ex: xxx min remaining
 *
 * case PAST program: (now >= endTime):         -> ex: Monday 18 August, hh:mm am/pm
 *
 * @public
 * @param  {number} startTime        : program startTime (timestamp in seconds)
 * @param  {number} endTime          : program endTime (timestamp in seconds)
 * @param  {number} now              : current time (timestamp in seconds)
 * @return {string}                  : formatted result
 */
const formatDate: (startTime: number, endTime: number, now: number) => string = (startTime, endTime, now) => {
  if (isNaN(startTime) || isNaN(endTime) || startTime === 0) {
    return '';
  }

  const formattedDate = formatDateInternal(startTime, endTime, now);

  return capitalizeFirstLetter(formattedDate);
};

const formatAvailabilityTimestamp: (timestamp: number) => string = (timestamp) => {
  const d = new Date(timestamp);
  return Localizer.localize('vod.pricing.availability.date', {
    date: pad2(d.getDate()),
    month: pad2(d.getMonth() + 1),
  });
};

const formatExpirationTimestamp: (expirationTime: number) => string = (expirationTime) => {
  if (expirationTime === Infinity) {
    return capitalizeFirstLetter(Localizer.localize('common.datetime.expiration.never'));
  }

  const now = AccurateTimestamp.nowInSeconds();
  const remainingTime = expirationTime - now;

  if (remainingTime <= 0) {
    // Catchup not available anymore
    return capitalizeFirstLetter(Localizer.localize('common.datetime.expiration.expired'));
  }

  if (remainingTime > ONE_YEAR_IN_SECONDS) {
    return capitalizeFirstLetter(Localizer.localize('common.datetime.expiration.more_1_year'));
  }

  if (remainingTime > THREE_MONTHS_IN_SECONDS) {
    return capitalizeFirstLetter(Localizer.localize('common.datetime.expiration.more_3_months'));
  }

  const durationD = Math.floor(remainingTime / SECONDS_PER_DAY);
  if (durationD > 1) {
    return capitalizeFirstLetter(Localizer.localize('common.datetime.expiration.day', { count: durationD }));
  }

  const durationH = Math.floor(remainingTime / SECONDS_PER_HOUR);
  if (durationH > 1) {
    return capitalizeFirstLetter(Localizer.localize('common.datetime.expiration.hour', { count: durationH }));
  }

  const durationM = Math.floor(remainingTime / SECONDS_PER_MINUTE);
  if (durationM > 1) {
    return capitalizeFirstLetter(Localizer.localize('common.datetime.expiration.minute', { count: durationM }));
  }

  return capitalizeFirstLetter(Localizer.localize('common.datetime.expiration.less_1_minute'));
};

const formatExpirationDate: (iso8601Date: string) => string = (iso8601Date) => formatExpirationTimestamp(new Date(iso8601Date).getTime() / MILLISECONDS_PER_SECOND);

/*
 * Get duration information in seconds
 * ex: PT1H30M --> 5400
 *
 * @public
 * @param  {string} iso8601Duration : duration in iso8601 format ex: PT1H30M
 * @return {number}                 : number of seconds
 */
const getIso8601DurationInSeconds: (iso8601Duration: ?string) => number = (iso8601Duration) => {
  if (!iso8601Duration) {
    return 0;
  }

  const time: Array<string> | null = /^P([0-9]+Y)?([0-9]+M)?([0-9]+D)?T?([0-9]+H)?([0-9]+M)?(?:([0-9]+)(?:\.[0-9]+)?S)?$/u.exec(iso8601Duration);

  if (!time) {
    return 0;
  }

  return [0, 0, SECONDS_PER_MONTH, SECONDS_PER_DAY, SECONDS_PER_HOUR, SECONDS_PER_MINUTE, 1].reduce((sum, coef, i) => {
    const { [i]: n } = time;
    return sum + (n && coef ? coef * parseInt(n, 10) : 0);
  }, 0);
};

/*
 * Format duration information (depending on language code)
 * ex: PT1H30M --> 1h30
 *
 * @public
 * @param  {string} iso8601Duration : duration in iso8601 format ex: PT1H30M
 * @param  {language} language      : iso639-1 language code
 * @return {string}                 : formatted result
 */
const formatIso8601Duration: (iso8601Duration: ?string) => string = (iso8601Duration) => {
  if (!iso8601Duration) {
    return '';
  }

  return formatDuration(getIso8601DurationInSeconds(iso8601Duration));
};

const formatDuration: (durationInSeconds: number) => string = (durationInSeconds) => {
  let durationS = Math.abs(Math.floor(durationInSeconds));

  const durationH = Math.floor(durationS / SECONDS_PER_HOUR);
  durationS %= SECONDS_PER_HOUR;

  const durationM = Math.floor(durationS / SECONDS_PER_MINUTE);

  if (durationH > 0) {
    return Localizer.localize('common.datetime.duration_hour_minute', {
      hours: durationH,
      minutes: durationM > 0 ? pad2(durationM) : '',
    });
  }

  return Localizer.localize('common.datetime.duration_minute', { minutes: durationM });
};

/*
 * Format seconds in HH:MM:SS
 * ex: toHHMMSS(0) => "00:00"
 * ex: toHHMMSS(59) => "00:59"
 * ex: toHHMMSS(3500) => "58:20"
 * ex: toHHMMSS(36000) => "10:00:00"
 *
 * @public
 * @param  {number} durationInSeconds : number of seconds to convert
 * @return {string}                   : formatted result
 */
const formatSecondsToHHMMSS: (durationInSeconds: number) => string = (durationInSeconds) => {
  let seconds = Math.abs(Math.floor(durationInSeconds));

  const hours = Math.floor(seconds / SECONDS_PER_HOUR);
  seconds %= SECONDS_PER_HOUR;

  const minutes = Math.floor(seconds / SECONDS_PER_MINUTE);
  seconds %= SECONDS_PER_MINUTE;

  let formattedDuration = [hours, minutes, seconds]
    .map((n: number): string => pad2(n))
    .filter((s: string, i: number) => s !== '00' || i > 0)
    .join(':');

  if (durationInSeconds < 0) {
    // Negative duration
    formattedDuration = `-${formattedDuration}`;
  }

  return formattedDuration;
};

const getIso8601DateInSeconds: (iso8601Date: ?string) => number = (iso8601Date) => {
  if (!iso8601Date) {
    return 0;
  }

  return new Date(iso8601Date).getTime() / MILLISECONDS_PER_SECOND;
};

export {
  formatAvailabilityTimestamp,
  formatDate,
  formatDuration,
  formatExpirationDate,
  formatExpirationTimestamp,
  formatIso8601Duration,
  formatSecondsToHHMMSS,
  formatTimeFromSeconds,
  getIso8601DurationInSeconds,
  getIso8601DateInSeconds,
};
