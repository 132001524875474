/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import { BO_API_ADD_FAVORITE } from './types/common';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_BO_FAVORITE_ADD } from '../../constants';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import getFavoriteList from './favoriteList';
import getRequestDetails from './helpers/requestCommon';

const addToFavoriteList: (titId: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (titId, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration: { mainDistributorId, useBOV2Api },
    } = getState();

    if (!mainDistributorId) {
      return Promise.reject(
        createCustomNetworkErrorFromKey('common.messages.errors.missing_parameters_list', {
          list: 'mainDistributorId',
          name: 'Add to favorite list',
        }),
      );
    }

    if (useBOV2Api) {
      // BO API v2
      return dispatch(getRequestDetails(mainDistributorId, BO_API_ADD_FAVORITE, useBOV2Api))
        .then((requestDetails) =>
          // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
          NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_FAVORITE_ADD, {
            ...requestDetails,
            signal,
            titId,
          }),
        )
        .then(() => dispatch(getFavoriteList(signal)));
    }

    // Old API
    return dispatch(getRequestDetails(mainDistributorId, BO_API_ADD_FAVORITE, useBOV2Api, { '<TIT_ID>': titId }))
      .then((requestDetails) =>
        // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
        NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_FAVORITE_ADD, {
          ...requestDetails,
          signal,
        }),
      )
      .then(() => dispatch(getFavoriteList(signal)));
  };

export default addToFavoriteList;
