/* @flow */

import * as React from 'react';
import type { AllTracks, AudioTrack, ShakaOfflineProgressUpdate, ShakaTrack, TextTrack, VideoTrack } from './shakaTypes';
import { HeightKind, WidthKind } from '../../buttons/types';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import ButtonFX from '../../buttons/ButtonFX';
import ShakaStorage from './shakaStorage';

const cloneAudioTracks: (tracks: Array<AudioTrack>) => Array<AudioTrack> = (tracks) =>
  tracks.map((track) => {
    return {
      ...track,
      roles: [...track.roles],
    };
  });

const cloneTextTracks: (tracks: Array<TextTrack>) => Array<TextTrack> = (tracks) =>
  tracks.map((track) => {
    return {
      ...track,
      roles: [...track.roles],
    };
  });

const cloneVideoTracks: (tracks: Array<VideoTrack>) => Array<VideoTrack> = (tracks) =>
  tracks.map((track) => {
    return {
      ...track,
      roles: [...track.roles],
    };
  });

// TODO: use cache
const cloneAllTracks: (tracks: AllTracks) => AllTracks = (tracks) => {
  const { audio, text, video } = tracks;

  return {
    audio: cloneAudioTracks(audio),
    text: cloneTextTracks(text),
    video: cloneVideoTracks(video),
  };
};

const handleCancelOnClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>, id: string) => void = (event, id) => {
  if (id) {
    ShakaStorage.cancelDownload(id);
  }
};

// Sending progress of 1 will immediately close the toast, whatever the value of autoClose
const ALMOST_ONE = 0.99;
const generateUpdateDownloadProgress: (toastId: string, title: string) => ShakaOfflineProgressUpdate =
  (toastId, title) =>
  (content: any, progress: number): void => {
    if (progress === -1) {
      // Error
      Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div>Erreur lors du téléchargement de &#xAB;&nbsp;{title}&nbsp;&#xBB;</div>, { progress, toastId });
      Messenger.emit(MessengerEvents.NOTIFY_CLOSE, toastId);
    } else if (progress < 1) {
      // Downloading
      Messenger.emit(
        MessengerEvents.NOTIFY_INFO,
        <>
          <div>Téléchargement de &#xAB;&nbsp;{title}&nbsp;&#xBB;...</div>
          <ButtonFX data={toastId} heightKind={HeightKind.Small} onClick={handleCancelOnClick} widthKind={WidthKind.Stretched}>
            Annuler
          </ButtonFX>
        </>,
        { progress, toastId },
      );
    } else {
      // Finished
      Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, <div>&#xAB;&nbsp;{title}&nbsp;&#xBB; a été téléchargé</div>, { progress: ALMOST_ONE, toastId });
      Messenger.emit(MessengerEvents.NOTIFY_CLOSE, toastId);
      Messenger.emit(MessengerEvents.DOWNLOAD_SUCCESSFUL);
    }
  };

const selectDownloadTracks: (tracks: Array<ShakaTrack>) => Array<ShakaTrack> = (tracks) => {
  let index = -1;
  let maxBandwidth = -1;
  for (let i = 0; i < tracks.length; ++i) {
    const {
      [i]: { bandwidth, type },
    } = tracks;
    if (type === 'variant' && bandwidth > maxBandwidth) {
      index = i;
      maxBandwidth = bandwidth;
    }
  }

  if (index > -1) {
    // Track with the highest bandwidth found
    return [tracks[index]];
  }

  // First variant track selected
  const firstTrack = tracks.find(({ type }) => type === 'variant');
  if (firstTrack) {
    return [firstTrack];
  }

  // No track
  return [];
};

export { cloneAllTracks, selectDownloadTracks, generateUpdateDownloadProgress };
