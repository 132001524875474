/* @flow */

import { type AllSettledPromises, SettledPromiseFulfilled } from '../../../../../helpers/jsHelpers/promise';
import { type BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE, Purchase, PurchaseSubType } from '../types/purchase';
import { MILLISECONDS_PER_SECOND, SECONDS_PER_DAY, SECONDS_PER_HOUR, SECONDS_PER_MINUTE } from '../../../../../helpers/dateTime/Format';
import { updateBOPurchaseListForDistributor, updateBOPurchaseListsForAllDistributors } from '../../../../ui/actions';
import AccurateTimestamp from '../../../../../helpers/dateTime/AccurateTimestamp';
import type { BO_RESPONSE_WITH_ETAG } from '../types/common';
import type { CombinedReducers } from '../../../../reducers';
import type { Dispatch } from '../../../../types/types';
import { HttpStatus } from '../../../../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import type { RequestResponseMethodDefinitionType } from '../../emitter';
import { logError } from '../../../../../helpers/debug/debug';
import sendPurchaseListRequest from '../purchaseList';

const DATE_PATTERN = /^(\d\d)\/(\d\d)\/(\d\d\d\d).*(\d\d):(\d\d)$/u;

// Returns an expiration date as a timestamp in seconds
const convertTimeLeftToExpirationTime: (timeLeft?: string) => number = (timeLeft) => {
  if (!timeLeft) {
    return 0;
  }

  const now = AccurateTimestamp.nowInSeconds();

  const value = parseInt(timeLeft.slice(0, -1), 10);
  const kind = timeLeft[timeLeft.length - 1];

  if (kind === 'J') {
    // Add days
    return now + value * SECONDS_PER_DAY;
  }

  if (kind === 'H') {
    // Add hours
    return now + value * SECONDS_PER_HOUR;
  }

  // Add minutes
  return now + value * SECONDS_PER_MINUTE;
};

// Forced to parse because of a freaking non-standard weird format from BO
const convertToIsoDate: (date: string) => string | null = (date) => {
  const res: Array<string> | null = DATE_PATTERN.exec(date);

  if (!res) {
    return null;
  }

  const [, days, months, years, hours, minutes] = res.map((n) => Number(n));
  return new Date(years, months, days, hours, minutes).toISOString();
};

const getPurchaseList: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const {
      netgemApi: { distributors },
    } = getState();

    const promises = Array.from(Object.keys(distributors)).map((distributorId) => dispatch(getPurchaseListForDistributor(distributorId, signal, true)));

    return Promise.allSettled(promises).then((results: AllSettledPromises) => {
      if (signal?.aborted) {
        return;
      }

      const allPurchaseLists = [];

      results.forEach(({ status, value }) => {
        // When a response is the same as previously (HTTP 304), 'value' is undefined
        if (status === SettledPromiseFulfilled && typeof value !== 'undefined') {
          allPurchaseLists.push(value);
        }
      });

      dispatch(updateBOPurchaseListsForAllDistributors(allPurchaseLists));
    });
  };

const getPurchaseListForDistributor: (distributorId: string, signal?: AbortSignal, skipStoreUpdate?: boolean) => RequestResponseMethodDefinitionType =
  (distributorId, signal, skipStoreUpdate) =>
  (dispatch: Dispatch): Promise<any> =>
    dispatch(sendPurchaseListRequest(distributorId, signal))
      .then(({ eTag, response }: BO_RESPONSE_WITH_ETAG) => {
        if (signal?.aborted || !response) {
          return Promise.reject(new Error(`Could not get purchase list for ${distributorId}`));
        }

        const purchaseList: BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE = {};

        if (response.body) {
          // BO API v2
          const { body } = response;
          body.forEach((p) => {
            const {
              data: {
                purchaseTitleData: { licenseExpirationDate, licenseTimeLeft, packVtiId, vhiDate, videoPosition, vtiId },
                subType,
              },
            } = p;
            const existingItem = purchaseList[vtiId.toString()];

            if (!existingItem || (typeof existingItem.packVtiId === 'undefined' && typeof packVtiId !== 'undefined')) {
              purchaseList[vtiId.toString()] = {
                expirationTime: licenseExpirationDate ? new Date(licenseExpirationDate).getTime() / MILLISECONDS_PER_SECOND : 0,
                lastBookmark: videoPosition,
                packVtiId,
                purchaseDate: vhiDate,
                subType,
                type: typeof licenseTimeLeft === 'undefined' || licenseTimeLeft === null ? Purchase.Buy : Purchase.Rent,
              };
            }
          });
        } else {
          // BO API v1
          const { purchases = [] } = response;

          purchases.forEach((p) => {
            const { currentTime: lastBookmark, licenseTimeLeft, vhiDate, vtiId } = p;

            purchaseList[vtiId.toString()] = {
              expirationTime: convertTimeLeftToExpirationTime(licenseTimeLeft),
              lastBookmark,
              purchaseDate: convertToIsoDate(vhiDate),
              subType: PurchaseSubType.Movie,
              type: typeof licenseTimeLeft === 'undefined' || licenseTimeLeft === null ? Purchase.Buy : Purchase.Rent,
            };
          });
        }

        if (!skipStoreUpdate) {
          dispatch(updateBOPurchaseListForDistributor(distributorId, purchaseList, eTag));
        }

        return { distributorId, eTag, purchaseList };
      })
      .catch((error) => {
        if (error.getStatus() !== HttpStatus.NotModified) {
          logError(`Error retrieving purchase list for "${distributorId}": ${error.message}`);
        }
      });

export { getPurchaseList, getPurchaseListForDistributor };
