/* @flow */

import { HeaderName, HeaderValue } from '../../../../../libs/netgemLibrary/v8/constants/Headers';
import type { AppConfigurationState } from '../../../../appConf/reducers';
import type { NETGEM_API_V8_REQUEST_HEADERS } from '../../../../../libs/netgemLibrary/v8/types/Headers';
import { buildHeaderList } from '../../../../../libs/netgemLibrary/v8/helpers/RequestHeaders';
import { logError } from '../../../../../helpers/debug/debug';

const buildBOHeaders: (distributorId: string, appConfiguration: AppConfigurationState, extraHeaders?: Array<string>, useBOV2Api: boolean) => NETGEM_API_V8_REQUEST_HEADERS = (
  distributorId,
  appConfiguration,
  extraHeaders,
  useBOV2Api,
) => {
  const {
    distributorAppKeys: { [distributorId]: appKey },
  } = appConfiguration;

  if (!appKey) {
    logError(`Missing app key for distributor ${distributorId}`);
  }

  let headersConf = [HeaderName.ContentType, useBOV2Api ? HeaderValue.ApplicationJson : HeaderValue.ApplicationFormUrlencoded, HeaderName.AppKey, appKey];
  if (extraHeaders) {
    headersConf = headersConf.concat(extraHeaders);
  }

  return buildHeaderList(...headersConf);
};

export { buildBOHeaders };
