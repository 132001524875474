/* @flow */

import './Menu.css';
import * as React from 'react';

type MenuPropType = {|
  +children: React.Node,
|};

class Menu extends React.PureComponent<MenuPropType> {
  render(): React.Node {
    const { children } = this.props;

    return <div className='menuView'>{children}</div>;
  }
}

export default (Menu: React.ComponentType<MenuPropType>);
