/* @flow */

import { HeaderName, HeaderValue } from '../../v8/constants/Headers';
import { type CommonPromisedXMLHttpRequestReturnType } from '../../helpers/CommonPromisedXMLHttpRequest';
import { HttpMethod } from '../../v8/types/HttpMethod';
import { type NETGEM_API_DMS_REQUEST_RESPONSE_AUTHENTICATE } from '../types/RequestResponseAuthenticate';
import { buildHeaderList } from '../../v8/helpers/RequestHeaders';
import hmacSHA1 from 'crypto-js/hmac-sha1';
import sendDmsPromisedXMLHttpRequest from '../helpers/PromisedXMLHttpRequest';

const dmsLoginRequest: (baseAuthDeviceUrl: string, applicationId: string, subscriberId: string, deviceKey: string, oem: ?string, version: ?string, signal?: AbortSignal) => Promise<any> = (
  baseAuthDeviceUrl,
  applicationId,
  subscriberId,
  deviceKey,
  oem,
  version,
  signal,
) => {
  const requestParamArray = [];

  if (oem) {
    requestParamArray.push(`oem=${oem}`);
  }

  if (version) {
    requestParamArray.push(`version=${version}`);
  }

  const requestParam = requestParamArray.length > 0 ? `?${requestParamArray.join('&')}` : '';

  const authenticateBody = {
    applicationId,
    subscriberId,
  };

  const returnAuthenticateObject: CommonPromisedXMLHttpRequestReturnType = sendDmsPromisedXMLHttpRequest(
    `${baseAuthDeviceUrl}authenticate`,
    HttpMethod.POST,
    buildHeaderList(HeaderName.ContentType, HeaderValue.ApplicationJson),
    JSON.stringify(authenticateBody),
    requestParam,
    false,
    signal,
  );

  return returnAuthenticateObject.promise.then((authenticateJSONResponse: NETGEM_API_DMS_REQUEST_RESPONSE_AUTHENTICATE) => {
    const { challenge, opaque } = authenticateJSONResponse;
    const hmac = hmacSHA1(challenge, deviceKey).toString();

    const challengeBody = {
      applicationId,
      hmac,
      opaque,
      subscriberId,
    };

    // Running with HTTP method POST
    const returnChallengeObject: CommonPromisedXMLHttpRequestReturnType = sendDmsPromisedXMLHttpRequest(
      `${baseAuthDeviceUrl}challenge`,
      HttpMethod.POST,
      buildHeaderList(HeaderName.ContentType, HeaderValue.ApplicationJson),
      JSON.stringify(challengeBody),
      null,
      false,
      signal,
    );

    return returnChallengeObject.promise;
  });
};

export default dmsLoginRequest;
