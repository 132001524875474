/* @flow */

import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { type NETGEM_API_V8_LOCATION_RIGHTS, type NETGEM_API_V8_RIGHTS, type NETGEM_API_V8_RIGHTS_KIND, NETGEM_API_V8_RIGHTS_PLAYBACK } from '../../libs/netgemLibrary/v8/types/Rights';
import { PendingOperationReason, storePendingOperation } from './pendingOperations';
import type { CARD_DATA_MODAL_TYPE } from '../../components/modal/cardModal/CardModalConstsAndTypes';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_V8_ITEM_LOCATION_TYPE } from '../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_METADATA_SCHEDULE_LOCATION } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { getLocationType } from '../../libs/netgemLibrary/v8/helpers/Item';

const getRequiredLocationRights: (kind: NETGEM_API_V8_RIGHTS_KIND, rights: ?(NETGEM_API_V8_LOCATION_RIGHTS | NETGEM_API_V8_RIGHTS)) => ?Set<string> = (kind, rights) => {
  if (!rights) {
    // No rights found
    return null;
  }

  const { [kind]: kindRights } = rights;
  if (Array.isArray(kindRights)) {
    return new Set(kindRights);
  }

  // No rights found
  return null;
};

const getRequiredPlaybackRightsForLocationType: (locType: ?NETGEM_API_V8_ITEM_LOCATION_TYPE, rights: ?NETGEM_API_V8_RIGHTS) => ?Set<string> = (locType, rights) => {
  if (!rights) {
    // No rights found
    return null;
  }

  const { [NETGEM_API_V8_RIGHTS_PLAYBACK]: playbackRights } = rights;
  if (!playbackRights) {
    // No rights found
    return null;
  }

  if (locType) {
    const { [locType]: locationTypeRights } = playbackRights;
    if (locationTypeRights) {
      // Returning rights found for this location type on this channel
      return new Set(locationTypeRights);
    }
  }

  const { default: defaultRights } = playbackRights;
  if (defaultRights) {
    // Returning default rights found for this channel
    return new Set(defaultRights);
  }

  // No rights found
  return null;
};

const getRequiredRights: (
  kind: NETGEM_API_V8_RIGHTS_KIND,
  location: ?NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
  channelRights: ?NETGEM_API_V8_RIGHTS,
  defaultRights: ?NETGEM_API_V8_RIGHTS
) => Set<string> = (kind, location, channelRights, defaultRights) => {
  const locType = getLocationType(location?.id);

  // 1. Check at location level
  const requiredLocationRights = getRequiredLocationRights(kind, location?.rights);
  if (requiredLocationRights) {
    // Required rights have been found at location level
    return requiredLocationRights;
  }

  // 2. Check at channel level
  const requiredChannelRights = kind === NETGEM_API_V8_RIGHTS_PLAYBACK ? getRequiredPlaybackRightsForLocationType(locType, channelRights) : getRequiredLocationRights(kind, channelRights);
  if (requiredChannelRights) {
    // Required rights have been found at channel level
    return requiredChannelRights;
  }

  // 3. Check at default level
  const requiredDefaultRights = kind === NETGEM_API_V8_RIGHTS_PLAYBACK ? getRequiredPlaybackRightsForLocationType(locType, defaultRights) : getRequiredLocationRights(kind, defaultRights);
  if (requiredDefaultRights) {
    // Required rights have been found at default level
    return requiredDefaultRights;
  }

  // No rights found: returning an empty set to indicate that no rights are required
  return new Set<string>();
};

const getIntersectionRights: (rights1: Set<string>, rights2: Set<string>) => Set<string> = (rights1, rights2) => new Set([...rights1].filter((r) => rights2.has(r)));

const isAccessGranted: (requiredRights: Set<string>, userRights: Array<string> | null) => boolean = (requiredRights, userRights) => {
  if (requiredRights.size === 0) {
    // No required rights
    return true;
  }

  // Some rights are required: compare user rights against them
  return getIntersectionRights(requiredRights, new Set(userRights)).size > 0;
};

const getMatchingCommercialOffers: (requiredRights: Set<string>, commercialOffers: ?KeyValuePair<Array<string>>) => Array<string> = (requiredRights, commercialOffers) => {
  if (!commercialOffers) {
    return [];
  }

  const matchingOffers = new Set<string>();
  requiredRights.forEach((right) => commercialOffers[right]?.forEach((offer) => matchingOffers.add(offer)));
  return [...matchingOffers];
};

const showMissingSubscription: (data: CARD_DATA_MODAL_TYPE, requiredRights: Set<string>, commercialOffers: ?KeyValuePair<Array<string>>) => void = (data, requiredRights, commercialOffers) => {
  if (data.pendingOperation) {
    // Always true but Flow does not know it
    data.pendingOperation.reason = PendingOperationReason.RequireSubscription;
  }

  storePendingOperation(data);

  const matchingOffers = getMatchingCommercialOffers(requiredRights, commercialOffers);

  Messenger.emit(MessengerEvents.WATCH_RIGHTS_CHANGE, [...requiredRights]);
  Messenger.emit(MessengerEvents.SHOW_SUBSCRIBE, matchingOffers);
};

const areRightsDifferent: (r1: ?Array<string>, r2: ?Array<string>) => boolean = (r1, r2) => {
  if (!r1 && !r2) {
    return false;
  }

  if (!r1 || !r2 || r1.length !== r2.length) {
    return true;
  }

  const s2 = new Set(r2);
  return r1.some((r) => !s2.has(r));
};

export { areRightsDifferent, getMatchingCommercialOffers, getRequiredRights, isAccessGranted, showMissingSubscription };
