/* @flow */

import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import { SentryTagName, SentryTagValue } from '../debug/sentryTypes';
import { logError, logInfo } from '../debug/debug';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import { Localizer } from '@ntg/utils/dist/localization';
import React from 'react';
import SentryWrapper from '../debug/sentry';
import { Timer } from '@ntg/utils/dist/timer';
import { getPriceAsInteger } from '../maths/maths';

// After this time, page load is considered a failure (in ms)
const EXTERNAL_PAYMENT_SYSTEM_LOAD_TIMEOUT = 20_000;

let ChargebeeWrapper = null;

const initialize: (token: string, urls: KeyValuePair<string>, useBOV2Api: boolean) => void = (token, urls, useBOV2Api) => {
  if (!process.env.REACT_APP_CHARGEBEE_INSTANCE) {
    // Chargebee disabled
    return;
  }

  if (ChargebeeWrapper !== null) {
    // Chargebee already initialized
    ChargebeeWrapper.updateToken(token);
    return;
  }

  import('@ntg/utils/dist/payment/chargebee').then((module) => {
    ChargebeeWrapper = module.default;
    ChargebeeWrapper.initialize(token, urls, useBOV2Api).catch((error) => {
      Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div>{Localizer.localize('common.messages.errors.payment_system_not_loaded')}</div>);
      logError(error.message);
      SentryWrapper.error({
        breadcrumbs: ['ChargebeeWrapper.initialize error'],
        message: error.message,
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.PaymentSystem,
      });
    });
  });
};

const destroy: () => void = () => {
  if (!ChargebeeWrapper) {
    return;
  }

  ChargebeeWrapper.destroy();
  ChargebeeWrapper = null;
};

const showDebug: () => void = () => {
  if (!ChargebeeWrapper) {
    logInfo('Chargebee not initialized');
    return;
  }

  ChargebeeWrapper.showDebug();
};

const genericErrorCallback: (error: Error | string) => void = (error) => {
  const message = typeof error === 'string' ? error : error.message;
  Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div>{message}</div>);
};

const openSelfCare: () => void = () => {
  if (!ChargebeeWrapper) {
    logError('Chargebee not initialized');
    return;
  }

  const timer = new Timer(EXTERNAL_PAYMENT_SYSTEM_LOAD_TIMEOUT, genericErrorCallback, new Error(Localizer.localize('external_payment_system.portal.load_failure')));

  if (!ChargebeeWrapper) {
    timer.clear();
    return;
  }

  ChargebeeWrapper.openPortal(timer).catch((error) => {
    if (timer.timedOut) {
      // Ignore error since time out message has already been displayed
      return;
    }

    timer.clear();
    genericErrorCallback(error);
  });
};

const openCheckOut: (identity: string, vtiId: number, title: string, originalPrice: string, promoCode: string | null, discountPrice: string | null) => Promise<any> = (
  identity,
  vtiId,
  title,
  originalPrice,
  promoCode,
  discountPrice,
) => {
  if (!ChargebeeWrapper) {
    return Promise.reject(new Error('Chargebee not initialized'));
  }

  let description = title;
  if (promoCode) {
    description = `${description} (prix original : ${originalPrice})`;
  }

  const discountPriceAsInteger = discountPrice !== null ? getPriceAsInteger(discountPrice) : null;
  const timer = new Timer(EXTERNAL_PAYMENT_SYSTEM_LOAD_TIMEOUT, genericErrorCallback, new Error(Localizer.localize('external_payment_system.check_out.load_failure')));

  return new Promise((resolve, reject) => {
    ChargebeeWrapper?.openCheckOut(identity, vtiId, description, getPriceAsInteger(originalPrice), promoCode, discountPriceAsInteger, timer)
      .then(resolve)
      .catch((error) => {
        if (timer.timedOut) {
          // Ignore error since time out message has already been displayed
          return;
        }

        timer.clear();
        reject(error);
      });
  });
};

export { destroy, initialize, openSelfCare, openCheckOut, showDebug };
