/* @flow */

const parseBoolean: (value: ?string) => boolean = (value) => {
  if (typeof value !== 'string') {
    return false;
  }

  return ['1', 'true', 'enabled', 'yes'].includes(value.toLowerCase());
};

const parseFloat: (value: ?string, defaultValue?: number) => number = (value, defaultValue) => {
  if (typeof value === 'undefined' || value === null || isNaN(value)) {
    return defaultValue || -1;
  }

  return Number(value);
};

const parseInteger: (value: ?string, defaultValue?: number) => number = (value, defaultValue) => {
  const f = parseFloat(value, defaultValue);
  return Number.isInteger(f) ? f : defaultValue ?? -1;
};

const parseStringArray = (value: ?string, delimiter: string = ',', defaultValue: Array<string> = []): Array<string> => {
  if (typeof value !== 'string') {
    return defaultValue;
  }

  return value.split(delimiter).filter((s) => s);
};

const parseIntegerArray: (value: ?string, delimiter?: string) => Array<number> = (value, delimiter = ',') => {
  if (typeof value !== 'string') {
    return [];
  }

  return value
    .split(delimiter)
    .filter((s) => !isNaN(s))
    .map((s) => Number(s));
};

export { parseBoolean, parseFloat, parseInteger, parseIntegerArray, parseStringArray };
