/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import { REDUX_MSG_REQUEST_ENTITLEMENT_GET } from '../../constants/';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { generateApiUrl } from '../helpers/api';

const sendNtgEntitlementGetRequest: (customData: string, service: string, url: string, channelName: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (customData, service, url, channelName, signal) =>
  (dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();
    const {
      appRegistration: { applicationId },
      netgemApi: { entitlementGetUrl },
    } = state;

    if (!entitlementGetUrl) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_url_definition', { urlDef: 'entitlementGetUrl' }));
    }

    const { method, authent } = entitlementGetUrl;
    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_ENTITLEMENT_GET, {
      applicationId,
      authent,
      channelName,
      customData,
      method,
      service,
      signal,
      uri: generateApiUrl(entitlementGetUrl, {}, state),
      url,
    });
  };

export default sendNtgEntitlementGetRequest;
