/* @flow */

import type { ChannelMap, METRICS_PROPERTIES, METRICS_TYPE, MetricsStreamType } from '../../libs/netgemLibrary/v8/types/Channel';
import { FAKE_LOCATION, FAKE_SCHEDULED_EVENT_ITEM } from '../../components/avenue/section/SectionConstsAndTypes';
import { buildFeedItem, getFirstLocation } from '../../libs/netgemLibrary/v8/helpers/Item';
import { logDebug, logWarning } from '../debug/debug';
import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import type { CombinedReducers } from '../../redux/reducers';
import { FAKE_EPG_LIVE_PREFIX } from '../ui/item/types';
import type { LOCATION_METADATA_METRICS } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { NETGEM_API_URL_LIFECYCLE } from '../../libs/netgemLibrary/ntgEntitlement/types';
import type { NETGEM_API_V8_FEED } from '../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_RIGHTS } from '../../libs/netgemLibrary/v8/types/Rights';
import type { NETGEM_API_V8_SECTION } from '../../libs/netgemLibrary/v8/types/Section';
import { getValueForParameter } from '../../redux/netgemApi/actions/helpers/api';

const filterDuplicates: (feed: NETGEM_API_V8_FEED) => void = (feed) => {
  const duplicateChannels: Array<{| channelId: string, index: number |}> = [];
  const channels: Set<string> = new Set();

  feed.forEach((item, index) => {
    const {
      selectedLocation: { channelId },
    } = item;

    if (channelId) {
      if (channels.has(channelId)) {
        duplicateChannels.push({ channelId, index });
      } else {
        channels.add(channelId);
      }
    }
  });

  if (duplicateChannels.length > 0) {
    logWarning(`Live duplicates: ${duplicateChannels.length} (${duplicateChannels.map(({ channelId }) => channelId).join(', ')})`);
    duplicateChannels.reverse().forEach(({ index }) => feed.splice(index, 1));
  }
};

const addMissingChannels: (feed: NETGEM_API_V8_FEED, sectionChannels: Set<string>) => void = (feed, sectionChannels) => {
  // Find missing channels
  const missingChannels = new Set(sectionChannels);
  for (let i = 0; i < feed.length; i++) {
    const location = getFirstLocation(feed[i]);
    if (location?.channelId) {
      missingChannels.delete(location.channelId);
    }
  }

  if (missingChannels.size === 0) {
    // All channels have been found
    return;
  }

  logDebug('Missing channels in live feed:');
  missingChannels.forEach((channelId) => logDebug(` - ${channelId ?? '<no channel Id>'}`));
  logDebug('Feed:');
  logDebug(feed);

  // Add missing channels with fake (i.e. empty) scheduled events
  const now = AccurateTimestamp.nowInSeconds();
  missingChannels.forEach((channelId) => {
    const fakeItem = {
      ...FAKE_SCHEDULED_EVENT_ITEM,
      id: `${FAKE_EPG_LIVE_PREFIX}${channelId?.replace(/^.+\/([^/]+)$/u, '$1') ?? 'no-channel-id'}/${now}`,
    };
    const fakeLocation = {
      ...FAKE_LOCATION,
      channelId,
    };
    fakeItem.locations = [fakeLocation];
    const feedItem = buildFeedItem(fakeItem);
    if (feedItem) {
      feed.push(feedItem);
    }
  });
};

const getChannelsParameterName: (uri: string) => string | null = (uri) => {
  const sectionUri = new URL(uri);
  const channelsParam = [...sectionUri.searchParams.entries()].find(([name]) => name === 'channels');

  if (!channelsParam || channelsParam.length <= 1) {
    // No parameter for 'channels'
    return null;
  }

  return channelsParam[1].slice(1, -1);
};

const getSectionChannels: (section: NETGEM_API_V8_SECTION, channelsParameter: string | null, allChannels: ChannelMap, state: CombinedReducers) => Set<string> = (
  section,
  channelsParameter,
  allChannels,
  state,
) => {
  const {
    appConfiguration: { deviceChannels, listAlias, listAliasChannelLists },
  } = state;

  const allChannelIds = [...Object.keys(deviceChannels)];

  const getAllSectionChannels = (): Array<string> => {
    if (!section.model || !section.model.params || channelsParameter === null) {
      // No model or no parameter
      return allChannelIds;
    }

    const {
      model: { params },
    } = section;

    // Value look like: "{channelList}"
    const channelsParameterName = channelsParameter.slice(1, -1);

    const param = params.find((p) => p.name === channelsParameterName);

    if (!param) {
      // Parameter not found
      return allChannelIds;
    }

    const channelListOrListAlias = getValueForParameter(param.value, null, state);

    if (!channelListOrListAlias) {
      // Parameter lead to nothing
      return [];
    }

    if (Array.isArray(channelListOrListAlias)) {
      // List of channel Ids
      return channelListOrListAlias;
    }

    if (channelListOrListAlias.startsWith('[')) {
      // Channel list
      return JSON.parse(channelListOrListAlias);
    }

    // Check master list alias first
    if (channelListOrListAlias === listAlias) {
      // Value is list alias for all channels
      return allChannelIds;
    }

    // Check all other list aliases
    const { [channelListOrListAlias]: channelList } = listAliasChannelLists;

    // Value is another list alias
    return channelList;
  };

  return new Set(getAllSectionChannels().filter((channelId) => !allChannels[channelId].isHidden));
};

const getBooleanSetting: (channels: ChannelMap, channelId: ?string, settingName: string) => boolean = (channels, channelId, settingName) => {
  if (!channelId) {
    return false;
  }

  if (settingName !== 'hasCatchup' && settingName !== 'hasKeepFromReplay' && settingName !== 'hasStartover' && settingName !== 'hasTimeshift' && settingName !== 'isRecordable') {
    // Not a known setting
    return false;
  }

  const { [channelId]: channel } = channels;
  return channel?.consolidated?.[settingName] ?? false;
};

const getChannelImageId: (channels: ChannelMap, channelId: ?string) => string | null = (channels, channelId) => {
  if (!channelId) {
    // Universe, channel, channel group, etc.
    return null;
  }

  const { [channelId]: channel } = channels;
  return channel?.consolidated?.imageId ?? null;
};

const getChannelRights: (channels: ChannelMap, channelId: ?string) => ?NETGEM_API_V8_RIGHTS = (channels, channelId) => {
  if (!channelId) {
    // Universe, channel, channel group, etc.
    return null;
  }

  const { [channelId]: channel } = channels;
  return channel?.info?.rights ?? null;
};

const getChannelName: (channels: ChannelMap, channelId: ?string) => string = (channels, channelId) => {
  if (!channelId) {
    return '';
  }

  const { [channelId]: channel } = channels;
  return channel?.name ?? '';
};

const getChannelNumber: (channels: ChannelMap, channelId: ?string) => number = (channels, channelId) => {
  if (!channelId) {
    return -1;
  }

  const { [channelId]: channel } = channels;
  return channel?.number ?? -1;
};

const getChannelGroupId: (channels: ChannelMap, channelId: ?string) => string | null = (channels, channelId) => {
  if (!channelId) {
    return null;
  }

  const { [channelId]: channel } = channels;
  return channel?.info?.channelGrId ?? null;
};

const getChannelListFromGroupId: (channels: ChannelMap, channelGroupId: string | null) => Array<string> = (channels, channelGroupId) => {
  if (!channelGroupId) {
    return [];
  }

  const channelList = [];

  for (const { epgid, info } of Object.keys(channels).map((key) => channels[key])) {
    if (info?.channelGrId === channelGroupId) {
      channelList.push(epgid);
    }
  }

  return channelList;
};

const getChannelListFromTag: (channels: ChannelMap, tag: string) => Array<string> = (channels, tag) => {
  const channelList = [];

  for (const { epgid, info } of Object.keys(channels).map((key) => channels[key])) {
    if (info) {
      const { tags } = info;

      if (tags && tags.indexOf(tag) > -1) {
        channelList.push(epgid);
      }
    }
  }

  return channelList;
};

const getAllTags: (channels: ChannelMap) => Array<string> = (channels) => {
  const allTags = new Set<string>();

  for (const { info } of Object.keys(channels).map((key) => channels[key])) {
    if (info) {
      const { tags } = info;
      tags?.forEach((tag) => allTags.add(tag));
    }
  }

  return [...allTags];
};

const getChannelMetrics: (
  channels: ChannelMap,
  channelId: ?string,
  type: METRICS_TYPE,
  streamType: MetricsStreamType,
  locationMetrics: ?Array<LOCATION_METADATA_METRICS>,
) => METRICS_PROPERTIES | null = (channels, channelId, type, streamType, locationMetrics) => {
  if (!channelId) {
    return null;
  }

  const { [channelId]: channel } = channels;
  if (channel?.info?.metrics) {
    const {
      info: { metrics },
    } = channel;

    const typeMetrics = metrics.find((m) => m.type === type);
    if (typeMetrics) {
      const metricsProperties = typeMetrics.properties.find((m) => m.type === streamType);

      if (metricsProperties) {
        const typeLocationMetrics = locationMetrics?.find((m) => m.type === type);

        if (typeLocationMetrics) {
          return {
            ...metricsProperties,
            params: {
              ...metricsProperties.params,
              ...typeLocationMetrics.properties,
            },
          };
        }

        return metricsProperties;
      }
    }
  }

  return null;
};

const getChannelUrlLifecycle: (channels: ChannelMap, channelId: ?string) => NETGEM_API_URL_LIFECYCLE | null = (channels, channelId) => {
  if (!channelId) {
    return null;
  }

  const { [channelId]: channel } = channels;
  return channel?.data.urlLifecycle ?? null;
};

const channelHasCatchup: (channels: ChannelMap, channelId: ?string) => boolean = (channels, channelId) => getBooleanSetting(channels, channelId, 'hasCatchup');

const channelHasKeepFromReplay: (channels: ChannelMap, channelId: ?string) => boolean = (channels, channelId) => getBooleanSetting(channels, channelId, 'hasKeepFromReplay');

const channelHasStartover: (channels: ChannelMap, channelId: ?string) => boolean = (channels, channelId) => getBooleanSetting(channels, channelId, 'hasStartover');

const channelHasTimeshift: (channels: ChannelMap, channelId: ?string) => boolean = (channels, channelId) => getBooleanSetting(channels, channelId, 'hasTimeshift');

const channelIsRecordable: (channels: ChannelMap, channelId: ?string) => boolean = (channels, channelId) => getBooleanSetting(channels, channelId, 'isRecordable');

export {
  addMissingChannels,
  channelHasCatchup,
  channelHasKeepFromReplay,
  channelHasStartover,
  channelHasTimeshift,
  channelIsRecordable,
  filterDuplicates,
  getAllTags,
  getChannelGroupId,
  getChannelImageId,
  getChannelUrlLifecycle,
  getChannelListFromGroupId,
  getChannelListFromTag,
  getChannelMetrics,
  getChannelName,
  getChannelNumber,
  getChannelRights,
  getChannelsParameterName,
  getSectionChannels,
};
