/* @flow */

import { type NETGEM_API_V8_AGGREGATION_FEED } from './AggregationFeed';
import { type NETGEM_API_V8_NTG_VIDEO_FEED } from './NtgVideoFeed';
import { type NETGEM_API_V8_WIDGET } from './Widget';
import { SectionType } from '../../../../helpers/ui/section/types';

export const LocalFeed = Object.freeze({
  Channels: 'channels/list',
  ViewingHistory: 'viewinghistory',
  Wishlist: 'wishlist',
});

export type NETGEM_API_V8_SECTION = {|
  ...NETGEM_API_V8_WIDGET,
  kind: SectionType,
  model: NETGEM_API_V8_AGGREGATION_FEED | NETGEM_API_V8_NTG_VIDEO_FEED,
  version: number,
|};
