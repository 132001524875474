/* @flow */

import AccurateTimestamp from '../dateTime/AccurateTimestamp';
import type { KeyValuePair } from '@ntg/utils/dist/types';

const E_TAGS: KeyValuePair<{| eTag: string | null, timestamp: number |}> = {};

const getETag: (url: string) => string | null = (url) => E_TAGS[url]?.eTag ?? null;

const setETag: (url: string, eTag: string | null) => void = (url, eTag) => {
  E_TAGS[url] = {
    eTag,
    timestamp: AccurateTimestamp.now(),
  };
};

export { getETag, setETag };
