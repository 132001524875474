/* @flow */

import { BO_API_FAVORITE_LIST, type BO_RESPONSE_WITH_ETAG } from './types/common';
import { HeaderName, HeaderValue } from '../../../../libs/netgemLibrary/v8/constants/Headers';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { HttpStatus } from '../../../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import { REDUX_MSG_REQUEST_BO_FAVORITE_LIST } from '../../constants';
import { createCustomNetworkErrorFromKey } from '../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import getRequestDetails from './helpers/requestCommon';
import { logError } from '../../../../helpers/debug/debug';
import { updateBOFavoriteList } from '../../../ui/actions';

const getFavoriteList: (signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration: { mainDistributorId, useBOV2Api },
      ui: { favoriteListETag },
    } = getState();

    if (!mainDistributorId) {
      return Promise.reject(
        createCustomNetworkErrorFromKey('common.messages.errors.missing_parameters_list', {
          list: 'mainDistributorId',
          name: 'Get favorite list',
        }),
      );
    }

    return dispatch(getRequestDetails(mainDistributorId, BO_API_FAVORITE_LIST, useBOV2Api, undefined, [HeaderName.Accept, HeaderValue.ApplicationJson]))
      .then((requestDetails) =>
        // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
        NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_FAVORITE_LIST, {
          ...requestDetails,
          eTag: favoriteListETag,
          signal,
        }),
      )
      .then(({ eTag, response }: BO_RESPONSE_WITH_ETAG) => {
        if (signal?.aborted) {
          return;
        }

        // BO returns HTTP 204 when list is empty (hence result is null)

        let favorites: Array<string> = [];

        if (response?.favorites) {
          // Old API
          favorites = response.favorites.map((f) => f.titId);
        } else if (response?.body) {
          // BO API v2
          favorites = response.body.map((f) => f.data.itemId.toString());
        }

        dispatch(updateBOFavoriteList(favorites, eTag));
      })
      .catch((error) => {
        if (error.getStatus() !== HttpStatus.NotModified) {
          logError(`Error retrieving favorite list for "${mainDistributorId}": ${error.message}`);
        }
      });
  };

export default getFavoriteList;
