/* @flow */

import type { BO_API_GET_STREAMS_TYPE, BO_API_STREAM_TYPE } from '../types/stream';
import type { BO_API_REQUEST_RESPONSE_BASE } from '../../../../../libs/netgemLibrary/videofutur/types/RequestResponseBase';
import type { CombinedReducers } from '../../../../reducers';
import type { Dispatch } from '../../../../types/types';
import { Localizer } from '@ntg/utils/dist/localization';
import type { RequestResponseMethodDefinitionType } from '../../emitter';
import { createCustomNetworkErrorFromKey } from '../../../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { logError } from '../../../../../helpers/debug/debug';
import sendGetStreamsRequest from '../getStreams';
import sendStreamStopRequest from '../streamStop';

const stopStreamsForThisDevice: (distributorId: string, streams: Array<BO_API_STREAM_TYPE>, applicationId: string, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (distributorId, streams, applicationId, bookmark, signal) =>
  (dispatch: Dispatch): Promise<any> => {
    const promises = streams.filter((stream) => stream.appId === applicationId).map((stream) => dispatch(sendStreamStopRequest(distributorId, stream.id, undefined, signal)));
    return Promise.allSettled(promises);
  };

const stopOpenStreams: (distributorId: string | null, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (distributorId, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers): Promise<any> => {
    const state = getState();
    const {
      appConfiguration: { mainDistributorId },
      appRegistration: { applicationId },
    } = state;

    if (!applicationId) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_application_id'));
    }

    const distributor = distributorId ?? mainDistributorId;

    if (!distributor) {
      return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.missing_distributor_id'));
    }

    return dispatch(sendGetStreamsRequest(distributor, signal)).then((response: BO_API_REQUEST_RESPONSE_BASE) => {
      const { body, streams } = (response: BO_API_GET_STREAMS_TYPE);

      const openStreamCount = (body ?? streams).length;

      if (openStreamCount > 0) {
        dispatch(stopStreamsForThisDevice(distributor, body ? mapStreams(body) : streams, applicationId, signal)).catch(() =>
          logError(Localizer.localize('common.messages.errors.streams_stop_failure')),
        );
      }

      return Promise.resolve(openStreamCount);
    });
  };

// TODO: use correct type (BO API v2)
const mapStreams = (body: any): Array<BO_API_STREAM_TYPE> =>
  body.map((stream) => {
    const {
      data: { appId, vstId },
    } = stream;

    return {
      appId,
      id: vstId,
    };
  });

export { stopOpenStreams };
