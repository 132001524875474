/* @flow */

import type { BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE, BO_PURCHASE_LIST_ITEM_TYPE, BO_PURCHASE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/purchase';
import type { NETGEM_API_VIEWINGHISTORY_ITEM, NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS, VIEWING_HISTORY_TYPE } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import type { NETGEM_API_WISHLIST, NETGEM_API_WISHLIST_ITEM } from '../../../libs/netgemLibrary/v8/types/Wishlist';
import type { BO_FAVORITE_LIST_TYPE } from '../../../redux/netgemApi/actions/videofutur/types/favorite';
import type { NETGEM_API_V8_AVENUE } from '../../../libs/netgemLibrary/v8/types/Avenue';
import type { NETGEM_API_V8_HUB } from '../../../libs/netgemLibrary/v8/types/Hub';

const areArraysDifferent: (a1: Array<string>, a2: Array<string>) => boolean = (a1, a2) => a1.concat(a2).filter((val, index, arr) => arr.indexOf(val) === arr.lastIndexOf(val)).length > 0;

const areAvenuesDifferent: (avenue1: ?NETGEM_API_V8_AVENUE, avenue2: ?NETGEM_API_V8_AVENUE) => boolean = (avenue1, avenue2) => {
  if (!avenue1 && !avenue2) {
    return false;
  }

  if (!avenue1 || !avenue2) {
    return true;
  }

  return JSON.stringify(avenue1) !== JSON.stringify(avenue2);
};

const areHubsDifferent: (hub1: ?NETGEM_API_V8_HUB, hub2: ?NETGEM_API_V8_HUB) => boolean = (hub1, hub2) => {
  if (!hub1 && !hub2) {
    return false;
  }

  if (!hub1 || !hub2 || hub1.length !== hub2.length) {
    return true;
  }

  return JSON.stringify(hub1) !== JSON.stringify(hub2);
};

const arePurchasedItemsDifferent: (item1: ?BO_PURCHASE_LIST_ITEM_TYPE, item2: ?BO_PURCHASE_LIST_ITEM_TYPE) => boolean = (item1, item2) => {
  if (!item1 && !item2) {
    return false;
  }

  if (!item1 || !item2) {
    return true;
  }

  return item1.lastBookmark !== item2.lastBookmark || item1.purchaseDate !== item2.purchaseDate || item1.expirationTime !== item2.expirationTime || item1.type !== item2.type;
};

const arePurchaseListsByDistributorDifferent: (l1: BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE, l2: BO_PURCHASE_LIST_BY_DISTRIBUTOR_TYPE) => boolean = (l1, l2) => {
  // VTI Ids
  const keys1 = Array.from(Object.keys(l1));
  const keys2 = Array.from(Object.keys(l2));

  if (keys1.length !== keys2.length || areArraysDifferent(keys1, keys2)) {
    return true;
  }

  for (const key of keys1) {
    // Purchased items
    if (arePurchasedItemsDifferent(l1[key], l2[key])) {
      return true;
    }
  }

  return false;
};

const arePurchaseListsDifferent: (l1: BO_PURCHASE_LIST_TYPE | null, l2: BO_PURCHASE_LIST_TYPE | null) => boolean = (l1, l2) => {
  if (!l1 && !l2) {
    return false;
  }

  if (!l1 || !l2) {
    return true;
  }

  // Distributor Ids
  const keys1 = Array.from(Object.keys(l1));
  const keys2 = Array.from(Object.keys(l2));

  if (keys1.length !== keys2.length || areArraysDifferent(keys1, keys2)) {
    return true;
  }

  for (const key of keys1) {
    // Purchase list by distributor
    if (arePurchaseListsByDistributorDifferent(l1[key], l2[key])) {
      return true;
    }
  }

  return false;
};

const arePlayedItemsDifferent: (playedItems1?: NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS, playedItems2?: NETGEM_API_VIEWINGHISTORY_PLAYED_ITEMS) => boolean = (playedItems1, playedItems2) => {
  if (!playedItems1 && !playedItems2) {
    return false;
  }

  if (!playedItems1 || !playedItems2) {
    return true;
  }

  const keys1 = Array.from(Object.keys(playedItems1));
  const keys2 = Array.from(Object.keys(playedItems2));

  if (keys1.length !== keys2.length || areArraysDifferent(keys1, keys2)) {
    return true;
  }

  for (const key of keys1) {
    const { [key]: item1 } = playedItems1;
    const { [key]: item2 } = playedItems2;

    if (item1.date !== item2.date || item1.position !== item2.position) {
      return true;
    }
  }

  return false;
};

const areViewingHistoryItemsDifferent: (item1: NETGEM_API_VIEWINGHISTORY_ITEM, item2: NETGEM_API_VIEWINGHISTORY_ITEM) => boolean = (item1, item2) =>
  item1.date !== item2.date || item1.id !== item2.id || item1.rating !== item2.rating || item1.watchingstatus !== item2.watchingstatus;

const areViewingHistoriesDifferent: (l1?: VIEWING_HISTORY_TYPE, l2?: VIEWING_HISTORY_TYPE) => boolean = (l1, l2) => {
  if (l1 === l2) {
    return false;
  }

  if (typeof l1 === 'undefined' || typeof l2 === 'undefined') {
    return true;
  }

  if (l1.length !== l2.length) {
    return true;
  }

  for (let i = 0; i < l1.length; ++i) {
    const { [i]: item1 } = l1;
    const { [i]: item2 } = l2;
    if (areViewingHistoryItemsDifferent(item1, item2)) {
      return true;
    }

    const { playeditems: playedItems1 } = item1;
    const { playeditems: playedItems2 } = item2;
    if ((playedItems1 && !playedItems2) || (!playedItems1 && playedItems2)) {
      return true;
    } else if (playedItems1 && playedItems2 && arePlayedItemsDifferent(playedItems1, playedItems2)) {
      return true;
    }
  }

  return false;
};

const areWishlistItemsDifferent: (item1: NETGEM_API_WISHLIST_ITEM, item2: NETGEM_API_WISHLIST_ITEM) => boolean = (item1, item2) =>
  item1.channelId !== item2.channelId || item1.date !== item2.date || item1.id !== item2.id || item1.type !== item2.type;

// Given wishlists are sorted by date
const areWishlistsDifferent: (l1?: NETGEM_API_WISHLIST, l2?: NETGEM_API_WISHLIST) => boolean = (l1, l2) => {
  if (l1 === l2) {
    return false;
  }

  if (typeof l1 === 'undefined' || typeof l2 === 'undefined') {
    return true;
  }

  if (l1.length !== l2.length) {
    return true;
  }

  for (let i = 0; i < l1.length; ++i) {
    if (areWishlistItemsDifferent(l1[i], l2[i])) {
      return true;
    }
  }

  return false;
};

const areFavoriteListsDifferent: (l1: BO_FAVORITE_LIST_TYPE, l2: BO_FAVORITE_LIST_TYPE) => boolean = (l1, l2) => {
  if (l1.length !== l2.length) {
    return true;
  }

  for (let i = 0; i < l1.length; ++i) {
    if (l1[i] !== l2[i]) {
      return true;
    }
  }

  return false;
};

export { areAvenuesDifferent, areFavoriteListsDifferent, areHubsDifferent, arePlayedItemsDifferent, arePurchaseListsDifferent, areViewingHistoriesDifferent, areWishlistsDifferent };
