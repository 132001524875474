/* @flow */

import './mu-7.3.1.js';
import { DEFAULT_MEASURE, MediametrieDiffusion, MediametrieDiffusionMode, MediametrieState, MediametrieStateEvent, type STREAM_ANALYTICS_CONFIGURATION_TYPE } from './types';
import { type METRICS_PROPERTIES, MetricsStreamType } from '../../libs/netgemLibrary/v8/types/Channel';
import DidomiWrapper from '../consent/didomi';

/*
 * Switching from LIVE to TIMESHIFTING when reader head is more than 60s behind live and switching back to LIVE when it's less than 50s behind live (in seconds)
 * See 3.1.1 in "Mesure 4 Ecrans - Plan de marquage eStat 3 niveaux TV - TF1 - V3.0 - 11042016.pdf"
 */
const DOWNWARD_TIMESHIFT_THRESHOLD = 60;
const UPWARD_TIMESHIFT_THRESHOLD = 50;

const addIfDefined = (obj: any, key: string, value: ?string) => {
  if (typeof value === 'string') {
    obj[key] = value;
  }
};

const callbackStateAsNumber =
  (callbackState: () => MediametrieState): (() => number) =>
  () =>
    (callbackState(): number);

export default class Mediametrie {
  // $FlowFixMe: eStartTag is defined in mu-*.js script but bindings are not exported
  contentStreamTag: eStatTag;

  constructor(metrics: METRICS_PROPERTIES, playerName: string, playerVersion: string, callbackState: () => MediametrieState, callbackPosition: () => number, forceOptOutConsent: boolean) {
    const {
      params: { chId, cmsGR, cmsSN, dom, miCh, msCh, msCid, msDm },
      serial,
      type,
    } = metrics;

    const isLive = type === MetricsStreamType.Live;

    const diffusion = isLive ? MediametrieDiffusion.Timeshifting : MediametrieDiffusion.Replay;

    // Following is always initialized to 'LIVE' when diffusion === 'timeshifting', but could change later to 'TIMESHIFTING' depending on user's actions
    const mediaDiffMode = isLive ? MediametrieDiffusionMode.Live : msDm || MediametrieDiffusionMode.Tvod;

    const configuration: STREAM_ANALYTICS_CONFIGURATION_TYPE = {
      consentString: DidomiWrapper.getConsentString(),
      consentType: DidomiWrapper.isMediametrieEnabled() && !forceOptOutConsent ? 'optin' : 'optout',
      domain: dom,
      measure: DEFAULT_MEASURE,
      mediaInfo: {
        mediaChannel: msCh || chId,
        mediaContentId: isLive ? '0' : msCid,
        mediaDiffMode: (mediaDiffMode: string),
      },
      serial,
      streaming: {
        callbackPosition,
        callbackState: callbackStateAsNumber(callbackState),
        diffusion: (diffusion: string),
        playerName,
        playerVersion,
        probe: true,
        streamName: isLive ? '-' : cmsSN || '-',
      },
    };

    if (!isLive) {
      addIfDefined(configuration.streaming, 'streamGenre', cmsGR);
    }

    if (miCh) {
      configuration.netMeasurement = miCh;
    }

    this.addLevels(configuration, metrics);
    this.addNewLevels(configuration, metrics);

    // $FlowFixMe: eStartTag is defined in mu-*.js script but bindings are not exported
    this.contentStreamTag = new eStatTag(configuration); // eslint-disable-line new-cap
  }

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'Mediametrie';
  }

  destroy: () => void = () => {
    const { contentStreamTag } = this;

    if (contentStreamTag) {
      contentStreamTag.disable();
    }
  };

  addLevels: (configuration: STREAM_ANALYTICS_CONFIGURATION_TYPE, metrics: METRICS_PROPERTIES) => void = (configuration, metrics) => {
    const {
      params: { cmsS1, cmsS2, cmsS3, cmsS4, cmsS5 },
    } = metrics;

    if (cmsS1 || cmsS2 || cmsS3 || cmsS4 || cmsS5) {
      configuration.levels = {};
      addIfDefined(configuration.levels, 'level_1', cmsS3);
      addIfDefined(configuration.levels, 'level_2', cmsS2);
      addIfDefined(configuration.levels, 'level_3', cmsS1);
      addIfDefined(configuration.levels, 'level_4', cmsS4);
      addIfDefined(configuration.levels, 'level_5', cmsS5);
    }
  };

  addNewLevels: (configuration: STREAM_ANALYTICS_CONFIGURATION_TYPE, metrics: METRICS_PROPERTIES) => void = (configuration, metrics) => {
    const {
      params: { ml1, ml10, ml11, ml2, ml3, ml4, ml5, ml6, ml7, ml8, ml9 },
    } = metrics;

    if (ml1 || ml2 || ml3 || ml4 || ml5 || ml6 || ml7 || ml8 || ml9 || ml10 || ml11) {
      configuration.newLevels = {};
      addIfDefined(configuration.newLevels, 'newLevel_1', ml1);
      addIfDefined(configuration.newLevels, 'newLevel_2', ml2);
      addIfDefined(configuration.newLevels, 'newLevel_3', ml3);
      addIfDefined(configuration.newLevels, 'newLevel_4', ml4);
      addIfDefined(configuration.newLevels, 'newLevel_5', ml5);
      addIfDefined(configuration.newLevels, 'newLevel_6', ml6);
      addIfDefined(configuration.newLevels, 'newLevel_7', ml7);
      addIfDefined(configuration.newLevels, 'newLevel_8', ml8);
      addIfDefined(configuration.newLevels, 'newLevel_9', ml9);
      addIfDefined(configuration.newLevels, 'newLevel_10', ml10);
      addIfDefined(configuration.newLevels, 'newLevel_11', ml11);
    }
  };

  setDuration: (duration: number) => void = (duration) => {
    const { contentStreamTag } = this;

    contentStreamTag.set({ streaming: { streamDuration: Math.round(duration) } });
  };

  updateTimeshift: (value: number) => void = (value) => {
    const {
      contentStreamTag,
      contentStreamTag: {
        configuration: {
          mediaInfo: { mediaDiffMode },
        },
      },
    } = this;

    if (mediaDiffMode === MediametrieDiffusionMode.Live && value >= DOWNWARD_TIMESHIFT_THRESHOLD) {
      // Stop session because we're behind the live stream
      this.notifyStop();
      contentStreamTag.set({ mediaInfo: { mediaDiffMode: (MediametrieDiffusionMode.Timeshifting: string) } });
      this.notifyPlay();
    } else if (mediaDiffMode === MediametrieDiffusionMode.Timeshifting && value <= UPWARD_TIMESHIFT_THRESHOLD) {
      // Stop session because we're not timeshifting anymore
      this.notifyStop();
      contentStreamTag.set({ mediaInfo: { mediaDiffMode: (MediametrieDiffusionMode.Live: string) } });
      this.notifyPlay();
    }
  };

  notifyPlay: () => void = () => {
    const { contentStreamTag } = this;

    contentStreamTag.notifyPlayer((MediametrieStateEvent.Play: string));
  };

  notifyPause: () => void = () => {
    const { contentStreamTag } = this;

    contentStreamTag.notifyPlayer((MediametrieStateEvent.Pause: string));
  };

  notifyStop: () => void = () => {
    const { contentStreamTag } = this;

    contentStreamTag.notifyPlayer((MediametrieStateEvent.Stop: string));
  };
}
