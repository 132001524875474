/* @flow */

import './Header.css';
import * as React from 'react';
import { type HeaderPropType, LOGO_LOAD_TIMEOUT } from './HeaderConstsAndTypes';
import { buildSecurityInfo, getHeaderClass, logSecurityInfo, renderFreeTrialButton, renderSubscriptionPresentationButton } from './HeaderHelper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppHeaderLogo } from '../../helpers/applicationCustomization/ui';
import { AvenueType } from '../../helpers/ui/avenue/types';
import HotKeys from '../../helpers/hotKeys/hotKeys';
import NavigationMenuView from '../navigationMenu/NavigationMenu';
import { RegistrationType } from '../../redux/appRegistration/types/types';
import SearchBox from '../search/SearchBox';
import UserMenu from './UserMenu';
import { updateFocusedAvenue } from '../../redux/ui/actions';

const Header = ({ isScrolling, onLogoLoaded, onSearch }: HeaderPropType): React.Node => {
  const dispatch = useDispatch();

  const [isLogoLoaded, setIsLogoLoaded] = useState<boolean>(false);
  const [headerClass, setHeaderClass] = useState<string>('header');

  const applicationName = useSelector((state) => state.appConfiguration.applicationName);
  const focusedAvenue = useSelector((state) => state.ui.focusedAvenue);
  const mainAvenue = useSelector((state) => state.ui.mainAvenue);
  const isDebugModeEnabled = useSelector((state) => state.appConfiguration.isDebugModeEnabled);
  const isInTheaterMode = useSelector((state) => state.ui.isInTheaterMode);
  const securityInfo = useSelector((state) => buildSecurityInfo(state));
  const subscriptionPresentationDisplay = useSelector((state) => state.appConfiguration.subscriptionPresentationDisplay);
  const subscriptionPresentationUrl = useSelector((state) => state.appConfiguration.configuration.subscriptionPresentationUrl ?? '');
  const hasFreeTrialButton = useSelector((state) => state.appConfiguration.hasFreeTrialButton);
  const freeTrialUrl = useSelector((state) => state.appConfiguration.configuration.subscribeWebUrl ?? '');
  const isRegisteredAsGuest = useSelector((state) => state.appRegistration.registration === RegistrationType.RegisteredAsGuest);

  const logoLoadTimer = useRef<TimeoutID | null>(null);
  const searchBoxRef = useRef<React.ElementRef<any> | null>(null);

  const resetLogoLoadTimer = useCallback(() => {
    if (logoLoadTimer.current) {
      clearTimeout(logoLoadTimer.current);
      logoLoadTimer.current = null;
    }
  }, []);

  const handleHeaderImageOnLoad = useCallback(() => {
    resetLogoLoadTimer();
    setIsLogoLoaded(true);
    onLogoLoaded();
  }, [onLogoLoaded, resetLogoLoadTimer]);

  useEffect(() => {
    const handleOpenSearchBoxHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
      if ((!focusedAvenue || focusedAvenue.type !== AvenueType.Search) && searchBoxRef.current && searchBoxRef.current.isClosed()) {
        event.preventDefault();
        event.stopPropagation();
        searchBoxRef.current?.open();
      }
    };

    HotKeys.register('s', handleOpenSearchBoxHotKey, { name: 'Header.openSearch' });
    logoLoadTimer.current = setTimeout(handleHeaderImageOnLoad, LOGO_LOAD_TIMEOUT);

    return () => {
      HotKeys.unregister('s', handleOpenSearchBoxHotKey);
      resetLogoLoadTimer();
    };
  }, [focusedAvenue, handleHeaderImageOnLoad, resetLogoLoadTimer, searchBoxRef.current]);

  useEffect(() => {
    setHeaderClass(getHeaderClass(isInTheaterMode, isLogoLoaded, isScrolling));
  }, [isInTheaterMode, isLogoLoaded, isScrolling]);

  const handleHeaderImageOnClick = useCallback(
    (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
      if (isDebugModeEnabled && (event.ctrlKey || event.altKey) && event.shiftKey) {
        logSecurityInfo(securityInfo);
        return;
      }

      dispatch(updateFocusedAvenue(mainAvenue.index, mainAvenue.type));
    },
    [isDebugModeEnabled, mainAvenue, securityInfo],
  );

  const handleOnSearch = useCallback(
    (searchString: string) => {
      onSearch(searchString);
    },
    [onSearch],
  );

  return (
    <div className={headerClass}>
      <div className='headerLeft'>
        <img alt={applicationName} className='headerImage' draggable={false} onClick={handleHeaderImageOnClick} onLoad={handleHeaderImageOnLoad} src={AppHeaderLogo} />
      </div>
      <div className='headerMiddle'>
        <NavigationMenuView />
      </div>
      <div className='headerRight'>
        {renderSubscriptionPresentationButton(subscriptionPresentationDisplay, subscriptionPresentationUrl, dispatch)}
        <SearchBox onSearch={handleOnSearch} ref={searchBoxRef} />
        {renderFreeTrialButton(isRegisteredAsGuest, hasFreeTrialButton, freeTrialUrl)}
        <UserMenu />
      </div>
    </div>
  );
};

export default Header;
