/* @flow */

import * as React from 'react';
import { ISO8601_DURATION_ZERO, formatDate, formatIso8601Duration } from '../../../helpers/dateTime/Format';
import { ItemContent, ItemType, type NETGEM_API_V8_FEED_ITEM, NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT } from '../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_METADATA_PROGRAM, NETGEM_API_V8_METADATA_SERIES } from '../../../libs/netgemLibrary/v8/types/MetadataProgram';
import { BOVodAssetStatus } from '../../../helpers/videofutur/metadata';
import { LoadableStatus } from '../../../helpers/loadable/loadable';
import { Localizer } from '@ntg/utils/dist/localization';
import { MetadataStatus } from './ItemConstsAndTypes';
import type { NETGEM_API_VIEWINGHISTORY } from '../../../libs/netgemLibrary/v8/types/ViewingHistory';
import { TileConfig } from '../../../libs/netgemLibrary/v8/types/WidgetConfig';
import type { VOD_STATUS_TYPE } from '../../../helpers/ui/metadata/Types';
import clsx from 'clsx';
import { getProgress } from '../../../helpers/viewingHistory/ViewingHistory';
import { getStartAndEndTimes } from '../../../helpers/ui/metadata/Format';

const getTimeText = (item: NETGEM_API_V8_FEED_ITEM, now: number, description: Set<TileConfig>): string | null => {
  const {
    selectedLocation: { duration, scheduledEventDuration },
  } = item;

  const timeParts: Array<string> = [];

  if (description.has(TileConfig.Date)) {
    const { endTime, startTime } = getStartAndEndTimes(item);
    timeParts.push(formatDate(startTime, endTime, now));
  }

  if (description.has(TileConfig.Duration)) {
    const localDuration = scheduledEventDuration ?? duration;
    const durationText = localDuration !== ISO8601_DURATION_ZERO ? formatIso8601Duration(localDuration) : '';

    if (durationText) {
      timeParts.push(`(${durationText})`);
    }
  }

  if (timeParts.length === 0) {
    return null;
  }

  return timeParts.join(' ');
};

const getWatchingStatus = (
  item: NETGEM_API_V8_FEED_ITEM,
  viewingHistory: NETGEM_API_VIEWINGHISTORY,
  viewingHistoryStatus: LoadableStatus,
  contentType: ItemContent,
  programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
  seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
  vodStatus: VOD_STATUS_TYPE | null,
): number | null => {
  const { locType, type } = item;

  if (contentType === ItemContent.Unknown) {
    return null;
  }

  if (contentType === ItemContent.VODOrDeeplink) {
    const status = vodStatus?.status ?? BOVodAssetStatus.Unknown;
    if (status === BOVodAssetStatus.Locked) {
      // Item has been watched (at least partially) but its rent has expired
      return null;
    }
  }

  if (viewingHistoryStatus !== LoadableStatus.Loaded || locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT) {
    return null;
  }

  /*
   * About progress
   *  VOD: show the progress of the series or program
   *  TV:  always show the progress of the surfaced program
   */
  return getProgress(viewingHistory, item, programMetadata, seriesMetadata, type === ItemType.Series && contentType === ItemContent.VODOrDeeplink, vodStatus);
};

const renderProgramTitle = (
  programMetadataStatus: MetadataStatus,
  seriesMetadataStatus: MetadataStatus,
  programTitle: string,
  timeText: ?string,
  className?: string,
  noInfoTextAllowed: boolean,
): React.Node => {
  if (programMetadataStatus === MetadataStatus.Loading || seriesMetadataStatus === MetadataStatus.Loading) {
    return null;
  }

  const text = noInfoTextAllowed && (programMetadataStatus === MetadataStatus.Error || !programTitle) ? Localizer.localize('player.no_program_info') : programTitle;
  return (
    <div className={clsx('text', className)}>
      {text}
      {timeText ? `${text !== '' ? ' - ' : ''}${timeText}` : ''}
    </div>
  );
};

const renderSeriesTitle = (programMetadataStatus: MetadataStatus, seriesMetadataStatus: MetadataStatus, seriesTitle: string): React.Node => {
  if (programMetadataStatus === MetadataStatus.Loading || seriesMetadataStatus === MetadataStatus.Loading || seriesTitle === '') {
    return null;
  }

  const text = seriesMetadataStatus === MetadataStatus.Error ? Localizer.localize('player.no_program_info') : seriesTitle;
  return <div className='text'>{text}</div>;
};

export { getTimeText, getWatchingStatus, renderProgramTitle, renderSeriesTitle };
