/* @flow */

import AccurateTimestamp from '../dateTime/AccurateTimestamp';

type VideoStructuredDataType = {|
  '@context': string,
  '@type': string,
  description: string,
  duration: string,
  name: string,
  regionsAllowed?: string,
  thumbnailUrl?: Array<string>,
  uploadDate: string,
  url: string,
|};

const getOrCreateScript: () => HTMLElement = () => {
  let jsonLd = document.querySelector('script[type="application/ld+json"]');
  if (!jsonLd) {
    jsonLd = document.createElement('script');
    jsonLd.type = 'application/ld+json';
    document.querySelector('head')?.appendChild(jsonLd);
  }

  return jsonLd;
};

const addLinkedData: (url: string, name: string, description: string, duration: string, imageUrl: ?string, uploadDate: ?string) => void = (url, name, description, duration, imageUrl, uploadDate) => {
  const data: VideoStructuredDataType = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    description,
    duration,
    name,
    uploadDate: uploadDate ?? AccurateTimestamp.nowAsIsoString(),
    url,
  };

  if (imageUrl) {
    data.thumbnailUrl = [imageUrl];
  }

  const regionsAllowed = process.env.REACT_APP_REGIONS_ALLOWED;
  if (regionsAllowed) {
    data.regionsAllowed = regionsAllowed;
  }

  const jsonLd = getOrCreateScript();
  jsonLd.innerText = JSON.stringify(data);
};

export { addLinkedData };
