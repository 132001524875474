/* @flow */

// Values are CSS class names and should not be changed
const Theme = Object.freeze({
  Dark: 'dark',
  Light: 'light',
});

export type ThemeType = 'light' | 'dark';

const Luminosity = {
  Dark: 'dk',
  Light: 'lt',
};

export type LuminosityType = 'dk' | 'lt';

const Variant = {
  ...Luminosity,
  Default: 'default',
};

// Return themed image based on given variant
const getThemedImageFromVariant: (assetId: string, variant: string) => string = (assetId, variant) => `${assetId}_${variant}`;

const getVariant: (luminosity?: LuminosityType, theme?: ThemeType) => string = (luminosity, theme) => {
  // Luminosity has been provided
  if (luminosity === Luminosity.Dark) {
    return Variant.Dark;
  }
  if (luminosity === Luminosity.Light) {
    return Variant.Light;
  }

  // Theme has been provided
  if (theme) {
    return theme === Theme.Dark ? Variant.Light : Variant.Dark;
  }

  // Nothing has been provided
  return Variant.Default;
};

const getOppositeMode: (theme: ThemeType) => ThemeType = (theme) => (theme === Theme.Dark ? Theme.Light : Theme.Dark);

module.exports = {
  Luminosity,
  Theme,
  Variant,
  getOppositeMode,
  getThemedImageFromVariant,
  getVariant,
};
