/* @flow */

import type { NETGEM_API_V8_FEED_PARAM, NETGEM_API_V8_FEED_PARAM_ARGS } from '../../../../libs/netgemLibrary/v8/types/Param';
import { getChannelGroupId, getChannelListFromGroupId, getChannelListFromTag } from '../../../../helpers/channel/helper';
import { getRoundedDateToISOString, getRoundedDurationToISOString, getRoundedNowToISOString } from '../../../../libs/netgemLibrary/v8/helpers/Date';
import { getSettingSection, getSettingValueByName } from './settings';
import { APP_LANGUAGES_AS_STRING } from '../../../../index';
import AccurateTimestamp from '../../../../helpers/dateTime/AccurateTimestamp';
import Base64 from 'crypto-js/enc-base64';
import type { CombinedReducers } from '../../../reducers';
import { HttpMethod } from '../../../../libs/netgemLibrary/v8/types/HttpMethod';
import type { NETGEM_API_V8_REQUEST_RESPONSE } from '../../../../libs/netgemLibrary/v8/types/RequestResponse';
import { type NETGEM_API_V8_URL_DEFINITION } from '../../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import type { STREAM_PRIORITIES_TYPE } from '../../../../helpers/ui/metadata/Types';
import { getBOSetting } from './boSettings';
import { getValueFromPrefix } from '../../../../helpers/ui/item/traverser';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import { logWarning } from '../../../../helpers/debug/debug';

type GetterType = (op: string, args: NETGEM_API_V8_FEED_PARAM_ARGS, additionalParameters: any, state: CombinedReducers) => string | null;

const generateApiUrl: (urlDef: NETGEM_API_V8_URL_DEFINITION, additionalParameters: any, state: CombinedReducers) => string = (urlDef, additionalParameters, state) => {
  const { appConfiguration } = state;
  const { method, params, provider, sakName } = urlDef;
  let { uri: url } = urlDef;

  const cncParameter = additionalParameters?.cncParameter;
  if (provider?.toLowerCase() === 'cncresolver' && cncParameter) {
    // First replace '{cncparameter}' by actual parameter, then apply parameters replacement as usual
    url = url.replace('{cncparameter}', `&${cncParameter}`);
  }

  // Replace parameters
  if (params) {
    for (let i = 0; i < params.length; i++) {
      const { name, value } = params[i];
      const v = getValueForParameter(value, additionalParameters, state) || '';
      url = url.replace(new RegExp(`\\{${name}\\}`, 'gu'), name !== 'options' && name !== 'videostreams' && name !== 'deeplinkParameter' ? encodeURIComponent(v) : v);
    }
  }

  // Sign
  const apiKeyValue = sakName ? getSettingValueByName('platform.sas', sakName, appConfiguration) : null;

  return sakName ? createSignedApiUrl(url, sakName, apiKeyValue, method || HttpMethod.GET) : url;
};

const apiKeyIdValueGetter: GetterType = (op, args, additionalParameters, state) => getSettingValueByName('platform.discovery', 'apikeyid', state.appConfiguration);

const channelGr2ChannelListValueGetter: GetterType = (op, args, additionalParameters, state) => {
  let channelGroupId: string | null = null;
  const [arg] = args;

  if (typeof arg === 'string') {
    channelGroupId = arg;
  } else if (typeof arg !== 'number' && !Array.isArray(arg)) {
    channelGroupId = getValueForParameter(arg, additionalParameters, state);
  }

  return JSON.stringify(getChannelListFromGroupId(state.appConfiguration.deviceChannels, channelGroupId));
};

const tag2ChannelListValueGetter: GetterType = (op, args, additionalParameters, state) => {
  const [tag] = args;

  if (typeof tag !== 'string') {
    return null;
  }

  return JSON.stringify(getChannelListFromTag(state.appConfiguration.deviceChannels, tag).sort());
};

const tag2ListAliasValueGetter: GetterType = (op, args, additionalParameters, state) => {
  const [tag] = args;

  if (typeof tag !== 'string') {
    return null;
  }

  const {
    tagListAliases: { [tag]: listAlias },
  } = state.appConfiguration;

  if (!listAlias && state.appConfiguration.isDebugModeEnabled) {
    logWarning(`Missing tag: ${tag}`);
  }

  return listAlias ?? null;
};

const channel2ChannelGrValueGetter: GetterType = (op, args, additionalParameters, state) => {
  let channelId: string | null = null;
  const [arg] = args;

  if (typeof arg === 'string') {
    channelId = arg;
  } else if (typeof arg !== 'number' && !Array.isArray(arg)) {
    channelId = getValueForParameter(arg, additionalParameters, state);
  }

  return getChannelGroupId(state.appConfiguration.deviceChannels, channelId);
};

const channelListValueGetter: GetterType = (op, args, additionalParameters, state) => {
  if (additionalParameters) {
    const { [op]: p } = additionalParameters;
    if (typeof p === 'string') {
      // Given parameter is a list alias
      return p;
    }
    if (Array.isArray(p)) {
      // Given parameter is an array of channel Ids
      return JSON.stringify(p);
    }
  }

  // No given parameter: use global list alias (i.e. all channels)
  return state.appConfiguration.listAlias;
};

const selectedValueGetter: GetterType = (op, args, additionalParameters) => {
  if (!additionalParameters) {
    return null;
  }

  const { assetId, item } = additionalParameters;
  const [paramPrefix] = args;

  if (typeof paramPrefix !== 'string') {
    return null;
  }

  if (assetId?.startsWith(paramPrefix)) {
    return assetId;
  }

  return item ? getValueFromPrefix(item, paramPrefix) : null;
};

const settingsValueGetter: GetterType = (op, args, additionalParameters, state) => {
  const [section, name, defaultValue] = args;

  if (typeof section !== 'string' || typeof name !== 'string') {
    return '';
  }

  // DMS setting > default value > empty string
  return getSettingValueByName(section, name, state.appConfiguration) ?? defaultValue?.toString() ?? '';
};

const orValueGetter: GetterType = (op, args, additionalParameters, state) => {
  for (const arg of args) {
    if (typeof arg !== 'number' && typeof arg !== 'string' && !Array.isArray(arg)) {
      const argValue = getValueForParameter(arg, additionalParameters, state);
      if (argValue && argValue !== '[]') {
        return argValue;
      }
    } else if (arg !== null && typeof arg !== 'undefined' && !Array.isArray(arg)) {
      return `${arg}`;
    }
  }

  return null;
};

const unionValueGetter: GetterType = (op, args, additionalParameters, state) => {
  const union = [];

  const pushIfMissing = (item: string) => {
    if (union.indexOf(item) === -1) {
      union.push(item);
    }
  };

  args.forEach((arg) => {
    if (Array.isArray(arg)) {
      arg.forEach((i) => pushIfMissing(i));
    } else if (typeof arg === 'number' || typeof arg === 'string') {
      pushIfMissing(`${arg}`);
    } else {
      const argValue = getValueForParameter(arg, additionalParameters, state);
      if (argValue) {
        if (Array.isArray(argValue)) {
          argValue.forEach((v: string) => {
            pushIfMissing(v);
          });
        } else {
          pushIfMissing(argValue);
        }
      }
    }
  });

  return JSON.stringify(union);
};

const valueListGetter: GetterType = (op, args) => {
  if (args.every((item) => typeof item === 'string')) {
    return JSON.stringify(args);
  }

  return null;
};

const dateValueGetter: GetterType = (op, args, additionalParameters) => {
  const [paramName, paramRound] = args;
  const round = Number(paramRound) || 0;
  const paramValue = (additionalParameters && Number(additionalParameters[paramName])) || 0;
  return getRoundedDateToISOString(round, paramValue);
};

const deeplinkParameterValueGetter: GetterType = (op, args, additionalParameters) => {
  const { deeplinkParameter } = additionalParameters;
  return deeplinkParameter ?? null;
};

const defaultKeyValueGetter: GetterType = (op, args, additionalParameters, state) => getSettingValueByName('platform', 'defaultkey', state.appConfiguration);

const langValueGetter: () => string = () => APP_LANGUAGES_AS_STRING;

const nowValueGetter: GetterType = (op, args) => {
  const round = Number(args[0]) || 0;
  const offset = Number(args[1]) || 0;
  return getRoundedNowToISOString(round, offset);
};

const optionsValueGetter: GetterType = (op, args, additionalParameters, state) => {
  const [paramName] = args;
  let result: string | null = null;

  if (paramName && typeof paramName === 'string') {
    const section = getSettingSection(`platform.${paramName}.options`, state.appConfiguration);
    if (section) {
      const options: { [string]: string } = {};
      for (let i = 0; i < section.length; i++) {
        const { name, value } = section[i];
        options[name] = value;
      }

      result = Object.entries(options)
        .map(([key, value]) => `&${key}=${((value: any): string)}`)
        .join('');
    }
  }

  return result;
};

const rangeValueGetter: GetterType = (op, args, additionalParameters) => {
  const round = (Array.isArray(args) && args.length > 0 && Number(args[0])) || 0;
  const paramValue = (additionalParameters && Number(additionalParameters[op])) || 0;
  return getRoundedDurationToISOString(paramValue, round);
};

const realmValueGetter: GetterType = (op, args, additionalParameters, state) => getSettingValueByName('platform', 'realm', state.appConfiguration);

const boDeviceValueGetter: GetterType = (op, args, additionalParameters, state) => getSettingValueByName('platform.vf', 'device', state.appConfiguration);

const boDistributorValueGetter: GetterType = (op, args, additionalParameters, state) => getSettingValueByName('platform.vf', 'distributor', state.appConfiguration);

const boProductIdValueGetter: GetterType = (op, args, additionalParameters, state) => getBOSetting('productId', state.appConfiguration);

const videostreamsValueGetter: GetterType = (op, args, additionalParameters) => {
  if (additionalParameters) {
    const { [op]: p } = additionalParameters;
    if (typeof p === 'string') {
      return p;
    }
  }

  return null;
};

const defaultValueGetter: GetterType = (op, args, additionalParameters) => {
  if (additionalParameters) {
    const { [op]: p } = additionalParameters;
    if (typeof p === 'string' || typeof p === 'number' || typeof p === 'boolean') {
      return p.toString();
    }
  }

  return null;
};

const getValueForParameter: (value: NETGEM_API_V8_FEED_PARAM, additionalParameters: any, state: CombinedReducers) => string | null = (value, additionalParameters, state) => {
  const { op, args } = value;

  if (!op) {
    return null;
  }

  const valueGetterMap: {| [string]: GetterType |} = {
    apiKeyId: apiKeyIdValueGetter,
    channel2ChannelGr: channel2ChannelGrValueGetter,
    channelGr2ChannelList: channelGr2ChannelListValueGetter,
    channelList: channelListValueGetter,
    date: dateValueGetter,
    deeplinkParameter: deeplinkParameterValueGetter,
    defaultKey: defaultKeyValueGetter,
    lang: langValueGetter,
    now: nowValueGetter,
    options: optionsValueGetter,
    or: orValueGetter,
    range: rangeValueGetter,
    realm: realmValueGetter,
    selected: selectedValueGetter,
    settings: settingsValueGetter,
    tag2ChannelList: tag2ChannelListValueGetter,
    tag2ListAlias: tag2ListAliasValueGetter,
    union: unionValueGetter,
    valueList: valueListGetter,
    vfDevice: boDeviceValueGetter,
    vfDistributor: boDistributorValueGetter,
    vfProductId: boProductIdValueGetter,
    videostreams: videostreamsValueGetter,
  };

  const getter = valueGetterMap[op] ? valueGetterMap[op] : defaultValueGetter;
  return getter(op, args, additionalParameters, state);
};

const createSignedApiUrl: (unsignedUrl: string, apiKeyId: ?string, apiKeyValue: ?string, httpMethod: HttpMethod) => string = (unsignedUrl, apiKeyId, apiKeyValue, httpMethod) => {
  const url = new URL(unsignedUrl);
  const { hash, pathname, search } = url;
  const pathQuerySegment = `${pathname || ''}${search || ''}${hash || ''}`;
  const decodedPathQuerySegment = decodeURIComponent(pathQuerySegment);
  const request = `${(httpMethod: string)} ${decodedPathQuerySegment}`;
  const h = hmacSHA256(request, Base64.parse(apiKeyValue));

  url.searchParams.set('sas', Base64.stringify(h));
  return url.toString();
};

// From Microsoft: https://docs.microsoft.com/en-us/rest/api/eventhub/generate-sas-token
const computeSas: (unsignedUrl: string, apiKeyId: string, apiKeyValue: string, sasDelay: number) => string = (unsignedUrl, apiKeyId, apiKeyValue, sasDelay) => {
  const encoded = encodeURIComponent(unsignedUrl);
  const ttl = AccurateTimestamp.nowInSeconds() + sasDelay;
  const fullUrl = `${encoded}\n${ttl}`;
  const hash = hmacSHA256(fullUrl, apiKeyValue);
  const encodedHash = encodeURIComponent(Base64.stringify(hash));
  return `SharedAccessSignature sr=${encoded}&sig=${encodedHash}&se=${ttl}&skn=${apiKeyId}`;
};

const filterAndSortPlaybackUrls: (response: NETGEM_API_V8_REQUEST_RESPONSE, playerStreamPriorities: STREAM_PRIORITIES_TYPE | null) => NETGEM_API_V8_REQUEST_RESPONSE = (
  response,
  playerStreamPriorities,
) => {
  const {
    result: {
      location: { playbackUrls },
    },
  } = response;

  if (!playerStreamPriorities || !playbackUrls || playbackUrls.length === 0) {
    return response;
  }

  const urls = [];

  // Filter
  playbackUrls.forEach((u) => {
    const {
      player,
      start: { params },
    } = u;
    const priorities = playerStreamPriorities[player] || playerStreamPriorities.default;

    let found = false;
    if (priorities) {
      for (let i = 0; i < params.length && !found; ++i) {
        const { [i]: param } = params;
        const {
          value: { args },
        } = param;
        const newArgs = [];

        for (let j = 0; j < args.length; ++j) {
          const { [j]: arg } = args;
          const { type } = arg;
          const index = priorities[type];

          if (typeof index !== 'undefined' && index > -1) {
            found = true;
            newArgs.push(arg);
          }
        }

        // Sort
        newArgs.sort((a, b) => {
          const index1 = priorities[a.type];
          const index2 = priorities[b.type];
          if (index1 === index2) {
            return 0;
          }
          return index1 < index2 ? -1 : 1;
        });

        param.value.args = newArgs;
      }
    }

    if (found) {
      urls.push(u);
    }
  });

  response.result.location.playbackUrls = urls;

  return response;
};

export { computeSas, filterAndSortPlaybackUrls, generateApiUrl, getValueForParameter };
