/* @flow */

import {
  REDUX_MSG_APPLICATION_REGISTER,
  REDUX_MSG_APPLICATION_UNREGISTER,
  REDUX_MSG_ASSOCIATED_DEVICES_UPDATE,
  REDUX_MSG_SET_DEVICE_ID,
  REDUX_MSG_UPDATE_AUTHENTICATION_TOKEN,
  REDUX_MSG_USER_INFO_RESET,
  REDUX_MSG_USER_INFO_UPDATE,
} from '../constants/';
import { RegistrationType, type UserInfo } from '../types/types';
import { destroy as destroyPaymentSystem, initialize as initializePaymentSystem } from '../../../helpers/applicationCustomization/externalPaymentSystem';
import type { AppRegistrationAction } from '../actions';
import LocalStorageManager from '../../../helpers/localStorage/localStorageManager';
import type { NETGEM_API_DMS_ASSOCIATED_DEVICE } from '../../../libs/netgemLibrary/dms/types/AssociatedDevice';
import SentryWrapper from '../../../helpers/debug/sentry';
import { StorageKeys } from '../../../helpers/localStorage/keys';
import { produce } from 'immer';

export type AppRegistrationState = {|
  anonymous: boolean,
  applicationId: string,
  associatedDevices: Array<NETGEM_API_DMS_ASSOCIATED_DEVICE>,
  authDeviceUrl: string,
  authenticationToken: string | null,
  deviceId: string,
  deviceKey: string,
  error: ?string,
  logoutUrl: string,
  registration: RegistrationType,
  subscriberId: string,
  upgradeDeviceUrl: string,
  userInfo: UserInfo | null,
|};

const InitialState: AppRegistrationState = {
  anonymous: false,
  applicationId: '',
  associatedDevices: [],
  authDeviceUrl: '',
  authenticationToken: null,
  deviceId: '',
  deviceKey: '',
  error: null,
  logoutUrl: '',
  registration: RegistrationType.NotRegistered,
  subscriberId: '',
  upgradeDeviceUrl: '',
  userInfo: null,
};

const DefaultAction: AppRegistrationAction = { type: null };

// eslint-disable-next-line consistent-return
const appRegistrationReducer: (state: AppRegistrationState, action: AppRegistrationAction) => AppRegistrationState = produce((draft = InitialState, action = DefaultAction) => {
  switch (action.type) {
    case REDUX_MSG_UPDATE_AUTHENTICATION_TOKEN: {
      if (action.authenticationToken && draft.registration === RegistrationType.Registered) {
        // Initialize external payment system if needed
        initializePaymentSystem(action.authenticationToken, action.paymentSystemUrls, action.useBOV2Api);
      }

      draft.authenticationToken = action.authenticationToken;

      break;
    }

    case REDUX_MSG_APPLICATION_REGISTER: {
      const {
        asGuest,
        userInfo,
        userInfo: { subscriberId },
      } = action;
      SentryWrapper.updateSubscriberId(subscriberId);

      // Return entirely new draft
      return {
        ...draft,
        ...userInfo,
        deviceId: draft.deviceId,
        registration: asGuest ? RegistrationType.RegisteredAsGuest : RegistrationType.Registered,
      };
    }

    case REDUX_MSG_APPLICATION_UNREGISTER: {
      LocalStorageManager.delete(StorageKeys.UserDeviceInfo);

      // Destroy external payment system if needed
      destroyPaymentSystem();

      SentryWrapper.updateSubscriberId('');

      // Return entirely new draft
      return {
        ...InitialState,
        deviceId: draft.deviceId,
      };
    }

    case REDUX_MSG_USER_INFO_RESET: {
      draft.userInfo = null;
      break;
    }

    case REDUX_MSG_USER_INFO_UPDATE: {
      draft.userInfo = action.userInfo;
      break;
    }

    case REDUX_MSG_SET_DEVICE_ID: {
      draft.deviceId = action.deviceId;
      break;
    }

    case REDUX_MSG_ASSOCIATED_DEVICES_UPDATE: {
      draft.associatedDevices = action.associatedDevices;
      break;
    }

    default:
  }
}, InitialState);

export default appRegistrationReducer;
