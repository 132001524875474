/* @flow */

import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import type { NETGEM_API_V8_FEED_ITEM } from '../../../../libs/netgemLibrary/v8/types/FeedItem';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { REDUX_MSG_REQUEST_CUSTOM_STRATEGY } from '../../constants';
import { generateApiUrl } from '../helpers/api';

const sendV8CustomStrategyRequest: (urlDefinition: NETGEM_API_V8_URL_DEFINITION, item: NETGEM_API_V8_FEED_ITEM, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (urlDefinition, item, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const state = getState();

    return NetgemApiEmitter.emit(REDUX_MSG_REQUEST_CUSTOM_STRATEGY, {
      signal,
      uri: generateApiUrl(
        urlDefinition,
        {
          assetId: item.id,
          item,
        },
        state
      ),
    });
  };

export default sendV8CustomStrategyRequest;
