/* @flow */

import { logError } from '../debug/debug';

export default class DidomiWrapper {
  static instance: any;

  // $FlowFixMe: Flow does not support symbols yet
  get [Symbol.toStringTag]() {
    return 'DidomiWrapper';
  }

  static initialize: (didomi: any) => void = (didomi) => {
    if (DidomiWrapper.instance) {
      // Already initialized
      return;
    }

    DidomiWrapper.instance = didomi;
  };

  static resetConsent: () => void = () => {
    if (!DidomiWrapper.instance) {
      logError('DidomiWrapper not initialized');
    }

    DidomiWrapper.instance.reset();
  };

  static manageConsent: () => void = () => {
    if (!DidomiWrapper.instance) {
      logError('DidomiWrapper not initialized');
    }

    DidomiWrapper.instance.preferences.show();
  };

  static getUserId: () => string = () => {
    if (!DidomiWrapper.instance) {
      logError('DidomiWrapper not initialized');
      return '';
    }

    return DidomiWrapper.instance.getCurrentUserStatus().user_id;
  };

  static getConsentString: () => string = () => {
    if (!DidomiWrapper.instance) {
      logError('DidomiWrapper not initialized');
      return '';
    }

    return DidomiWrapper.instance.getCurrentUserStatus().consent_string;
  };

  static isVendorEnabled: (vendor: string) => boolean = (vendor) => {
    if (!DidomiWrapper.instance) {
      logError('DidomiWrapper not initialized');
      return false;
    }

    const {
      vendors: {
        [vendor]: { enabled },
      },
    } = DidomiWrapper.instance.getCurrentUserStatus();

    return enabled;
  };

  static isGtmEnabled: () => boolean = () => DidomiWrapper.isVendorEnabled('googleana-4TXnJigR');

  static isMediametrieEnabled: () => boolean = () => DidomiWrapper.isVendorEnabled('mediametri-Npn8xCFG');

  static addFastChannelConsent: (url: string) => string = (url) => {
    const u = new URL(url);
    u.searchParams.set('cmid', DidomiWrapper.getUserId());
    u.searchParams.set('cmtoken', DidomiWrapper.getConsentString());
    return u.toString();
  };
}
