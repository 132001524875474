/* @flow */

import { WishlistItemKind } from '../types/Wishlist';

export const getWishlistTypeFromId: (assetId: string) => WishlistItemKind = (assetId) => {
  const [type] = assetId.split(':');
  switch (type) {
    case 'season':
      return WishlistItemKind.Season;

    case 'series':
      return WishlistItemKind.Series;

    case 'program':
    default:
      return WishlistItemKind.Program;
  }
};
