/* @flow */

import {
  REDUX_MSG_APPLICATION_REGISTER,
  REDUX_MSG_APPLICATION_UNREGISTER,
  REDUX_MSG_ASSOCIATED_DEVICES_UPDATE,
  REDUX_MSG_SET_DEVICE_ID,
  REDUX_MSG_UPDATE_AUTHENTICATION_TOKEN,
  REDUX_MSG_USER_INFO_RESET,
  REDUX_MSG_USER_INFO_UPDATE,
} from '../constants/';
import type { UserDeviceInfoType, UserInfo } from '../types/types';
import type { KeyValuePair } from '@ntg/utils/dist/types';
import type { NETGEM_API_DMS_ASSOCIATED_DEVICE } from '../../../libs/netgemLibrary/dms/types/AssociatedDevice';

export type AppRegistrationAction =
  // Default action
  | {| type: null |}
  // Register user
  | {|
      asGuest: boolean,
      type: typeof REDUX_MSG_APPLICATION_REGISTER,
      userInfo: UserDeviceInfoType,
    |}
  // Unregister user
  | {| type: typeof REDUX_MSG_APPLICATION_UNREGISTER |}
  // Update associated devices
  | {|
      associatedDevices: Array<NETGEM_API_DMS_ASSOCIATED_DEVICE>,
      type: typeof REDUX_MSG_ASSOCIATED_DEVICES_UPDATE,
    |}
  // Set device Id
  | {|
      deviceId: string,
      type: typeof REDUX_MSG_SET_DEVICE_ID,
    |}
  // Reset user info
  | {| type: typeof REDUX_MSG_USER_INFO_RESET |}
  // Update user info
  | {|
      userInfo: UserInfo,
      type: typeof REDUX_MSG_USER_INFO_UPDATE,
    |}
  // Update authentication token
  | {|
      authenticationToken: string | null,
      paymentSystemUrls: KeyValuePair<string>,
      type: typeof REDUX_MSG_UPDATE_AUTHENTICATION_TOKEN,
      useBOV2Api: boolean,
    |};

const registerApplication: (userInfo: UserDeviceInfoType, asGuest: boolean) => AppRegistrationAction = (userInfo, asGuest) => {
  return {
    asGuest,
    type: REDUX_MSG_APPLICATION_REGISTER,
    userInfo,
  };
};

const setDeviceId: (deviceId: string) => AppRegistrationAction = (deviceId) => {
  return {
    deviceId,
    type: REDUX_MSG_SET_DEVICE_ID,
  };
};

const resetUserInfo: () => AppRegistrationAction = () => {
  return { type: REDUX_MSG_USER_INFO_RESET };
};

const updateUserInfo: (userInfo: UserInfo) => AppRegistrationAction = (userInfo) => {
  return {
    type: REDUX_MSG_USER_INFO_UPDATE,
    userInfo,
  };
};

const unregisterApplication: () => AppRegistrationAction = () => {
  return { type: REDUX_MSG_APPLICATION_UNREGISTER };
};

const updateAssociatedDevices: (associatedDevices: Array<NETGEM_API_DMS_ASSOCIATED_DEVICE>) => AppRegistrationAction = (associatedDevices) => {
  return {
    associatedDevices,
    type: REDUX_MSG_ASSOCIATED_DEVICES_UPDATE,
  };
};

const updateAuthenticationToken: (authenticationToken: string | null, paymentSystemUrls: KeyValuePair<string>, useBOV2Api?: boolean) => AppRegistrationAction = (
  authenticationToken,
  paymentSystemUrls,
  useBOV2Api = false,
) => {
  return {
    authenticationToken,
    paymentSystemUrls,
    type: REDUX_MSG_UPDATE_AUTHENTICATION_TOKEN,
    useBOV2Api,
  };
};

export { registerApplication, resetUserInfo, setDeviceId, unregisterApplication, updateAssociatedDevices, updateAuthenticationToken, updateUserInfo };
