/* @flow */

import { getRandomInteger } from '../maths/maths';
import { logDebug } from './debug';

// https://codepen.io/brandonmcconnell/full/WNOeYLp

const drawingsDescription = [
  // Sonic
  {
    colors: [
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '212494ff',
      '212494ff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      't',
      't',
      't',
      't',
      '212494ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '2149deff',
      '2149deff',
      '212494ff',
      'ffb694ff',
      '212494ff',
      '2149deff',
      '6b6dffff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      't',
      '212494ff',
      '212494ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      '212494ff',
      '2149deff',
      '6b6dffff',
      '6b6dffff',
      '2149deff',
      '2149deff',
      '2149deff',
      '212494ff',
      '212494ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212494ff',
      '2149deff',
      '212494ff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'ffb694ff',
      '2149deff',
      '6b6dffff',
      '6b6dffff',
      '6b6dffff',
      '2149deff',
      '2149deff',
      '2149deff',
      '212494ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '2149deff',
      '2149deff',
      '212494ff',
      'b56d4aff',
      'b56d4aff',
      '2149deff',
      '2149deff',
      '6b6dffff',
      '6b6dffff',
      'dedbffff',
      '6b6dffff',
      '6b6dffff',
      '2149deff',
      '2149deff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '6b6dffff',
      '6b6dffff',
      '2149deff',
      '2149deff',
      '6b6dffff',
      '6b6dffff',
      '6b6dffff',
      '2149deff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '6b6dffff',
      '6b6dffff',
      '6b6dffff',
      '6b6dffff',
      '6b6dffff',
      '6b6dffff',
      '212494ff',
      'b5b6deff',
      'b5b6deff',
      'dedbffff',
      '6b6dffff',
      '6b6dffff',
      '2149deff',
      '2149deff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '6b6dffff',
      'ffffffff',
      '6b6dffff',
      '6b6dffff',
      '212494ff',
      '6b6d94ff',
      'dedbffff',
      'ffffffff',
      'ffffffff',
      '2149deff',
      '6b6dffff',
      '6b6dffff',
      '2149deff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '2149deff',
      '2149deff',
      '2149deff',
      '6b6dffff',
      '2149deff',
      '6b6dffff',
      '212494ff',
      'b5b6deff',
      'b5b6deff',
      'b5b6deff',
      'ffffffff',
      'dedbffff',
      '2149deff',
      '6b6dffff',
      '2149deff',
      '212494ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '212494ff',
      'b5b6deff',
      '212421ff',
      'b5b6deff',
      'ffffffff',
      'b5b6deff',
      '2149deff',
      '6b6dffff',
      '6b6dffff',
      '212494ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      'b5b6deff',
      '212421ff',
      '6b6d94ff',
      'ffffffff',
      'ffffffff',
      'dedbffff',
      '212494ff',
      '212494ff',
      'b5b6deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '6b6dffff',
      '212421ff',
      'b5b6deff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      '6b6d94ff',
      '212421ff',
      '6b6d94ff',
      '6b6d94ff',
      't',
      't',
      't',
      't',
      'b5b6deff',
      'ffffffff',
      't',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '2149deff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '2149deff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'b5b6deff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      'b56d4aff',
      'b5b6deff',
      '212421ff',
      't',
      't',
      't',
      't',
      'b5b6deff',
      'ffffffff',
      'ffffffff',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      '212494ff',
      '212494ff',
      '212494ff',
      'b56d4aff',
      'ffb694ff',
      'ffffffff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'dedbffff',
      'dedbffff',
      'ffb694ff',
      'ffb694ff',
      '212421ff',
      '212421ff',
      't',
      't',
      't',
      'b5b6deff',
      'ffffffff',
      'ffffffff',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '2149deff',
      '2149deff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'ffb694ff',
      'ffb694ff',
      'ffb694ff',
      'ffb694ff',
      'b56d4aff',
      't',
      't',
      'dedbffff',
      'b5b6deff',
      'ffffffff',
      'ffffffff',
      't',
      't',
      't',
      't',
      't',
      '2149deff',
      '2149deff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      'b56d4aff',
      'b56d4aff',
      'b56d4aff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'ffb694ff',
      'b56d4aff',
      'b56d4aff',
      't',
      't',
      'dedbffff',
      'ffffffff',
      'ffffffff',
      'b5b6deff',
      'ffffffff',
      'ffffffff',
      't',
      't',
      't',
      't',
      '2149deff',
      '212494ff',
      't',
      't',
      't',
      't',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      'b56d4aff',
      'b56d4aff',
      'b56d4aff',
      'b56d4aff',
      'b56d4aff',
      't',
      't',
      't',
      't',
      'dedbffff',
      'ffffffff',
      'b5b6deff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '212494ff',
      't',
      't',
      't',
      't',
      't',
      'dedbffff',
      'ffffffff',
      '6b6d94ff',
      'ffffffff',
      'ffffffff',
      'b5b6deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b56d4aff',
      'b56d4aff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'b56d4aff',
      '212494ff',
      '2149deff',
      'b56d4aff',
      'ffb694ff',
      'ffffffff',
      'b56d4aff',
      't',
      't',
      't',
      'dedbffff',
      'b5b6deff',
      'dedbffff',
      'ffffffff',
      '6b6d94ff',
      'b5b6deff',
      'b5b6deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b56d4aff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'ffb694ff',
      'b56d4aff',
      'b56d4aff',
      '212494ff',
      '2149deff',
      'b56d4aff',
      'ffb694ff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      'b56d4aff',
      'b56d4aff',
      't',
      'b56d4aff',
      'dedbffff',
      'b5b6deff',
      'dedbffff',
      'ffffffff',
      'b5b6deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b56d4aff',
      'ffb694ff',
      'b56d4aff',
      'b56d4aff',
      'b56d4aff',
      '212494ff',
      '212494ff',
      '212494ff',
      '2149deff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'ffffffff',
      'ffb694ff',
      'b56d4aff',
      'b56d4aff',
      'b56d4aff',
      'b56d4aff',
      'ffb694ff',
      'b5b6deff',
      'b5b6deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b56d4aff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'b56d4aff',
      '6b6d94ff',
      '212494ff',
      '212494ff',
      '2149deff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      'ffb694ff',
      'ffb694ff',
      't',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '6b6d94ff',
      '6b6d94ff',
      'b56d4aff',
      'ffb694ff',
      'b56d4aff',
      'b5b6deff',
      '212494ff',
      '212494ff',
      '212494ff',
      '2149deff',
      'b56d4aff',
      'ffb694ff',
      'ffb694ff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '6b6d94ff',
      'dedbffff',
      '6b6d94ff',
      '6b6d94ff',
      'b56d4aff',
      'b5b6deff',
      'ffffffff',
      '6b6d94ff',
      '212494ff',
      '212494ff',
      '212494ff',
      '2149deff',
      '2149deff',
      '2149deff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '6b6d94ff',
      'b5b6deff',
      'dedbffff',
      'dedbffff',
      'ffffffff',
      'ffffffff',
      '6b6d94ff',
      '6b6d94ff',
      't',
      '212494ff',
      '2149deff',
      '2149deff',
      't',
      '212494ff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '6b6d94ff',
      '6b6d94ff',
      'b5b6deff',
      'dedbffff',
      '6b6d94ff',
      '940000ff',
      '940000ff',
      't',
      '212494ff',
      '212494ff',
      '2149deff',
      't',
      '212494ff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '6b6d94ff',
      '6b6d94ff',
      '940000ff',
      '940000ff',
      '940000ff',
      '940000ff',
      'ffffffff',
      'ffffffff',
      '212494ff',
      '212494ff',
      '2149deff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212421ff',
      '212421ff',
      '940000ff',
      '940000ff',
      '940000ff',
      '940000ff',
      'ffffffff',
      'ffffffff',
      '212494ff',
      '2149deff',
      '2149deff',
      '2149deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212421ff',
      '940000ff',
      'ff0000ff',
      'ff0000ff',
      '940000ff',
      '940000ff',
      'ffffffff',
      'ffffffff',
      '212494ff',
      '2149deff',
      '6b6d94ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212421ff',
      '212421ff',
      'ffffffff',
      'ffffffff',
      'ff0000ff',
      '940000ff',
      '6b6d94ff',
      'b5b6deff',
      'b5b6deff',
      'ffffffff',
      'ffffffff',
      'b5b6deff',
      '6b6d94ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212421ff',
      '940000ff',
      'ff0000ff',
      'ff0000ff',
      'b5b6deff',
      '940000ff',
      't',
      '6b6d94ff',
      '6b6d94ff',
      '6b6d94ff',
      'b5b6deff',
      'b5b6deff',
      '6b6d94ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212421ff',
      '940000ff',
      'ff0000ff',
      'ff0000ff',
      'ff0000ff',
      '940000ff',
      'b5b6deff',
      't',
      '212421ff',
      '940000ff',
      'ff0000ff',
      'ff0000ff',
      '940000ff',
      '212421ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212421ff',
      '940000ff',
      'ff0000ff',
      'ff0000ff',
      'ff0000ff',
      'ff0000ff',
      '940000ff',
      '212421ff',
      '940000ff',
      'b5b6deff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      '212421ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212421ff',
      '212421ff',
      '940000ff',
      'ff0000ff',
      'ffffffff',
      'ff0000ff',
      '940000ff',
      '212421ff',
      'b5b6deff',
      'ff0000ff',
      'ff0000ff',
      'ff0000ff',
      'ff0000ff',
      '940000ff',
      'b5b6deff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212421ff',
      '212421ff',
      '940000ff',
      '940000ff',
      'ff0000ff',
      'ff0000ff',
      '940000ff',
      '212421ff',
      '940000ff',
      '940000ff',
      'ff0000ff',
      'ff0000ff',
      'ffffffff',
      'ff0000ff',
      '940000ff',
      '212421ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212421ff',
      '212421ff',
      '940000ff',
      '940000ff',
      '940000ff',
      '940000ff',
      '212421ff',
      '940000ff',
      '940000ff',
      '940000ff',
      'ff0000ff',
      'ff0000ff',
      'ff0000ff',
      '940000ff',
      '212421ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '212421ff',
      '212421ff',
      '940000ff',
      '940000ff',
      't',
      't',
      '212421ff',
      '212421ff',
      '212421ff',
      '212421ff',
      '212421ff',
      '212421ff',
      '212421ff',
      '212421ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
    ],
    size: { height: 39, width: 31 },
  },
  // Blinky Pinky
  {
    colors: [
      't',
      't',
      't',
      't',
      't',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      't',
      't',
      't',
      't',
      't',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      't',
      't',
      't',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ffffffff',
      'ffffffff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ffffffff',
      'ffffffff',
      'ff0066ff',
      't',
      't',
      'ff0066ff',
      'ff0066ff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      'ff0066ff',
      'ff0066ff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      't',
      't',
      'ff0066ff',
      'ff0066ff',
      'ffffffff',
      'ffffffff',
      '0056d4ff',
      '0056d4ff',
      'ff0066ff',
      'ff0066ff',
      'ffffffff',
      'ffffffff',
      '0056d4ff',
      '0056d4ff',
      't',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ffffffff',
      'ffffffff',
      '0056d4ff',
      '0056d4ff',
      'ff0066ff',
      'ff0066ff',
      'ffffffff',
      'ffffffff',
      '0056d4ff',
      '0056d4ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ffffffff',
      'ffffffff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ffffffff',
      'ffffffff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      't',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      't',
      't',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      't',
      'ff0066ff',
      'ff0066ff',
      'ff0066ff',
      't',
      't',
      't',
      'ff0066ff',
      'ff0066ff',
      't',
      't',
      'ff0066ff',
      'ff0066ff',
      't',
      't',
      't',
      'ff0066ff',
    ],
    size: { height: 14, width: 14 },
  },
  // Mario
  {
    colors: [
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      't',
      't',
      't',
      't',
      't',
      't',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      't',
      't',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      't',
      't',
      't',
      't',
      't',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'fec355ff',
      'fec355ff',
      't',
      't',
      't',
      't',
      't',
      '85561fff',
      '85561fff',
      '85561fff',
      'fec355ff',
      'fec355ff',
      '85561fff',
      'fec355ff',
      't',
      '85561fff',
      '85561fff',
      '85561fff',
      't',
      't',
      't',
      't',
      '85561fff',
      'fec355ff',
      '85561fff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      '85561fff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      '85561fff',
      '85561fff',
      't',
      't',
      't',
      't',
      '85561fff',
      'fec355ff',
      '85561fff',
      '85561fff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      '85561fff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      '85561fff',
      't',
      't',
      't',
      't',
      '85561fff',
      '85561fff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      '85561fff',
      't',
      't',
      't',
      't',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      'e7222cff',
      '85561fff',
      '85561fff',
      '85561fff',
      'e7222cff',
      '85561fff',
      't',
      't',
      't',
      't',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      'e7222cff',
      '85561fff',
      '85561fff',
      '85561fff',
      'e7222cff',
      't',
      't',
      '85561fff',
      'fec355ff',
      'fec355ff',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      '85561fff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      't',
      't',
      '85561fff',
      'fec355ff',
      'fec355ff',
      'fec355ff',
      't',
      't',
      'e7222cff',
      '85561fff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'fec355ff',
      'e7222cff',
      'fec355ff',
      'e7222cff',
      '85561fff',
      '85561fff',
      't',
      'fec355ff',
      't',
      '85561fff',
      't',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      '85561fff',
      '85561fff',
      't',
      't',
      '85561fff',
      '85561fff',
      '85561fff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      '85561fff',
      '85561fff',
      't',
      '85561fff',
      '85561fff',
      '85561fff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      't',
      't',
      't',
      't',
      't',
      't',
      '85561fff',
      't',
      't',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      'e7222cff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
    ],
    size: { height: 16, width: 16 },
  },
  // Baby Yoda, a.k.a. Grogu
  {
    colors: [
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      't',
      't',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      '2a871bff',
      '2a871bff',
      'b',
      't',
      'b',
      'b',
      '2a871bff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      '2a871bff',
      'b',
      'b',
      't',
      't',
      't',
      't',
      'b',
      '2a871bff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'b',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      'b',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      '2a871bff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '2a871bff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'b',
      '2a871bff',
      'b',
      'b',
      'b',
      'ffffffff',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      'ffffffff',
      'b',
      '2a871bff',
      'b',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      '2a871bff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '2a871bff',
      '2a871bff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'b',
      '2a871bff',
      'b',
      'b',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      'b',
      'b',
      '2a871bff',
      'b',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      'e7bd69ff',
      '2a871bff',
      '2a871bff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      'd59c58ff',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      'b',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      'b',
      'b',
      'b',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      'b',
      '764c25ff',
      'b',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      '522c0dff',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      '2a871bff',
      'b',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      'b',
      '2a871bff',
      '2a871bff',
      'b',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      'b',
      'b',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '522c0dff',
      '764c25ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '522c0dff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      '522c0dff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '764c25ff',
      'd59c58ff',
      '764c25ff',
      'd59c58ff',
      '522c0dff',
      '522c0dff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'd59c58ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '764c25ff',
      '764c25ff',
      'd59c58ff',
      'd59c58ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      '764c25ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
    ],
    size: { height: 32, width: 43 },
  },
  // Super Saiyan Gohan
  {
    colors: [
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '933b00ff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '933b00ff',
      't',
      't',
      '923b00ff',
      'fcca00ff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '933b00ff',
      't',
      't',
      't',
      '933b00ff',
      '923b00ff',
      't',
      '933b00ff',
      'ffe800ff',
      'fbc900ff',
      '923b00ff',
      't',
      't',
      't',
      't',
      '00ebfeff',
      '00ebfdff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '923b00ff',
      '933b00ff',
      't',
      't',
      '923b00ff',
      'fcca00ff',
      '933b00ff',
      'd3a400ff',
      'ffffffff',
      'ffffffff',
      'fbc900ff',
      '923b00ff',
      't',
      '933b00ff',
      't',
      't',
      '00ecfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '933b00ff',
      'ffe800ff',
      '933b00ff',
      '923b00ff',
      'ffe700ff',
      'fbc900ff',
      '933b00ff',
      'd2a300ff',
      'ffffffff',
      'ffffffff',
      'ffe800ff',
      '923a00ff',
      't',
      '923b00ff',
      't',
      't',
      '00ebfeff',
      '00ebfdff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '933b00ff',
      't',
      '933b00ff',
      'd2a300ff',
      'ffe800ff',
      '933b00ff',
      'ffe800ff',
      'fcca00ff',
      'd2a300ff',
      'd2a300ff',
      'd2a300ff',
      'ffffffff',
      'ffffffff',
      'fbc900ff',
      '933b00ff',
      'fbca00ff',
      '923b00ff',
      't',
      't',
      't',
      't',
      't',
      '00ebfdff',
      't',
      't',
      't',
      't',
      't',
      't',
      '923a00ff',
      '933b00ff',
      '933b00ff',
      'd2a300ff',
      'ffe800ff',
      '933b00ff',
      'ffe800ff',
      'ffe700ff',
      'd2a300ff',
      'ffe700ff',
      'd2a300ff',
      'ffffffff',
      'ffffffff',
      'ffe700ff',
      '923b00ff',
      'ffe800ff',
      '923b00ff',
      't',
      '923b00ff',
      't',
      't',
      '00ebfdff',
      't',
      '00ebfdff',
      't',
      't',
      't',
      't',
      't',
      '923b00ff',
      'fbc900ff',
      'd2a300ff',
      '933b00ff',
      'd2a300ff',
      '923b00ff',
      'fcca00ff',
      'ffe800ff',
      'd2a300ff',
      'ffe800ff',
      'fbc900ff',
      'd2a300ff',
      'ffffffff',
      'ffe800ff',
      'd2a300ff',
      'ffffffff',
      '933b00ff',
      '923b00ff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '933b00ff',
      'ffe800ff',
      'ffffffff',
      'd2a300ff',
      '923a00ff',
      'fbc900ff',
      'ffe800ff',
      'd2a300ff',
      'ffffffff',
      'ffe800ff',
      'd2a300ff',
      'ffe700ff',
      'd2a300ff',
      'ffffffff',
      'ffffffff',
      '933b00ff',
      'fbc900ff',
      '923a00ff',
      '00ebfdff',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      '933b00ff',
      't',
      't',
      '923a00ff',
      'ffe800ff',
      'ffe800ff',
      'ffffffff',
      '933b00ff',
      'd2a300ff',
      'fcca00ff',
      'd3a300ff',
      'ffffffff',
      'ffffffff',
      'd2a300ff',
      'fcca00ff',
      'd2a300ff',
      'ffffffff',
      'ffe800ff',
      'd2a300ff',
      'fbc900ff',
      '923b00ff',
      '00ebfeff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      '933b00ff',
      '923a00ff',
      '933b00ff',
      '933b00ff',
      'fcca00ff',
      'ffe800ff',
      'ffffffff',
      'ffffffff',
      'd2a300ff',
      'fbc900ff',
      'd2a300ff',
      'ffffffff',
      'ffffffff',
      'fcca00ff',
      'd3a300ff',
      'ffffffff',
      'ffffffff',
      'ffe800ff',
      'd3a400ff',
      'fbc900ff',
      '933b00ff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      't',
      '933b00ff',
      'd2a300ff',
      'fcca00ff',
      '923b00ff',
      '923b00ff',
      'ffe800ff',
      'ffe800ff',
      'ffffffff',
      'ffffffff',
      'd2a300ff',
      'd2a300ff',
      'ffffffff',
      'ffffffff',
      'ffe800ff',
      'd2a300ff',
      'ffffffff',
      'ffffffff',
      'ffe800ff',
      'd2a300ff',
      'fcca00ff',
      '933b00ff',
      'd2a300ff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '923b00ff',
      'fbc900ff',
      'd2a300ff',
      '933b00ff',
      'fbc900ff',
      'ffe800ff',
      'ffffffff',
      'ffffffff',
      'd2a300ff',
      'd2a300ff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      'd3a300ff',
      'ffffffff',
      'ffe800ff',
      'd2a300ff',
      'fcca00ff',
      '933b00ff',
      'd2a300ff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '923b00ff',
      'fbc900ff',
      'd2a300ff',
      'fcca00ff',
      'd2a300ff',
      '933b00ff',
      'fbc900ff',
      'ffffffff',
      'ffffffff',
      'ffffffff',
      'd2a300ff',
      'ffe800ff',
      'ffffffff',
      'ffffffff',
      'd2a300ff',
      '923a00ff',
      '933b00ff',
      '923a00ff',
      'ffe800ff',
      'd2a300ff',
      'd2a300ff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      '00ebfeff',
      '933b00ff',
      'fbc900ff',
      'd2a300ff',
      'fcca00ff',
      'fbc900ff',
      'd3a300ff',
      'ffe800ff',
      'ffffffff',
      'ffe800ff',
      '933b00ff',
      '923a00ff',
      'fbc900ff',
      'fbc900ff',
      'fbc900ff',
      'ffffffff',
      'ffffffff',
      'ffe800ff',
      'ffe800ff',
      '933b00ff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      't',
      '923a00ff',
      '933b00ff',
      'fbc900ff',
      '923b00ff',
      'fbc900ff',
      'd3a300ff',
      'd2a300ff',
      'ffe800ff',
      '933b00ff',
      'ffd06cff',
      'ffeaa0ff',
      '933b00ff',
      '933b00ff',
      '923b00ff',
      '923b00ff',
      'd2a300ff',
      'fcca00ff',
      'ffe800ff',
      'ffffffff',
      '923b00ff',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      '00ebfeff',
      '00ebfeff',
      '00ebfeff',
      't',
      '923a00ff',
      '923b00ff',
      'd2a300ff',
      'd2a300ff',
      'fbc900ff',
      '933b00ff',
      'fcca00ff',
      '933b00ff',
      'ffeaa0ff',
      'ffeaa0ff',
      'ffeaa0ff',
      'ffeaa0ff',
      'ffd06bff',
      'ffd06bff',
      '933b00ff',
      'd2a300ff',
      'fbc900ff',
      'ffe800ff',
      'ffffffff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      '00ebfeff',
      '00ebfeff',
      '933b00ff',
      '923b00ff',
      '923b00ff',
      'd2a300ff',
      'd3a300ff',
      '933b00ff',
      'ffd06cff',
      'ffeaa0ff',
      'ffeaa0ff',
      'ffeaa0ff',
      'ffeaa0ff',
      'ffeaa0ff',
      'ffeaa0ff',
      'ffd06bff',
      '933b00ff',
      '933b00ff',
      'd3a400ff',
      'ffe800ff',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '923b00ff',
      '923b00ff',
      '933b00ff',
      'd2a300ff',
      'd3a400ff',
      'd3a300ff',
      'd2a300ff',
      '933b00ff',
      'ffd06cff',
      '956837ff',
      'b',
      'ffd06bff',
      'ffeaa0ff',
      'ffeaa0ff',
      'ffd06bff',
      'b',
      'b',
      '933b00ff',
      '923b00ff',
      '923b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      '933b00ff',
      '923b00ff',
      '933b00ff',
      '923b00ff',
      '933b00ff',
      '923b00ff',
      'ffd06cff',
      'ffd06bff',
      'ffffffff',
      'b',
      '956837ff',
      'ffd06cff',
      'b',
      'b',
      'b',
      't',
      't',
      '933b00ff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ecfeff',
      '00ebfeff',
      '923b00ff',
      'b',
      '956937ff',
      '923b00ff',
      'ffd06cff',
      'ffd06cff',
      'ffffffff',
      '00c400ff',
      'ffd06cff',
      'ffd06bff',
      'e8e8e8ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'ffd06bff',
      'ffd06cff',
      'ffd06bff',
      'ffeaa0ff',
      'ffe99fff',
      'ffea9fff',
      'ffeaa0ff',
      'ffeaa0ff',
      '956837ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      't',
      't',
      't',
      't',
      '00ebfdff',
      '00ebfeff',
      '00ebfdff',
      't',
      'b',
      'b',
      '956836ff',
      'ffea9fff',
      'ffeaa0ff',
      'ffeaa0ff',
      'ffeaa0ff',
      '956837ff',
      'ffd06cff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      '00ebfeff',
      't',
      't',
      '00ebfeff',
      't',
      't',
      '00ebfeff',
      'b',
      'b',
      '956937ff',
      '956937ff',
      'ffe99fff',
      'ffd06bff',
      '956836ff',
      'ffd06cff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ecfeff',
      '00ebfeff',
      '00ebfeff',
      '00ebfeff',
      't',
      'b',
      'b',
      '890fd3ff',
      'ffd06bff',
      '956937ff',
      'b',
      'b',
      'ffe99fff',
      'ffd06cff',
      'b',
      'b',
      '00ebfeff',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfdff',
      't',
      't',
      'b',
      'ffd06bff',
      '890ed2ff',
      '890ed2ff',
      'ffd06cff',
      'ffd06cff',
      '956937ff',
      '956836ff',
      'b',
      'b',
      '956937ff',
      '890fd2ff',
      '590c89ff',
      'b',
      '00ebfdff',
      '00ebfeff',
      't',
      't',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      't',
      'b',
      'ffeaa0ff',
      'ffea9fff',
      '5a0c89ff',
      '890ed2ff',
      '890fd2ff',
      'ffea9fff',
      'ffeaa0ff',
      'ffd06bff',
      '956937ff',
      '956937ff',
      '956937ff',
      '890ed2ff',
      '590c89ff',
      'b',
      't',
      '00ebfeff',
      't',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      '00ecfeff',
      '00ebfdff',
      '00ebfeff',
      '00ebfeff',
      '00ecfeff',
      '5a0d89ff',
      '5a0d8aff',
      '8a0fd3ff',
      '890fd3ff',
      '890fd3ff',
      'ffeaa0ff',
      'ffd06bff',
      'ffd06cff',
      'ffd06cff',
      '890ed2ff',
      '5a0d8aff',
      'b',
      't',
      '00ebfeff',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '956837ff',
      'ffd06cff',
      '00ebfeff',
      '00ebfeff',
      '00ebfdff',
      '00ebfeff',
      '890ed2ff',
      '590c89ff',
      '5a0d89ff',
      '890fd2ff',
      'ffd06bff',
      'ffd06bff',
      '890fd3ff',
      '5a0c89ff',
      'b',
      't',
      't',
      't',
      '00ebfeff',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'ffd06bff',
      'ffd06cff',
      '956836ff',
      'b',
      'b',
      '00ebfdff',
      '00ebfeff',
      '5a0d89ff',
      '5a0c89ff',
      '590c89ff',
      '890ed2ff',
      '5a0d89ff',
      '890fd2ff',
      '590c89ff',
      'b',
      't',
      't',
      't',
      '00ebfdff',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'ffd06bff',
      'ffeaa0ff',
      'b',
      'b',
      't',
      't',
      'b',
      '5a0d8aff',
      '5a0c89ff',
      '5a0d8aff',
      '5a0c89ff',
      '590c89ff',
      '5a0d89ff',
      '590c89ff',
      '590c89ff',
      'b',
      'b',
      '00ebfdff',
      '00ebfeff',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '000087ff',
      'b',
      'b',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'ffd06cff',
      'ffd06bff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'ffeaa0ff',
      'ffd06cff',
      'b',
      't',
      't',
      't',
      'b',
      'ff0000ff',
      'e30000ff',
      'e30000ff',
      'ff0000ff',
      'ff0000ff',
      'e30000ff',
      'b',
      '956836ff',
      'ffd06bff',
      'ffd06cff',
      'b',
      't',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      'b',
      '956837ff',
      '956937ff',
      'b',
      'b',
      't',
      't',
      't',
      'b',
      'ff0000ff',
      'ff0000ff',
      'e30000ff',
      'e30000ff',
      'e30000ff',
      'ff0000ff',
      'b',
      'b',
      '956836ff',
      '80fbffff',
      '80fbffff',
      'b',
      'ffeaa0ff',
      '00ebfeff',
      '00ebfeff',
      'b',
      't',
      't',
      't',
      't',
      'b',
      'ffeaa0ff',
      'ffeaa0ff',
      '956836ff',
      '956837ff',
      'b',
      't',
      'b',
      '5a0d8aff',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      '890ed2ff',
      'b',
      'b',
      '000088ff',
      '000088ff',
      'b',
      'ffd06cff',
      '00ebfeff',
      '956837ff',
      'b',
      't',
      't',
      't',
      't',
      'b',
      'ffeaa0ff',
      '956937ff',
      'ffeaa0ff',
      'ffd06cff',
      'b',
      'b',
      'b',
      '5a0d8aff',
      '890ed2ff',
      '890fd2ff',
      '890ed2ff',
      '5a0d89ff',
      '890fd3ff',
      '5a0d89ff',
      '890fd3ff',
      'b',
      't',
      'b',
      '00ebfeff',
      '00ebfeff',
      '00ebfeff',
      '00ebfeff',
      'ffeaa0ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      'b',
      'ffd06bff',
      'ffd06cff',
      '956837ff',
      'ffd06cff',
      'b',
      '590c89ff',
      '890fd3ff',
      '890fd2ff',
      '890ed2ff',
      '5a0c89ff',
      '5a0d8aff',
      '890fd3ff',
      '590c89ff',
      '890ed2ff',
      'b',
      't',
      't',
      'b',
      'ffd06bff',
      '956836ff',
      '956937ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'ffd06cff',
      'b',
      'b',
      '590c89ff',
      '890ed2ff',
      '8a0fd3ff',
      '890ed2ff',
      '5a0d8aff',
      'b',
      '5a0d89ff',
      '890ed2ff',
      '890fd2ff',
      'b',
      't',
      't',
      't',
      'b',
      'ffeaa0ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      '5a0d89ff',
      '5a0d89ff',
      '890fd3ff',
      '890fd3ff',
      '590c89ff',
      '5a0c89ff',
      'b',
      'b',
      '890ed2ff',
      '890ed2ff',
      '590c89ff',
      'b',
      't',
      't',
      't',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '5a0d8aff',
      '890ed2ff',
      '890ed2ff',
      '890ed2ff',
      '5a0c89ff',
      '590c89ff',
      'b',
      '590c89ff',
      '890fd3ff',
      '890fd2ff',
      '590c89ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '5a0d89ff',
      '5a0d89ff',
      '890ed2ff',
      '890ed2ff',
      '590c89ff',
      '590c89ff',
      'b',
      'b',
      '5a0d89ff',
      '890fd3ff',
      '890fd3ff',
      '00ebfeff',
      '00ebfdff',
      '00ebfeff',
      't',
      't',
      't',
      '00ecfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      't',
      't',
      't',
      'b',
      '5a0c89ff',
      '5a0d89ff',
      '890fd2ff',
      '890ed2ff',
      '5a0c89ff',
      'b',
      't',
      'b',
      '5a0d89ff',
      '5a0d89ff',
      '00ebfeff',
      '5a0c89ff',
      'b',
      '00ebfeff',
      '00ebfeff',
      't',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      't',
      't',
      'b',
      '5a0d8aff',
      '5a0c89ff',
      '5a0d89ff',
      '5a0d8aff',
      '890ed2ff',
      '5a0d89ff',
      'b',
      't',
      'b',
      '5a0d89ff',
      '00ebfeff',
      '5a0d8aff',
      '5a0c89ff',
      'b',
      't',
      '00ebfdff',
      '00ebfeff',
      '00ebfdff',
      't',
      't',
      't',
      '00ebfeff',
      't',
      't',
      '00ebfdff',
      '00ebfeff',
      '00ebfeff',
      '00ebfeff',
      'b',
      '5a0d89ff',
      '5a0c89ff',
      '5a0d89ff',
      '5a0d89ff',
      '5a0c89ff',
      'b',
      't',
      't',
      'b',
      '5a0c89ff',
      '5a0d8aff',
      '5a0c89ff',
      '5a0d89ff',
      'b',
      't',
      't',
      't',
      '00ecfeff',
      't',
      't',
      't',
      't',
      '00ebfeff',
      '00ebfeff',
      '00ebfdff',
      't',
      't',
      '00ebfeff',
      '00ebfdff',
      '590c89ff',
      '5a0d89ff',
      '5a0d8aff',
      '5a0d89ff',
      '5a0d89ff',
      'b',
      't',
      't',
      'b',
      '5a0c89ff',
      '5a0d8aff',
      '5a0c89ff',
      '5a0d89ff',
      'b',
      't',
      't',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfdff',
      't',
      't',
      't',
      'b',
      '00ebfeff',
      '00ebfdff',
      '00ebfdff',
      '00ebfdff',
      '00ebfeff',
      '5a0d89ff',
      'b',
      't',
      't',
      'b',
      '5a0d8aff',
      '5a0d89ff',
      '590c89ff',
      '5a0d89ff',
      'b',
      't',
      '00ebfdff',
      't',
      '00ebfeff',
      't',
      't',
      't',
      't',
      't',
      '00ecfeff',
      't',
      't',
      't',
      'b',
      '5a0d8aff',
      '00ebfdff',
      '00ebfeff',
      '590c89ff',
      '5a0d8aff',
      '00ebfeff',
      't',
      't',
      'b',
      '5a0d89ff',
      '590c89ff',
      '5a0d89ff',
      '590c89ff',
      '5a0c89ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      't',
      't',
      't',
      'b',
      '5a0d89ff',
      '5a0d89ff',
      '5a0c89ff',
      '5a0d89ff',
      '590c89ff',
      'b',
      '00ebfdff',
      't',
      'b',
      '890fd3ff',
      '5a0d8aff',
      '5a0d8aff',
      '5a0d89ff',
      '5a0d89ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      '00ebfeff',
      't',
      'b',
      '8a0fd3ff',
      '5a0c89ff',
      '5a0c89ff',
      '5a0d89ff',
      '5a0d89ff',
      '5a0d8aff',
      'b',
      't',
      't',
      'b',
      '890ed2ff',
      '890ed2ff',
      '590c89ff',
      '5a0d89ff',
      '5a0d89ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      '00ebfeff',
      '00ebfdff',
      'b',
      '890ed2ff',
      '890ed2ff',
      '5a0d89ff',
      '890fd3ff',
      '5a0d89ff',
      'b',
      't',
      't',
      't',
      'b',
      '590c89ff',
      '890ed2ff',
      '890fd2ff',
      '890ed2ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '890ed2ff',
      '890ed2ff',
      '890ed2ff',
      '590c89ff',
      'b',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      'b',
      '956837ff',
      '956937ff',
      '956836ff',
      '956837ff',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      '956836ff',
      '956837ff',
      '956937ff',
      '956937ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      'b',
      '956836ff',
      '956937ff',
      '956837ff',
      'd1924cff',
      'd0914cff',
      '956837ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'd0914cff',
      'd1924cff',
      '956937ff',
      '956937ff',
      '956937ff',
      'b',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      'b',
      'b',
      'b',
      'b',
      'b',
      'b',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
      't',
    ],
    size: { height: 55, width: 29 },
  },
];

const drawings: Array<Array<string>> = [];

const getDrawing: (index: number) => Array<string> = (index) => {
  let generatedDrawing = drawings[index];

  if (generatedDrawing) {
    return generatedDrawing;
  }

  const {
    colors,
    size: { height, width },
  } = drawingsDescription[index];

  // Design
  const line = Array(width).fill('%c ').join(' ');
  const design = Array(height).fill(line).join('\n');

  generatedDrawing = [
    design,
    ...colors.map((c) => {
      if (c === 't') {
        return 'background-color:#00000000';
      } else if (line === 'b') {
        return 'background-color:#000000ff';
      }
      return `background-color:#${c}`;
    }),
  ];

  drawings[index] = generatedDrawing;
  return generatedDrawing;
};

const showEE: () => void = () => {
  const i = getRandomInteger(0, drawingsDescription.length - 1);
  logDebug(...getDrawing(i));
};

export { showEE };
