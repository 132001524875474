/* @flow */

import { BO_INVALID_APPLICATION_V2, BO_INVALID_JWT_V2, BO_INVALID_SUBSCRIBER_V2, BO_STREAM_DEVICE_NOT_FOUND } from '../../libs/netgemLibrary/videofutur/types/ErrorCodes';
import type { KeyValuePair, Undefined } from '@ntg/utils/dist/types';
import { type NETGEM_API_V8_FEED_RAW_ITEM, NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT } from '../../libs/netgemLibrary/v8/types/FeedItem';
import { type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PARAM, type NETGEM_API_V8_PLAYBACK_URL, StreamType } from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import type { STREAM_PRIORITIES_BY_TYPE_TYPE, STREAM_PRIORITIES_TYPE } from '../../helpers/ui/metadata/Types';
import { SubtitlesMimeType, VIDEOPLAYER_ERRORS, type VideoPlayerExternalSubtitles, type VideoPlayerInitData } from './implementation/types';
import { compareDrmsFromName, getSupportedDrms, getSupportedTypesPerDrm, isDrmSupported } from '../../helpers/jsHelpers/Drm';
import { getLocationStartAndEndTime, getLocationType } from '../../libs/netgemLibrary/v8/helpers/Item';
import type { BO_API_ERROR_TYPE } from '../../redux/netgemApi/actions/videofutur/types/common';
import { CustomNetworkError } from '../../libs/netgemLibrary/helpers/CustomNetworkError';
import DidomiWrapper from '../../helpers/consent/didomi';
import type { NETGEM_API_CHANNEL } from '../../libs/netgemLibrary/v8/types/Channel';

const filterSubtitlesTrack = (map: Map<string, Array<VideoPlayerExternalSubtitles>>, subtitles: VideoPlayerExternalSubtitles) => {
  const { captions, format, language, mimeType, url } = subtitles;

  // Copy subtitles because object is coming from location metadata which is already stored into Player's state through immer
  const subtitlesCopy = { ...subtitles };

  if (!mimeType) {
    /*
     * If mime type is not provided, try to deduce it from format or URL
     * Mime type will be used later by Shaka Player when loading sidecar subtitles
     */
    subtitlesCopy.mimeType = getMimeType(format, url);
  }
  if (!subtitlesCopy.mimeType) {
    return;
  }

  let subsForLanguage = map.get(language);

  if (subsForLanguage && subsForLanguage.length > 0) {
    // Subtitles for same language have already been found
    const pos = subsForLanguage.findIndex((s) => s.captions === captions && s.mimeType !== mimeType);

    if (pos > -1) {
      // Subtitles for same language and same captions but different mime type
      const { [pos]: subsOtherMimeType } = subsForLanguage;
      if (subsOtherMimeType.mimeType === SubtitlesMimeType.TTML && subtitlesCopy.mimeType === SubtitlesMimeType.WebVTT) {
        // Replace TTML subtitles by WebVTT ones
        subsForLanguage[pos] = subtitlesCopy;
      }
    } else {
      // Add subtitles to list of existing subtitles for this language
      subsForLanguage.push(subtitlesCopy);
    }
  } else {
    // Create map entry and array of subtitles
    subsForLanguage = [subtitlesCopy];
    map.set(language, subsForLanguage);
  }
};

// Filter subtitles by removing same language and role with different format (WebVTT > TTML)
const filterExternalSubtitles = (subtitles?: Array<VideoPlayerExternalSubtitles>): Array<VideoPlayerExternalSubtitles> => {
  if (typeof subtitles === 'undefined') {
    return [];
  }

  const map = new Map<string, Array<VideoPlayerExternalSubtitles>>();
  subtitles.forEach((subs) => filterSubtitlesTrack(map, subs));

  // Convert map back to array
  const filteredSubs = [];
  map.forEach((subsForLanguage) => subsForLanguage.forEach((subs) => filteredSubs.push(subs)));

  return filteredSubs;
};

const getMimeType = (format: ?string, url: string): Undefined<SubtitlesMimeType> => {
  if (format) {
    // Use specified format
    const lcFormat = format.toLowerCase();
    if (lcFormat === 'ttml') {
      return SubtitlesMimeType.TTML;
    }
    if (lcFormat === 'webvtt' || lcFormat === 'vtt') {
      return SubtitlesMimeType.WebVTT;
    }
  }

  // Try to determine format through URL
  if (/ttml/iu.test(url)) {
    return SubtitlesMimeType.TTML;
  } else if (/\.vtt/iu.test(url)) {
    return SubtitlesMimeType.WebVTT;
  }

  return undefined;
};

// Return a list of supported streams
const getStreamsFromPlaybackUrls = (streamPriorities: STREAM_PRIORITIES_TYPE | null, playbackUrls: Array<NETGEM_API_V8_PLAYBACK_URL>): { allStreams: ?Array<any>, errorCode: ?string } => {
  const [
    {
      player,
      start: { params },
    },
  ] = playbackUrls;
  const videostreams = params?.find((p) => p.name === 'videostreams');
  if (!videostreams) {
    return {
      allStreams: null,
      errorCode: VIDEOPLAYER_ERRORS.VS002StreamNotFound,
    };
  }

  const {
    value: { args: streams },
  } = videostreams;

  if (streams.length === 0) {
    return {
      allStreams: null,
      errorCode: VIDEOPLAYER_ERRORS.VS002StreamNotFound,
    };
  }

  // Create an array of well-supported streams
  const supportedTypes = getSupportedTypes();
  const allStreams: Array<any> = [];
  let drmStreamFound = false;

  for (let j = 0; j < streams.length; ++j) {
    const { [j]: stream } = streams;
    const { drms, path, properties, type } = ((stream: any): NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PARAM);
    const isTypeSupported = supportedTypes.includes(type);

    if (drms && drms.length > 0) {
      // DRM is present
      for (let k = 0; k < drms.length; ++k) {
        drmStreamFound = true;
        const {
          [k]: { type: drmType, laUrl, ntgEntitlement },
        } = drms;
        if (isDrmSupported(drmType) && isTypeSupported) {
          allStreams.push({
            drm: drmType,
            laUrl: laUrl?.url ?? null,
            ntgEntitlement,
            path,
            properties,
            type,
          });
        }
      }
    } else {
      // No DRM
      allStreams.push({
        path,
        properties,
        type,
      });
    }
  }

  if (allStreams.length === 0) {
    return {
      allStreams: null,
      errorCode: drmStreamFound ? VIDEOPLAYER_ERRORS.VD002DrmNotSupportedError : VIDEOPLAYER_ERRORS.VS001NoStreamSupported,
    };
  }

  return {
    allStreams: sortStreams(streamPriorities, allStreams, player),
    errorCode: null,
  };
};

const getSupportedTypes = (): Array<StreamType> => {
  const supportedTypes: Array<StreamType> = [];

  getSupportedDrms().forEach((drm) => supportedTypes.push(...getSupportedTypesPerDrm(drm)));

  return supportedTypes;
};

const getVideoStreamDataFromPlatformChannel = (channel: NETGEM_API_CHANNEL): {| errorCode?: string, initData: VideoPlayerInitData | null |} => {
  const { epgid: channelId, info } = channel;

  const notFound = { errorCode: VIDEOPLAYER_ERRORS.VS002StreamNotFound, initData: null };

  if (!info) {
    return notFound;
  }

  const { urlContentType } = info;
  if (!urlContentType) {
    return notFound;
  }

  const { playbacks } = urlContentType;
  if (!playbacks || playbacks.length === 0) {
    return notFound;
  }

  const [
    {
      start: { params, uri },
    },
  ] = playbacks;
  if (uri !== '{videostreams}' || params.length === 0) {
    return notFound;
  }

  const [
    {
      value: { args },
    },
  ] = params;
  if (args.length === 0) {
    return notFound;
  }

  for (const { drms, path: url, type } of args) {
    // No DRM
    if (drms.length === 0) {
      return {
        initData: {
          channelId,
          type,
          url,
        },
      };
    }

    const [{ type: drm, ntgEntitlement }] = drms;

    // Fast channel
    if (ntgEntitlement?.service === 'fastchannel') {
      return {
        initData: {
          channelId,
          ntgEntitlement,
          type,
          url,
        },
      };
    }

    if (isDrmSupported(drm)) {
      return {
        initData: {
          channelId,
          drm,
          ntgEntitlement,
          type,
          url,
        },
      };
    }
  }

  return notFound;
};

const getVideoStreamDataFromDmsChannel = (channel: NETGEM_API_CHANNEL): {| errorCode?: string, initData: VideoPlayerInitData | null |} => {
  const {
    data: {
      stream: { drmType, msdrmCustomData, service, type },
    },
    epgid: channelId,
    isFastChannel,
    url,
  } = channel;

  const localUrl = isFastChannel ? DidomiWrapper.addFastChannelConsent(url) : url;

  if (typeof type === 'undefined') {
    return { errorCode: VIDEOPLAYER_ERRORS.VS002StreamNotFound, initData: null };
  }

  if (!isDrmSupported(drmType)) {
    return { errorCode: VIDEOPLAYER_ERRORS.VD002DrmNotSupportedError, initData: null };
  }

  if (!service || !msdrmCustomData) {
    // No entitlement
    return {
      initData: {
        channelId,
        drm: drmType,
        type,
        url: localUrl,
      },
    };
  }

  return {
    initData: {
      channelId,
      drm: drmType,
      ntgEntitlement: {
        customData: msdrmCustomData,
        service,
        url: localUrl,
      },
      type,
      url: localUrl,
    },
  };
};

const getVideoStreamDataFromChannel = (channel: NETGEM_API_CHANNEL): {| errorCode?: string, initData: VideoPlayerInitData | null |} => {
  const ptfResult = getVideoStreamDataFromPlatformChannel(channel);
  const { errorCode: ptfErrorCode, initData: ptfInitData } = ptfResult;

  if (ptfInitData !== null) {
    return ptfResult;
  }

  const dmsResult = getVideoStreamDataFromDmsChannel(channel);
  const { errorCode: dmsErrorCode, initData: dmsInitData } = dmsResult;

  if (dmsInitData !== null) {
    return dmsResult;
  }

  return {
    errorCode: dmsErrorCode ?? ptfErrorCode,
    initData: null,
  };
};

const getClosestStartTime = (item: NETGEM_API_V8_FEED_RAW_ITEM): number => {
  const stack = [item];
  let closestStartTime = 0;

  while (stack.length > 0) {
    const rawItem = stack.pop();

    // Since flowjs v0.239.0, "rawItem" is said to be potentially undefined (which is impossible, here)
    if (!rawItem) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { elements, locations } = rawItem;

    if (elements) {
      for (let i = 0; i < elements.length; ++i) {
        stack.unshift(elements[i]);
      }
    } else if (locations) {
      for (let j = 0; j < locations.length; ++j) {
        const { [j]: location } = locations;
        const { id } = location;
        const locType = getLocationType(id);
        if (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT) {
          const { startTime } = getLocationStartAndEndTime(location);
          if (startTime > closestStartTime) {
            closestStartTime = startTime;
          }
        }
      }
    }
  }

  return closestStartTime;
};

const sortStreams = (streamPriorities: STREAM_PRIORITIES_TYPE | null, streams: Array<any>, player: string): Array<any> => {
  let priorities: ?STREAM_PRIORITIES_BY_TYPE_TYPE = null;
  if (streamPriorities) {
    priorities = streamPriorities[player] || streamPriorities.default;
  }

  // Sort bitrates inside each stream
  streams.forEach((a) => {
    if (a.properties && a.properties.videos && a.properties.videos.length > 0) {
      a.properties.videos.sort((b, c) => c.bitrate - b.bitrate);
    } else {
      a.properties = { videos: [{ bitrate: 0 }] };
    }
  });

  // Sort per DRM (streams with DRM first), then per format then per bitrate
  streams.sort((a, b) => {
    if (a.drm && !b.drm) {
      return -1;
    }
    if (!a.drm && b.drm) {
      return 1;
    }

    const drmComparison = compareDrmsFromName(a.drm, b.drm);
    if (drmComparison !== 0) {
      return drmComparison;
    }

    if (priorities) {
      const index1 = priorities[a.type];
      const index2 = priorities[b.type];
      if (index1 !== index2) {
        return index1 - index2;
      }
    }

    return b.properties.videos[0].bitrate - a.properties.videos[0].bitrate;
  });

  return streams;
};

/* eslint-disable no-magic-numbers */
const getHtmlMediaErrorText = (code: number): string => {
  switch (code) {
    case 1:
      return 'MEDIA_ERR_ABORTED';
    case 2:
      return 'MEDIA_ERR_NETWORK';
    case 3:
      return 'MEDIA_ERR_DECODE';
    case 4:
      return 'MEDIA_ERR_SRC_NOT_SUPPORTED';
    default:
      return `UNKNOWN (code: ${code})`;
  }
};
/* eslint-enable no-magic-numbers */

const isInvalidTokenError = (error: Error | BO_API_ERROR_TYPE): boolean => {
  if (!(error instanceof CustomNetworkError)) {
    return false;
  }

  const code = error.getCustomCode();
  return code === BO_STREAM_DEVICE_NOT_FOUND || code === BO_INVALID_JWT_V2 || code === BO_INVALID_APPLICATION_V2 || code === BO_INVALID_SUBSCRIBER_V2;
};

const getInitDataAsSentryContext = (initData: ?VideoPlayerInitData): KeyValuePair<string | number | boolean | null | void> => {
  if (!initData) {
    return {};
  }

  const { audioLanguage, channelId, customHeaders, drm, fairplayCertificateUrl, laUrl, manifestUpdatePeriod, maxBitrate, ntgEntitlement, subtitlesLanguage, trackingStart, type, url, vuDrmToken } =
    initData;

  return {
    audioLanguage,
    channelId,
    customHeaders: JSON.stringify(customHeaders),
    drm: drm ? (drm: string) : 'undefined',
    fairplayCertificateUrl,
    laUrl,
    manifestUpdatePeriod,
    maxBitrate,
    ntgEntitlement: JSON.stringify(ntgEntitlement),
    subtitlesLanguage,
    trackingStart: JSON.stringify(trackingStart),
    type: (type: string),
    url,
    vuDrmToken,
  };
};

export { filterExternalSubtitles, getClosestStartTime, getHtmlMediaErrorText, getInitDataAsSentryContext, getStreamsFromPlaybackUrls, getVideoStreamDataFromChannel, isInvalidTokenError };
