/* @flow */

import './SearchHistoryTerm.css';
import * as React from 'react';
import { PictoReplay, PictoX } from '@ntg/components/dist/pictos/Element';
import type { Dispatch } from '../../redux/types/types';
import { connect } from 'react-redux';
import { deleteSearchHistoryTerm } from '../../redux/ui/actions';

type ReduxSearchHistoryTermDispatchToPropsType = {|
  +localDeleteSearchString: (searchString: string) => void,
|};

type SearchHistoryTermPropType = {|
  +onClick: (term: string) => void,
  +term: string,
|};

type CompleteSearchHistoryTermPropType = {|
  ...SearchHistoryTermPropType,
  ...ReduxSearchHistoryTermDispatchToPropsType,
|};

type SearchHistoryTermStateType = {||};

class SearchHistoryTermView extends React.PureComponent<CompleteSearchHistoryTermPropType, SearchHistoryTermStateType> {
  handleSearchOnClick: () => void = () => {
    const { onClick, term } = this.props;

    onClick(term);
  };

  handleDeleteOnClick: () => void = () => {
    const { localDeleteSearchString, term } = this.props;

    localDeleteSearchString(term);
  };

  render(): React.Node {
    const { term } = this.props;

    return (
      <div className='searchHistoryTerm'>
        <div className='delete' onClick={this.handleDeleteOnClick}>
          <PictoX onClick={this.handleDeleteOnClick} />
        </div>
        <div className='term' onClick={this.handleSearchOnClick}>
          {term}
        </div>
        <PictoReplay className='refresh' />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxSearchHistoryTermDispatchToPropsType => {
  return {
    localDeleteSearchString: (searchString: string) => dispatch(deleteSearchHistoryTerm(searchString)),
  };
};

const SearchHistoryTerm: React.ComponentType<SearchHistoryTermPropType> = connect(null, mapDispatchToProps, null, { forwardRef: true })(SearchHistoryTermView);

export default SearchHistoryTerm;
