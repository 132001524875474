/* @flow */

import { parseBoolean, parseInteger, parseIntegerArray, parseStringArray } from '../jsHelpers/parser';
import type { DATA_COLLECTION_SETTINGS } from './types';
import type { DmsSettingMap } from '../../libs/netgemLibrary/dms/types/DeviceInfoSettings';
import { MILLISECONDS_PER_SECOND } from '../dateTime/Format';
import { getSettingValueByNameFromDeviceSettings } from '../../redux/netgemApi/actions/helpers/settings';

const convertToMilliseconds: (seconds: number) => number = (seconds) => {
  if (seconds === -1) {
    return seconds;
  }

  return seconds * MILLISECONDS_PER_SECOND;
};

const getDataCollectionSettings: (deviceSettings: DmsSettingMap) => DATA_COLLECTION_SETTINGS = (deviceSettings) => {
  const isEnabled = parseBoolean(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection', 'enabled'));
  const coldMaxFlushDelay = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.batch', 'maxflushdelay'));
  const coldMaxFlushSize = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.batch', 'maxflushsize'));
  const coldSasDelay = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.batch', 'sasdelay'));
  const hotMaxFlushDelay = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.speed', 'maxflushdelay'));
  const hotMaxFlushSize = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.speed', 'maxflushsize'));
  const hotSasDelay = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.speed', 'sasdelay'));
  const sampling = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection', 'sampling'));
  const samplingDelay = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.heartbeat', 'samplingdelay'));
  const startDelay = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.heartbeat', 'startdelay'));

  // Message: Current state
  const currentStateEnabled = parseBoolean(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.v1_currentState', 'enabled'));
  const currentStateFrequency = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.v1_currentState', 'frequency'));
  const currentStateRampUp = parseIntegerArray(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.v1_currentState', 'rampup'));

  // Message: Navigation
  const navigationEnabled = parseBoolean(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.v1_navigation', 'enabled'));

  // Message: Player state
  const playerStateEnabled = parseBoolean(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.v1_playerState', 'enabled'));
  const playerStateFrequency = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.v1_playerState', 'frequency'));
  const playerStateRampUp = parseIntegerArray(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.v1_playerState', 'rampup'));
  const playerStateStreamTypes = parseStringArray(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.v1_playerState', 'streamTypes'));

  // Message: Turn off
  const turnOffEnabled = parseBoolean(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.v1_turnOff', 'enabled'));

  // Message: Turn on
  const turnOnEnabled = parseBoolean(getSettingValueByNameFromDeviceSettings(deviceSettings, 'datacollection.v1_turnOn', 'enabled'));

  return {
    coldHub: {
      maxFlushDelay: convertToMilliseconds(coldMaxFlushDelay),
      maxFlushSize: coldMaxFlushSize,
      sasDelay: convertToMilliseconds(coldSasDelay),
    },
    hotHub: {
      maxFlushDelay: convertToMilliseconds(hotMaxFlushDelay),
      maxFlushSize: hotMaxFlushSize,
      sasDelay: convertToMilliseconds(hotSasDelay),
    },
    isEnabled,
    msgCurrentState: {
      frequency: convertToMilliseconds(currentStateFrequency),
      isEnabled: currentStateEnabled && (currentStateRampUp.length > 0 || currentStateFrequency > -1),
      rampUp: currentStateRampUp.map((t) => t * MILLISECONDS_PER_SECOND),
    },
    msgNavigation: { isEnabled: navigationEnabled },
    msgPlayerState: {
      frequency: convertToMilliseconds(playerStateFrequency),
      isEnabled: playerStateEnabled && playerStateStreamTypes.length > 0 && (playerStateRampUp.length > 0 || playerStateFrequency > -1),
      rampUp: playerStateRampUp.map((t) => t * MILLISECONDS_PER_SECOND),
      streamTypes: playerStateStreamTypes,
    },
    msgTurnOff: { isEnabled: turnOffEnabled },
    msgTurnOn: { isEnabled: turnOnEnabled },
    sampling,
    samplingDelay: convertToMilliseconds(samplingDelay),
    startDelay: convertToMilliseconds(startDelay),
  };
};

export { getDataCollectionSettings };
