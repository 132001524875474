/* @flow */

import LocalStorageManager from './localStorageManager';
import { StorageKeys } from './keys';

const deleteOptimisticData = (): void => {
  LocalStorageManager.delete(StorageKeys.OptimisticDmsSettingsUsed, StorageKeys.OptimisticDmsSettings, StorageKeys.OptimisticHub, StorageKeys.OptimisticDefaultActions);
};

export { deleteOptimisticData };
