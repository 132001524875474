/* @flow */

import type { Dispatch, Store } from '../../types/types';
import { type KeyValuePair, isUndefinedOrNull } from '@ntg/utils/dist/types';
import {
  REDUX_MSG_REQUEST_BO_AUTHENTICATE,
  REDUX_MSG_REQUEST_BO_DISCOVERY,
  REDUX_MSG_REQUEST_BO_FAVORITE_ADD,
  REDUX_MSG_REQUEST_BO_FAVORITE_DELETE,
  REDUX_MSG_REQUEST_BO_FAVORITE_LIST,
  REDUX_MSG_REQUEST_BO_PERSONAL_DATA,
  REDUX_MSG_REQUEST_BO_PURCHASE,
  REDUX_MSG_REQUEST_BO_PURCHASE_LIST,
  REDUX_MSG_REQUEST_BO_PURCHASE_STATUS,
  REDUX_MSG_REQUEST_BO_STREAMS_GET,
  REDUX_MSG_REQUEST_BO_STREAM_CREATE,
  REDUX_MSG_REQUEST_BO_STREAM_START,
  REDUX_MSG_REQUEST_BO_STREAM_STOP,
  REDUX_MSG_REQUEST_CUSTOM_STRATEGY,
  REDUX_MSG_REQUEST_DATA_COLLECTION,
  REDUX_MSG_REQUEST_DATA_COLLECTION_COLLECTOR_ID,
  REDUX_MSG_REQUEST_ENTITLEMENT_GET,
  REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE,
  REDUX_MSG_REQUEST_GENERIC,
  REDUX_MSG_REQUEST_GET_ASSOCIATED_DEVICES,
  REDUX_MSG_REQUEST_GET_DEVICE_CHANNELS,
  REDUX_MSG_REQUEST_GET_DEVICE_QUOTA_PVR,
  REDUX_MSG_REQUEST_GET_DEVICE_SETTINGS,
  REDUX_MSG_REQUEST_LIST_ALIAS_MULTI,
  REDUX_MSG_REQUEST_LIST_ALIAS_POST,
  REDUX_MSG_REQUEST_LOGIN,
  REDUX_MSG_REQUEST_LOGOUT,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_DELETE,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_FUTURE,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_LIST,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_METADATA,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_QUOTA,
  REDUX_MSG_REQUEST_NPVR_RECORDINGS_RETRY,
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_CREATE,
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_DELETE,
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_LIST,
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_METADATA,
  REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_UPDATE,
  REDUX_MSG_REQUEST_PERSONAL_DATA_DELETE,
  REDUX_MSG_REQUEST_PERSONAL_DATA_GET,
  REDUX_MSG_REQUEST_PERSONAL_DATA_POST,
  REDUX_MSG_REQUEST_SECTION_FEED,
} from '../constants/';
import type { STREAM_PRIORITIES_BY_TYPE_TYPE, STREAM_PRIORITIES_TYPE } from '../../../helpers/ui/metadata/Types';
import { parseBoolean, parseInteger } from '../../../helpers/jsHelpers/parser';
import { unregisterApplication, updateAssociatedDevices, updateAuthenticationToken } from '../../appRegistration/actions';
import {
  updateBOSettings,
  updateBOState,
  updateDeviceChannels,
  updateDeviceSettings,
  updateDistributorAppKeys,
  updateMainDistributor,
  updateMeshState,
  updatePlayerStreamPriorities,
} from '../../appConf/actions';
import { updateNpvrState, updateQuotaPvr } from '../../npvr/actions';
import type { BO_API_PARAMETERS_TYPE } from '../../../helpers/videofutur/queryParameters';
import type { ChannelMap } from '../../../libs/netgemLibrary/v8/types/Channel';
import Collector from '../../../helpers/dataCollection/collector';
import type { CombinedReducers } from '../../reducers';
import { CustomNetworkError } from '../../../libs/netgemLibrary/helpers/CustomNetworkError';
import { type DmsSettingMap } from '../../../libs/netgemLibrary/dms/types/DeviceInfoSettings';
import { HttpMethod } from '../../../libs/netgemLibrary/v8/types/HttpMethod';
import { type NETGEM_API_DMS_ASSOCIATED_DEVICE } from '../../../libs/netgemLibrary/dms/types/AssociatedDevice';
import { type NETGEM_API_DMS_QUOTA_PVR } from '../../../libs/netgemLibrary/dms/types/QuotaPvr';
import type { NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD } from '../../../libs/netgemLibrary/v8/types/DataCollection';
import type { NETGEM_API_V8_REQUEST_HEADERS } from '../../../libs/netgemLibrary/v8/types/Headers';
import type { NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND } from '../../../libs/netgemLibrary/v8/types/Npvr';
import { type NETGEM_API_V8_SECTION } from '../../../libs/netgemLibrary/v8/types/Section';
import NetgemApi from '../../../libs/netgemLibrary';
import { XhrResponseType } from '../../../helpers/jsHelpers/xhr';
import { createCustomNetworkErrorFromKey } from '../../../libs/netgemLibrary/helpers/CreateCustomNetworkError';
import { getDataCollectionSettings } from '../../../helpers/dataCollection/settings';
import { getSettingValueByNameFromDeviceSettings } from './helpers/settings';
import { logError } from '../../../helpers/debug/debug';

let ntgApiInstance: NetgemApi | null = null;

const buildPriorities: (streamPriorities: ?string) => STREAM_PRIORITIES_TYPE | null = (streamPriorities) => {
  if (!streamPriorities) {
    return null;
  }

  const priorities: STREAM_PRIORITIES_TYPE = { default: {} };

  const prioritiesRaw = streamPriorities.split(',') ?? [];
  prioritiesRaw.forEach((p) => {
    const [name, ...value] = p.split('.');

    if (value.length === 0) {
      priorities.default[name] = Object.keys(priorities.default).length;
    } else {
      let pList: STREAM_PRIORITIES_BY_TYPE_TYPE = priorities[name];
      if (!pList) {
        pList = {};
        priorities[name] = pList;
      }

      // $FlowFixMe: weird prop-missing error since keys can be any string, and we're assigning here
      pList[value.join('.')] = Object.keys(pList).length;
    }
  });

  return priorities;
};

const extractDistributorAppKeys: (settings: DmsSettingMap) => KeyValuePair<string> = (settings) => {
  const appKeys: KeyValuePair<string> = {};

  const { 'platform.vf.appkey': list } = settings;

  if (list) {
    list.forEach(({ name, value }) => {
      appKeys[name] = value;
    });
  }

  return appKeys;
};

const createNetgemApiLibraryInstance: (dispatch: Dispatch, getState: () => CombinedReducers) => void = (dispatch, getState) => {
  const connectedCallback = (authenticationToken: string | null) => {
    const {
      appConfiguration: { configuration, useBOV2Api },
    } = getState();

    dispatch(updateAuthenticationToken(authenticationToken, configuration, useBOV2Api));
  };

  const disconnectedCallback = () => {
    dispatch(unregisterApplication());
    dispatch(updateAuthenticationToken(null, {}));
  };

  const deviceChannelsCallback: (deviceChannels: ChannelMap) => void = (deviceChannels) => dispatch(updateDeviceChannels(deviceChannels));

  const associatedDevicesCallback = (associatedDevices: Array<NETGEM_API_DMS_ASSOCIATED_DEVICE>) => dispatch(updateAssociatedDevices(associatedDevices));

  const quotaPvrCallback = (quotaPvr: ?NETGEM_API_DMS_QUOTA_PVR) => dispatch(updateQuotaPvr(quotaPvr));

  const boAuthenticationCallback = (settings?: KeyValuePair<string>) => {
    dispatch(updateBOState(!isUndefinedOrNull(settings)));
    dispatch(updateBOSettings(settings ?? {}));
  };

  const deviceSettingsCallback: (deviceSettings: DmsSettingMap) => void = (deviceSettings) => {
    dispatch(updateDeviceSettings(deviceSettings));
    dispatch(updateDistributorAppKeys(extractDistributorAppKeys(deviceSettings)));

    const distributorId = getSettingValueByNameFromDeviceSettings(deviceSettings, 'platform.vf', 'distributor');
    if (distributorId) {
      dispatch(updateMainDistributor(distributorId));
    }

    const streamPriorities = getSettingValueByNameFromDeviceSettings(deviceSettings, 'videoplayer', 'streampriorities');
    dispatch(updatePlayerStreamPriorities(buildPriorities(streamPriorities)));

    const isEnabled = parseBoolean(getSettingValueByNameFromDeviceSettings(deviceSettings, 'npvr', 'enabled'));
    const recFromBeginning = parseBoolean(getSettingValueByNameFromDeviceSettings(deviceSettings, 'npvr', 'rec_from_beginning'));
    const keepFromReplay = parseBoolean(getSettingValueByNameFromDeviceSettings(deviceSettings, 'npvr.keep_from_replay', 'enabled'));
    const startMargin = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'npvr.margin', 'start'));
    const endMargin = parseInteger(getSettingValueByNameFromDeviceSettings(deviceSettings, 'npvr.margin', 'end'));
    dispatch(updateNpvrState(isEnabled, recFromBeginning, keepFromReplay, startMargin, endMargin));

    const meshSetting = getSettingValueByNameFromDeviceSettings(deviceSettings, 'mesh', 'enabled');
    const isMeshEnabled = meshSetting !== null ? parseBoolean(meshSetting) : null;
    dispatch(updateMeshState(isMeshEnabled));

    const {
      appConfiguration: { versionApp },
    } = getState();
    const realm = getSettingValueByNameFromDeviceSettings(deviceSettings, 'platform', 'realm') || 'unknown realm';
    const dataCollectionSettings = getDataCollectionSettings(deviceSettings);
    Collector.initialize(dispatch, versionApp, realm, dataCollectionSettings);
  };

  ntgApiInstance = new NetgemApi(connectedCallback, disconnectedCallback, deviceSettingsCallback, deviceChannelsCallback, associatedDevicesCallback, quotaPvrCallback, boAuthenticationCallback);
};

export const initialize: (store: Store) => void = (store) => {
  const { dispatch, getState } = store;
  createNetgemApiLibraryInstance(dispatch, getState);
};

type PossibleEmitterType =
  | typeof REDUX_MSG_REQUEST_CUSTOM_STRATEGY
  | typeof REDUX_MSG_REQUEST_DATA_COLLECTION
  | typeof REDUX_MSG_REQUEST_DATA_COLLECTION_COLLECTOR_ID
  | typeof REDUX_MSG_REQUEST_ENTITLEMENT_GET
  | typeof REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE
  | typeof REDUX_MSG_REQUEST_GENERIC
  | typeof REDUX_MSG_REQUEST_GET_ASSOCIATED_DEVICES
  | typeof REDUX_MSG_REQUEST_GET_DEVICE_CHANNELS
  | typeof REDUX_MSG_REQUEST_GET_DEVICE_QUOTA_PVR
  | typeof REDUX_MSG_REQUEST_GET_DEVICE_SETTINGS
  | typeof REDUX_MSG_REQUEST_LIST_ALIAS_MULTI
  | typeof REDUX_MSG_REQUEST_LIST_ALIAS_POST
  | typeof REDUX_MSG_REQUEST_LOGIN
  | typeof REDUX_MSG_REQUEST_LOGOUT
  | typeof REDUX_MSG_REQUEST_NPVR_RECORDINGS_DELETE
  | typeof REDUX_MSG_REQUEST_NPVR_RECORDINGS_FUTURE
  | typeof REDUX_MSG_REQUEST_NPVR_RECORDINGS_LIST
  | typeof REDUX_MSG_REQUEST_NPVR_RECORDINGS_METADATA
  | typeof REDUX_MSG_REQUEST_NPVR_RECORDINGS_QUOTA
  | typeof REDUX_MSG_REQUEST_NPVR_RECORDINGS_RETRY
  | typeof REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_CREATE
  | typeof REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_DELETE
  | typeof REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_LIST
  | typeof REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_METADATA
  | typeof REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_UPDATE
  | typeof REDUX_MSG_REQUEST_PERSONAL_DATA_DELETE
  | typeof REDUX_MSG_REQUEST_PERSONAL_DATA_GET
  | typeof REDUX_MSG_REQUEST_PERSONAL_DATA_POST
  | typeof REDUX_MSG_REQUEST_SECTION_FEED
  | typeof REDUX_MSG_REQUEST_BO_AUTHENTICATE
  | typeof REDUX_MSG_REQUEST_BO_DISCOVERY
  | typeof REDUX_MSG_REQUEST_BO_FAVORITE_ADD
  | typeof REDUX_MSG_REQUEST_BO_FAVORITE_DELETE
  | typeof REDUX_MSG_REQUEST_BO_FAVORITE_LIST
  | typeof REDUX_MSG_REQUEST_BO_PERSONAL_DATA
  | typeof REDUX_MSG_REQUEST_BO_PURCHASE
  | typeof REDUX_MSG_REQUEST_BO_PURCHASE_LIST
  | typeof REDUX_MSG_REQUEST_BO_PURCHASE_STATUS
  | typeof REDUX_MSG_REQUEST_BO_STREAM_CREATE
  | typeof REDUX_MSG_REQUEST_BO_STREAM_START
  | typeof REDUX_MSG_REQUEST_BO_STREAM_STOP
  | typeof REDUX_MSG_REQUEST_BO_STREAMS_GET;

export type NetgemApiEmitterType = {|
  emit: (type: PossibleEmitterType, payload: any) => Promise<any>,
|};

type NtgApiActionPayloadType = {|
  signal?: AbortSignal,
  uri: string,
|};

export type ApplicationInfo = {|
  appVersion: string,
  browserName: string,
  browserVersion: string,
  displayResolution: string,
  osVersion: string,
  osName: string,
  screenResolution: string,
  timestamp: string,
|};

type NtgApiActionDmsSettingsPayloadType = {|
  ...NtgApiActionPayloadType,
  appInfo: ApplicationInfo,
|};

type NtgApiPayloadBaseType = {|
  ...NtgApiActionPayloadType,
  authent: boolean,
  method: HttpMethod,
|};

type NtgApiReducerPayloadType = {|
  ...NtgApiPayloadBaseType,
  responseType?: string,
  state?: CombinedReducers,
|};

type NtgApiPayloadWithETagType = {|
  ...NtgApiPayloadBaseType,
  eTag: string,
|};

type NtgLogoutRequestPayloadType = {|
  logoutUrl?: string,
|};

type NtgLoginRequestPayloadType = {|
  applicationId: string,
  authDeviceUrl: string,
  deviceKey: string,
  isFirstToken: ?boolean,
  oem: ?string,
  signal?: AbortSignal,
  subscriberId: string,
  upgradeDeviceUrl: string,
  version: ?string,
|};

type NtgEntitlementRequestPayloadType = {|
  ...NtgApiPayloadBaseType,
  applicationId: string,
  channelName: string,
  customData: string,
  service: string,
  url: string,
|};

type NtgListAliasPostRequestPayloadType = {|
  ...NtgApiPayloadBaseType,
  bodyParam: string,
|};

type NtgScheduledRecordingsCreatePayloadType = {|
  ...NtgApiPayloadBaseType,
  channelId: ?string,
  endMargin: number,
  fromBeginning: boolean,
  fromUtc: ?string,
  increasingEpisodes: ?boolean,
  recordsToKeep: ?number,
  scheduledRecordKind: NETGEM_API_V8_SCHEDULED_RECORDINGS_KIND,
  startMargin: number,
  target: string,
  toUtc: ?string,
|};

type NtgScheduledRecordingsUpdatePayloadType = {|
  ...NtgApiPayloadWithETagType,
  assetId: string,
  fromUtc: ?string,
  increasingEpisodes: ?boolean,
  recordsToKeep: ?number,
  toUtc: ?string,
|};

type NtgPersonalDataPostRequestPayloadType = {|
  ...NtgApiPayloadWithETagType,
  data: any,
|};

type NtgSectionFeedRequestPayloadType = {|
  searchString: ?string,
  section: NETGEM_API_V8_SECTION,
  signal?: AbortSignal,
  state: CombinedReducers,
|};

type NtgDataCollectionPayloadType = {|
  ...NtgApiPayloadBaseType,
  content: NETGEM_API_V8_DATA_COLLECTION_BATCH_PAYLOAD,
  headers: NETGEM_API_V8_REQUEST_HEADERS,
|};

type BOApiPayloadBaseType = {|
  authent: boolean,
  eTag?: string,
  headers: NETGEM_API_V8_REQUEST_HEADERS,
  method: HttpMethod,
  signal?: AbortSignal,
  uri: string,
  useBOV2Api?: boolean,
|};

type BOFavoritesApiPayloadType = {|
  ...BOApiPayloadBaseType,
  titId?: string,
|};

type BOWithVtiIdApiPayloadType = {|
  ...BOApiPayloadBaseType,
  vtiId: number,
|};

type BOPurchaseApiPayloadType = {|
  ...BOWithVtiIdApiPayloadType,
  promocode: string,
|};

type BOCreateStreamApiPayloadType = {|
  ...BOApiPayloadBaseType,
  drm?: string,
  format?: string,
  license?: boolean,
  params?: BO_API_PARAMETERS_TYPE | null,
|};

type BOStreamApiPayloadType = {|
  ...BOApiPayloadBaseType,
  bookmark?: string,
  keepAlive?: boolean,
  streamId: number,
|};

type BOAuthenticationSettingsRequestPayloadType = {|
  appKey: string,
  applicationId: string,
  authenticationUrl: string,
  deviceKey: string,
  signal?: AbortSignal,
|};

type BODiscoveryRequestPayloadType = {|
  device: string,
  discoveryUrl: string,
  distributor: string,
  identity: string,
  signal?: AbortSignal,
  useBOV2Api?: boolean,
|};

/* eslint-disable complexity, max-lines-per-function */
export const emit: (type: PossibleEmitterType, payload: any) => Promise<any> = (type, payload) => {
  if (!ntgApiInstance) {
    return Promise.reject(createCustomNetworkErrorFromKey('common.messages.errors.ntg_api_not_initialized'));
  }

  switch (type) {
    case REDUX_MSG_REQUEST_LOGIN: {
      const { applicationId, authDeviceUrl, deviceKey, isFirstToken, oem, signal, subscriberId, upgradeDeviceUrl, version } = (payload: NtgLoginRequestPayloadType);
      return ntgApiInstance.sendLoginRequest(authDeviceUrl, upgradeDeviceUrl, applicationId, subscriberId, deviceKey, oem, version, isFirstToken, signal);
    }

    case REDUX_MSG_REQUEST_LOGOUT: {
      const { logoutUrl } = (payload: NtgLogoutRequestPayloadType);
      return ntgApiInstance.sendLogoutRequest(logoutUrl);
    }

    case REDUX_MSG_REQUEST_GET_DEVICE_SETTINGS: {
      const { appInfo, signal } = (payload: NtgApiActionDmsSettingsPayloadType);
      return ntgApiInstance.getDeviceSettings(appInfo, signal);
    }

    case REDUX_MSG_REQUEST_GET_DEVICE_CHANNELS: {
      const { signal } = (payload: NtgApiActionPayloadType);
      return ntgApiInstance.getDeviceChannelList(signal);
    }

    case REDUX_MSG_REQUEST_GET_ASSOCIATED_DEVICES: {
      const { signal } = (payload: NtgApiActionPayloadType);
      return ntgApiInstance.getAssociatedDeviceList(signal);
    }

    case REDUX_MSG_REQUEST_GET_DEVICE_QUOTA_PVR:
      return ntgApiInstance.getQuotaPvr();

    case REDUX_MSG_REQUEST_ENTITLEMENT_GET:
    case REDUX_MSG_REQUEST_ENTITLEMENT_RELEASE: {
      const { applicationId, authent, channelName, customData, method, service, signal, uri, url } = (payload: NtgEntitlementRequestPayloadType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendNtgEntitlementRequest(uri, applicationId, channelName, customData, service, url, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_LIST_ALIAS_MULTI:
    case REDUX_MSG_REQUEST_LIST_ALIAS_POST: {
      const { authent, bodyParam, method, signal, uri } = (payload: NtgListAliasPostRequestPayloadType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8ListAliasPostRequest(uri, bodyParam, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_SECTION_FEED: {
      const { searchString, section, signal, state } = (payload: NtgSectionFeedRequestPayloadType);
      return ntgApiInstance.sendV8SectionFeedRequest(section, searchString, state, signal);
    }

    case REDUX_MSG_REQUEST_GENERIC: {
      const { authent, method, responseType, signal, state, uri } = (payload: NtgApiReducerPayloadType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8GenericRequest(uri, method, authenticationToken, responseType, state, signal);
    }

    case REDUX_MSG_REQUEST_CUSTOM_STRATEGY: {
      const { signal, uri } = (payload: NtgApiActionPayloadType);
      return ntgApiInstance.sendV8CustomStrategyRequest(uri, signal);
    }

    case REDUX_MSG_REQUEST_PERSONAL_DATA_DELETE: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendPersonalDataDeleteRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_PERSONAL_DATA_GET: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendPersonalDataGetRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_PERSONAL_DATA_POST: {
      const { authent, data, eTag, method, signal, uri } = (payload: NtgPersonalDataPostRequestPayloadType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendPersonalDataPostRequest(uri, data, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_NPVR_RECORDINGS_DELETE: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8RecordingsDeleteRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_NPVR_RECORDINGS_FUTURE: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8RecordingsFutureRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_NPVR_RECORDINGS_LIST: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8RecordingsListRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_NPVR_RECORDINGS_METADATA: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8RecordingsMetadataRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_NPVR_RECORDINGS_QUOTA: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8RecordingsQuotaRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_NPVR_RECORDINGS_RETRY: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8RecordingsRetryRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_CREATE: {
      const { authent, channelId, endMargin, fromBeginning, fromUtc, increasingEpisodes, method, recordsToKeep, scheduledRecordKind, signal, startMargin, target, toUtc, uri } =
        (payload: NtgScheduledRecordingsCreatePayloadType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8ScheduledRecordingCreateRequest(
        uri,
        scheduledRecordKind,
        target,
        fromBeginning,
        startMargin,
        endMargin,
        channelId,
        fromUtc,
        toUtc,
        increasingEpisodes,
        recordsToKeep,
        method,
        authenticationToken,
        signal,
      );
    }

    case REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_DELETE: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8ScheduledRecordingDeleteRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_LIST: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8ScheduledRecordingListRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_METADATA: {
      const { authent, eTag, method, signal, uri } = (payload: NtgApiPayloadWithETagType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8ScheduledRecordingMetadataRequest(uri, eTag, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_NPVR_SCHEDULED_RECORDINGS_UPDATE: {
      const { assetId, authent, eTag, fromUtc, increasingEpisodes, method, recordsToKeep, signal, toUtc, uri } = (payload: NtgScheduledRecordingsUpdatePayloadType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendV8ScheduledRecordingUpdateRequest(uri, assetId, fromUtc, toUtc, eTag, increasingEpisodes, recordsToKeep, method, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_DATA_COLLECTION_COLLECTOR_ID: {
      const { signal, uri } = (payload: NtgApiActionPayloadType);
      const { authenticationToken } = ntgApiInstance;
      return ntgApiInstance.sendV8DataCollectionCollectorIdRequest(uri, authenticationToken, signal);
    }

    case REDUX_MSG_REQUEST_DATA_COLLECTION: {
      const { content, headers, method, signal, uri } = (payload: NtgDataCollectionPayloadType);
      return ntgApiInstance.sendV8DataCollectionRequest(uri, content, method, headers, signal);
    }

    case REDUX_MSG_REQUEST_BO_AUTHENTICATE: {
      const { appKey, applicationId, authenticationUrl, deviceKey, signal } = (payload: BOAuthenticationSettingsRequestPayloadType);
      return ntgApiInstance.sendBOAuthenticationRequest(authenticationUrl, applicationId, deviceKey, appKey, signal);
    }

    case REDUX_MSG_REQUEST_BO_DISCOVERY: {
      const { device, discoveryUrl, distributor, identity, signal, useBOV2Api } = (payload: BODiscoveryRequestPayloadType);
      return ntgApiInstance.sendVideofuturDiscoveryRequest(discoveryUrl, device, distributor, identity, signal, useBOV2Api);
    }

    case REDUX_MSG_REQUEST_BO_PERSONAL_DATA: {
      const { authent, headers, method, signal, uri } = (payload: BOApiPayloadBaseType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendVideofuturRequest(uri, method, authenticationToken, XhrResponseType.Json, null, null, headers, signal);
    }

    case REDUX_MSG_REQUEST_BO_STREAM_CREATE: {
      const { authent, headers, method, params, signal, uri, useBOV2Api } = (payload: BOCreateStreamApiPayloadType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendVideofuturRequest(uri, method, authenticationToken, XhrResponseType.Json, !useBOV2Api ? params : null, useBOV2Api ? params : null, headers, signal);
    }

    case REDUX_MSG_REQUEST_BO_STREAMS_GET: {
      const { authent, headers, method, signal, uri } = (payload: BOApiPayloadBaseType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendVideofuturRequest(uri, method, authenticationToken, XhrResponseType.Json, null, null, headers, signal);
    }

    case REDUX_MSG_REQUEST_BO_STREAM_START:
    case REDUX_MSG_REQUEST_BO_STREAM_STOP: {
      const { authent, bookmark, headers, keepAlive, method, signal, streamId, uri, useBOV2Api } = (payload: BOStreamApiPayloadType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendVideofuturRequest(
        uri,
        method,
        authenticationToken,
        XhrResponseType.Json,
        useBOV2Api ? null : { streamId },
        // TODO: BO API v2 | pass correct bookmark from player or undefined at app start
        useBOV2Api ? { bookmark: bookmark ?? 'PT0S', vstId: streamId } : null,
        headers,
        signal,
        keepAlive,
      );
    }

    case REDUX_MSG_REQUEST_BO_FAVORITE_LIST:
    case REDUX_MSG_REQUEST_BO_FAVORITE_ADD:
    case REDUX_MSG_REQUEST_BO_FAVORITE_DELETE: {
      const { authent, eTag, headers, method, signal, titId, uri } = (payload: BOFavoritesApiPayloadType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendVideofuturRequest(uri, method, authenticationToken, XhrResponseType.Json, null, titId ? { titId } : null, headers, signal, false, eTag);
    }

    case REDUX_MSG_REQUEST_BO_PURCHASE_STATUS:
    case REDUX_MSG_REQUEST_BO_PURCHASE: {
      const { authent, headers, method, promocode, signal, uri, useBOV2Api, vtiId } = (payload: BOPurchaseApiPayloadType);
      const parameters: BO_API_PARAMETERS_TYPE = {
        promocode,
        vtiId,
      };
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendVideofuturRequest(
        uri,
        method,
        authenticationToken,
        useBOV2Api ? XhrResponseType.Json : XhrResponseType.Xml,
        !useBOV2Api ? parameters : null,
        useBOV2Api ? parameters : null,
        headers,
        signal,
      );
    }

    case REDUX_MSG_REQUEST_BO_PURCHASE_LIST: {
      const { authent, eTag, headers, method, signal, uri } = (payload: BOApiPayloadBaseType);
      const authenticationToken = authent ? ntgApiInstance.authenticationToken : null;
      return ntgApiInstance.sendVideofuturRequest(uri, method, authenticationToken, XhrResponseType.Json, null, null, headers, signal, false, eTag);
    }

    default:
      logError('No default case for emitter');
      return Promise.reject(
        new CustomNetworkError({
          message: 'No default case for emitter',
          status: -1,
        }),
      );
  }
};
/* eslint-enable complexity, max-lines-per-function */

export type RequestResponseMethodDefinitionType = (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType) => Promise<any>;

export type InternalActionDefinitionType = (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType) => void;
